import React from 'react';
import { useGetAdjudicationSubtypes } from 'modules/adjudication/api';
import { LoadingModal } from 'modules/adjudication/ui';
import { Provider } from '../context';
import Modal from './modal';
import { Screens } from '../transitions';

type Props = {
  onClose: () => void;
  open: boolean;
  initialScreen?: keyof typeof Screens;
};

const New: React.FC<Props> = ({ onClose, open, initialScreen }) => {
  const { isLoading } = useGetAdjudicationSubtypes();

  if (isLoading) return <LoadingModal onClose={onClose} open={open} />;

  return (
    <Provider onClose={onClose} open={open} initialScreen={initialScreen}>
      <Modal />
    </Provider>
  );
};

export default New;
