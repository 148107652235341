import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useErrorNotifier, useNotifier } from 'hooks';
import * as Notifications from 'state/notifications/entity';
import { GenericObject } from 'types';

import {
  fetchAtsIntegrationConfigSchema,
  fetchAtsIntegrationConfigs,
  fetchAtsIntegrationApiLogs,
  updateAtsIntegrationConfigs,
  validateAtsIntegration,
} from './actions';

type AtsIntegrationConfig = {
  resource_id: string;
  name: string;
  partner_id: string;
  tenant_config: Record<string, string | boolean>;
  slug: string;
  checkr_account_id: string;
  enabled: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  custom_field_definitions: string[];
  partner: string;
};

type ApiLogData =
  | {
      url: string[];
      request_body: Record<string, any> | string;
      response_body: Record<string, any> | string;
    }
  | {};

type AtsIntegrationApiLog = {
  kind: string;
  ip: string;
  url: string;
  http_method: string;
  body: Record<string, string | boolean>;
  integration: string;
  http_status_code: number;
  created_at: string;
  data: ApiLogData;
};

type AtsIntegrationApiLogResponse = {
  api_logs: AtsIntegrationApiLog[];
  meta: {
    count: number;
    page: number;
    pages: number;
    last: number;
  };
};

export const useAtsIntegrationConfigs = (enabled: boolean) => {
  const key: AnyQueryKey = ['integrationConfigs'];

  const request = () => fetchAtsIntegrationConfigs();

  const errorNotifier = useErrorNotifier();

  return useQuery<AtsIntegrationConfig, AnyQueryKey, Error>(key, request, {
    refetchOnWindowFocus: false,
    enabled,
    onError: (err: Error) => {
      // 404 is expected for users without the ATS integration
      errorNotifier(err, { ignoreCodes: 404 });
    },
  });
};

export type AtsConfigFieldSchema = {
  encrypted?: boolean;
  field_name: string;
  field_type: string;
  description: string;
  field_description: string;
};

export type AtsApiLogSchema = {
  kind: string;
  ip: string;
  url: string;
  http_method: string;
  body: Record<string, string | boolean>;
  integration: string;
  http_status_code: number;
  created_at: string;
  data: ApiLogData;
};

export type AtsApiLogResponseSchema = {
  api_logs: AtsApiLogSchema[];
  meta: {
    count: number;
    page: number;
    pages: number;
    last: number;
  };
};

export type AtsValidationSchema = {
  name: string;
  method: string;
  description: string;
};

export type AtsObjectSyncSchema = {
  name: string;
  method: string;
  description: string;
};

type AtsIntegrationConfigSchema = {
  config_schema: AtsConfigFieldSchema[];
  validation_schema: AtsValidationSchema[];
  object_sync_schema: AtsObjectSyncSchema[];
};

export const useAtsIntegrationConfigSchema = () => {
  const key: AnyQueryKey = ['integrationConfigSchema'];

  const request = () => fetchAtsIntegrationConfigSchema();

  return useQuery<AtsIntegrationConfigSchema, AnyQueryKey, Error>(
    key,
    request,
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useAtsIntegrationApiLogs = (filters = {}) => {
  const key: AnyQueryKey = ['integrationApiLogs', filters];

  const request = () => fetchAtsIntegrationApiLogs(filters);

  return useQuery<AtsIntegrationApiLogResponse, AnyQueryKey, Error>(
    key,
    request,
    {
      refetchOnWindowFocus: false,
    },
  );
};

type AtsIntegrationSuccess = {
  validation_id: string;
  message: string;
  success: true;
};

export type AtsIntegrationFailureReason = {
  field_id: string;
  message?: string | undefined | null;
};

export type AtsIntegrationValidationFailure = {
  validation_id: string;
  message: string;
  success: false;
  linked_fields?: AtsIntegrationFailureReason[] | null | undefined;
};

export type AtsIntegrationValidationResult = (
  | AtsIntegrationSuccess
  | AtsIntegrationValidationFailure
)[];

export const useAtsIntegrationValidation = (
  partnerSlug: string,
  validationIds: string[],
  onSuccess: (result: AtsIntegrationValidationResult) => void,
  onError: (error: Error, validationIds: string[]) => void,
) => {
  const key: AnyQueryKey = [
    'integrationValidation',
    partnerSlug,
    { validationIds },
  ];

  const request = () => validateAtsIntegration(partnerSlug, validationIds);
  const errorNotifier = useErrorNotifier();
  const notifier = useNotifier();

  return useQuery<AtsIntegrationValidationResult, AnyQueryKey, Error>(
    key,
    request,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: true,
      cacheTime: 0,
      onSuccess: (res: AtsIntegrationValidationResult) => {
        if (res[0].success) {
          notifier(
            Notifications.success({
              title: 'Success',
              message: res[0].message,
            }),
          );
        }
        onSuccess(res);
      },
      onError: (err: Error) => {
        errorNotifier(err);
        onError(err, validationIds);
      },
    },
  );
};

export const useUpdateAtsIntegrationConfigs = () => {
  const request = (params: { tenant_config: GenericObject }) => {
    return updateAtsIntegrationConfigs(params);
  };
  const notifier = useNotifier();
  const errorNotifier = useErrorNotifier();
  const [call, result] = useMutation(request, {
    onError: (err: any) => {
      errorNotifier(err);
    },
    onSuccess: (res: any) => {
      notifier(
        Notifications.success({
          title: 'Success',
          message: 'Settings saved successfully',
        }),
      );
    },
  });
  return {
    call,
    result,
  };
};
