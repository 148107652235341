import { adjudicationClient } from './client';

export type DecisionType = 'disqualified' | 'neutral' | 'engaged';

export type AdjudicationDecisionResponse = {
  decision: DecisionType;
};

export type VariantDecisionPayload = {
  variantId: string;
  decision: DecisionType;
};

export const setVariantDecision = (
  data: VariantDecisionPayload,
): Promise<AdjudicationDecisionResponse> => {
  const path = `/decisions/items/assess/assessment/variant/${data.variantId}`;
  return adjudicationClient.put(path, { decision: data.decision });
};
