import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledPendingSetupTag = styled(M.Tag)`
  background: ${colors.brandYellow2};
  margin-left: 8px !important;
  border-radius: 4px;

  font-size: 12px;
`;

export const StyledMultiSelectWrapper = styled.div`
  width: 100%;

  @media (min-width: 1400px) {
    max-width: 530px;
  }
  .mastodon-multi-select-with-sections-wrapper {
    width: 100% !important;
  }
  .cds--list-box--disabled {
    .cds--list-box__field {
      background-color: ${colors.uiGrey100} !important;
    }
  }
  & .cds--tag {
    background-color: ${colors.brandSlate2} !important;
    svg {
      fill: ${colors.brandSlate5} !important;
    }
    .cds--tag__label {
      color: ${colors.brandSlate7} !important;
      font-size: 12px !important;
    }
  }
  .cds--list-box__menu-item--active {
    background-color: ${colors.brandSlate2} !important;
    .cds--checkbox-label {
      color: ${colors.brandNavy2} !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .cds--checkbox-label[data-contained-checkbox-state='true']::before {
    background-color: ${colors.brandNavy2};
  }
  .cds--list-box[data-invalid] .cds--list-box__field {
    border-bottom: 1px solid ${colors.uiOrange600} !important;
  }
  .cds--combo-box[data-invalid] .cds--text-input:not(:focus) {
    outline: none !important;
  }
  .menu-item--disabled {
    .cds--checkbox-label::before {
      border-color: ${colors.brandSlate3} !important;
    }
  }
  .cds--list-box__field {
    input::placeholder {
      color: ${colors.brandSlate6} !important;
      font-style: italic;
      font-size: 14px;
    }
    .cds--tag--filter {
      background-color: ${colors.brandNavy3} !important;
      .cds--tag__label {
        color: ${colors.uiGrey0} !important;
      }
      & :hover {
        background-color: ${colors.brandNavy3} !important;
      }
    }
  }

  .disabled-button {
    cursor: not-allowed;

  .disabled-button:disabled {
    cursor: not-allowed;
  }
`;

export const StyledPendingContainer = styled.div`
  color: ${colors.brandSlate5};
  padding-top: 7px;
`;

export const StyledPendingIcon = styled(M.Icon)`
  padding-top: 5px;
`;

export const StyledSetupButton = styled(M.Button)`
  height: 40px;
  background: ${colors.uiGrey0} !important;
  color: ${colors.brandNavy4} !important;
  border-color: ${colors.brandNavy4} !important;

  @media (max-device-width: 530px) {
    height: 32px;
  }
`;

export const StyledDrugTestDropdown = styled(M.Dropdown)`
  width: 100%;

  @media screen and (min-width: 1400px) {
    max-width: 530px;
  }
`;
