import React, { ReactNode } from 'react';
import { M } from '@dashboard-experience/mastodon';

const acronyms = ['BAC', 'CCDC', 'DWAI', 'DWI', 'LGL', 'NSP', 'PB', 'PC'];
const MIN_LENGTH = 200;
const LOWER_CASE_THRESHOLD = 0.5;
const DATE_PATTERN = /\b\d{2}\/\d{2}\/\d{4}\b/g;
const DATE_RANGE_PATTERN = /\b\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}\b/g;

const breakPatterns = [
  /(;|;;)\s*/g, // Break on semicolons and remove trailing spaces
  /\|\s*([A-Z]+:)/g, // Pipe followed by space and a keyword with colon
  /\|([A-Z]+:)/g, // Pipe followed by a keyword with colon (without space)
  /(SENTENCE DATE:|START DATE:|END DATE:|LENGTH:|TYPE:|CONDITION:|COSTS:|FINE:|RESTITUTION:|BALANCE:|PROCESS TYPE:|SPECIAL CONDITION:)/g, // Keywords to break before
  /(?<!(SENTENCE DATE|START DATE|END DATE|TO):?\s*)\b\d{2}\/\d{2}\/\d{4}\b(?!-)/g, // Modified to avoid breaking first date in a range
];

const chargeSentenceReadability = (
  sentence: string | null | undefined | React.ReactNode,
  textReturn: Boolean = false,
): ReactNode | string => {
  // Add defensive check for non-string values
  if (!sentence || typeof sentence !== 'string') {
    return sentence || '';
  }

  if (sentence.length < MIN_LENGTH) return sentence;

  // Protect date ranges by replacing them with placeholders
  const dateRanges: string[] = [];
  // Now process the sentence with protected date ranges
  let processedSentence = sentence.replace(DATE_RANGE_PATTERN, match => {
    const placeholder = `__DATE_RANGE_${dateRanges.length}__`;
    dateRanges.push(match);
    return placeholder;
  });

  // Break on semicolons
  processedSentence = processedSentence.replace(breakPatterns[0], '\n');

  // Replace `| keyword:` (with or without space) with a line break before the keyword
  processedSentence = processedSentence.replace(breakPatterns[1], '\n$1');
  processedSentence = processedSentence.replace(breakPatterns[2], '\n$1');

  // Ensure keywords start on new lines
  processedSentence = processedSentence.replace(breakPatterns[3], '\n$1');

  // Keep valid dates intact when they follow specific keywords
  processedSentence = processedSentence.replace(breakPatterns[4], '\n$&');

  // Remove trailing pipes and spaces from the end of each line
  processedSentence = processedSentence.replace(/\s*\|?\s*\n/g, '\n');

  // Remove double newlines caused by multiple replacements
  processedSentence = processedSentence.replace(/\n{2,}/g, '\n').trim();

  // Restore date ranges
  dateRanges.forEach((dateRange, index) => {
    processedSentence = processedSentence.replace(
      `__DATE_RANGE_${index}__`,
      dateRange,
    );
  });

  // if sentence already has >50% lower case letters, we won't process to avoid making it worse
  const lower_case_letters = processedSentence.match(/[a-z]/g) || [];
  const all_letters = processedSentence.match(/[A-Za-z]/g) || [' '];
  if (lower_case_letters.length / all_letters.length > LOWER_CASE_THRESHOLD) {
    if (textReturn) return processedSentence;

    return convertToReactComponents(processedSentence);
  }

  // Store alphanumeric identifiers so we can preserve them
  const identifierPattern = /\b[A-Z]\d+(-[A-Z]\d+)?\b/g;
  const identifiers: { [key: string]: string } = {};
  let match;

  // Find all identifiers and store them
  while ((match = identifierPattern.exec(processedSentence)) !== null) {
    // eslint-disable-next-line prefer-destructuring
    identifiers[match[0].toLowerCase()] = match[0];
  }

  // each word to start with a capital letter, ignore words with numbers
  processedSentence = processedSentence.replace(
    /(^\D|\s\D)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase(),
  );

  // capitalize acronyms
  processedSentence = processedSentence.replace(/([^\d][a-zA-Z]+)/g, (_, m1) =>
    acronyms.includes(m1.toUpperCase().trim()) ? m1.toUpperCase() : m1,
  );

  // Restore original identifiers
  Object.keys(identifiers).forEach(lowercaseId => {
    const originalId = identifiers[lowercaseId];
    // Use word boundaries to avoid partial replacements
    const replaceRegex = new RegExp(`\\b${lowercaseId}\\b`, 'gi');
    processedSentence = processedSentence?.replace(replaceRegex, originalId);
  });

  if (textReturn) return processedSentence;

  return convertToReactComponents(processedSentence);
};

// Helper function to convert a string with newlines to React components
const convertToReactComponents = (text: string): ReactNode => {
  if (!text.includes('\n')) {
    return formatTextWithStrongDates(text);
  }

  const segments = text.split('\n');
  let currentGroupIndex = 0;
  let hasDateInCurrentSegment = false;

  return (
    <M.SeeMore
      className='charge-sentence'
      onTop
      moreText='Show all'
      lessText='Minimize'
      initExpanded
    >
      {segments.map((segment, index) => {
        // Check if this segment contains a date or date range
        const containsDate =
          DATE_PATTERN.test(segment) || DATE_RANGE_PATTERN.test(segment);

        // Reset lastIndex after using the regex test
        DATE_PATTERN.lastIndex = 0;
        DATE_RANGE_PATTERN.lastIndex = 0;

        // If we find a date, increment the group index for this and following segments
        if (containsDate) {
          currentGroupIndex += 1;
          hasDateInCurrentSegment = true;
        } else if (
          index > 0 &&
          (segments[index - 1].match(DATE_PATTERN) ||
            segments[index - 1].match(DATE_RANGE_PATTERN))
        ) {
          // This is the first segment after a date segment
          hasDateInCurrentSegment = false;
        }

        return (
          <p
            key={`segment-${segment
              .trim()
              .substring(0, 100)
              .replace(/\s+/g, '-')}`}
            className={`charge-segment date-group-${currentGroupIndex} ${
              hasDateInCurrentSegment ? 'date-segment' : 'related-info-segment'
            }`}
          >
            {formatTextWithStrongDates(segment || ' ')}
          </p>
        );
      })}
    </M.SeeMore>
  );
};

// Helper function to format text with dates wrapped in <strong> tags
const formatTextWithStrongDates = (text: string): ReactNode[] => {
  // First check for date ranges
  if (DATE_RANGE_PATTERN.test(text)) {
    // Process text with date ranges
    DATE_RANGE_PATTERN.lastIndex = 0;
    const segments: ReactNode[] = [];
    let lastIndex = 0;
    let match;

    // Find all date ranges and split the text
    while ((match = DATE_RANGE_PATTERN.exec(text)) !== null) {
      if (match.index > lastIndex) {
        // Process the text before the date range for individual dates
        const beforeText = text.substring(lastIndex, match.index);
        segments.push(...processIndividualDates(beforeText));
      }

      // Add the date range wrapped in span
      segments.push(
        <span
          key={`date-range-${match[0]}-${match.index}`}
          className='date-marker'
        >
          {match[0]}
        </span>,
      );

      lastIndex = match.index + match[0].length;
    }

    // Process any remaining text after the last date range
    if (lastIndex < text.length) {
      segments.push(...processIndividualDates(text.substring(lastIndex)));
    }

    return segments;
  }

  // If no date ranges, process for individual dates
  return processIndividualDates(text);
};

// Helper to process text for individual dates
const processIndividualDates = (text: string): ReactNode[] => {
  if (!DATE_PATTERN.test(text)) {
    return [text];
  }

  DATE_PATTERN.lastIndex = 0;
  const segments: ReactNode[] = [];
  let lastIndex = 0;
  let match;

  while ((match = DATE_PATTERN.exec(text)) !== null) {
    // Skip if this date is part of a date range (has a "-" after it)
    if (text.substring(match.index + match[0].length).startsWith('-')) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (match.index > lastIndex) {
      segments.push(text.substring(lastIndex, match.index));
    }

    segments.push(
      <span key={`date-${match[0]}-${match.index}`} className='date-marker'>
        {match[0]}
      </span>,
    );

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    segments.push(text.substring(lastIndex));
  }

  return segments;
};

export default chargeSentenceReadability;
