import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ReportStatuses, getResultOrStatus } from '@dashboard-experience/utils';
import { useGetReportSettings } from 'components/AccountSettings';
import { useUser } from 'context/CurrentUser';
import { AI_CHARGE_EXPLAINER } from 'Flags';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import React from 'react';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import { handleAiClick } from '../../../api/aiExplainr';

const TerroristWatchlist: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const currentUser = useUser();
  const { account } = report;
  const { terrorist_watchlist_search: terroristWatchlist } = report;

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  const { show_additional_charge_information: showChargeExplanationPanel } =
    useGetReportSettings(account);

  return terroristWatchlist ? (
    <M.Screening
      title='Global Watchlist Search'
      screening={terroristWatchlist}
      statusType={reportStatusType}
      id={SCREENING_TITLES.terrorist_watchlist_search.id}
    >
      {getResultOrStatus(terroristWatchlist) === ReportStatuses.CONSIDER &&
        terroristWatchlist?.records?.map((record: any) => {
          const handleClickWithExplainer = handleAiClick(
            record.state || '',
            record.county || '',
          );

          return (
            <M.Record
              key={record.id}
              record={record}
              currentUser={currentUser}
              report={report}
              reportStatusType={reportStatusType}
              chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
              displayChargeExplanation={showChargeExplanationPanel}
              chargesExplainer={
                aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
              }
            />
          );
        })}
    </M.Screening>
  ) : null;
};

export default TerroristWatchlist;
