import { M, colors } from '@dashboard-experience/mastodon';
import StyledComponent from 'styled-components';

export const FilterContainer = StyledComponent.div`
  padding: 16px;
  background-color: white;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const FilterGroup = StyledComponent.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DateFilterWrapper = StyledComponent.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 100%;

  .cds--date-picker-container,
  .bx--date-picker-container {
    width: 100%;

    .cds--date-picker__input,
    .bx--date-picker__input {
      width: 100%;
    }
  }

  .cds--date-picker {
    width: 100%;
  }
`;

export const ClearFiltersText = StyledComponent.span`
  align-items: center;
  color: ${colors.uiNavy600};
  cursor: pointer;
  display: flex;
  line-height: 1.25rem;
  font-weight: 700;
  padding-left: 16px;

  svg {
    margin-right: 0.25rem;
  }
`;

export const FilterLabel = StyledComponent.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${colors.uiGrey600};
`;

export const StyledContentSwitcher = StyledComponent(M.ContentSwitcher)`
  width: 100%;
  max-width: 25rem;
`;
