import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const Banner = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid ${colors.uiGrey200};
  background: linear-gradient(
    89deg,
    ${colors.uiGrey50} 0%,
    ${colors.uiAqua100} 100%
  );
  margin-bottom: 16px;
  a {
    cursor: pointer;
  }
  .bold {
    color: ${colors.uiTextPrimaryLight};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const Column = styled.div`
  display: flex;
  padding-right: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
