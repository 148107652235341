import { StyledContainer } from 'components/Account/Users/UsersStyledComponents';
import PackageScreeningItem from 'components/BetterOrderExperience/SelectPackageStep/PackageScreeningItem';
import { getDisplayPrice } from 'components/BetterOrderExperience/utils/PricingUtils';
import React from 'react';
import { Screening } from 'types';
import { PostPackageType } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../locales';
import {
  StyledSection,
  StyledFlex,
  StyledPackagePriceSpan,
} from '../OrderSummary.styles';
import { ScreeningProductType } from '../../AddonsStep.enums';

const SelectedPackageSection: React.FC<{
  selectedPackage: PostPackageType;
  packagePrice: number;
}> = ({ selectedPackage, packagePrice }) => {
  const { t } = useTranslation(namespace);

  return (
    <StyledSection>
      <StyledFlex>
        <h5>{selectedPackage.name}</h5>
        <StyledPackagePriceSpan>
          {selectedPackage.price === 0
            ? t('orderSummary.priceVaries')
            : getDisplayPrice(packagePrice)}
        </StyledPackagePriceSpan>
      </StyledFlex>
      {selectedPackage?.screenings?.map((screening, idx: number) => {
        const key = `screening-${idx}`;
        //  @ts-expect-error: TODO: Figure out PostPackageScreeningType after moving to utils; PostScreening should include `type`
        return screening.type !==
          ScreeningProductType.INTERNATIONAL_ADVERSE_MEDIA_SEARCH ? (
          <StyledContainer key={key}>
            <PackageScreeningItem
              screening={screening as Screening}
              showScreeningSettings={false}
              compact
            />
          </StyledContainer>
        ) : null;
      })}
    </StyledSection>
  );
};

export default SelectedPackageSection;
