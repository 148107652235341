/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { toastError } from 'actions';
import { useMemo } from 'react';
import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  createContinuousCheck,
  CreateContinuousCheckParams,
  createSubscription,
  CreateSubscriptionParams,
  deleteContinuousCheck,
  deleteSubscription,
  getContinuousChecks,
  getCsvExport,
  getEnrollments,
  getSubscriptionErrors,
  getSubscriptions,
  requestPerformBulkAction,
  updateContinuousCheck,
  UpdateContinuousCheckParams,
} from './actions';

export const useDeleteContinuousCheck = () => {
  const dispatch = useDispatch();
  const request = (params: { id: string }) => deleteContinuousCheck(params);

  const [deleteCall, deleteResult] = useMutation(request, {
    onError: data => {
      // @ts-ignore
      dispatch(toastError('Error:', data.response.data.error));
    },
  });

  return {
    deleteCall,
    deleteResult,
  };
};

export const useCreateContinuousCheck = () => {
  const dispatch = useDispatch();
  const request = (params: CreateContinuousCheckParams) =>
    createContinuousCheck(params);

  const [createCall, createResult] = useMutation(request, {
    onError: data => {
      // @ts-ignore
      dispatch(toastError('Error:', data.response.data.error));
    },
  });

  return {
    createCall,
    createResult,
  };
};

export const useUpdateContinuousCheck = () => {
  const dispatch = useDispatch();
  const request = (params: UpdateContinuousCheckParams) =>
    updateContinuousCheck(params);

  const [updateCall, updateResult] = useMutation(request, {
    onError: data => {
      // @ts-ignore
      dispatch(toastError('Error:', data.response.data.error));
    },
  });

  return {
    updateCall,
    updateResult,
  };
};

export const useGetContinuousChecks = (candidateId: string) => {
  const request = () => getContinuousChecks(candidateId);
  const key: AnyQueryKey = ['getContinuousChecks', { id: candidateId }];
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useGetSubscriptions = (candidateId: string) => {
  const request = () => getSubscriptions(candidateId);
  const key: AnyQueryKey = ['getSubscriptions', { id: candidateId }];
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useCreateSubscription = () => {
  const dispatch = useDispatch();
  const request = (params: CreateSubscriptionParams) =>
    createSubscription(params);

  const [createCall, createResult] = useMutation(request, {
    onError: data => {
      // @ts-ignore
      dispatch(toastError('Error:', data.response.data.error));
    },
  });

  return {
    createCall,
    createResult,
  };
};

export const useDeleteSubscription = () => {
  const dispatch = useDispatch();
  const request = (params: { id: string }) => deleteSubscription(params);

  const [deleteCall, deleteResult] = useMutation(request, {
    onError: data => {
      // @ts-ignore
      dispatch(toastError('Error:', data.response.data.error));
    },
  });

  return {
    deleteCall,
    deleteResult,
  };
};

export const usePerformBulkAction = () => {
  const request = (params: {
    account_id: string;
    action: string;
    file_storage_key: string;
    subscription_type: string;
    uploader_email: string;
  }) => requestPerformBulkAction(params);

  const [bulkActionCall, bulkActionResult] = useMutation(request);
  return {
    bulkActionCall,
    bulkActionResult,
  };
};

export const useGetSubscriptionErrors = (
  page: number,
  per_page: number,
  candidate_id: string | null,
  error_code: string | null,
) => {
  if (candidate_id === '') {
    candidate_id = null;
  }
  if (error_code === '') {
    error_code = null;
  }
  const request = () =>
    getSubscriptionErrors({ page, per_page, candidate_id, error_code });
  const key = `getSubscriptionErrors_${page}_${candidate_id}_${error_code}`;
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useGetCsvExport = (exportId: string) => {
  const request = () => getCsvExport({ exportId });
  const key = `getCsvExport${exportId}`;
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useGetEnrollments = (page: number, per_page: number) => {
  const request = () => getEnrollments({ page, per_page });
  const key = `getEnrollments_${page}`;
  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useGetActiveEnrollmentsCount = (candidateId: string) => {
  const { data: continuousChecks } = useGetContinuousChecks(candidateId);
  const { data: subscriptions } = useGetSubscriptions(candidateId);

  return useMemo(
    () => (subscriptions?.data?.length ?? 0) + (continuousChecks?.length ?? 0),
    [subscriptions, continuousChecks],
  );
};
