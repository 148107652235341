import { useMemo } from 'react';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastError } from 'actions';
import { GenericObject } from 'types';
import { getUserTags, updateTags } from './actions';

export const useUpdateTags = (reportId: string, tagsBackup: string[]) => {
  const request = (tags: string[]) => updateTags(reportId, tags, tagsBackup);

  const dispatch = useDispatch();

  const [updateTagsCall, updateTagsResult] = useMutation(request, {
    onMutate: tags => {
      // Optimistically update cache
      // cache key assumes the reportId was gotten using useReportsWithMultiMvr
      queryCache.setQueryData(
        ['reports/multi-mvr', { reportId }],
        (previousData: any) => ({ ...previousData, tags }),
      );

      return { originalTags: tagsBackup };
    },
    onError: (err: any, context: any) => {
      dispatch(toastError(`Error Updating Tags: ${err}`));

      // Revert optimistic update
      if (context?.originalTags) {
        queryCache.setQueryData(
          ['reports/multi-mvr', { reportId }],
          context.originalTags,
        );
      }
    },
  });

  return { updateTagsCall, updateTagsResult };
};

export const useGetUserTags = (reportId: string) => {
  const request = () => getUserTags(reportId);
  const key: AnyQueryKey = ['userTags', { reportId }];
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
  });

  const userTags = useMemo(
    () => result?.data || { tags: [] },
    [result?.data],
  ) as GenericObject;
  return userTags;
};
