import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { ExpandablePanel, KeyValueList } from '../styles';
import { FieldKeys, SectionProps } from '../types';

export const fieldKeys: FieldKeys[] = ['status'];

const InvitationStatus: React.FC<SectionProps> = ({
  invitation,
  fieldKeysToItems,
}) => {
  const { t } = useTranslation();
  const title = t(`${namespace}:report.overview.general-details.title`);

  if (!invitation) {
    return null;
  }

  const generalDetails = fieldKeysToItems(fieldKeys);

  return generalDetails.length > 0 ? (
    <ExpandablePanel
      data-pendo-id='general-invitation-details-panel'
      initialExpanded
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={generalDetails}
        data-testid='overview-general-invitation-details'
      />
    </ExpandablePanel>
  ) : null;
};

export default InvitationStatus;
