import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';

export const getIndustries = async () => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.get(`${CHECKR_API_BASE_URL}/industries`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

function useGetIndustries() {
  return useQuery(['industries'], () => getIndustries(), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}

export default useGetIndustries;
