import React from 'react';
import PackageScreeningItem from 'components/BetterOrderExperience/SelectPackageStep/PackageScreeningItem';
import { PostPackageType } from '@dashboard-experience/utils';
import type { AddonsT } from 'components/BetterOrderExperience/AddonsStep';
import { ScreeningProductType } from 'components/BetterOrderExperience/AddonsStep/AddonsStep.enums';
import {
  StyledContainer,
  StyledFlexContainer,
  StyledPackageName,
  StyledPackageTitle,
  StyledSelectedPackage,
} from './SelectedPackageInfoSection.styles';
import PackagePrice from '../ui/PackagePrice';

const SelectedPackageInfoSection: React.FC<{
  selectedPackage: PostPackageType;
  aliasPrice?: number;
  newCreatedPackageName?: string;
  viewDetails?: boolean;
  packageAlreadyIncludesAlias?: boolean;
}> = ({
  selectedPackage,
  aliasPrice = 0,
  newCreatedPackageName = '',
  viewDetails = false,
  packageAlreadyIncludesAlias = false,
}) => {
  const nameToDisplay = newCreatedPackageName || selectedPackage.name;

  return (
    <StyledFlexContainer>
      <StyledSelectedPackage>
        <StyledPackageName>
          <StyledPackageTitle>{nameToDisplay} </StyledPackageTitle>
        </StyledPackageName>
        {selectedPackage.screenings?.map((screening, idx: number) => {
          const key = `screening-${idx}`;
          return (
            <StyledContainer key={key}>
              {/* @ts-expect-error: TODO: Figure out PostPackageScreeningType after moving to utils; PostScreening should include `type` */}
              {screening.type !==
                ScreeningProductType.INTERNATIONAL_ADVERSE_MEDIA_SEARCH && (
                <PackageScreeningItem
                  screening={screening as AddonsT.PostPackageScreeningType}
                  showScreeningSettings={viewDetails}
                />
              )}
            </StyledContainer>
          );
        })}
      </StyledSelectedPackage>
      <PackagePrice
        price={
          packageAlreadyIncludesAlias
            ? selectedPackage.price - aliasPrice
            : selectedPackage.price
        }
      />
    </StyledFlexContainer>
  );
};

export default SelectedPackageInfoSection;
