import React, { useCallback } from 'react';
import styled from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import { SegmentsVirtualTableList } from 'components/Segments';
import { ParentContext, Segment } from '../../api/segments';

const TableContainer = styled.div`
  margin-top: 1rem;
  height: 300px;
  overflow: auto;
`;

type Props = {
  filteredSegments: Segment[];
  selectedSegments: string[];
  updateSelectedSegments: Function;
  initialSelectedSegments: string[];
  updateUnselectedSegments: Function;
  parentContext: ParentContext;
};

const SegmentsTable: React.FC<Props> = ({
  filteredSegments,
  selectedSegments,
  updateSelectedSegments,
  initialSelectedSegments,
  updateUnselectedSegments,
  parentContext,
}) => {
  const containerRef = React.useRef<HTMLInputElement>(null);

  const onSelect = useCallback(
    (segmentId: string) => {
      if (initialSelectedSegments.includes(segmentId)) {
        updateUnselectedSegments(segmentId);
      }
      updateSelectedSegments(segmentId);
    },
    [initialSelectedSegments, updateSelectedSegments, updateUnselectedSegments],
  );

  // Define rowRenderer outside of JSX to avoid ESLint react/jsx-no-bind warning
  const rowRenderer = useCallback(
    ({ key, style }) => {
      // Create a virtual object that matches what SegmentsVirtualTableList expects
      const virtual = {
        style,
        items: filteredSegments,
      };

      return (
        <div key={key}>
          <SegmentsVirtualTableList
            virtual={virtual}
            onSelect={onSelect}
            selectedSegments={selectedSegments}
            parentContext={parentContext}
          />
        </div>
      );
    },
    [filteredSegments, onSelect, selectedSegments, parentContext],
  );

  return (
    <TableContainer ref={containerRef}>
      {containerRef.current && (
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowCount={1} // Only one row for the entire table
              rowHeight={height} // Full height for the table
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </TableContainer>
  );
};

export default SegmentsTable;
