/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
/* eslint-disable no-shadow */
import React, { useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { AutopayIndicator, LoadingScreen } from 'components';
import { debounce } from 'lodash';
import { PaymentProfilesTable } from 'components/PaymentProfiles';
import {
  HeadingButtonsContainer,
  HeadingContainer,
  StyledFormHeading,
} from 'components/PaymentProfiles/PaymentProfileStyledComponents';
import {
  useGetNodeWithBillingPrefs,
  usePutBillableNode,
  usePostPaymentMethodAssignment,
  usePutPaymentMethodAssignment,
} from 'api/billing';
import { scrollToTop } from 'utils';
import { useFetchNodes } from 'api/nodes';
import { useUser } from 'context/CurrentUser';
import NodeBillingForm from 'components/PaymentProfiles/NodeBillingForm';
import {
  parseNodeFilter,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { BillingEntity } from '../../types/Billing';
import BillingCustomerContext from './context';
import NodeBillingDisablePopup from '../../components/PaymentProfiles/NodeBillingDisablePopup';
import PaymentMethod from './PaymentMethod';

const tableHeaders = [
  {
    key: 'id',
    header: 'id',
    name: 'customId',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'name',
    header: 'name',
    name: 'nodeName',
    className: 'left',
    tooltip: false,
  },
  {
    key: 'paymentMethod',
    header: 'paymentMethod',
    name: 'paymentMethod',
    className: 'left',
    tooltip: false,
  },
  {
    key: 'autopay',
    header: 'autopay',
    name: 'autopay',
    className: 'left',
    tooltip: false,
  },
  {
    key: 'billable',
    header: 'billable',
    name: 'billable',
    className: 'left',
    tooltip: true,
  },
  {
    key: 'editButton',
    header: 'editButton',
    name: '',
    className: 'left',
    tooltip: false,
  },
];

const NodeBillingContainer: React.FC = () => {
  const { customerData, refetch: refetchBillingCustomer } = useContext(
    BillingCustomerContext,
  );
  const { t } = useTranslation();
  const { account = {} } = useUser();
  const { call: postPaymentMethodAssignment } =
    usePostPaymentMethodAssignment();
  const { call: putPaymentMethodAssignment } = usePutPaymentMethodAssignment();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);

  const handlePaymentMethodAssignment = useCallback(
    (billingEntitySlug: string, paymentMethodAssignments: any[]) => {
      if (!selectedPaymentMethod || !billingEntitySlug) return;

      const isEditing = !!paymentMethodAssignments?.length;
      const existingAssignment = paymentMethodAssignments?.find(
        (assignment: any) =>
          assignment?.payment_method?.id === selectedPaymentMethod.id,
      );

      // New node being added with a payment method (not "None")
      // OR editing node, selected a new payment method not in assignments
      if (
        (!isEditing && selectedPaymentMethod.id !== 'none') ||
        (isEditing &&
          selectedPaymentMethod.id !== 'none' &&
          !existingAssignment)
      ) {
        postPaymentMethodAssignment({
          account_id: account.id,
          payment_method_id: selectedPaymentMethod.id,
          billing_entity_slug: billingEntitySlug,
          is_default_payment: true,
        });
      }

      // Editing node, selected an existing payment method from assignments
      else if (
        isEditing &&
        selectedPaymentMethod.id !== 'none' &&
        existingAssignment
      ) {
        putPaymentMethodAssignment({
          account_id: account.id,
          payment_method_assignment_id: existingAssignment.id,
          is_default_payment: true,
        });
      }

      // Selected "None" option but had an initial payment method
      else if (
        isEditing &&
        selectedPaymentMethod.id === 'none' &&
        existingAssignment
      ) {
        putPaymentMethodAssignment({
          account_id: account.id,
          payment_method_assignment_id: existingAssignment.id,
          is_default_payment: false,
        });
      }
    },
    [
      selectedPaymentMethod,
      account.id,
      postPaymentMethodAssignment,
      putPaymentMethodAssignment,
    ],
  );

  const formatTableElements = (
    nodes: any[],
    billingEntities: any,
  ): BillingEntity[] => {
    return nodes.map((node: any) => {
      const billingEntity = billingEntities?.find(
        (entity: any) => entity.id === node.custom_id,
      );
      const defaultPaymentMethod =
        billingEntity?.payment_method_assignments?.find(
          (paymentMethod: any) => paymentMethod.is_default_payment,
        )?.payment_method;
      const pma = billingEntity?.payment_method_assignments || [];

      return {
        id: node.custom_id,
        name: node.name,
        email: node.billing_info?.primary_email || '',
        parent_custom_id: node.parent_custom_id,
        tier: node.tier,
        address: {
          address_line_1: node.billing_info.billing_address.address_line_1,
          address_line_2:
            node.billing_info.billing_address.address_line_2 || '',
          city: node.billing_info.billing_address.city,
          region: node.billing_info.billing_address.region,
          country: node.billing_info.billing_address.country,
          postal_code: node.billing_info.billing_address.postal_code,
        },
        billable: node.billable,
        paymentMethod: (
          <PaymentMethod
            brand={defaultPaymentMethod?.brand}
            last4={defaultPaymentMethod?.last4}
            bankName={defaultPaymentMethod?.bank_name}
          />
        ),
        payment_method_assignments: pma,
        autopay: <AutopayIndicator enabled={node.autopay_enabled} />,
      };
    });
  };

  const defaultNode = {
    id: '',
    custom_id: '',
    name: '',
    parent_custom_id: '',
    tier: '',
  };

  // Mark filter dirty immediately when changed
  // but debounce updating it to prevent over fetching
  const [nodeFilterIsDirty, setNodeFilterIsDirty] = useState(false);
  const [nodeFilter, setNodeFilter] = useState('');
  const [selectedNode, setSelectedNode] = useState(defaultNode);

  const { data: { data: nodes = [] } = {}, isLoading: nodeIsLoading } =
    useFetchNodes({
      account,
      nodeName: parseNodeFilter(nodeFilter).name,
    });

  const {
    data: { data: billableNodesData = [] } = {},
    isLoading: billableNodesLoading,
    error: billableNodesError,
    refetch,
  } = useGetNodeWithBillingPrefs();

  const refetchBillableNodes = useCallback(refetch, [refetch]);

  const nodeSelected = useCallback(
    value => {
      setSelectedNode(value);
    },
    [setSelectedNode],
  );

  const debouncedSetNodeFilter = useDebouncedCallback(
    (newFilter: string) => {
      setNodeFilter(newFilter);
      setNodeFilterIsDirty(false);
    },
    350,
    [debounce, setNodeFilter, setNodeFilterIsDirty],
  );

  const onNodeFilterChange = useCallback(
    newFilter => {
      setNodeFilterIsDirty(true);
      debouncedSetNodeFilter(newFilter);
    },
    [debouncedSetNodeFilter, setNodeFilterIsDirty],
  );

  const newPaymentProfile = {
    id: selectedNode?.custom_id,
    name: selectedNode?.name,
    email: '',
    address: {
      address_line_1: '',
      address_line_2: '',
      country: '',
      city: '',
      region: '',
      postal_code: '',
    },
  };

  const { call: putBillableNode } = usePutBillableNode();

  // FOR BUTTON
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
    scrollToTop();
  }, [setModalOpen]);

  const hideModal = useCallback(() => {
    setModalOpen(false);
    setSelectedNode(defaultNode);
  }, [defaultNode]);

  const submit = useCallback(
    (params, isDisable) => {
      let payload;
      if (!isDisable) {
        payload = {
          billable: true,
          custom_id: params.id,
          billing_address: {
            address_line_1: params.address_line_1,
            address_line_2: params.address_line_2,
            city: params.city,
            region: params.region,
            country: params.country,
            postal_code: params.postal_code,
          },
          primary_email: params.email,
        };
      } else {
        payload = {
          billable: false,
          custom_id: params.id,
        };
      }

      putBillableNode(payload)
        .then(() => {
          if (params.id) {
            // Add 1 second delay before handling payment method assignment
            return new Promise<void>(resolve => {
              setTimeout(() => {
                handlePaymentMethodAssignment(
                  params.id,
                  customerData?.billing_entities || [],
                );
                resolve();
              }, 1000);
            });
          }
          return Promise.resolve();
        })
        .then(() => {
          refetchBillableNodes();
          refetchBillingCustomer();
        });

      hideModal();
    },
    [
      putBillableNode,
      hideModal,
      refetchBillableNodes,
      handlePaymentMethodAssignment,
      customerData?.billing_entities,
    ],
  );

  if (!billableNodesData || billableNodesLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <HeadingContainer data-testid='node-billing-heading-container'>
        <StyledFormHeading data-testid='node-billing-heading-text'>
          {t('headings.nodeBilling')}
        </StyledFormHeading>
        <HeadingButtonsContainer>
          <M.TooltipButton
            data-testid='add-billable-node-button'
            kind='primary'
            onClick={showModal}
            label={t('tooltips.addBillableNodeButtonEnabled')}
          >
            <M.Icon icon='Add' />
            {t('payment_profile.add_billable_node_button')}
          </M.TooltipButton>
        </HeadingButtonsContainer>
      </HeadingContainer>
      <M.TableContainer style={{ overflow: 'auto' }}>
        <PaymentProfilesTable
          isLoading={billableNodesLoading}
          error={billableNodesError}
          submit={submit}
          tableHeaders={tableHeaders}
          records={formatTableElements(
            billableNodesData,
            customerData?.billing_entities,
          )}
          EditForm={NodeBillingForm}
          NodeBillingDisablePopup={NodeBillingDisablePopup}
        />
      </M.TableContainer>
      <NodeBillingForm
        data-testid='add-node-billing-form'
        paymentProfile={newPaymentProfile}
        submit={submit}
        modalOpen={modalOpen}
        hideModal={hideModal}
        isLoading={billableNodesLoading}
        nodes={nodes}
        selectedNode={selectedNode}
        nodeSelected={nodeSelected}
        onNodeFilterChange={onNodeFilterChange}
        nodeIsLoading={nodeIsLoading}
        nodeFilterIsDirty={nodeFilterIsDirty}
        onPaymentMethodSelected={setSelectedPaymentMethod}
      />
    </div>
  );
};

export default NodeBillingContainer;
