import React from 'react';

import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import { usePinnedFields, usePinnedFieldsFlag } from 'providers/Candidate';
import withReportContentContainer from '../report-content-container';
import { ExpandablePanel } from '../styles';
import { SectionProps } from '../types';
import DraggableList from './draggable-list';

const StyledExpandablePanel = styled.div`
  #mastodon && {
    .mastodon-expandable-panel
      .expandable-panel-content
      .expandable-panel-content-body {
      padding: 0.5rem 1.25rem 0.75rem 0.25rem;
    }
  }
`;

// TODO: see if this is a mastodon component
const StyledTooltipWrapper = styled.div`
  #mastodon && {
    .cds--popover--caret {
      --cds-popover-offset: 0.25rem;
    }
    .cds--popover-caret {
      visibility: hidden;
    }
    .cds--popover-content {
      max-width: 200px;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PinnedFields: React.FC<SectionProps> = ({ fieldKeysToItems }) => {
  const fieldKeys = usePinnedFields();
  const items = fieldKeysToItems(fieldKeys, true);

  const { t } = useTranslation();
  const titleString = t(`${namespace}:report.overview.pinned-fields.title`);

  const title = (
    <FlexRow>
      <span style={{ paddingRight: '0.5em' }}>{titleString}</span>
      <StyledTooltipWrapper>
        <M.TooltipDefinition
          align='right'
          highlighted={false}
          definition='These are only visible to you across all reports.'
        >
          <M.Icon icon='Information' />
        </M.TooltipDefinition>
      </StyledTooltipWrapper>
    </FlexRow>
  );
  const pinnedFieldsFlagEnabled = usePinnedFieldsFlag();
  return items.length > 0 && pinnedFieldsFlagEnabled ? (
    <StyledExpandablePanel>
      <ExpandablePanel
        initialExpanded
        data-pendo-id='pinned-fields-panel'
        title={title}
        style={{ marginTop: '2rem' }}
      >
        <DraggableList pinnedFields={items} />
      </ExpandablePanel>
    </StyledExpandablePanel>
  ) : null;
};

export default withReportContentContainer(PinnedFields);
