import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { PromoCodeBannerContainer } from './PromoCodeBanner.styles';

type PromoCodeBannerProps = {
  type?: 'success' | 'error';
  text?: string;
};

const PromoCodeBanner = ({
  type = 'success',
  text = '',
}: PromoCodeBannerProps) => {
  return (
    <PromoCodeBannerContainer data-testid='promo-code-banner' type={type}>
      <M.Icon
        data-testid='promo-code-banner-icon'
        icon={type === 'error' ? 'TagNone' : 'Tag'}
        color={type === 'error' ? colors.uiOrange600 : colors.uiGreen600}
      />
      {text}
    </PromoCodeBannerContainer>
  );
};

export default PromoCodeBanner;
