import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { headerSectionStyles } from '../styles';

export const TabsSection = styled.section`
  flex-grow: 1;
  width: 100%;

  .cds--tab--list,
  .cds--tabs {
    overflow-x: visible;
  }

  div[data-testid='candidate-tabs-header'] {
    display: flex;
    position: relative;
    background: ${colors.uiGrey0};
    ${headerSectionStyles}
  }

  .closed {
    margin-right: 84px;
  }
  .open {
    margin-right: 34px;
  }
`;

export const TabsList = styled(M.TabList)`
  box-shadow: none !important;
`;

export const TabPanels = styled(M.TabPanels)`
  padding: 0 !important;
`;

export const TabPanel = styled(M.TabPanel)`
  padding: 0 !important;
`;

export const ButtonContainer = styled.div<{ showOverview: boolean }>`
  position: absolute;
  z-index: 1;
  right: ${({ showOverview }) => (showOverview ? '-18px' : '32px')};
  transition: left 0.2s ease;
  top: 25px;
  transform: translateY(-50%);

  #mastodon && {
    .mastodon-tooltip-button {
      padding: 0.5rem;
    }
  }
`;
