import React, { useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useAtsIntegrationApiLogs } from 'api/atsIntegration/hooks';
import LogsTable from './LogsTable/LogsTable';
import LogsFilters from './LogsFilters/LogsFilters';
import { SectionContainer, SectionHeader } from '../../shared';
import PaginationControls from './PaginationControls';

const LogsSection: React.FC = () => {
  const [queryFilters, setQueryFilters] = useState({});
  const { data, isLoading } = useAtsIntegrationApiLogs(queryFilters);

  return (
    <SectionContainer data-testid='ats-logs-container'>
      <SectionHeader sectionKey='logs' header='Logs' />
      <LogsFilters setQueryFilters={setQueryFilters} />
      <span // Log count text
        data-testid='logs-results-count'
        style={{
          color: '#6B7280',
          fontSize: '0.9rem',
          marginLeft: 'auto',
          alignSelf: 'flex-end',
        }}
      >
        {data?.meta?.count?.toLocaleString()} results
      </span>
      {isLoading ? (
        <M.LoadingInline description='Loading logs...' />
      ) : (
        <LogsTable
          logs={data?.api_logs}
          queryFilters={queryFilters}
          setQueryFilters={setQueryFilters}
        />
      )}
      {data && (
        <PaginationControls
          logsMetadata={data?.meta}
          queryFilters={queryFilters}
          setQueryFilters={setQueryFilters}
        />
      )}
    </SectionContainer>
  );
};

export default LogsSection;
