import React, { useCallback } from 'react';
import { capitalize } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import { TLogsFilters } from '../../types';
import { FilterGroup, StyledContentSwitcher } from '../styles';

type DirectionSwitchItem = {
  index: number;
  name: string;
  text: string;
};

interface DirectionFilterProps {
  direction: TLogsFilters['direction'];
  setFilters: React.Dispatch<React.SetStateAction<TLogsFilters>>;
}

const DirectionFilter: React.FC<DirectionFilterProps> = ({
  direction,
  setFilters,
}) => {
  const handleDirectionChange = useCallback(
    (switchSelection: DirectionSwitchItem) => {
      setFilters(prev => ({
        ...prev,
        direction: switchSelection.name as TLogsFilters['direction'],
      }));
    },
    [setFilters],
  );

  const directionTypes: string[] = ['inbound', 'outbound', 'all'];

  return (
    <FilterGroup>
      <StyledContentSwitcher
        selectedIndex={directionTypes.findIndex(directionType =>
          direction ? directionType === direction : directionType === 'all',
        )}
        onChange={handleDirectionChange}
        data-testid='switch_direction'
      >
        {directionTypes.map(name => {
          return (
            <M.Switch
              key={name}
              name={name}
              text={capitalize(name)}
              data-testid={`switch_direction_${name}`}
            />
          );
        })}
      </StyledContentSwitcher>
    </FilterGroup>
  );
};

export default DirectionFilter;
