const preAAtransitionStates = {
  MobilitySubmit: {
    transitions: {},
  },
  Subtypes: {
    transitions: {
      incomplete_screenings: 'IncompleteScreenings',
      submit: 'Submit',
    },
  },
  IncompleteScreenings: {
    transitions: {
      // TODO: add previous transition?
      next: 'Submit',
    },
  },
  Submit: {
    transitions: {},
  },
  Progressive: {
    transitions: {
      next: 'Submit',
      engage: 'Submit',
    },
  },
};

export default preAAtransitionStates;
