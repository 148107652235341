import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import withReportContentContainer from '../report-content-container';
import { KeyValueList, ExpandablePanel } from '../styles';
import { FieldKeys, SectionProps } from '../types';

export const fieldKeys: FieldKeys[] = [
  'source',
  'work_locations',
  'package',
  'created_at',
  'completed_at',
  'upgraded_at',
  'revised_at',
  'estimated_completion',
  'estimated_updated',
  'turnaround_time',
];

export const OrderInformation: React.FC<SectionProps> = ({
  fieldKeysToItems,
}) => {
  const { t } = useTranslation();
  const title = t(`${namespace}:report.overview.order-information.title`);

  const items = fieldKeysToItems(fieldKeys);

  return items.length > 0 ? (
    <ExpandablePanel
      initialExpanded
      data-pendo-id='report-order-information-panel'
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList items={items} data-testid='overview-order-information' />
    </ExpandablePanel>
  ) : null;
};

export default withReportContentContainer(OrderInformation);
