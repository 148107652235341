import { GenericObject } from 'types';
import { AtsIntegrationAPI as Client } from 'utils/APIClient';
import querystring from 'querystring';

export const fetchAtsIntegrationConfigs = () => {
  return Client.get(`/accounts/tenant_config`);
};

export const fetchAtsIntegrationConfigSchema = () => {
  return Client.get(`/accounts/tenant_schema`);
};

export const fetchAtsIntegrationApiLogs = (filters = {}) => {
  return Client.get(`/api_logs`, {
    headers: {
      Accept: 'application/json',
    },
    params: filters,
  });
};

export const updateAtsIntegrationConfigs = (params: GenericObject) => {
  return Client.put('/accounts/tenant_config', params);
};

export const validateAtsIntegration = (
  partnerSlug: string,
  validationIds: string[],
) => {
  const params: string =
    validationIds.length === 1
      ? `?${querystring.encode({ validation_id: validationIds[0] })}`
      : '';
  return Client.get(`/${partnerSlug}/validations${params}`);
};
