import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { TLogsFilters } from '../../types';
import { FilterGroup, FilterLabel } from '../styles';

interface StatusFilterProps {
  status: TLogsFilters['status'];
  setFilters: React.Dispatch<React.SetStateAction<TLogsFilters>>;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ status, setFilters }) => {
  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setFilters(prev => ({
        ...prev,
        status: value as TLogsFilters['status'],
      }));
    },
    [setFilters],
  );

  return (
    <FilterGroup>
      <FilterLabel>API Status</FilterLabel>
      <M.Select
        value={status || 'all'}
        onChange={handleStatusChange}
        data-testid='ats-logs-status-select'
        noLabel
      >
        <option value='all'>All</option>
        <option value='success'>Success</option>
        <option value='failure'>Failure</option>
      </M.Select>
    </FilterGroup>
  );
};

export default StatusFilter;
