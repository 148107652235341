import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'components/AddScreenings/shared/ModalComponents';
import { useSignupPageContext } from 'pages/Signup/context';
import {
  SIGNUP_EVENT_NAMES,
  FOX_SIGNUP_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ALIAS_IN_SIGNUP, EMAIL_VERIFICATION_SIGNUP } from 'Flags';
import {
  buildPostBodyWithAddOns,
  mapPackage,
} from 'components/AddScreenings/shared/utils';
import { PostPackageType } from '@dashboard-experience/utils';
import { AxiosError } from 'axios';
import { Modal, Footer } from './ui/Modal';
import {
  Container,
  TitleContainer,
  PageSubtitleContainer,
  PageSubtitle,
  PassthroughFeesDisclaimer,
} from './styles';
import { CandidateInfoSection } from './ui/CandidateInfo';
import { updateSessionStorage } from './utils/sessionStorage';
import { isSelectedPackageInternational } from './utils/package';
import { countCandidates } from './utils/candidates';
import useQueueInvites from './hooks/useQueueInvites';
import BASE_PACKAGES from './constants/basePackages';
import VerifyEmailAddress from './ui/VerifyEmailAddress';
import OrderProcessing from './ui/OrderProcessing';
import Errors from './ui/Error';
import {
  PromoCodeResType,
  useRedeemPromotionCode,
  useValidatePromotionCode,
} from '../../api/promotionCodes';
import useGetUser from './hooks/useGetUser';
import useGetPackages from './hooks/useGetPackages';

const CandidateInfoPage = () => {
  const { t } = useTranslation('signup');
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const [error, setError] = useState<any>(null);
  const modalRef = useRef(null);
  const { data: user } = useGetUser();
  const accountId: string = user?.account_id;
  const aliasEnabled = useFlag(ALIAS_IN_SIGNUP)?.variantKey === 'on' || false;
  const enableEmailVerification =
    useFlag(EMAIL_VERIFICATION_SIGNUP)?.variantKey === 'on';
  const { data: packages } = useGetPackages();
  const [promoCodeValid, setPromoCodeValid] = useState<boolean | undefined>(
    undefined,
  );

  const {
    addOns,
    candidates,
    setCandidates,
    selectedPackage,
    workLocation,
    setWorkLocation,
    alias,
    promoCode,
    setPromoCode,
  } = useSignupPageContext();
  const isInternational = isSelectedPackageInternational(selectedPackage);

  const { isLoading, queueInvites } = useQueueInvites({
    alias,
    addOns,
    candidates,
    newPackageName: `Custom ${BASE_PACKAGES[selectedPackage].name}`,
    selectedPackage,
    workLocation,
    setError,
    promoCode,
  });
  const basePackage = packages?.find(
    (p: PostPackageType) => p.slug === selectedPackage,
  );

  const packageWithAddOnsOrAlias = buildPostBodyWithAddOns({
    basePackage: {
      ...mapPackage({ ...basePackage }),
      aliases_enabled: alias[selectedPackage] === 'true' ? 'on' : 'off',
    },
    addedScreeningTypes: addOns[selectedPackage],
    additionalProperties: {},
    packageName: BASE_PACKAGES[selectedPackage].name,
    setSlug: false,
    isPrivate: false,
    origin: 'customer_via_signup',
    partner_origin_id: '',
  });

  const packageForSelection =
    addOns[selectedPackage].length > 0 || alias[selectedPackage] === 'true'
      ? packageWithAddOnsOrAlias
      : basePackage;

  const promoCodeValidation = useValidatePromotionCode(
    {
      account_id: accountId ?? '',
      package_attributes: packageForSelection ?? ({} as PostPackageType),
      promo_code: promoCode ?? '',
    },
    (data: { promotional_campaign: PromoCodeResType }) => {
      if (
        data?.promotional_campaign?.errors &&
        data.promotional_campaign.errors.length > 0
      ) {
        trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PROMO_CODE_INVALID, {
          'Error Triggered': data.promotional_campaign.errors[0],
          'Promo Code Attempted': data.promotional_campaign.promo_code,
        });
        setPromoCodeValid(false);
      } else {
        trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PROMO_CODE_VALID, {
          'Promo Code': data.promotional_campaign.promo_code,
        });
        setPromoCodeValid(true);
      }
    },
    (_: AxiosError<{ promotional_campaign: PromoCodeResType }>) => {
      setPromoCodeValid(false);

      // TODO: Figure out why toasts don't work in Signup
      // dispatch(
      //   toastError(
      //     t('components.OrderSummary.promoCode.error'),
      //     error.response?.data?.promotional_campaign?.errors?.[0],
      //   ),
      // );
    },
  );

  const redeemParams = {
    account_id: accountId ?? '',
    package_attributes: packageForSelection ?? ({} as PostPackageType),
    promo_code: promoCode ?? '',
  };

  const { call: redeemPromoCode } = useRedeemPromotionCode(
    redeemParams,
    (data: { promotional_campaign: PromoCodeResType }) => {
      queueInvites();
      setPromoCode('');
    },
    (error: Error) => {
      setError({ error, source: 'code-redeem' });
      promoCodeValidation.reset();
    },
  );

  const handleContinue = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_INVITE_CANDIDATES_STEP_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    if (promoCode && promoCode.length > 0 && promoCodeValid) {
      redeemPromoCode();
    } else {
      queueInvites();
    }
  }, [trackEvent, promoCode, promoCodeValid, redeemPromoCode, queueInvites]);

  const handleBack = useCallback(() => {
    if (isInternational) {
      history.push({ pathname: '/signup/packages', search: location.search });
      trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_BACK_BUTTON_CLICKED, {
        Modal: 'Packages',
      });
    } else {
      history.push({ pathname: '/signup/add-ons', search: location.search });
      trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_BACK_BUTTON_CLICKED, {
        Modal: 'Add-ons',
      });
    }
  }, [history, isInternational, location.search, trackEvent]);

  const containsInvalidEmails = candidates.some(
    candidate => !validateEmail(candidate.email),
  );
  const hasNoCandidates = countCandidates(candidates) === 0;

  const continueButtonDisabled =
    (aliasEnabled && alias[selectedPackage] === null) ||
    containsInvalidEmails ||
    hasNoCandidates ||
    (isInternational && !workLocation.country) ||
    (!isInternational && !workLocation.state);

  useEffect(() => {
    updateSessionStorage('candidates', candidates);
  }, [addOns, candidates]);

  useEffect(() => {
    updateSessionStorage('workLocation', workLocation);
  }, [workLocation]);

  useEffect(() => {
    updateSessionStorage('alias', alias);
  }, [alias]);

  useEffect(() => {
    trackEvent(
      SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_INVITE_CANDIDATES_STEP_VIEWED,
      {
        'Page Type': 'checkr',
        'Self Serve Signup Version': 'FOX Signup v2',
      },
    );
  }, [trackEvent]);

  if (isLoading) {
    if (enableEmailVerification) {
      return <VerifyEmailAddress orderSubmitted />;
    }
    return <OrderProcessing />;
  }

  return (
    <Modal
      footer={
        <Footer
          primaryBtnText={t(`footer.submit`)}
          secondaryBtnText={t(`footer.secondary`)}
          primaryButtonProps={{
            onClick: handleContinue,
            disabled: continueButtonDisabled,
          }}
          secondaryOnClick={handleBack}
        />
      }
      stepNumber={3}
      data-dd-privacy='allow'
    >
      <Container ref={modalRef} data-testid='candidate-info-page'>
        <TitleContainer>
          <h3 className='mb-0'>{t('pages.candidateInfoPage.title')}</h3>
          <PageSubtitleContainer>
            <PageSubtitle>{t(`pages.candidateInfoPage.subtitle`)}</PageSubtitle>
          </PageSubtitleContainer>
        </TitleContainer>
        <Errors modalRef={modalRef} error={error} />
        <CandidateInfoSection
          addOns={addOns[selectedPackage]}
          isInternational={isInternational}
          candidates={candidates}
          setCandidates={setCandidates}
          selectedPackage={selectedPackage}
          workLocation={workLocation}
          setWorkLocation={setWorkLocation}
          alias={alias}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          promoCodeValid={promoCodeValid}
          promoCodeValidation={promoCodeValidation}
        />
        {aliasEnabled && !isInternational && (
          <PassthroughFeesDisclaimer>
            {t(`pages.candidateInfoPage.passthroughFees`)}
          </PassthroughFeesDisclaimer>
        )}
      </Container>
    </Modal>
  );
};

export default CandidateInfoPage;
