import React, { useCallback, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import XClose from 'components/AddScreenings/shared/svg/XClose.svg';
import { colors } from '@dashboard-experience/mastodon';
import STATUS from '../enums/Status';
import AddonSetup from './AddonSetup';
import AddonSetupCompact from './AddonSetupCompact';
import SetupType from '../enums/AddonSetupType';
import SkipForNowModal from './SkipForNowModal';

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: ${colors.uiGrey100};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const Title = styled.div`
  color: ${colors.uiTextPrimaryLight};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

const Description = styled.div`
  color: ${colors.uiTextSecondaryLight};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  overflow-wrap: break-word;
`;

const BoldDescription = styled.span`
  font-weight: 700;
`;

const AddonSetupList = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-direction: row;
  @media (max-width: 970px) {
    flex-wrap: wrap;
  }
`;

const XCloseButton = styled.img`
  width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export type PendingAddonData = {
  addon_type: string;
  completed_at: string;
  started_at: string;
  status: STATUS;
};

type AddonSetupContainerProps = {
  pendingAddons: PendingAddonData[];
  drugSetupStatus: STATUS;
  creditSetupStatus: STATUS;
  occHealthSetupStatus: STATUS;
  handleDrugSetupClick: Function;
  handleCreditSetupClick: Function;
  handleOccHealthSetupClick: Function;
  handleCloseClick: Function;
};

const formatDateToMMDDYYYY = (inputDate: string) => {
  const formattedDate = moment(inputDate).format('MM/DD/YYYY');
  return formattedDate;
};

const getDate = (source: string, data: PendingAddonData[]) => {
  return formatDateToMMDDYYYY(
    data?.find(s => s.addon_type === source)?.started_at as unknown as string,
  );
};

const AddonSetupContainer: React.FC<AddonSetupContainerProps> = ({
  pendingAddons,
  drugSetupStatus,
  creditSetupStatus,
  occHealthSetupStatus,
  handleDrugSetupClick,
  handleCreditSetupClick,
  handleOccHealthSetupClick,
  handleCloseClick,
}) => {
  const [showSkipModal, setShowSkipModal] = useState(false);

  const drugInitiationDate = getDate('drug_screening', pendingAddons);
  const creditInitiationDate = getDate('credit_report', pendingAddons);
  const occHealthInitiationDate = getDate(
    'occupational_health_screening',
    pendingAddons,
  );

  const hiddenIfOnePending =
    drugSetupStatus === STATUS.pending ||
    creditSetupStatus === STATUS.pending ||
    occHealthSetupStatus === STATUS.pending;

  const hasNotSetupAtLeastOne =
    drugSetupStatus === STATUS.disabled ||
    creditSetupStatus === STATUS.disabled ||
    occHealthSetupStatus === STATUS.disabled;

  const showSkipForNowModal = !hiddenIfOnePending && hasNotSetupAtLeastOne;
  const showCloseButton = !hiddenIfOnePending;

  const handleConfirm = useCallback(() => {
    setShowSkipModal(false);
    handleCloseClick();
  }, [handleCloseClick]);

  const handleCloseButtonClick = useCallback(() => {
    showSkipForNowModal ? setShowSkipModal(true) : handleConfirm();
  }, [handleConfirm, showSkipForNowModal]);

  const handleClose = useCallback(() => {
    setShowSkipModal(false);
  }, []);

  const addonsNotSetup: string[] = [];
  drugSetupStatus === STATUS.disabled && addonsNotSetup.push('drug tests');
  creditSetupStatus === STATUS.disabled &&
    addonsNotSetup.push('credit reports');
  occHealthSetupStatus === STATUS.disabled &&
    addonsNotSetup.push('occupational health');

  const allThreeNotSetup = addonsNotSetup.length === 3;

  return (
    <Container data-testid='setup-new-screenings-container'>
      {showSkipModal && (
        <SkipForNowModal
          open={showSkipModal}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          addonsNotSetup={addonsNotSetup}
        />
      )}
      <Flex>
        <Title>Set up new screenings</Title>
        {showCloseButton && (
          <XCloseButton
            data-testid='setup-screenings-close-button'
            src={XClose}
            alt=''
            role='button'
            onClick={handleCloseButtonClick}
          />
        )}
      </Flex>
      <Description>
        After you set up <BoldDescription>drug tests</BoldDescription>,{' '}
        <BoldDescription>credit reports</BoldDescription>, and{' '}
        <BoldDescription>occupational health screenings</BoldDescription>, you
        can add them to new or existing packages.
      </Description>
      <AddonSetupList>
        {allThreeNotSetup ? (
          <>
            <AddonSetupCompact
              setupType={SetupType.drug}
              handleSetupClick={handleDrugSetupClick}
            />
            <AddonSetupCompact
              setupType={SetupType.credit}
              handleSetupClick={handleCreditSetupClick}
            />
            <AddonSetupCompact
              setupType={SetupType.occupationalHealth}
              handleSetupClick={handleOccHealthSetupClick}
            />
          </>
        ) : (
          <>
            <AddonSetup
              setupType={SetupType.drug}
              status={drugSetupStatus}
              handleSetupClick={handleDrugSetupClick}
              initiationDate={drugInitiationDate}
            />
            <AddonSetup
              setupType={SetupType.credit}
              status={creditSetupStatus}
              handleSetupClick={handleCreditSetupClick}
              initiationDate={creditInitiationDate}
            />
            <AddonSetup
              setupType={SetupType.occupationalHealth}
              status={occHealthSetupStatus}
              handleSetupClick={handleOccHealthSetupClick}
              initiationDate={occHealthInitiationDate}
            />
          </>
        )}
      </AddonSetupList>
    </Container>
  );
};

export default AddonSetupContainer;
