import React from 'react';
import {
  AssessStatuses,
  getStatusTypeForReport,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { Report } from 'types';
import { useUser } from 'context/CurrentUser';
import { useReportInvitationStatusDecision } from '../utils';
import { DateLabel, StatusBadge } from './report-invitation-labels';

type Props = {
  report: Report;
};

const ReportInvitationDateStatus: React.FC<Props> = ({ report }) => {
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const status = useReportInvitationStatusDecision(report);
  const isReport = report.object === 'report';

  const currentUser = useUser();
  // @ts-ignore TODO: Remove comment once the Report type is consumed from Utils
  const statusType = getStatusTypeForReport(report, currentUser);

  const displayReportStatusAssessValues = [
    AssessStatuses.ESCALATED,
    AssessStatuses.REVIEW,
  ];

  const reportStatus = report?.status;
  const reportStatusIsCanceledOrSuspended =
    reportStatus === ReportStatuses.CANCELED ||
    reportStatus === ReportStatuses.SUSPENDED;

  const displayReportStatus =
    displayReportStatusAssessValues.includes(status) &&
    reportStatusIsCanceledOrSuspended;

  return (
    <>
      {isReport ? (
        <>
          <DateLabel isReport={isReport} createdAt={report.created_at || ''} />
          {displayReportStatus && (
            <>
              <StatusBadge
                data-testid='report-final-status-badge'
                /* @ts-ignore TODO: Remove comment once the Report type is consumed from Utils */
                status={report?.status}
                statusType={statusType}
              />
              <span
                className='cds--header__nav mastodon-header-navigation'
                style={{ marginInlineStart: '16px', paddingBottom: '8px' }}
              />
            </>
          )}
          <StatusBadge isReport={isReport} status={status} />
        </>
      ) : (
        // Invitations
        <>
          {status !== ReportStatuses.EXPIRED && (
            <DateLabel
              isReport={isReport}
              createdAt={report.created_at || ''}
            />
          )}
          <StatusBadge isReport={isReport} status={status} />
        </>
      )}
    </>
  );
};

export default ReportInvitationDateStatus;
