import React, { useReducer } from 'react';
import {
  getDocument,
  Report,
  useWorkflowScreenStates,
} from '@dashboard-experience/utils';
import { useGetReport } from 'api/reports';
import { useGetAdjudicationSubtypes } from 'modules/adjudication/api';
import CustomProvider from 'state/provider';
import { context } from './context';
import preAAtransitionStates, { Screens } from '../transitions';
import Reducer, { initialState } from './reducer';

type Props = {
  onClose: () => void;
  open: boolean;
  initialScreen?: keyof typeof Screens;
};

const Provider: React.FC<Props> = ({
  onClose,
  open,
  initialScreen,
  children,
}) => {
  const report = useGetReport() as unknown as Report;
  const pdfIndividualizedAssessment = getDocument(
    report,
    'pdf_individualized_assessment',
  );

  const individualizedAssessment = pdfIndividualizedAssessment?.download_uri
    ? { assessmentUrl: pdfIndividualizedAssessment.download_uri }
    : initialState.individualizedAssessment;

  const newState = {
    ...initialState,
    individualizedAssessment,
  };

  const [state, dispatch] = useReducer(Reducer, newState);
  const { adjudicationSubtypes } = useGetAdjudicationSubtypes();

  const defaultInitialScreen =
    adjudicationSubtypes?.length >= 2 ? Screens.Subtypes : Screens.AdverseItems;

  const { currentScreen, transitionToScreen, nextScreen, previousScreen } =
    useWorkflowScreenStates(
      initialScreen ? Screens[initialScreen] : defaultInitialScreen,
      preAAtransitionStates,
    );

  return (
    <CustomProvider
      context={context}
      props={{
        open,
        onClose,
        currentScreen,
        transitionToScreen,
        nextScreen,
        previousScreen,
        state,
        dispatch,
      }}
    >
      {children}
    </CustomProvider>
  );
};

export default Provider;
