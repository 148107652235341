import React, { useCallback } from 'react';
import {
  useTrackEvent,
  PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES,
} from 'utils/analytics';
import { Divider, Wrapper } from './IndustrySelection.styles';
import RoleTile from './RoleTile/RoleTile';
import useGetRoles from '../../hooks/useGetRoles';
import useSearchFilter from '../../hooks/useSearchFilter';
import FilterSection from './FilterSection';

interface Props {
  selectedRoles: string[];
  onRolesChange: (role: string[]) => void;
  selectedIndustryId: number;
  hasTrackedRoleSearch: React.MutableRefObject<boolean>;
}

const filterRoles = (item: string, searchFilter: string) =>
  !searchFilter || item.toLowerCase().includes(searchFilter.toLowerCase());

const RoleSelection: React.FC<Props> = ({
  selectedRoles,
  onRolesChange,
  selectedIndustryId,
  hasTrackedRoleSearch,
}) => {
  const { data, isSuccess } = useGetRoles(selectedIndustryId);
  const roles = data?.job_roles || [];
  const { searchParam, filteredData, searchFilterValue, handleSearchChange } =
    useSearchFilter(roles, filterRoles);
  const trackEvent = useTrackEvent();

  const onSelect = useCallback(
    (role: string) => {
      if (selectedRoles.includes(role)) return;
      onRolesChange([...selectedRoles, role]);
    },
    [onRolesChange, selectedRoles],
  );

  const onRemove = useCallback(
    (role: string) => {
      onRolesChange(selectedRoles.filter((r: string) => r !== role));
    },
    [onRolesChange, selectedRoles],
  );

  const handleRoleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearchChange(e);
      if (!hasTrackedRoleSearch.current) {
        trackEvent(
          PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLES_SELECTION_SEARCH_USED,
        );
        hasTrackedRoleSearch.current = true;
      }
    },
    [handleSearchChange, trackEvent, hasTrackedRoleSearch],
  );

  return (
    <>
      <FilterSection
        tKey='roleSelection'
        searchParam={searchParam}
        handleSearchChange={handleRoleSearch}
      />
      {isSuccess && (
        <Wrapper>
          {filteredData.map((role: string) => (
            <RoleTile
              key={role}
              role={role}
              selected={selectedRoles.includes(role)}
              onClick={onSelect}
            />
          ))}
          {searchParam.length > 0 && (
            <RoleTile
              role={searchFilterValue}
              onClick={onSelect}
              isCustom
              addable
            />
          )}
          {selectedRoles.length > 0 && (
            <>
              <Divider />
              {selectedRoles.map((role: string) => (
                <RoleTile
                  key={role}
                  role={role}
                  selected
                  onClick={onRemove}
                  removable
                />
              ))}
            </>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default RoleSelection;
