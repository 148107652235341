import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { FieldComponentProps } from '../../types';

const StyledLaunchIcon = styled(M.Icon)`
  margin-left: 5px;
`;

const Link: React.FC<FieldComponentProps> = ({ invitation, testid }) => {
  return (
    <div data-testid={testid}>
      {invitation?.invitation_url ? (
        <M.Link href={invitation?.invitation_url} target='_blank'>
          Open invitation link
          <StyledLaunchIcon icon='Launch' />
        </M.Link>
      ) : (
        'Temporarily unavailable'
      )}
    </div>
  );
};

export default Link;
