import { getResultOrStatus } from '@dashboard-experience/utils';
import isEmpty from 'lodash/isEmpty';
import { BaseParams } from './actions';

/**
 * Returns a mutated version of the Report where any instance of "status" gets updated to "result" if present
 *
 * @param report - Report data from the API
 * @returns The mutated report
 *
 */
// eslint-disable-next-line import/prefer-default-export
export const mapReportToStatus = (report: any) => {
  if (isEmpty(report)) {
    return report;
  }

  try {
    mapObjectResultToStatus(report);
    Object.keys(report).forEach(key => {
      if (report[key] && typeof report[key] === 'object') {
        if (Array.isArray(report[key])) {
          report[key].forEach((item: any) => mapObjectResultToStatus(item));
        } else {
          mapObjectResultToStatus(report[key]);
        }
      }
    });
    return report;
  } catch (error) {
    return report;
  }
};

export const mapObjectResultToStatus = (item: any) => {
  const newStatus = getResultOrStatus(item);
  if (item.status) {
    item.status = newStatus;
  }
};

export const getAssessmentVariantRegistrationId = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('assessment_variant_registration_id');
};

export const getParamsWithAssessmentVariantRegistrationId = (
  params: BaseParams,
) => {
  const assessmentVariantRegistrationId = getAssessmentVariantRegistrationId();
  if (!assessmentVariantRegistrationId) {
    return params;
  }

  return {
    ...params,
    params: {
      ...(params.params ?? {}),
      assessment_variant_registration_id: assessmentVariantRegistrationId,
    },
  };
};
