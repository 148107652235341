import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { Dropdown } from '../../SharedItems';
import { useOrderBackgroundCheckContext, actionTypes } from '../../Context';
import { BillingEntity } from '../../../../../types/Billing';
import parseAddressToString from '../../../../../utils/BillingAddressUtils';

export type SelectPaymentProfileProps = {
  payment_profiles: BillingEntity[];
  billing_entity_enabled: boolean;
  goBackClicked: boolean;
};

const PaymentProfileDropdown = styled(Dropdown)`
  width: 280px;
  margin: 10px 0;
  margin-bottom: 16px;
`;

const paymentProfileNameToString = (item: BillingEntity) => {
  const profileName = item?.name || '';
  const profileAddress = parseAddressToString(item?.address || null);
  if (profileAddress != '') {
    return profileName.concat(' (', profileAddress, ')');
  }
  return profileName;
};

const SelectPaymentProfile: React.FC<SelectPaymentProfileProps> = ({
  payment_profiles,
  billing_entity_enabled,
  goBackClicked,
}) => {
  const [paymentProfileChanged, setPaymentProfileChanged] = useState(false);
  const { state, dispatch } = useOrderBackgroundCheckContext();
  // Use base package to see whether the user has come from the review & submit page
  const fromReviewAndSubmitPage = !!state.basePackage.name;

  const currentProfile = useMemo(() => {
    return payment_profiles?.find(
      profile => profile.id === state.paymentProfile.id,
    );
  }, [state.paymentProfile, payment_profiles]);

  const handlePaymentProfileChange = useCallback(
    ({ selectedItem }: any) => {
      dispatch({
        type: actionTypes.SET_PAYMENT_PROFILE,
        payload: { paymentProfile: selectedItem },
      });

      setPaymentProfileChanged(true);
    },
    [dispatch, state.paymentProfile, fromReviewAndSubmitPage],
  );

  useEffect(() => {
    if (
      billing_entity_enabled &&
      payment_profiles.length > 0 &&
      !state.paymentProfile?.name
    ) {
      // Set initial state to Main Account
      dispatch({
        type: actionTypes.SET_PAYMENT_PROFILE,
        payload: { paymentProfile: payment_profiles[0] },
      });
    }
  }, [
    billing_entity_enabled,
    payment_profiles,
    state.paymentProfile,
    dispatch,
  ]);

  useEffect(() => {
    if (paymentProfileChanged || fromReviewAndSubmitPage || goBackClicked)
      return;
    // Default to account
    if (payment_profiles.keys.length > 1) {
      dispatch({
        type: actionTypes.SET_PAYMENT_PROFILE,
        payload: { paymentProfile: 'ACCOUNT' },
      });
    }
  }, [
    payment_profiles,
    dispatch,
    paymentProfileChanged,
    fromReviewAndSubmitPage,
    goBackClicked,
  ]);

  return (
    <>
      {billing_entity_enabled &&
        (currentProfile == undefined ? (
          <M.LoadingInline data-testid='payment-profile-dropdown-loading-testid' />
        ) : (
          <PaymentProfileDropdown
            data-testid='payment-profile-dropdown-testid'
            initialSelectedItem={currentProfile}
            items={payment_profiles}
            label='Saved payment profiles'
            itemToString={paymentProfileNameToString}
            onChange={handlePaymentProfileChange}
          />
        ))}
    </>
  );
};

export default SelectPaymentProfile;
