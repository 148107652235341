import { Filters } from './types';

export const newFilters = () => {
  return {
    order_by: 'created_at',
    sort: 'DESC',
    per_page: 25,
    page: 1,
  } as Filters;
};

export const newView = () => {
  return {
    filters: newFilters(),
  };
};
