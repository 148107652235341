import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';
import React from 'react';
import { Report, InternationalCriminalSearchV2 as Search } from 'types';
import CriminalRecord from '../Components/CriminalRecord';
import IDVerification from './IDVerification';
import SearchPDF from './SearchPDF';
import Substatus from './SubstatusCA';
import formatCountry from './utils';

const InternationalCriminalSearchV2: React.FC<{
  report: Report;
  search: Search;
  statusType: any;
}> = ({ report, search, statusType }) => {
  const title = formatCountry(search.country);
  const { result_substatus: substatus } = search;
  const result = getResultOrStatus(search);

  return (
    <M.SubScreening title={title} statusType={statusType} screening={search}>
      {search.identity_verifications?.map(verification => (
        <IDVerification
          key={verification.task_id}
          id_verification={verification}
          search={search}
          statusType={statusType}
        />
      ))}
      {substatus && <Substatus substatus={substatus} />}
      {result === ReportStatuses.CONSIDER &&
        search.criminal_records?.map(record => (
          <CriminalRecord
            key={record.id}
            report={report}
            statusType={statusType}
            record={record}
          />
        ))}
      {search.pdf_url && (
        <SearchPDF
          url={search.pdf_url}
          text={`View ${title} international criminal report`}
        />
      )}
    </M.SubScreening>
  );
};

export default InternationalCriminalSearchV2;
