import React from 'react';
import { capitalize } from 'lodash';
import { FieldComponentProps } from '../../types';

const Nodes: React.FC<FieldComponentProps> = ({ report, testid }) => {
  const segments = report.segment_stamps || [];

  const nodeValues = segments.map((segment: any) => {
    const [prop, value] = segment.split('|');
    if (prop && value) {
      return `${capitalize(prop)}: ${value}`;
    }
    if (prop) {
      return capitalize(prop);
    }
    if (value) {
      return value;
    }
    return '';
  });

  return (
    <div data-testid={testid}>
      <span>
        {nodeValues.map((line: string, i: number) => (
          <>
            {line}
            {i < nodeValues.length - 1 && <br />}
          </>
        ))}
      </span>
    </div>
  );
};

export default Nodes;
