/* eslint-disable import/prefer-default-export */
import { Report } from 'types';
import {
  accountHasPermission,
  hasPermission,
  Report as ReportType,
} from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';

export const useCanViewCaseManagement = (report: Report) => {
  const user = useUser();

  return (
    accountHasPermission(report as ReportType, 'case_management') &&
    (hasPermission(user, 'case_management_read_all') ||
      hasPermission(user, 'case_management_manage_all'))
  );
};
