import React, { useState, useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  useTrackEvent,
  PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES,
} from 'utils/analytics';
import RoleDefinitionSection from './RoleDefinitionSection';
import {
  OuterContainer,
  Container,
  Description1,
  Title1,
  Description2,
  RoleDefinitionCard,
  ChoiceCardContainer,
  ChoiceCard,
} from './RoleDefinitionStyles';
import { namespace } from '../../locales';

interface Props {
  selectedRoles: string[];
  onDefineLaterChange: (option: number) => void;
  onValidityChange: (isValid: boolean) => void;
  handleAttributeUpdate: any;
}

const RoleDefinition: React.FC<Props> = ({
  selectedRoles,
  onDefineLaterChange,
  onValidityChange,
  handleAttributeUpdate,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'components.PurchaseRecommendations',
  });

  const trackEvent = useTrackEvent();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [attributeSelection, setAttributeSelection] = useState<
    Record<string, boolean>
  >({});

  const handleOptionChange = useCallback(
    (option: number) => {
      setSelectedOption(option);
      if (onDefineLaterChange) {
        onDefineLaterChange(option);
      }
    },
    [onDefineLaterChange],
  );

  const updateAttributeSelection = useCallback(
    (roleName: string, isValid: boolean) => {
      setAttributeSelection(prev => ({
        ...prev,
        [roleName]: isValid,
      }));
    },
    [],
  );

  const handleValidityUpdate = useCallback(
    (role: string) => (isValid: boolean) => {
      updateAttributeSelection(role, isValid);
    },
    [updateAttributeSelection],
  );

  useEffect(() => {
    if (onValidityChange && selectedOption === 1) {
      const isEveryRoleValid = selectedRoles.every(
        role => attributeSelection[role],
      );
      onValidityChange(isEveryRoleValid);
    }
  }, [attributeSelection, onValidityChange, selectedRoles, selectedOption]);

  useEffect(() => {
    trackEvent(
      PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.ROLE_DEFINITION_VIEWED,
    );
  }, [trackEvent]);

  return (
    <OuterContainer>
      <Container>
        <Description1>{t('roleDefinition.whileWereHere')}</Description1>
        <Title1>{t('roleDefinition.defineRolesTitle')}</Title1>
        <Description2>
          {t('roleDefinition.defineRolesDescription')}
        </Description2>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '16px',
            margin: '16px 0',
          }}
        >
          {selectedRoles.map(role => (
            <RoleDefinitionCard key={role}>{role}</RoleDefinitionCard>
          ))}
        </div>

        <ChoiceCardContainer>
          <M.ChoiceCardGroup
            type='radio'
            name='role-definition-choice-group'
            onChange={handleOptionChange}
            grid={false}
          >
            <ChoiceCard
              id='define-roles-now'
              title={t('roleDefinition.yes')}
              value={1}
              data-testid='define-roles-now'
              description=''
            />
            <ChoiceCard
              id='define-roles-later'
              title={t('roleDefinition.noDoItLater')}
              value={2}
              data-testid='define-roles-later'
              description=''
            />
          </M.ChoiceCardGroup>
        </ChoiceCardContainer>

        {selectedOption === 1 &&
          selectedRoles.map((role, index) => (
            <RoleDefinitionSection
              data-testid='role-definition-section'
              key={role}
              roleName={role}
              roleNumber={index + 1}
              onValidityUpdate={handleValidityUpdate(role)}
              onAttributesUpdate={handleAttributeUpdate}
            />
          ))}
      </Container>
    </OuterContainer>
  );
};

export default RoleDefinition;
