import { useCallback, useState } from 'react';
import {
  buildPostBodyWithAddOns,
  mapPackage,
} from 'components/AddScreenings/shared/utils';
import {
  FOX_SIGNUP_EVENT_NAMES,
  useTrackEvent,
  SIGNUP_EVENT_NAMES,
} from 'utils';
import * as Sentry from '@sentry/react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { EMAIL_VERIFICATION_SIGNUP } from 'Constants';
import useGetPackages from './useGetPackages';
import usePostAccountPackage from './usePostAccountPackage';
import usePostCandidateInvites from './usePostCandidateInvites';
import useDeletePackage from './useDeletePackage';
import { BasePackage, Candidate } from '../types';
import {
  calculateSubtotal,
  calculateSubtotalAsNumber,
  getAddOnsTotal,
  getPackagePriceWithAddOns,
} from '../utils/pricing';
import useSignupAmplitudeEvents from './useSignupAmplitudeEvents';

export function convertToEmail(data: Array<Candidate>) {
  return data.map(item => item.email);
}

export const getAmplitudeEventValues = (
  selectedPackage: BasePackage,
  addOns: any,
  candidates: Candidate[],
) => {
  if (!selectedPackage || !addOns || !candidates) {
    return {};
  }
  const addOnScreenings = addOns[selectedPackage];
  const addOnSelected = addOnScreenings?.length > 0;
  const hasMvr = addOns[selectedPackage]?.includes('motor_vehicle_report');
  const addOnsPrice = parseFloat(
    (getAddOnsTotal(addOnScreenings) / 100).toFixed(2),
  );
  const packagePrice = parseFloat(
    (getPackagePriceWithAddOns(selectedPackage, addOnScreenings) / 100).toFixed(
      2,
    ),
  );
  const totalOrderPrice = calculateSubtotal(
    selectedPackage,
    addOnScreenings,
    candidates,
  );
  const totalOrderPriceNumber = calculateSubtotalAsNumber(
    selectedPackage,
    addOnScreenings,
    candidates,
  );

  return {
    addOnScreenings,
    addOnSelected,
    hasMvr,
    totalOrderPrice,
    totalOrderPriceNumber,
    packagePrice,
    addOnsPrice,
  };
};

type Props = {
  alias: {
    [key: string]: string | null;
  };
  addOns: any;
  candidates: Array<Candidate>;
  newPackageName: string | undefined;
  selectedPackage: string;
  workLocation: any;
  setError: (error: any) => void;
  promoCode?: string | undefined;
};

function useQueueInvites({
  alias,
  addOns,
  candidates,
  newPackageName,
  selectedPackage,
  workLocation,
  setError,
  promoCode,
}: Props) {
  const trackEvent = useTrackEvent();
  const [isProcessing, setIsProcessing] = useState(false);
  const { mutate: postAccountPackage, error: postPackageError } =
    usePostAccountPackage();
  const { mutate: postCandidateInvites, error: postInvitesError } =
    usePostCandidateInvites();
  const { mutate: deletePackage, error: deletePackageError } =
    useDeletePackage();
  const { data: packages } = useGetPackages();
  const { trackSignupOrderBGCPageCompletedEvent, trackAliasOfferedEvent } =
    useSignupAmplitudeEvents();

  const enableEmailVerification =
    useFlag(EMAIL_VERIFICATION_SIGNUP)?.variantKey === 'on';

  const {
    addOnScreenings,
    addOnSelected,
    hasMvr,
    totalOrderPriceNumber,
    packagePrice,
    addOnsPrice,
  } = getAmplitudeEventValues(selectedPackage as any, addOns, candidates);

  const handleSubmit = useCallback(async () => {
    setIsProcessing(true);
    let createPackageSlug = null;
    let createPackageId: string | null = null;

    let currentPackage = null;
    try {
      currentPackage = packages.find((p: any) => p.slug === selectedPackage);
    } catch (error) {
      setError({ error, source: 'queue-invites' });
      setIsProcessing(false);
      return;
    }

    if (
      addOns[selectedPackage].length > 0 ||
      alias[selectedPackage] === 'true'
    ) {
      const newPackageBody = buildPostBodyWithAddOns({
        basePackage: {
          ...mapPackage(currentPackage),
          aliases_enabled: alias[selectedPackage] === 'true' ? 'on' : 'off',
        },
        addedScreeningTypes: addOns[selectedPackage],
        additionalProperties: {},
        packageName: newPackageName,
        setSlug: false,
        isPrivate: false,
        origin: 'customer_via_signup',
      });
      await postAccountPackage(
        {
          body: newPackageBody,
        },
        {
          onSuccess: data => {
            createPackageSlug = data.slug;
            createPackageId = data.id;
          },
          onError: (error: any) => {
            const errorDetails = {
              message: error?.message,
              status: error?.response?.status,
              data: error?.response?.data,
              headers: error?.response?.headers,
              config: error?.config,
            };
            Sentry.captureException(errorDetails);
            setError({ error, source: 'queue-invites' });
            setIsProcessing(false);
          },
        },
      );
      if (!createPackageSlug) {
        setIsProcessing(false);
        return;
      }
    }

    const packageSlug =
      addOns[selectedPackage].length > 0
        ? createPackageSlug
        : currentPackage?.slug;

    await postCandidateInvites(
      {
        packageSlug,
        work_locations: [
          {
            country: workLocation.country?.country,
            state: workLocation.state?.state,
            city: workLocation.city?.city,
          },
        ],
        candidate_emails: convertToEmail(candidates),
      },
      {
        onError: async (error: any) => {
          const errorDetails = {
            message: error?.message,
            status: error?.response?.status,
            data: error?.response?.data,
            headers: error?.response?.headers,
            config: error?.config,
          };
          Sentry.captureException(errorDetails);
          setError({ error, source: 'queue-invites' });
          await deletePackage({ packageId: createPackageId });
          setIsProcessing(false);
        },
        onSuccess: () => {
          if (!enableEmailVerification) {
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
          }
          trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_ORDER_PLACED, {
            'Candidate Work Location Selected': 'In the USA',
            State: workLocation?.state?.name,
            City: workLocation?.city,
            Country: workLocation?.country
              ? workLocation?.country?.name
              : 'USA',
            'Package Selected': selectedPackage,
            'Number of Candidate Email Entered': candidates.length,
            'Add-on Price': addOnsPrice,
            'Add-on Screenings': addOnScreenings,
            'Add-On Selected': addOnSelected,
            'MVR Added': hasMvr,
            'Package Price': packagePrice,
            'Total Order Price': totalOrderPriceNumber,
            'Promo Code': promoCode,
          });
          trackAliasOfferedEvent();
          trackSignupOrderBGCPageCompletedEvent();
          trackEvent(
            SIGNUP_EVENT_NAMES.SIGNUP_INVITE_CANDIDATES_STEP_COMPLETED,
            {
              'Page Type': 'checkr',
              'Self Serve Signup Version': 'FOX Signup v2',
            },
          );
        },
      },
    );
  }, [
    addOnScreenings,
    addOnSelected,
    addOns,
    addOnsPrice,
    alias,
    candidates,
    deletePackage,
    hasMvr,
    newPackageName,
    packagePrice,
    packages,
    postAccountPackage,
    postCandidateInvites,
    selectedPackage,
    setError,
    totalOrderPriceNumber,
    trackAliasOfferedEvent,
    trackEvent,
    trackSignupOrderBGCPageCompletedEvent,
    workLocation.city,
    workLocation.country,
    workLocation.state?.name,
    workLocation.state?.state,
    promoCode,
  ]);

  return {
    queueInvites: handleSubmit,
    isLoading: isProcessing,
    error: postPackageError || postInvitesError || deletePackageError,
  };
}

export default useQueueInvites;
