import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;

export const Divider = styled(M.Divider)`
  margin: 16px 0 32px 0 !important;
  width: 100%;
`;

export const TextInput = styled(M.TextInput)`
  width: 400px;
`;
