import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useUser } from 'context/CurrentUser';
import { stateName } from 'utils';
import {
  MODAL_STEPS,
  ONE,
  TWO,
  THREE,
  INITIAL_CREDIT_REPORT_VALIDATIONS,
  creditReportFieldValidator,
  getInvalidErrorMessage,
  mapBackendFieldsToFrontend,
} from 'components/AddScreenings/shared/ModalComponents';
import {
  CreditSetupData,
  useCreditSetup,
} from 'components/AddScreenings/hooks/useCreditSetup';
import { useCreditReportReasons } from 'components/AddScreenings/hooks/useCreditReportReasons';
import CreditReportSetupModalPageOne, {
  CreditReportPageOneStateType,
} from './CreditReportSetupModalPageOne';
import CompletedSetup from './DrugAndHealthScreenings/CompletedSetup';
import CreditReportSetupModalPageTwo, {
  ApiData,
  CreditReportReason,
} from './CreditReportSetupModalPageTwo';
import { DEF01 } from '../../types/Screenings/CreditReport';

type FooterProps = {
  currentStep: number;
};

const Footer = styled(M.ModalFooter)<FooterProps>`
  display: flex;
  justify-content: ${props =>
    props.currentStep === 2 ? 'space-between' : 'right'};
`;

const CreditReportSetupDescription = {
  email: 'Trendsource will reach out via email to set up  location inspection.',
  calendar: 'This typically takes 14-21 business days including inspection. ',
  shoppingCatalog: 'Once verified, you can start using credit reports.',
};

export const getLocationsWithoutPurpose = (apiData: ApiData[]): Set<string> => {
  const locationsWithoutPurpose: Set<string> = new Set();

  apiData.forEach((item: ApiData) => {
    if (!item.purpose) {
      locationsWithoutPurpose.add(item.location);
    }
  });

  return locationsWithoutPurpose;
};

export const translateForConsumption = (
  arr: { location: string; purpose: string }[],
): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  for (const item of arr) {
    result[item.location] = item.purpose;
  }

  return result;
};

type CreditReportSetupModalProps = {
  open: boolean;
  handleClose: Function;
};

const CreditReportSetupModal: React.FC<CreditReportSetupModalProps> = ({
  open,
  handleClose,
}) => {
  const currentUser = useUser();
  const { data } = useCreditReportReasons();

  const {
    account: { id: accountId, company },
  } = currentUser;
  const companyStateName = stateName(company?.state);
  const [pageOneState, setPageOneState] =
    useState<CreditReportPageOneStateType>({
      name: '',
      title: '',
      email: '',
      phoneNumber: '',
      street: company?.street || '',
      city: company?.city || '',
      zip: company?.zipcode || '',
      state: companyStateName || '',
      businessType: '',
    });
  const [hasError, setHasError] = useState(false);
  const [hasSelectedError, setHasSelectedError] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const selectedEmpty = selected.length < 1;
  const nilLocationSelected = selected.includes(DEF01);
  const locationSelected = !selected.includes(DEF01) && !selectedEmpty;
  const [canSelectLocation, setCanSelectLocation] = useState(
    !nilLocationSelected && true,
  );
  const [canSelectNilLocation, setCanSelectNilLocation] = useState(
    !locationSelected && true,
  );
  const [apiData, setApiData] = useState<ApiData[]>([]);

  const [currentStep, setCurrentStep] = useState(1);
  const [validationWarnings, setValidationWarnings] = useState(
    INITIAL_CREDIT_REPORT_VALIDATIONS,
  );
  const [incompletePurposeLocations, setIncompletePurposeLocations] = useState<
    Set<string>
  >(new Set());

  const { call: setupCredit, result: setupCreditResult } = useCreditSetup({
    accountId,
  });

  const handleBackClick = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const getPermissablePurposeIds = useCallback(() => {
    const permissablePurposeIds: any[] = [];
    (data as CreditReportReason[]).forEach(fetchedData =>
      apiData.forEach(selectedData => {
        if (
          selectedData.location === fetchedData.state.name &&
          selectedData.purpose === fetchedData.title
        ) {
          permissablePurposeIds.push(fetchedData.id);
        }
      }),
    );

    return permissablePurposeIds;
  }, [apiData, data]);

  const handleNextClick = useCallback(() => {
    const { fieldsAreValid, invalidFields } = creditReportFieldValidator(
      pageOneState.email,
      pageOneState.phoneNumber,
      pageOneState.city,
      pageOneState.zip,
      pageOneState.street,
      pageOneState.state,
      pageOneState.name,
      pageOneState.title,
      pageOneState.businessType,
    );
    setValidationWarnings(invalidFields);

    if (fieldsAreValid) {
      setCurrentStep(currentStep + 1);
      setValidationWarnings(INITIAL_CREDIT_REPORT_VALIDATIONS);
    }
  }, [currentStep, pageOneState]);

  const validatePermissiblePurposeSelections = useCallback(() => {
    if (nilLocationSelected) {
      setCanSelectLocation(false);
    } else {
      setCanSelectLocation(true);
    }

    if (locationSelected) {
      setCanSelectNilLocation(false);
    } else {
      setCanSelectNilLocation(true);
    }
  }, [selected]);

  const handleSubmitClick = useCallback(() => {
    const incompleteLocations = getLocationsWithoutPurpose(apiData);

    if (selectedEmpty) {
      setHasSelectedError(true);
    } else if (
      selected.length > 0 &&
      incompleteLocations.size > 0 &&
      !nilLocationSelected
    ) {
      setIncompletePurposeLocations(incompleteLocations);
    } else {
      const ids = getPermissablePurposeIds();

      const data: CreditSetupData = {
        contact_name: pageOneState.name,
        contact_title: pageOneState.title,
        contact_email: pageOneState.email,
        contact_phone: pageOneState.phoneNumber,
        business_address_street: pageOneState.street,
        business_address_city: pageOneState.city,
        business_address_state: pageOneState.state,
        business_address_zipcode: pageOneState.zip,
        business_type: pageOneState.businessType,
        credit_permissible_purpose: ids,
      };

      setupCredit(data);
    }
  }, [
    apiData,
    pageOneState.businessType,
    pageOneState.city,
    pageOneState.email,
    pageOneState.name,
    pageOneState.phoneNumber,
    pageOneState.state,
    pageOneState.street,
    pageOneState.title,
    pageOneState.zip,
    selected.length,
    setupCredit,
    getPermissablePurposeIds,
  ]);

  useEffect(() => {
    validatePermissiblePurposeSelections();

    return () => {
      setCanSelectLocation(!nilLocationSelected && true);
      setCanSelectNilLocation(!locationSelected && true);
    };
  }, [selected]);

  useEffect(() => {
    if (setupCreditResult.isSuccess && MODAL_STEPS[currentStep] === TWO) {
      setCurrentStep(currentStep + 1);
      setValidationWarnings(INITIAL_CREDIT_REPORT_VALIDATIONS);
    }
    if (
      setupCreditResult.isError &&
      validationWarnings.email.isValid &&
      !hasError
    ) {
      const errorField = (setupCreditResult.error as any).response.data
        .errors[0].field;
      const field = mapBackendFieldsToFrontend(errorField);
      const fieldErrorMessage = getInvalidErrorMessage(field);
      setValidationWarnings({
        ...validationWarnings,
        [field]: { isValid: false, error: fieldErrorMessage },
      });
      setCurrentStep(1);
      setHasError(true);
    }
  }, [
    setupCreditResult.error,
    setupCreditResult.isSuccess,
    currentStep,
    setupCreditResult.isError,
    validationWarnings,
    setupCreditResult,
    hasError,
  ]);

  return (
    <M.ComposedModal
      data-testid='credit-reports-setup-modal'
      open={open}
      onClose={handleClose}
    >
      <M.ModalHeader
        data-testid='credit-reports-setup-modal-header'
        closeModal={handleClose}
      >
        <h2>Set up credit reports</h2>
      </M.ModalHeader>
      <M.ModalBody id='credit-reports-setup-modal-body'>
        <div data-testid='credit-reports-modal-body'>
          {MODAL_STEPS[currentStep] === ONE && (
            <CreditReportSetupModalPageOne
              state={pageOneState}
              setState={setPageOneState}
              validationWarnings={validationWarnings}
            />
          )}
          {MODAL_STEPS[currentStep] === TWO && (
            <CreditReportSetupModalPageTwo
              selected={selected}
              setSelected={setSelected}
              hasSelectedError={hasSelectedError}
              apiData={apiData}
              setApiData={setApiData}
              incompletePurposeLocations={incompletePurposeLocations}
              purposes={data as unknown as any}
              canSelectLocation={canSelectLocation}
              canSelectNilLocation={canSelectNilLocation}
            />
          )}
          {MODAL_STEPS[currentStep] === THREE && (
            <CompletedSetup description={CreditReportSetupDescription} />
          )}
        </div>
      </M.ModalBody>
      {MODAL_STEPS[currentStep] === ONE && (
        <Footer currentStep={currentStep}>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='credit-reports-modal-next-button'
            onClick={handleNextClick}
          >
            Next
          </M.Button>
        </Footer>
      )}

      {MODAL_STEPS[currentStep] === TWO && (
        <Footer currentStep={currentStep}>
          <M.Button
            kind='secondary'
            size='sm'
            data-testid='credit-reports-modal-back-button'
            onClick={handleBackClick}
          >
            Back
          </M.Button>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='credit-reports-modal-submit-button'
            onClick={handleSubmitClick}
          >
            Submit
          </M.Button>
        </Footer>
      )}
      {MODAL_STEPS[currentStep] === THREE && (
        <Footer>
          <M.Button
            kind='primary'
            size='sm'
            data-testid='credit-reports-modal-close-button'
            onClick={handleClose}
          >
            Close
          </M.Button>
        </Footer>
      )}
    </M.ComposedModal>
  );
};

export default CreditReportSetupModal;
