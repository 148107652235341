import { M, RECORD_TYPES } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useGetReportSettings } from 'components/AccountSettings';
import { useRecordsFilter } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { AI_CHARGE_EXPLAINER, SENTENCE_READABILITY } from 'Flags';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import React from 'react';
import {
  CountyCriminalSearch,
  GenericObject,
  MunicipalCriminalSearch,
  Report,
} from 'types';
import { BaseCharge } from 'types/report/screenings/record';
import chargeSentenceReadability from 'utils/ChargeSentenceReadability';
import { handleAiClick } from '../../../../api/aiExplainr';

type Props = {
  search: CountyCriminalSearch | MunicipalCriminalSearch | GenericObject; // FederalCriminalSearch | FederalCivilSearch
  filteredRecords: number;
  report: Report;
  reportStatusType: string;
  filter?: string;
};

const FilteredByAdjudicationRecords: React.FC<Props> = ({
  search,
  filteredRecords,
  report,
  reportStatusType,
  filter,
}) => {
  const currentUser = useUser();
  const { account } = report;
  const recordsFilter = useRecordsFilter();
  const finalFilter = recordsFilter || filter;

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  const sentenceReadabilityFlagEnabled =
    useFlag(SENTENCE_READABILITY)?.variantKey === 'on';

  const { show_additional_charge_information: showChargeExplanationPanel } =
    useGetReportSettings(account);

  return (
    <M.Expandable
      title={
        <M.Screening.Heading
          headingLeft={{
            name: `${filteredRecords} Record(s) filtered by ${finalFilter}`,
          }}
        />
      }
    >
      {search.filtered_by_positive_adjudication_records?.map(
        (filteredRecord: GenericObject) => {
          const handleClickWithExplainer = handleAiClick(
            filteredRecord.state || '',
            filteredRecord.county || '',
          );

          if (sentenceReadabilityFlagEnabled) {
            filteredRecord.charges = filteredRecord.charges?.map(
              (c: BaseCharge) => {
                c.sentence = chargeSentenceReadability(c.sentence);
                return c;
              },
            );
            filteredRecord.filtered_by_positive_adjudication_charges =
              filteredRecord.filtered_by_positive_adjudication_charges?.map(
                (c: BaseCharge) => {
                  c.sentence = chargeSentenceReadability(c.sentence);
                  return c;
                },
              );
            filteredRecord.charges_hidden_by_assess =
              filteredRecord.charges_hidden_by_assess?.map((c: BaseCharge) => {
                c.sentence = chargeSentenceReadability(c.sentence);
                return c;
              });
          }

          return (
            <M.Record
              key={filteredRecord.id}
              record={filteredRecord}
              recordType={RECORD_TYPES.filteredByAdjudication}
              currentUser={currentUser}
              report={report}
              reportStatusType={reportStatusType}
              chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
              displayChargeExplanation={showChargeExplanationPanel}
              chargesExplainer={
                aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
              }
            />
          );
        },
      )}
    </M.Expandable>
  );
};

export default FilteredByAdjudicationRecords;
