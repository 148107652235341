/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { queryCache } from 'react-query';
import { useModalState } from '../context';
import { Screens } from '../transitions';

export const useRefetchReportOnClose = () => {
  const currentScreen = useModalState('currentScreen');
  const onClose = useModalState('onClose');

  return useCallback(() => {
    if (currentScreen === Screens.SuccessMessage) {
      queryCache.invalidateQueries('reports/multi-mvr');
    }
    onClose();
  }, [currentScreen, onClose]);
};
