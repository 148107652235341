import { useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const useRenderMonitor = (componentName: string) => {
  const renders = useRef(0);
  const firstRenderTime = useRef(performance.now());

  useEffect(() => {
    renders.current += 1;
    const now = performance.now();
    const diff = now - firstRenderTime.current;

    // Want to track more than 10 renders in the first 5 seconds
    if (renders.current > 10 && diff < 5000) {
      datadogRum.addAction('high_render_frequency', {
        component: componentName,
        renders: renders.current,
        duration_ms: diff,
      });
      renders.current = 0;
      firstRenderTime.current = now;
    }
  });
};

export default useRenderMonitor;
