import React from 'react';
import {
  hasPermission,
  CurrentUser as User,
} from '@dashboard-experience/utils';
import * as GeneralDetailsFields from './general-details/fields';
import * as OrderInfoFields from './order-information/fields';
import * as InvitationFields from './order-invitation-information/fields';
import * as AssessGuidelines from './assess-guidelines/fields';
import * as CandidateInfoFields from './candidate-information/fields';
import Nodes from './nodes/fields';
import ConfigItemKey from './config-item-key';

import {
  FieldKeys,
  GetFieldKVItemParams,
  BuildFieldItemsListParams,
  FieldKVItem,
  FieldConfig,
} from './types';

const componentMap: Record<FieldKeys, React.FC<any>> = {
  status: GeneralDetailsFields.Status,
  adjudication: GeneralDetailsFields.Adjudication,
  report_id: GeneralDetailsFields.ReportID,
  assigned_to: GeneralDetailsFields.AssignedTo,
  tags: GeneralDetailsFields.Tags,
  package: OrderInfoFields.Package,
  source: OrderInfoFields.Source,
  work_locations: OrderInfoFields.WorkLocation,
  created_at: OrderInfoFields.Date,
  completed_at: OrderInfoFields.Date,
  revised_at: OrderInfoFields.RevisedAt,
  upgraded_at: OrderInfoFields.UpgradedAt,
  turnaround_time: OrderInfoFields.TurnaroundTime,
  estimated_updated: OrderInfoFields.EstimationUpdated,
  estimated_completion: OrderInfoFields.EstimatedCompletion,
  invitation_link: InvitationFields.Link,
  invitation_link_expires: InvitationFields.LinkExpires,
  mvr_ruleset: AssessGuidelines.Mvr,
  criminal_ruleset: AssessGuidelines.Criminal,
  custom_id: CandidateInfoFields.CustomID,
  date_of_birth: CandidateInfoFields.DateOfBirth,
  drivers_license: CandidateInfoFields.DriversLicense,
  email: CandidateInfoFields.Email,
  first_name: CandidateInfoFields.Name,
  middle_name: CandidateInfoFields.Name,
  last_name: CandidateInfoFields.Name,
  invitation_sent: CandidateInfoFields.InvitationSent,
  location: CandidateInfoFields.Location,
  phone: CandidateInfoFields.Phone,
  postal_code: CandidateInfoFields.PostalCode,
  social_security: CandidateInfoFields.SocialSecurity,
  candidate_created_at: CandidateInfoFields.CreatedAt,
  nodes: Nodes,
};

export const allowEdit = (user: User) => {
  return (field: string) => {
    if (field === 'package') {
      return hasPermission(user, 'perform_upgrade');
    }
    if (field === 'email' || field === 'custom_id') {
      return hasPermission(user, `update_candidate_${field}s`);
    }
    return user.roles?.includes('admin');
  };
};

export const getFieldKVItem = ({
  fieldName,
  fieldKey,
  user,
  report,
  invitation,
  reportPrediction,
  reportEstimationGenerated,
  candidate,
  candidateUpdates,
  setCandidateUpdates,
}: GetFieldKVItemParams) => {
  const configItem: FieldKVItem = {
    id: '',
    itemKey: null,
    itemValue: null,
  };

  configItem.id = fieldKey;

  const FieldComponent = componentMap[fieldKey];

  if (!FieldComponent) return configItem;

  // set FieldKVItem value
  const allowEditField = allowEdit(user);

  configItem.itemValue = (
    <FieldComponent
      fieldKey={fieldKey}
      displayName={fieldName}
      allowCandidateEdit={allowEditField(fieldKey)}
      testid={`report-information-field-${fieldKey}`}
      report={report}
      invitation={invitation}
      currentUser={user}
      // note sure
      reportPrediction={reportPrediction}
      reportEstimationGenerated={reportEstimationGenerated}
      candidate={candidate}
      candidateUpdates={candidateUpdates}
      setCandidateUpdates={setCandidateUpdates}
    />
  );

  // set FieldKVItem key
  configItem.itemKey = (
    <ConfigItemKey fieldName={fieldName} fieldKey={fieldKey} />
  );

  return configItem;
};

export const buildFieldItemsList = (params: BuildFieldItemsListParams) => {
  const { fields, isPinnedPanel } = params;

  const config: FieldKVItem[] = fields
    .map((field: FieldConfig) => {
      const { getFieldName, fieldKey } = field;
      const fieldName = getFieldName(isPinnedPanel);

      const item = getFieldKVItem({
        fieldName,
        fieldKey,
        ...params,
      });

      return item;
    })
    .filter((item: FieldKVItem) => item.itemKey);
  return config;
};
