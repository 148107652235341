import {
  DashboardBetaBanner,
  DeauthorizedBanner,
  ImpersonatorBanner,
} from 'components';
import CandidateBetaBanner from 'components/Beta/BetaBanner';
import {
  CANDIDATE_REDESIGN_LEARN_MORE_URL,
  IS_ANGULAR_IFRAME,
} from 'Constants';
import { ApiStatusBannerContainer } from 'containers';
import { CANDIDATE_REDESIGN } from 'Flags';
import { useIsCandidateRoute, useIsReportsListRoute } from 'modules/candidate';
import ReportsListRedeisgnNavContainer from 'modules/layout/navigation/ui/nav-container-redesign';
import NavigationContainer from 'modules/layout/navigation/ui/navigation-container';
import { useReportsListRedesignPhaseOne } from 'modules/reports-list';
import DashboardLandingPage from 'pages/DashboardLandingPage';
import { BetaState, useBetaEnabled, useBetaState } from 'providers/Beta';
import React, { useState } from 'react';
import { isSignupFlow } from 'utils';
import { useIsBillingRoute } from '../../api/billing/hooks';
import { useIsSmallScreenForHamburgerNavigation } from '../../modules/layout/navigation/utilities/hooks';
import { AccountCredentialingBanner } from '../DashboardLanding/ui';
import {
  Body,
  ContainerMain,
  Content,
  ContentWrap,
  ContentWrapReportsListRedesign,
  FlexGrowContainer,
  MainContent,
} from './DashboardStyles';
import Footer from './Footer';
import { useIsAnalyticsRoute } from './utils';

export const DashboardWrapper: React.FC<{}> = ({ children }) => {
  const isSignup = isSignupFlow();
  const [navCollapsed, setNavCollapse] = useState(false);

  const candidateRedesignState = useBetaState(CANDIDATE_REDESIGN);
  const betaEnabled = useBetaEnabled(CANDIDATE_REDESIGN);
  const reportsListRedesignState = useReportsListRedesignPhaseOne();
  const withinCandidate = useIsCandidateRoute();
  const withinBilling = useIsBillingRoute();
  const withinAnalytics = useIsAnalyticsRoute();
  const onReportsList = useIsReportsListRoute();
  const isHamburgerNavigationTrue = useIsSmallScreenForHamburgerNavigation();

  // Show beta banner in standalone mode for candidate routes
  const showBetaCandidateBanner =
    !IS_ANGULAR_IFRAME &&
    withinCandidate &&
    candidateRedesignState === BetaState.OPT_OUT;

  const showFooter =
    !withinCandidate ||
    ![BetaState.OPT_IN, BetaState.INITIAL].includes(candidateRedesignState);

  const removePadding =
    (withinCandidate && betaEnabled) || withinBilling || withinAnalytics;

  return (
    <ContainerMain>
      <div data-testid='dashboard-wrapper'>
        {reportsListRedesignState && isHamburgerNavigationTrue && (
          <ReportsListRedeisgnNavContainer />
        )}
        <Body
          reportsListRedesignState={reportsListRedesignState}
          isHamburgerNavigationTrue={isHamburgerNavigationTrue}
        >
          <MainContent data-testid='main-content'>
            {(!reportsListRedesignState || !isHamburgerNavigationTrue) && (
              <NavigationContainer setNavCollapse={setNavCollapse} />
            )}
            {reportsListRedesignState && isHamburgerNavigationTrue ? (
              <ContentWrapReportsListRedesign>
                {!isSignup && (
                  <>
                    <ImpersonatorBanner />
                    <DashboardBetaBanner />
                    <ApiStatusBannerContainer />
                    {showBetaCandidateBanner && (
                      <CandidateBetaBanner
                        flagKey={CANDIDATE_REDESIGN}
                        translationKey='candidate_redesign'
                        learnMoreLink={CANDIDATE_REDESIGN_LEARN_MORE_URL}
                      />
                    )}
                    <AccountCredentialingBanner />
                  </>
                )}
                <Content
                  removePadding={removePadding}
                  onReportsList={onReportsList}
                >
                  {!isSignup && <DeauthorizedBanner />}
                  {isSignup && <DashboardLandingPage />}
                  <FlexGrowContainer>
                    {children}

                    {showFooter && <Footer className='footer-new' />}
                  </FlexGrowContainer>
                </Content>
              </ContentWrapReportsListRedesign>
            ) : (
              <ContentWrap navCollapsed={navCollapsed}>
                {!isSignup && (
                  <>
                    <ImpersonatorBanner />
                    <DashboardBetaBanner />
                    <ApiStatusBannerContainer />
                    {showBetaCandidateBanner && (
                      <CandidateBetaBanner
                        flagKey={CANDIDATE_REDESIGN}
                        translationKey='candidate_redesign'
                        learnMoreLink={CANDIDATE_REDESIGN_LEARN_MORE_URL}
                      />
                    )}
                    <AccountCredentialingBanner />
                  </>
                )}
                <Content
                  removePadding={removePadding}
                  onReportsList={onReportsList}
                >
                  {!isSignup && <DeauthorizedBanner />}
                  {isSignup && <DashboardLandingPage />}
                  <FlexGrowContainer>
                    {children}

                    {showFooter && <Footer className='footer-new' />}
                  </FlexGrowContainer>
                </Content>
              </ContentWrap>
            )}
          </MainContent>
        </Body>
      </div>
    </ContainerMain>
  );
};

export default DashboardWrapper;
