import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { includes } from 'lodash';
import * as Countries from 'i18n-iso-countries';
import { isInternationalCountry } from 'utils/Filters';
import { M } from '@dashboard-experience/mastodon';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  ADD_CHECKS,
} from '../../Context';

const CountryDropdown = styled(M.ComboBox)`
  width: 280px;
  margin: 10px 0;
`;

const COUNTRIES_NOT_AVAILABLE = ['AF'];

const countryItemToString = (item: string) => {
  const countryName = Countries.getName(item, 'en') || item || '';

  if (includes(COUNTRIES_NOT_AVAILABLE, item)) {
    return `${countryName} (Unavailable)`;
  }

  return countryName;
};

export type SelectCountryProps = {
  countries: string[];
  segmentation_enabled: boolean;
  hierarchy_present: boolean;
  internationalWorkLocationSelection: boolean;
  goBackClicked: boolean;
};

const SelectCountry: React.FC<SelectCountryProps> = ({
  countries = ['US'],
  segmentation_enabled,
  hierarchy_present,
  internationalWorkLocationSelection,
  goBackClicked,
}) => {
  const { state, dispatch } = useOrderBackgroundCheckContext();
  // Use base package to see whether the user has come from the review & submit page
  const fromReviewAndSubmitPage = !!state.basePackage.name;

  // Determines if 'Domestic' or 'International'

  const isInitialMount = useRef(true);

  const updateGeoType = useCallback(
    (country: string | null) => {
      dispatch({
        type: actionTypes.SET_SELECTED_GEO,
        payload: {
          selectedGeo: isInternationalCountry(country)
            ? 'international'
            : 'domestic',
        },
      });
    },
    [dispatch],
  );

  const isCountryDisabled = useMemo(() => {
    return (
      state.workflow === ADD_CHECKS ||
      (hierarchy_present && Object.keys(state.node).length === 0) ||
      !segmentation_enabled
    );
  }, [hierarchy_present, segmentation_enabled, state.node, state.workflow]);

  const sortedCountries = useMemo(() => {
    const result = [...countries];
    result.sort((a, b) =>
      countryItemToString(a).localeCompare(countryItemToString(b)),
    );
    return result;
  }, [countries]);

  const handleCountryChange = useCallback(
    (selectedItem: string | null) => {
      if (state.location.country !== selectedItem && fromReviewAndSubmitPage) {
        dispatch({
          type: actionTypes.RESET_ALL_EXCEPT_LOCATION,
          payload: {},
        });
      }

      updateGeoType(selectedItem);

      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: {
          location: { country: selectedItem || '', state: '', city: '' },
        },
      });
    },
    [dispatch, state.location.country, fromReviewAndSubmitPage, updateGeoType],
  );

  const onCountryChange = useCallback(
    ({ selectedItem }: { selectedItem: string | null }) => {
      handleCountryChange(selectedItem);
    },
    [handleCountryChange],
  );

  useEffect(() => {
    if (
      !isInitialMount.current ||
      state.workflow === ADD_CHECKS ||
      fromReviewAndSubmitPage ||
      goBackClicked
    ) {
      return;
    }

    if (!state.location.country) {
      const defaultCountry = countries.length === 1 ? countries[0] : 'US';
      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: {
          location: { country: defaultCountry, state: '', city: '' },
        },
      });
    }

    isInitialMount.current = false;
  }, [
    countries,
    dispatch,
    fromReviewAndSubmitPage,
    goBackClicked,
    state.location.country,
    state.workflow,
  ]);

  return (
    <>
      {segmentation_enabled && internationalWorkLocationSelection && (
        <CountryDropdown
          data-testid='country-dropdown-testid'
          items={sortedCountries}
          label='Country'
          disabled={isCountryDisabled}
          itemToString={countryItemToString}
          onChange={onCountryChange}
          selectedItem={state.location.country || null}
        />
      )}
    </>
  );
};

export default SelectCountry;
