import React from 'react';
import { M } from '@dashboard-experience/mastodon';

interface ActionsSubMenuItemProps {
  openModal: (e: React.MouseEvent) => void;
  handleI9Click: (e: React.MouseEvent) => void;
  isI9FormDisabled: boolean;
}

const ActionsSubMenuItem: React.FC<ActionsSubMenuItemProps> = ({
  openModal,
  handleI9Click,
  isI9FormDisabled,
}) => {
  return (
    <M.ActionSubMenuItem title='Add checks' position='left-bottom'>
      <M.ActionMenuItem
        title='A-la-carte order'
        description='Add checks to this order'
        onClick={openModal}
      />
      {!isI9FormDisabled && (
        <M.ActionMenuItem
          title='Order I-9'
          description='Prepare to hire this candidate'
          onClick={handleI9Click}
        />
      )}
    </M.ActionSubMenuItem>
  );
};

export default ActionsSubMenuItem;
