/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { useWorkflowScreenStates } from '@dashboard-experience/utils';
import {
  useAdjudicationChangeAllowed,
  useMobilityEngageEnabled,
} from 'modules/adjudication/utilities';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { useGetAdjudicationSubtypes } from 'modules/adjudication/api';
import { LoadingModal } from 'modules/adjudication/ui';
import { useFlag } from '@dashboard-experience/react-flagr';
import { PROGRESSIVE_V2_MANUAL_UPGRADE } from 'Constants';

import { useFetchAssessmentVariantRegitrations } from 'api/assessmentVariants/hooks';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';
import Content from './content';
import engageflowScreenStates from '../transitions';
import { useGetIncompleteScreenings } from '../../utils';

type Props = {
  open: boolean;
  hideModal: () => void;
};

enum Screens {
  MobilitySubmit = 'MobilitySubmit',
  Subtypes = 'Subtypes',
  IncompleteScreenings = 'IncompleteScreenings',
  Submit = 'Submit',
  Progressive = 'Progressive',
  Upgrade = 'Upgrade',
}

const Modal: React.FC<Props> = ({ open, hideModal }) => {
  const { isLoading } = useGetAdjudicationSubtypes();

  return isLoading ? (
    <LoadingModal onClose={hideModal} open={open} />
  ) : (
    <EngageModal open={open} hideModal={hideModal} />
  );
};

const EngageModal: React.FC<Props> = ({ open, hideModal }) => {
  const [selection, setSelection] = useState();
  const { report } = useGetReport();
  const { t } = useTranslation();

  const incompleteScreenings = useGetIncompleteScreenings();
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();
  const { engagedSubtypes } = useGetAdjudicationSubtypes();
  const mobilityEngageEnabled = useMobilityEngageEnabled();
  const useProgressiveV2Screen =
    useFlag(PROGRESSIVE_V2_MANUAL_UPGRADE)?.variantKey === 'on';

  const getInitialScreen = useCallback(() => {
    if (
      report?.account?.adjudication_subtypes_enabled &&
      adjudicationChangeAllowed &&
      engagedSubtypes.length >= 2
    ) {
      return Screens.Subtypes;
    }
    if (incompleteScreenings.length) {
      return Screens.IncompleteScreenings;
    }
    if (
      report?.progressive_info?.allow_dashboard_upgrade &&
      useProgressiveV2Screen
    ) {
      return Screens.Progressive;
    }
    if (mobilityEngageEnabled) {
      return Screens.MobilitySubmit;
    }
    return Screens.Submit;
  }, [
    incompleteScreenings,
    report,
    adjudicationChangeAllowed,
    engagedSubtypes,
    mobilityEngageEnabled,
    useProgressiveV2Screen,
  ]);

  const { currentScreen, transitionToScreen, nextScreen, previousScreen } =
    useWorkflowScreenStates(getInitialScreen(), engageflowScreenStates);

  const getHeader = useCallback(() => {
    if (currentScreen === 'Subtypes') {
      return t(`${namespace}:report.actions.engaged.modal.subtypes.header`);
    }
    if (currentScreen === 'IncompleteScreenings') {
      return t(`${namespace}:report.actions.engaged.modal.incomplete.header`);
    }
    if (currentScreen === 'Progressive' || currentScreen === 'Upgrade') {
      return t(`${namespace}:report.actions.progressive.header`);
    }
    return t(`${namespace}:report.actions.engaged.modal.submit.header`);
  }, [currentScreen, t]);

  return (
    <M.ComposedModal
      data-testid='engage-modal'
      onClose={hideModal}
      open={open}
      className='actions-modal'
    >
      <M.ModalHeader closeModal={hideModal}>
        <h2>{getHeader()}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <Content
          nextScreen={nextScreen}
          onClose={hideModal}
          transitionToScreen={transitionToScreen}
          currentScreen={currentScreen}
          onChange={setSelection}
          selection={selection}
        />
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default Modal;
