import React from 'react';
import { kebabCase, orderBy, reverse } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import {
  hasPermission,
  CurrentUser,
  localStorageFallback,
  GenericObject,
} from '@dashboard-experience/utils';
import { Candidate } from 'types';
import CellLink from 'components/Search/CellLink';
import { ProcessingStatus } from 'components/Search/SearchTableContent';
import {
  ProcessingStatusTableCell,
  StyledSearchCell,
} from 'components/Search/SearchTableContent/SearchTableStyledComponents';
import Cell from 'components/Search/SearchTableContent/Cell';

type Props = {
  currentUser: CurrentUser;
  candidates: GenericObject;
  rows: any;
  columns: any;
  getRowProps: any;
  isDSP: boolean;
};

const getCandidateUri = (candidate: Candidate, currentUser: CurrentUser) => {
  if (hasPermission(currentUser, 'read_candidate_details')) {
    const lastReport = candidate.reports?.[candidate.reports.length - 1];
    const path = lastReport
      ? `/candidates/${candidate.id}/reports/${lastReport.id}`
      : `/candidates/${candidate.id}`;
    return path;
  }
  return '';
};

const showProcessingStatus = (candidate: Candidate) => {
  const storageData = localStorageFallback.getItem('processingStatusData');
  if (storageData) {
    const processingStatusData = JSON.parse(storageData);

    if (processingStatusData[candidate.id]) {
      const processingTimestamp = processingStatusData[candidate.id].timestamp;
      const candidateIndexedAt = new Date(
        candidate.indexed_at as any,
      ).getTime();

      // If there is no indexed_at, or the indexed_at is earlier than the timestamp of the action, the update hasn't been indexed yet so its still processing. if the indexed_at happened after the action, its done processing
      if (!candidate.indexed_at || processingTimestamp > candidateIndexedAt)
        return true;
    }
  }
  return false;
};

const TableBody: React.FC<Props> = ({
  currentUser,
  candidates,
  rows,
  columns,
  getRowProps,
  isDSP,
}) => {
  const canReadCandidateDetails = hasPermission(
    currentUser,
    'read_candidate_details',
  );

  return (
    <M.TableBody data-testid='search-table-body'>
      {rows.map((row: any, rowIdx: number) => {
        const candidate = candidates.data[rowIdx];
        // Fixes an intermediate state bug when the candidates list updates to a smaller amount
        if (!candidate) return null;
        const { reports } = candidate;
        const latestReport = reverse(
          orderBy(reports, ['created_at'], ['asc']),
        )?.[0];

        const candidateURI = getCandidateUri(candidate, currentUser);

        const shouldShowProcessingStatus = showProcessingStatus(candidate);

        const { geos } = currentUser;

        return (
          <React.Fragment key={candidate.id}>
            <M.TableRow
              {...getRowProps({ row })}
              data-testid={`search-table-row-${rowIdx}`}
            >
              {!shouldShowProcessingStatus && (
                <StyledSearchCell className='empty-cell' />
              )}
              {shouldShowProcessingStatus && (
                <ProcessingStatusTableCell className='start-cell flex-cell newSearchTableCell'>
                  <ProcessingStatus tooltipAlignment='bottom-left' />
                </ProcessingStatusTableCell>
              )}
              {columns.map(
                (column: any) =>
                  column.hasPermission({ currentUser, geos }) && (
                    <StyledSearchCell
                      className={isDSP ? 'unclickable' : ''}
                      key={`${column.key}-${candidate.id}`}
                      style={column.cellStyle || {}}
                    >
                      {!isDSP ? (
                        <CellLink
                          data-testid={`search-column-${kebabCase(
                            column.key,
                          )}-${rowIdx}`}
                          className={
                            !canReadCandidateDetails ? 'limited-role' : ''
                          }
                          path={candidateURI}
                        >
                          <Cell
                            report={latestReport}
                            currentUser={currentUser}
                            candidate={candidate}
                            cellKey={column.key}
                          />
                        </CellLink>
                      ) : (
                        <Cell
                          currentUser={currentUser}
                          data-testid={`search-column-${kebabCase(
                            column.key,
                          )}-${rowIdx}`}
                          candidate={candidate}
                          cellKey={column.key}
                        />
                      )}
                    </StyledSearchCell>
                  ),
              )}
            </M.TableRow>
          </React.Fragment>
        );
      })}
    </M.TableBody>
  );
};

export default TableBody;
