/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { NAV_COLLAPSE_TRANSITION } from 'modules/layout/navigation/constants';
import { headerSectionStyles } from '../styles';

const OVERVIEW_SECTION_WIDTH = '445px';

export const OverviewSection = styled.section`
  transition: transform ${NAV_COLLAPSE_TRANSITION};

  &.show {
    border-left: 1px solid ${colors.uiGrey200};
    transform: translateX(0);
  }

  &.hidden {
    transform: translateX(100%);
  }

  div[data-testid='candidate-overview-header'] {
    padding: 0.75rem 2rem;
    background: ${colors.uiGrey0};
    display: flex;
    align-items: center;
    ${headerSectionStyles};

    p {
      font-size: 1rem;
      font-weight: 700;
      color: ${colors.uiGrey900};
    }
  }

  .candidate-overview-shrink-section {
    transition: width ${NAV_COLLAPSE_TRANSITION};

    &.show {
      width: ${OVERVIEW_SECTION_WIDTH};
    }

    &.hidden {
      width: 0px;
    }
  }

  .candidate-overview-container {
    width: ${OVERVIEW_SECTION_WIDTH};
  }
`;
