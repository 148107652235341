import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';
import withReportContentContainer from '../report-content-container';
import { ExpandablePanel, KeyValueList } from '../styles';
import { FieldKeys, SectionProps } from '../types';

export const fieldKeys: FieldKeys[] = [
  'status',
  'adjudication',
  'report_id',
  'assigned_to',
  'tags',
];

const GeneralDetails: React.FC<SectionProps> = ({ fieldKeysToItems }) => {
  const reportInformation = fieldKeysToItems(fieldKeys);

  const { t } = useTranslation();
  const title = t(`${namespace}:report.overview.general-details.title`);

  return reportInformation.length > 0 ? (
    <ExpandablePanel
      data-pendo-id='report-information-panel'
      initialExpanded
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={reportInformation}
        data-testid='overview-report-information'
      />
    </ExpandablePanel>
  ) : null;
};

export default withReportContentContainer(GeneralDetails);
