import React, { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

type Props = {
  itemKey: any;
  itemValue: any;
  id: string;
};

const SortableItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 0.75rem;
  transform-origin: center;
  -webkit-font-smoothing: antialiased;

  &:hover .drag-handle {
    opacity: 1;
  }

  .mastodon-actionable-text-field {
    padding: 0 !important;
    max-width: 12.75rem !important;
  }
`;

const DragHandle = styled.span`
  cursor: grab;
  display: flex;
  align-items: center;
  width: 1rem;
  opacity: 0;
  transition: opacity 0.2s ease;

  &:active {
    cursor: grabbing;
  }
`;

const KeySpan = styled.span`
  font-weight: 500;
  color: ${colors.uiGrey600};
  min-width: 9.25rem;
`;

const ValueSpan = styled.span`
  color: ${colors.uiGrey900};
  flex: 1;
  max-width: 12.75rem;
  overflow-wrap: break-word;
`;

const SortableItem = ({ itemKey, itemValue, id }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style: CSSProperties = {
    transform: transform
      ? `translate3d(${Math.round(transform.x)}px, ${Math.round(
          transform.y,
        )}px, 0)`
      : undefined,
    transition,
  };

  return (
    <SortableItemContainer ref={setNodeRef} style={style} {...attributes}>
      <DragHandle className='drag-handle' {...listeners}>
        <M.Icon
          icon='Draggable'
          style={{ fill: colors.uiGrey900, opacity: 0.65 }}
          size={16}
        />
      </DragHandle>
      <KeySpan className='key'>{itemKey}</KeySpan>
      <ValueSpan className='value'>{itemValue}</ValueSpan>
    </SortableItemContainer>
  );
};

export default SortableItem;
