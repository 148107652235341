import React, { useEffect, useState } from 'react';
import { toastSuccess } from 'actions';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import ReportModal from 'components/Report/ReportModal';
import { useRemoveSuppressions } from 'api/communicator/hooks';
import { Candidate } from 'types';
import { namespace } from 'modules/candidate/locales';

type SuppressionModalProps = {
  candidate: Candidate;
  showModal: boolean;
  allowEdit: boolean;
  onClose: () => void;
};

// Component to display for removing a Candidate from the Suppression list
const SuppressionModal: React.FC<SuppressionModalProps> = ({
  candidate,
  showModal,
  onClose,
  allowEdit,
}) => {
  const { removalCall, removalResult } = useRemoveSuppressions(candidate);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const tKey = `${namespace}:report.email-suppression`;

  useEffect(() => {
    // Check for a response from the server, for success/error of removing Candidate from the Suppression list
    if (removalResult.isSuccess || removalResult.isError) {
      if (removalResult.isSuccess) {
        dispatch(toastSuccess('Success:', t(`${tKey}.success`)));
        onClose();
      } else {
        // Displaying a ToastError message is left at the Hooks level, because that's our default platform-wide behavior
        setIsError(true);
      }

      // After acting upon the success/error server response, reset its state
      removalResult.reset();
    }
  }, [dispatch, onClose, removalResult, t]);

  // Error state, for the response from the API call
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <ReportModal
      open={showModal}
      onClose={onClose}
      type='small'
      kind='destructive'
    >
      <M.ModalHeader
        closeModal={onClose}
        title={t(`${tKey}.heading`)}
        icon={<M.Icon icon='WarningHexFilled' />}
      />

      <M.ModalBody padding='0'>
        <p>{t(`${tKey}.body`)}</p>

        <p>
          <Trans
            t={t}
            i18nKey={`${tKey}.update_prompt`}
            components={{ strong: <strong /> }}
          />
        </p>
      </M.ModalBody>

      <M.ModalFooter
        actions={{
          primary: {
            onClick: removalCall,
            name: t(`${tKey}.try_again_button`),
          },
          secondary: { onClick: onClose, name: t('verbs.cancel') },
        }}
      >
        {isError && (
          <div className='errorContainer'>
            <span>{t('data_deletion.error')}</span>
          </div>
        )}
      </M.ModalFooter>
    </ReportModal>
  );
};

export default SuppressionModal;
