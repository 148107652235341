import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { useTranslation } from 'react-i18next';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import { namespace } from '../locales';
import { StyledFooter, StyledStepContainer } from '../ui';
import { STEPS } from '../utils/constants';
import {
  CSV_TEMPLATE,
  CSV_EXAMPLE,
  StyledContainer,
  StyledStepTitle,
  StyledStepContent,
  StyledSecondaryBtn,
} from './BulkUploadStep.styles';
import Upload from './Upload';

const BulkUploadStep: React.FC<StepProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'bulkUploadStep',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const history = useHistory();
  const { contextId } = useContext(UIContext);
  const { selectedPackage } = useBetterOrderExperienceContext();

  const handleDownloadTemplate = useCallback(() => {
    window.location.href = CSV_TEMPLATE;
  }, []);

  const handleDownloadExample = useCallback(() => {
    window.location.href = CSV_EXAMPLE;
  }, []);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/review-and-submit';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.REVIEW_AND_SUBMIT);
  }, [history, contextId, setCurrentStep]);

  useEffect(() => {
    // Fail safe for if a user navigates to this step via a back button and no package was selected
    if (!selectedPackage?.name) {
      history.replace(STEPS.GET_STARTED.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledStepContainer data-testid='bulk-upload-step-container'>
      <StyledContainer>
        <h4 data-testid='upload-info-heading'>{t('uploadInfo')}</h4>
        <StyledStepTitle data-testid='upload-info-step-1-title'>
          {t('downloadOurTemplate')}
        </StyledStepTitle>
        <StyledStepContent>
          <StyledSecondaryBtn
            kind='secondary'
            onClick={handleDownloadTemplate}
            data-testid='upload-info-download-template'
          >
            <M.Icon icon='Download' />
            {t('downloadTemplate')}
          </StyledSecondaryBtn>
          <M.Button
            kind='tertiary'
            onClick={handleDownloadExample}
            data-testid='upload-info-download-example'
          >
            {t('downloadExample')}
          </M.Button>
        </StyledStepContent>
        <StyledStepTitle data-testid='upload-info-step-2-title'>
          {t('uploadCompletedTemplate')}
        </StyledStepTitle>
        <StyledStepContent data-testid='upload-info-upload-section'>
          <Upload setIsButtonDisabled={setIsButtonDisabled} />
        </StyledStepContent>
        <p>{t('confirm')}</p>
      </StyledContainer>
      <StyledFooter>
        <M.Button
          type='button'
          data-testid='bulk-upload-continue-button'
          disabled={isButtonDisabled}
          onClick={handleContinue}
        >
          {t('continue')}
        </M.Button>
      </StyledFooter>
    </StyledStepContainer>
  );
};

export default BulkUploadStep;
