import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { PromoCodeResType } from 'api/promotionCodes';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { BETTER_ORDER_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import {
  StyledAppliedPromoCode,
  StyledPromoCodeBannerContainer,
  StyledPromoCodeInputContainer,
} from './PricingSection.styles';
import { namespace } from '../../locales';
import PromoCodeBanner from './PromoCodeBanner';

export type PromoCodeValidationType = {
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  reset: () => void;
  call: () => Promise<any>;
  data?: { promotional_campaign?: PromoCodeResType };
};

type PromoCodeInputType = {
  promoCode?: string;
  setPromoCode: Dispatch<SetStateAction<string>>;
  promoCodeValidation: PromoCodeValidationType;
};

const PromoCodeInput = ({
  promoCode = '',
  setPromoCode,
  promoCodeValidation,
}: PromoCodeInputType) => {
  const { t } = useTranslation(namespace);
  const trackEvent = useTrackEvent();
  const {
    isIdle,
    isSuccess,
    reset: resetValidatePromoCode,
    call: validatePromoCodeCall,
    data: validatePromoCodeData,
  } = promoCodeValidation;
  const promotionalCampaignData = validatePromoCodeData?.promotional_campaign;

  const resetPromoCodeClickHandler = useCallback(() => {
    setPromoCode('');
    resetValidatePromoCode();
  }, [resetValidatePromoCode, setPromoCode]);

  const promoCodeInputChangeHandler = useCallback(
    e => {
      setPromoCode(e.target.value);
    },
    [setPromoCode],
  );

  const handlePromoCodeInputClick = useCallback(() => {
    trackEvent(BETTER_ORDER_EVENT_NAMES.APPLY_PROMO_CODE_CLICKED);
  }, [trackEvent]);

  const handlePromoCodeApplyClick = useCallback(() => {
    validatePromoCodeCall();
  }, [validatePromoCodeCall]);

  return (
    <>
      <StyledPromoCodeInputContainer>
        {isIdle ? (
          <M.TextInput
            data-test-id='summary-promo-code-text-input'
            placeholder={t(
              `reviewAndSubmit.infoSection.pricingSummary.promoDiscount.addPromo`,
            )}
            onChange={promoCodeInputChangeHandler}
            onClick={handlePromoCodeInputClick}
          />
        ) : (
          <StyledAppliedPromoCode>
            {promoCode}
            <M.Icon
              icon='Close'
              size={16}
              onClick={resetPromoCodeClickHandler}
            />
          </StyledAppliedPromoCode>
        )}
        <M.Button
          data-test-id='summary-promo-code-apply-btn'
          kind='secondary'
          onClick={handlePromoCodeApplyClick}
          disabled={!isIdle || promoCode === ''}
        >
          {t(`reviewAndSubmit.infoSection.pricingSummary.promoDiscount.apply`)}
        </M.Button>
      </StyledPromoCodeInputContainer>
      {isSuccess && (
        <StyledPromoCodeBannerContainer>
          <PromoCodeBanner
            type={
              promotionalCampaignData?.errors &&
              promotionalCampaignData?.errors?.length > 0
                ? 'error'
                : 'success'
            }
            text={
              promotionalCampaignData?.errors &&
              promotionalCampaignData?.errors?.length > 0
                ? promotionalCampaignData?.errors[0]
                : t(
                    `reviewAndSubmit.infoSection.pricingSummary.promoDiscount.success`,
                  )
            }
          />
        </StyledPromoCodeBannerContainer>
      )}
    </>
  );
};

export default PromoCodeInput;
