import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useHandlePasteForInviteForm } from 'hooks';
import { AddOnsType, BasePackage, Candidate } from 'components/Signup/types';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ALIAS_IN_SIGNUP, PROMO_CODE_FIELD } from 'Flags';
import useCalculatePrices from 'components/Signup/hooks/useCalculatePrices';
import OrderSummary from '../../OrderSummary';
import WorkLocationSection from '../WorkLocationSection';
import ContactInfoSection from '../ContactInfoSection';
import { Container, InnerContainer } from './styles';
import { ADD_ONS_PRICES } from '../../../constants/screenings';
import BasePackages from '../../../constants/basePackages';
import AliasRadioButton from '../../Alias';
import { ValidatePromoCodeType } from '../../OrderSummary/OrderSummary';

type Props = {
  addOns: AddOnsType[];
  candidates: Candidate[];
  setCandidates: Dispatch<SetStateAction<Candidate[]>>;
  isInternational: boolean;
  selectedPackage: BasePackage;
  workLocation: any;
  setWorkLocation: Dispatch<SetStateAction<any>>;
  alias: { [key: string]: string | null };
  promoCode: string | undefined;
  setPromoCode: Dispatch<SetStateAction<string | undefined>>;
  promoCodeValid: boolean | undefined;
  promoCodeValidation?: ValidatePromoCodeType;
};

export const transformCandidate = (candidates: Candidate[]) => {
  return candidates.map(candidate => {
    return {
      email: {
        value: candidate.email,
      },
    };
  });
};

export const transformDispatchPayload = (data: any[]) => {
  return data.map(candidateInfo => {
    return {
      id: candidateInfo.id,
      email: candidateInfo.email.value,
    };
  });
};

const CandidateInfoSection: React.FC<Props> = ({
  addOns,
  candidates,
  setCandidates,
  isInternational,
  selectedPackage,
  workLocation,
  setWorkLocation,
  alias,
  promoCode,
  setPromoCode,
  promoCodeValid,
  promoCodeValidation,
}) => {
  const aliasEnabled = useFlag(ALIAS_IN_SIGNUP)?.variantKey === 'on' || false;
  const showPromoCodeField = useFlag(PROMO_CODE_FIELD)?.variantKey === 'on';
  const { aliasPrice, subtotal, basePackagePrice, customPackagePrice } =
    useCalculatePrices();

  const candidateSetter = useCallback(
    data => {
      const pastedCandidates = transformDispatchPayload(data.payload);
      setCandidates(pastedCandidates);
    },
    [setCandidates],
  );

  // TODO: Refactor or create a new reusable paste function
  const transformCandidateState = transformCandidate(candidates);

  const handlePaste = useHandlePasteForInviteForm(
    { invites: transformCandidateState }, // Acting as our "state" input
    candidateSetter, // Acting as our "dispatch" input
  );

  const clearPromoCode = useCallback(() => {
    if (!promoCodeValidation?.isIdle) {
      promoCodeValidation?.reset();
      setPromoCode(undefined);
    }
  }, [setPromoCode, promoCodeValidation]);

  return (
    <Container data-testid='candidate-info-section' onPaste={handlePaste}>
      <InnerContainer>
        <WorkLocationSection
          isInternational={isInternational}
          workLocation={workLocation}
          setWorkLocation={setWorkLocation}
        />
        <ContactInfoSection
          candidates={candidates}
          setCandidates={setCandidates}
        />
        {aliasEnabled && !isInternational && (
          <AliasRadioButton
            aliasPrice={aliasPrice}
            clearPromoCode={clearPromoCode}
          />
        )}
      </InnerContainer>
      <OrderSummary
        packageName={BasePackages[selectedPackage].name}
        basePackagePrice={basePackagePrice}
        customPackagePrice={customPackagePrice}
        screenings={addOns}
        candidates={candidates}
        screeningPrices={ADD_ONS_PRICES}
        isInternational={isInternational}
        isAliasEnabled={alias[selectedPackage]}
        aliasPrice={aliasPrice}
        subtotal={subtotal}
        showPromoCodeField={showPromoCodeField}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        promoCodeValid={promoCodeValid}
        promoCodeValidation={promoCodeValidation}
      />
    </Container>
  );
};

export default CandidateInfoSection;
