import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import gavel from 'components/AddScreenings/shared/svg/gavel.svg';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import CountyCivilSearch from './CountyCivilSearch';
import FederalCivilSearch from './FederalCivilSearch';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
};

const CivilSearchesSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  addOnPrices,
  isAdded,
  isIncluded,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.civilSearches',
  });

  const isCountyCivilSearchIncluded = isIncluded(
    ScreeningProductType.COUNTY_CIVIL_SEARCH,
  );

  const isFederalCivilSearchIncluded = isIncluded(
    ScreeningProductType.FEDERAL_CIVIL_SEARCH,
  );

  const countyCivilSearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.COUNTY_CIVIL_SEARCH,
  );

  return (
    <ScreeningSection id='civil-searches' name={t('sectionName')} icon={gavel}>
      <StyledFlex flexDirection='column'>
        <CountyCivilSearch
          id={ScreeningProductType.COUNTY_CIVIL_SEARCH as string}
          price={countyCivilSearchPrice}
          isAdded={isAdded(ScreeningProductType.COUNTY_CIVIL_SEARCH)}
          isIncluded={isCountyCivilSearchIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
        <FederalCivilSearch
          id={ScreeningProductType.FEDERAL_CIVIL_SEARCH as string}
          price={countyCivilSearchPrice}
          isAdded={isAdded(ScreeningProductType.FEDERAL_CIVIL_SEARCH)}
          isIncluded={isFederalCivilSearchIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
      </StyledFlex>
    </ScreeningSection>
  );
};

export default CivilSearchesSection;
