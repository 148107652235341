import React, { useCallback, Fragment } from 'react';
import {
  useTrackEvent,
  PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES,
} from 'utils/analytics';
import useGetIndustries from '../../hooks/useGetIndustries';
import IndustryTile from './IndustryTile/IndustryTile';
import { Wrapper, Divider, TextInput } from './IndustrySelection.styles';
import useSearchFilter from '../../hooks/useSearchFilter';
import FilterSection from './FilterSection';

interface Props {
  selectedOption: number | null;
  onOptionChange: (industry: { id: number; name: string }) => void;
  customIndustry: string;
  setCustomIndustry: (industry: string) => void;
  hasTrackedIndustrySearch: React.MutableRefObject<boolean>;
}

const filterIndustries = (
  item: { id: number; name: string; svg_s3_url: string },
  searchFilter: string,
) =>
  !searchFilter || item.name.toLowerCase().includes(searchFilter.toLowerCase());

const IndustrySelection: React.FC<Props> = ({
  selectedOption,
  onOptionChange,
  customIndustry,
  setCustomIndustry,
  hasTrackedIndustrySearch,
}) => {
  const trackEvent = useTrackEvent();
  const { data, isSuccess } = useGetIndustries();
  const { searchParam, filteredData, handleSearchChange } = useSearchFilter(
    data?.industries,
    filterIndustries,
  );

  const onTextInputChange = useCallback(
    e => setCustomIndustry(e.target.value),
    [],
  );

  const handleIndustrySearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearchChange(e);
      if (!hasTrackedIndustrySearch.current) {
        trackEvent(
          PURCHASE_RECOMMENDATION_ONBOARDING_EVENT_NAMES.INDUSTRY_SEARCH_USED,
        );
        hasTrackedIndustrySearch.current = true;
      }
    },
    [handleSearchChange, trackEvent, hasTrackedIndustrySearch],
  );

  return (
    <>
      <FilterSection
        tKey='industrySelection'
        searchParam={searchParam}
        handleSearchChange={handleIndustrySearch}
      />
      {isSuccess && (
        <Wrapper>
          {filteredData.map(
            (item: { id: number; name: string; svg_s3_url: string }) =>
              item.name !== 'Custom Industry' ? (
                <IndustryTile
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  icon={item.svg_s3_url}
                  selected={item.id === selectedOption}
                  onClick={onOptionChange}
                />
              ) : (
                <Fragment key={item.id}>
                  <Divider />
                  <IndustryTile
                    id={item.id}
                    name='None of these'
                    icon={item.svg_s3_url}
                    selected={item.id === selectedOption}
                    onClick={onOptionChange}
                  />
                  {item.id === selectedOption && (
                    <TextInput
                      id='custom-industry-input'
                      placeholder='Please enter an industry name'
                      value={customIndustry}
                      onChange={onTextInputChange}
                    />
                  )}
                </Fragment>
              ),
          )}
        </Wrapper>
      )}
    </>
  );
};

export default IndustrySelection;
