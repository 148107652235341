import { ReportStatuses } from '@dashboard-experience/utils';
import { titleCase } from 'humanize-plus';
import { IdentityVerification, InternationalCriminalSearchV2 } from 'types';
import { isoCountry } from 'utils';

const formatCountry = (country: string) =>
  `${titleCase(isoCountry(country))} (${country})`;

export const getInternationalRecordStatusOrAssessment = (
  status: string,
  assessment?: string | null,
) => {
  return status === ReportStatuses.CONSIDER && assessment ? assessment : status;
};

export const hasFailedOrExpiredVerifications = (
  verifications?: IdentityVerification[],
): boolean => {
  if (!verifications?.length) return false;

  return verifications.some(verification => {
    const status = verification.task_result?.status?.toLowerCase() || '';
    return status === 'expired' || status === 'failed';
  });
};

// TODO: Remove after change is made in backend to update screening status
// from 'canceled' to  'suspended' when IDV is failed or expired
export const transformSearchStatuses = (
  searches: InternationalCriminalSearchV2[] | false,
  idvRedesignFlagEnabled: boolean,
): InternationalCriminalSearchV2[] | false => {
  if (!idvRedesignFlagEnabled || !searches || (searches && !searches?.length))
    return searches;

  return searches.map((search: InternationalCriminalSearchV2) => {
    if (
      search.country === 'GB' &&
      search.status === 'suspended' &&
      hasFailedOrExpiredVerifications(search.identity_verifications)
    ) {
      return {
        ...search,
        status: 'canceled',
      };
    }
    return search;
  });
};

export default formatCountry;
