import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const Card = styled.div`
  display: flex;
  background: ${colors.uiGrey0};
  margin: 0 16px 16px 0;
  cursor: pointer;
  padding: 7px 12px;
  border-radius: 6px;
  border: 1px solid ${colors.borderPrimaryLight};

  line-height: 24px;
  color: ${colors.uiTextSecondaryLight};
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;

  &.disabled {
    cursor: not-allowed;
  }

  &.selected {
    border-color: ${colors.uiNavy200};
    background: ${colors.uiNavy100};
    border-color: ${colors.uiNavy200};
  }

  &:not(.selected):hover {
    color: ${colors.uiTextPrimaryLight};
  }

  &.selected.removable:hover {
    background: ${colors.uiNavy200};
  }
`;

export const TextBox = styled.div``;
