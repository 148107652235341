import React, { useEffect, useMemo, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  NodesDropdown,
  CountryDropdown,
  StateDropdown,
  CityDropdown,
} from '../Dropdowns';
import type { GetStartedT } from '../..';
import {
  City,
  MAJOR_CITIES,
} from '../../../Packages/OrderBackgroundCheck/GetStarted/MajorCities';

type Props = {
  node?: GetStartedT.Node;
  countries: string[];
  selectedLocation: GetStartedT.WorkLocation;
  setSelectedLocation: (location: GetStartedT.WorkLocation) => void;
  nodes: GetStartedT.Node[];
  nodesIsLoading: boolean;
  selectedNode?: GetStartedT.Node;
  setSelectedNode: (node: GetStartedT.Node) => void;
  handleNodeSearchTextChange: (searchText: string) => void;
  nodeSearchText: string;
};

const NodesAndWorkLocationSection: React.FC<Props> = ({
  node,
  countries,
  selectedLocation,
  setSelectedLocation,
  nodes = [],
  nodesIsLoading,
  selectedNode,
  setSelectedNode,
  handleNodeSearchTextChange,
  nodeSearchText,
}) => {
  const singleNode = nodes.length === 1;
  const hasNoNodes = nodes.length === 0;

  // Disable the nodes dropdown if there is only one node
  // Accounts with one node will also be autoselected in the useEffect below
  // const nodesInvalid = !nodesIsLoading && !blankNodeSearchText && hasNoNodes;

  const nodesDisabled = singleNode && nodeSearchText === '';
  const nodesInvalid =
    !nodesIsLoading && nodeSearchText !== '' && nodes.length === 0;

  const showNodesDropdown =
    (nodes.length === 0 && nodeSearchText !== '') || nodes.length > 0;

  const showCountryDropdown = countries.length > 0;
  const countryAndStateDisabled = nodes.length > 0 && !selectedNode;
  const [showStateDropdown, setShowStateDropdown] = useState(
    selectedLocation?.country === 'US',
  );
  const showCityDropdown = selectedLocation?.state;

  const selectableCities = useMemo(
    () => MAJOR_CITIES.filter((c: City) => c.state === selectedLocation?.state),
    [selectedLocation?.state],
  );
  const cityDropdownShouldBeShown =
    showCityDropdown && selectableCities.length > 0;

  useEffect(() => {
    // If account has one node, auto-select the node
    if (singleNode) {
      setSelectedNode(nodes[0]);
    }

    if (
      (!selectedLocation || Object.keys(selectedLocation).length === 0) &&
      (singleNode || hasNoNodes)
    ) {
      // Reset location
      setSelectedLocation({
        country: 'US',
        state: undefined,
        city: undefined,
      });
    }
  }, [
    hasNoNodes,
    node,
    nodes,
    selectedLocation,
    setSelectedLocation,
    setSelectedNode,
    singleNode,
  ]);

  useEffect(() => {
    if (selectedLocation?.country === 'US') {
      setShowStateDropdown(true);
    } else {
      setShowStateDropdown(false);
    }
  }, [selectedLocation]);

  return (
    <M.Grid style={{ margin: 0 }} data-testid='nodes-and-work-location-section'>
      <M.GridRow style={{ margin: 0 }}>
        {showNodesDropdown && (
          <NodesDropdown
            nodes={nodes}
            handleNodeFilter={handleNodeSearchTextChange}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            setSelectedLocation={setSelectedLocation}
            isLoading={nodesIsLoading}
            invalid={nodesInvalid}
            disabled={nodesDisabled}
          />
        )}
        {showCountryDropdown && (
          <CountryDropdown
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            countries={countries.length > 0 ? (countries as string[]) : ['US']}
            disabled={countryAndStateDisabled}
          />
        )}
      </M.GridRow>

      <M.GridRow>
        {showStateDropdown && (
          <StateDropdown
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            disabled={countryAndStateDisabled}
          />
        )}
        {cityDropdownShouldBeShown && (
          <CityDropdown
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        )}
      </M.GridRow>
    </M.Grid>
  );
};

export default NodesAndWorkLocationSection;
