import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const StyledBoldTitle = styled.span`
  color: ${colors.uiTextPrimaryLight};
  font-weight: 700;
`;

export const StyledRecommendedLabel = styled(M.TagV2)`
  margin-left: 16px !important;
  background-color: #c4f3f8 !important;
`;

export const StyledDescription = styled.div`
  color: ${colors.uiTextSecondaryLight};
  margin-top: 1rem;
`;

export const StyledClickableText = styled.span`
  color: ${colors.uiNavy600};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSelectableAliasContainer = styled.div`
  margin-top: 1rem;
  width: 100%;

  #mastodon & .mastodon-choice-card {
    margin-right: 0;
    width: 100%;
  }
`;
