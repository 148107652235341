import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledSearchContainer = styled(M.Search)`
  width: 100%;
  max-width: 500px;
  margin-bottom: 36px !important;
  .cds--search-input {
    color: ${colors.brandSlate6} !important;
    border-bottom: 1px solid ${colors.brandSlate7} !important;
  }
  .cds--search-input::placeholder {
    color: ${colors.brandSlate6};
  }
  .cds--search-magnifier-icon {
    fill: ${colors.brandSlate6};
  }
`;

export const StyledHeader = styled.p`
  width: 744px;
  color: ${colors.brandNavy4};
  margin-bottom: 1rem !important;

  @media all and (max-width: 1023px) {
    width: 100%;
  }
`;

export const StyledPaginationContainer = styled(M.Pagination)`
  display: flex;
  justify-content: center;
`;

export const StyledUnavailablePackageTooltip = styled(M.Tooltip)`
  &.cds--tooltip {
    width: 100% !important;
    .cds--popover-content {
      max-width: 100% !important;
    }
  }
`;
