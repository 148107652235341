import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const Card = styled.div`
  display: flex;
  width: 240px;
  min-width: 240px;
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--text-icon-white-primary, #fff);
  border: 1px solid #e1e6eb;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);
  }

  &.selected {
    border-color: #d1e4f8;
    background: #f5f9fc;
  }
`;

export const IconBox = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  flex-shrink: 0;
  background: #f7f9fa;

  ${Card}:hover & {
    background: #f2f5f7;
  }

  ${Card}.selected & {
    background: #e8f0f9;
  }

  svg {
    fill: #8793a1;

    ${Card}:hover & {
      fill: #626e7a;
    }

    ${Card}.selected & {
      fill: #0a57a4;
    }

    ${Card}.selected:hover & {
      fill: #04417f;
    }
  }
`;

export const TextBox = styled.div`
  display: flex;
  height: 40px;
  max-width: 180px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: #1a2026c7;

  /* Mastodon/P3 */
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  ${Card}:hover & {
    color: #1a2026;
  }
`;

export const StyledIcon = styled(M.Icon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
