import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useFlag } from '@dashboard-experience/react-flagr';
import {
  humanizeOnUnderscore,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import { updateSearchParams } from 'actions/SearchActions';
import { useUser } from 'context/CurrentUser';
import { IDENTITY_VERIFICATION_REDESIGN } from 'Flags';
import { useDispatch } from 'react-redux';
import { CANDIDATE_SEARCH_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { useReduxState } from '../hooks';
import MultiSelectFilter from './MultiSelectFilter';
import { styledFilter } from './StyledComponents';

const IDV_STATUS_ITEMS = [
  { label: 'Pending', idv_status: 'pending' },
  { label: 'Clear', idv_status: 'completed' },
  { label: 'Not successful', idv_status: 'unsuccessful' },
];

const StyledMultiSelectFilter = styled(MultiSelectFilter)`
  ${styledFilter}
`;

const getInitialStatuses = (params: any) =>
  params.filter((t: string) =>
    IDV_STATUS_ITEMS.map((item: any) => item.idv_status.includes(t)),
  );

const IdentityVerificationStatus = () => {
  const { searchParams } = useReduxState();
  const currentUser = useUser();
  const dispatch = useDispatch();
  const ref: any = useRef();

  const initialSelectedStatuses = useMemo(
    () => getInitialStatuses(searchParams.idv_status || []),
    [searchParams.idv_status],
  );

  const [idvStatus, setIdvStatus] = useState(
    initialSelectedStatuses.map((item: { idv_status: any }) => item.idv_status),
  );

  useEffectAfterInitialized(() => {
    if (idvStatus !== undefined && idvStatus.length > 0) {
      dispatch(updateSearchParams({ idv_status: idvStatus }));
    } else {
      dispatch(updateSearchParams({ idv_status: undefined }));
      ref?.current?.clearAll();
    }
  }, [idvStatus, dispatch]);

  const ukGeoExists = useMemo(
    () =>
      currentUser?.geos?.find(
        (geo: any) => geo.name === 'United Kingdom' && !geo.deleted_at,
      ),
    [currentUser?.geos],
  );

  const IdentityVerificationStatusFlagEnabled =
    useFlag(IDENTITY_VERIFICATION_REDESIGN)?.variantKey === 'on';

  const trackEvent = useTrackEvent();

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const onChange = useCallback(
    (setFilters: (params: any) => void) =>
      ({ selectedItems }: { selectedItems: Array<any> }) => {
        const statuses = selectedItems.map(item => item.idv_status);

        if (selectedItems.length > selectedItemsCount) {
          trackEvent(
            CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_IDV_STATUS_FILTER_SELECTED,
            {
              'IDV Status': humanizeOnUnderscore(statuses.slice(-1).pop()),
              'Selected Filter': 'IDV Status',
            },
          );
        }
        setSelectedItemsCount(selectedItems.length);
        setFilters({ idv_status: statuses });
        setIdvStatus(statuses);
      },
    [selectedItemsCount, trackEvent],
  );

  if (!IdentityVerificationStatusFlagEnabled || !ukGeoExists) {
    return null;
  }

  return (
    <StyledMultiSelectFilter
      initialSelectedItems={initialSelectedStatuses}
      items={IDV_STATUS_ITEMS}
      onChange={onChange}
      component='FilterMultiSelect'
      placeholder='identity verification'
      titleText='Identity verification status'
      type='idv_status'
    />
  );
};

export default IdentityVerificationStatus;
