import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { ActionType } from '../state/types';
import { useDispatch } from '../state/hooks';

type Props = {
  count: number;
  pageNum: number;
  perPage?: number;
};

const FlexContainer = styled.div`
  .displayFlexJustified {
    display: flex;
    justify-content: flex-end;
  }
`;

const PaginationContainer: React.FC<Props> = ({
  count,
  pageNum,
  perPage = 25,
}) => {
  const dispatch = useDispatch();

  const pageCount = Math.ceil(count / perPage);

  const handlePageClick = useCallback(
    pageIdx =>
      dispatch({
        type: ActionType.UPDATE_PAGE,
        payload: pageIdx + 1,
      }),
    [dispatch],
  );

  return (
    <div data-testid='reports-list-pagination'>
      <FlexContainer>
        <M.Pagination
          pageCount={pageCount}
          onPageClick={handlePageClick}
          selectedIndex={pageNum - 1}
          className='displayFlexJustified'
        />
      </FlexContainer>
    </div>
  );
};

export default PaginationContainer;
