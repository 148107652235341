import React, { memo, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getStatusTypeForReport } from '@dashboard-experience/utils';
import styled from 'styled-components';

import { useNestedPreference } from 'api/dashboardPreferences';
import { useReportsWithMultiMvr } from 'api/reports';
import { useReportEta } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { useLastReportTask } from 'modules/case-management/ui/TasksPage/hooks';
import { useInvitation } from 'modules/candidate/utilities';
import {
  useCandidate,
  usePinnedFields,
  usePinnedFieldsFlag,
} from 'providers/Candidate';

import Sections from './sections';
import configureFieldKeysToItems from './sections/configure-field-keys-to-items';

const ComponentsContainer = styled.div`
  margin: 1.5rem;

  #mastodon && {
    .expandable-panel-content-body {
      padding: 0.5rem 1.25rem 1.25rem 1.25rem;
    }
  }
`;

const Overview: React.FC<{}> = () => {
  const user = useUser();
  const candidate = useCandidate();
  const reportMatch = useRouteMatch<{ reportId: string }>(
    '/candidates/:candidateId/reports/:reportId',
  );
  const reportId = reportMatch?.params?.reportId as string;
  const { report, isLoading } = useReportsWithMultiMvr({
    reportId,
  });

  const invitation = useInvitation();

  const { reportPrediction, reportEstimationGenerated } = useReportEta(report);

  // @ts-ignore TODO: Import Report type from utils when is up to date
  const statusType = getStatusTypeForReport(report, user) || null;

  const task = useLastReportTask({ report });

  const [showPreference] = useNestedPreference('report', 'showEmptyFields');
  const pinnedFields = usePinnedFields();
  const pinnedFieldsFlagEnabled = usePinnedFieldsFlag();

  const fieldsToColumnConfig = useMemo(
    () =>
      configureFieldKeysToItems({
        user,
        candidate,
        report,
        invitation,
        reportPrediction,
        reportEstimationGenerated,
        statusType,
        task,
        showPreference,
        pinnedFields,
        pinnedFieldsFlagEnabled,
      }),
    [
      user,
      candidate,
      report,
      invitation,
      reportPrediction,
      reportEstimationGenerated,
      statusType,
      task,
      showPreference,
      pinnedFields,
      pinnedFieldsFlagEnabled,
    ],
  );

  return (
    <ComponentsContainer data-testid='candidate-overview'>
      {/* TODO: add error state for report info sections, define this with design */}
      {(Object.keys(Sections) as Array<keyof typeof Sections>).map(section => {
        const Component = Sections[section];

        return (
          <Component
            key={section}
            report={report}
            isLoading={isLoading}
            statusType={statusType}
            candidate={candidate}
            invitation={invitation}
            fieldKeysToItems={fieldsToColumnConfig}
          />
        );
      })}
    </ComponentsContainer>
  );
};

export default memo(Overview);
