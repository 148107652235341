import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  usePinnedFields,
  usePinnedFieldsFlag,
  useSetPinnedFields,
} from 'providers/Candidate';

import { fieldKeys as AssessGuidelinesFieldKeys } from './assess-guidelines/assess-guidelines';
import { fieldKeys as CandidateInformationFieldKeys } from './candidate-information/candidate-information';
import { fieldKeys as GeneralDetailsKeys } from './general-details/general-details';
import { fieldKeys as NodesKeys } from './nodes/nodes';
import { fieldKeys as OrderInformationKeys } from './order-information/order-information';
import { fieldKeys as OrderInvitationInformationKeys } from './order-invitation-information/order-invitation-information';
import { FieldKeys } from './types';

type Props = {
  fieldName: string;
  fieldKey: FieldKeys;
};

// TODO: see if this is a mastodon component
const StyledTooltipWrapper = styled.span`
  opacity: 0;
  transition: opacity 0.1s;

  .cds--popover--caret {
    --cds-popover-offset: 0.25rem;
  }

  .cds--popover-caret {
    visibility: hidden;
  }

  #mastodon && .cds--popover-container svg {
    fill: ${colors.brandNavy1};
  }
`;

const StyledSpan = styled.span`
  &:hover ${StyledTooltipWrapper} {
    opacity: 1;
  }
`;

const ConfigItemKey: React.FC<Props> = ({ fieldName, fieldKey }) => {
  const flagEnabled = usePinnedFieldsFlag();

  const pinnedFields = usePinnedFields();
  const setPinnedFields = useSetPinnedFields();

  const isPinned = pinnedFields.includes(fieldKey);

  const togglePin = useCallback(() => {
    if (isPinned) {
      setPinnedFields(pinnedFields.filter(key => key !== fieldKey));
    } else {
      setPinnedFields([...pinnedFields, fieldKey]);
    }
  }, [fieldKey, fieldName, isPinned, pinnedFields, setPinnedFields]);

  // TODO: remove once all fields can be pinned
  const validKeys = AssessGuidelinesFieldKeys.concat(
    CandidateInformationFieldKeys,
  )
    .concat(GeneralDetailsKeys)
    .concat(NodesKeys)
    .concat(OrderInformationKeys)
    .concat(OrderInvitationInformationKeys);

  if (!flagEnabled || !validKeys.includes(fieldKey))
    return (
      <span data-testid={`candidate-field-key-${fieldKey}`}>{fieldName}</span>
    );

  return (
    <StyledSpan>
      <span data-testid={`candidate-field-key-${fieldKey}`}>{fieldName}</span>
      &nbsp;&nbsp;
      <StyledTooltipWrapper>
        <M.TooltipDefinition
          align='top'
          highlighted={false}
          definition={isPinned ? 'Unpin' : 'Pin to top'}
        >
          <M.Icon
            icon={isPinned ? 'PinFilled' : 'Pin'}
            size={14}
            onClick={togglePin}
          />
        </M.TooltipDefinition>
      </StyledTooltipWrapper>
    </StyledSpan>
  );
};

export default ConfigItemKey;
