import React, { useEffect, useMemo, useState } from 'react';
import {
  AddOnsType,
  BasePackage,
  Candidate,
  WorkLocation,
} from 'components/Signup/types';
import useAmplitudeExperiment from 'components/Signup/hooks/useAmplitudeExperiment';

import { getFromSessionStorage } from '../../components/Signup/utils/sessionStorage';
import SignupPage from './context';

const Provider: React.FC = ({ children }) => {
  const { variant: ab13Variant, loading } = useAmplitudeExperiment();
  // Wait for experiment to load before setting default package
  useEffect(() => {
    if (!loading && ab13Variant) {
      const experimentDefaultPackage =
        ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v2' ||
        ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v3'
          ? 'checkrdirect_complete_criminal'
          : 'checkrdirect_essential_criminal';

      setSelectedPackage(experimentDefaultPackage);
    }
  }, [ab13Variant, loading]);

  const [candidates, setCandidates] = useState<Array<Candidate>>(
    getFromSessionStorage('candidates', []),
  );

  const [workLocation, setWorkLocation] = useState<WorkLocation>(
    getFromSessionStorage('workLocation', {
      country: undefined,
      city: undefined,
      state: undefined,
    }),
  );

  const [addOns, setAddOns] = useState<Record<BasePackage, Array<AddOnsType>>>(
    getFromSessionStorage('addOns', {
      checkrdirect_basic_plus_criminal: [],
      checkrdirect_complete_criminal: [],
      checkrdirect_essential_criminal: [],
      checkrdirect_professional_criminal: [],
      checkrdirect_international_basic_plus: [],
      checkrdirect_international_professional: [],
      checkrdirect_international_professional_plus: [],
    }),
  );

  const [selectedPackage, setSelectedPackage] = useState<BasePackage>(
    getFromSessionStorage('selectedPackage', 'checkrdirect_essential_criminal'),
  );

  const [alias, setAlias] = useState<Record<BasePackage, string | null>>(
    getFromSessionStorage('alias', {
      checkrdirect_basic_plus_criminal: null,
      checkrdirect_complete_criminal: null,
      checkrdirect_essential_criminal: null,
      checkrdirect_professional_criminal: null,
    }),
  );

  const [promoCode, setPromoCode] = useState<string | undefined>(
    getFromSessionStorage('promoCode', ''),
  );

  const providerValue = useMemo(
    () => ({
      addOns,
      setAddOns,
      selectedPackage,
      setSelectedPackage,
      candidates,
      setCandidates,
      workLocation,
      setWorkLocation,
      alias,
      setAlias,
      promoCode,
      setPromoCode,
    }),
    [addOns, selectedPackage, candidates, workLocation, alias, promoCode],
  );

  return (
    <SignupPage.Provider value={providerValue}>{children}</SignupPage.Provider>
  );
};

export default Provider;
