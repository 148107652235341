import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledLookbackModalBody = styled(M.ModalBody)`
  padding-bottom: 1rem !important;
  p {
    padding-right: 0;
  }
`;

export const StyledEmploymentVerificationDropdown = styled(M.Dropdown)`
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: 1400px) {
    max-width: 530px;
  }
  .cds--list-box__invalid-icon {
    z-index: 1 !important;
  }
  .cds--list-box__label {
    color: ${colors.slate06} !important;
    font-style: normal !important;
  }
`;

export const StyledReferenceDropdown = styled(M.Dropdown)`
  margin-bottom: 1rem;
  height: 2rem;

  .cds--list-box__label {
    color: ${colors.slate06} !important;
    font-style: normal !important;
  }
`;
