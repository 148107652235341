import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useFetchAssessmentVariantRegitrations } from 'api/assessmentVariants/hooks';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';
import MobilityVariantCheckbox from './mobility-variant-checkbox';

type Props = {
  adjudicationKind: 'negative' | 'positive';
  onSelectionsChange?: (
    selectedRegistrations: AssessmentVariantRegistration[],
  ) => void;
};

const MobilityVariantCheckboxGroup: React.FC<Props> = ({
  adjudicationKind,
  onSelectionsChange,
}) => {
  const { data } = useFetchAssessmentVariantRegitrations();

  const onChange = useCallback(
    (
      registrationItems: {
        id: string;
        value: string | number;
        checked: boolean;
      }[],
    ) => {
      const newSelectedRegistrations = registrationItems.flatMap((item) => {
        return data?.filter((reg: AssessmentVariantRegistration) => {
          if (
            reg.id === item.id &&
            reg.current_variant?.adjudication?.kind !== adjudicationKind
          ) {
            return item.checked;
          }
          return false;
        });
      });

      if (onSelectionsChange) {
        onSelectionsChange(newSelectedRegistrations);
      }
    },
    [onSelectionsChange, data, adjudicationKind],
  );

  if (!data || data?.length < 1) {
    return (
      <M.LoadingInline
        data-testid='mobility-variant-checkbox-group-loading'
        description='Loading...'
      />
    );
  }

  return (
    <div data-testid='mobility-variant-checkbox-group'>
      <M.CheckboxGroup onChange={onChange}>
        {data.map((registration: AssessmentVariantRegistration) => {
          return (
            <MobilityVariantCheckbox
              key={registration.id}
              id={registration.id}
              adjudicationKind={adjudicationKind}
              registration={registration}
            />
          );
        })}
      </M.CheckboxGroup>
    </div>
  );
};

export default MobilityVariantCheckboxGroup;
