import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import { updateParentWindowUrl } from 'utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import UIContext from 'context/UI';
import * as BetterOrderExperience from 'components/BetterOrderExperience';
import { BETTER_DASHBOARD_ORDER_EXPERIENCE } from 'Flags';
import { STEPS } from 'components/BetterOrderExperience/utils/constants';
import { Breadcrumbs, BreadcrumbT } from 'components/BetterOrderExperience';
import AccountProvider from '../Account/AccountProvider';
import BetterOrderExperienceProvider from './Provider';
import BetterOrderExperienceContext from './context';
import { AwaitingCredentialingNotification } from '../../components/Search';
import UserContext from '../../context/CurrentUser';

const BetterOrderExperiencePage: React.FC<{}> = () => {
  const [currentStep, setCurrentStep] = useState<BreadcrumbT.Step>(
    {} as BreadcrumbT.Step,
  );
  const { t } = useTranslation('better_order_experience');
  const { contextId } = useContext(UIContext);
  const history = useHistory();
  const currentUser = useContext(UserContext);
  const { account } = currentUser;
  const boxFlagEnabled =
    useFlag(BETTER_DASHBOARD_ORDER_EXPERIENCE)?.variantKey === 'on';

  const { selectedPackage } = useContext(BetterOrderExperienceContext);

  const showAwaitingCredentialingNotification =
    account?.pre_credentialed_invitations_allowed &&
    !account?.api_authorized &&
    !account?.authorized &&
    !account?.credentialed_at;

  const redirectToPath = useCallback(
    (redirectPath = STEPS.GET_STARTED.path) => {
      if (contextId) {
        updateParentWindowUrl({
          path: redirectPath,
          contextId,
          reload: true,
        });
      } else {
        history.push(redirectPath);
      }
    },
    [contextId, history],
  );

  useEffect(() => {
    const path = window.location.pathname;

    if (!boxFlagEnabled) {
      redirectToPath('/order/get-started');
    } else if (!selectedPackage?.name) {
      // If no base package was selected, redirect back to first step
      redirectToPath();
      setCurrentStep(STEPS.GET_STARTED);
    } else {
      const matchingStep = find(STEPS, step => step.path === path);
      setCurrentStep(matchingStep as BreadcrumbT.Step);
    }
  }, [selectedPackage?.name, history, redirectToPath, boxFlagEnabled]);

  return (
    <AccountProvider>
      <BetterOrderExperienceProvider>
        <h2 data-testid='order-background-check-header'>{t('heading')}</h2>
        {showAwaitingCredentialingNotification && (
          <AwaitingCredentialingNotification />
        )}
        <Breadcrumbs currentStep={currentStep} />
        <Switch>
          <Route exact path={STEPS.GET_STARTED.path}>
            <BetterOrderExperience.GetStartedStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.SELECT_YOUR_PACKAGE.path}>
            <BetterOrderExperience.SelectPackageStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.ADD_ONS.path}>
            <BetterOrderExperience.AddonsStep setCurrentStep={setCurrentStep} />
          </Route>

          <Route exact path={STEPS.UPLOAD_CANDIDATE_INFO.path}>
            <BetterOrderExperience.BulkUploadStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.REVIEW_AND_SUBMIT.path}>
            <BetterOrderExperience.ReviewAndSubmitStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.MANUAL_ENTRY.path}>
            <BetterOrderExperience.ManualEntryStep />
          </Route>
        </Switch>
      </BetterOrderExperienceProvider>
    </AccountProvider>
  );
};

export default BetterOrderExperiencePage;
