import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { getDisplayPrice } from 'components/BetterOrderExperience/utils/PricingUtils';
import { BETTER_ORDER_EVENT_NAMES, useTrackEvent } from 'utils';
import { namespace } from '../../../../locales';
import {
  StyledBoldTitle,
  StyledClickableText,
  StyledContainer,
  StyledDescription,
  StyledRecommendedLabel,
  StyledSelectableAliasContainer,
} from './AliasCheckbox.styles';
import AliasInfoModal from './AliasInfoModal';

type AliasCheckboxProps = {
  aliasIsChecked: boolean;
  setAliasIsChecked: Dispatch<SetStateAction<boolean>>;
  aliasPrice?: number;
};

const AliasCheckbox = ({
  aliasIsChecked,
  setAliasIsChecked,
  aliasPrice = 0,
}: AliasCheckboxProps) => {
  const { t } = useTranslation(namespace);
  const trackEvent = useTrackEvent();
  const basePath = `${namespace}:addOns.alias`;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    // TODO: potential tracking event
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  const handleAddAliasToggle = useCallback(() => {
    setAliasIsChecked(!aliasIsChecked);
    trackEvent(
      !aliasIsChecked
        ? BETTER_ORDER_EVENT_NAMES.ALIAS_ADDED
        : BETTER_ORDER_EVENT_NAMES.ALIAS_REMOVED,
    );
  }, [setAliasIsChecked, aliasIsChecked, trackEvent]);

  useEffect(() => {
    trackEvent(BETTER_ORDER_EVENT_NAMES.ALIAS_OFFERED);
  }, [trackEvent]);

  return (
    <>
      <StyledContainer data-testid='add-alias-container'>
        <>
          <StyledBoldTitle data-testid='alias-bold-title'>
            {t(`${basePath}.title`)}
          </StyledBoldTitle>
          <StyledRecommendedLabel type='aqua'>
            {t(`${basePath}.recommended`)}
          </StyledRecommendedLabel>
        </>
        <StyledDescription className='p4'>
          {t(`${basePath}.description.ptOne`)}{' '}
          <StyledClickableText onClick={handleOpenModal}>
            {t(`${basePath}.description.ptTwo`)}
          </StyledClickableText>{' '}
          {t(`${basePath}.description.ptThree`)}
        </StyledDescription>
      </StyledContainer>
      <StyledSelectableAliasContainer data-testid='selectable-alias-container'>
        <M.ChoiceCard
          data-testid='selectable-alias'
          id='selectable-alias'
          type='checkbox'
          checked={aliasIsChecked}
          onChange={handleAddAliasToggle}
          title={t(`${basePath}.checkboxLabel`)}
          description={t(`${basePath}.checkboxDescription`, {
            price: getDisplayPrice(aliasPrice),
          })}
        />
      </StyledSelectableAliasContainer>
      <AliasInfoModal
        isOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default AliasCheckbox;
