import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import { getDollarAmount } from 'components/BetterOrderExperience/utils/PricingUtils';
import {
  StyledAliasPriceContainer,
  StyledAliasPriceRow,
  StyledHelpIcon,
  StyledTooltip,
} from './AliasPrice.styles';
import { namespace } from '../../../../locales';

type AliasPriceProps = {
  aliasPrice: number;
};

const AliasPrice = ({ aliasPrice }: AliasPriceProps) => {
  const { t } = useTranslation(namespace);

  return (
    <StyledAliasPriceRow>
      <StyledAliasPriceContainer data-testid='display-alias-price-container'>
        <div>
          {t('orderSummary.aliases')}
          <StyledTooltip
            align='bottom-left'
            definition={t('orderSummary.aliasesTooltipDefinition')}
          >
            <StyledHelpIcon
              icon='HelpFilled'
              size='16'
              style={{
                fill: colors.uiNavy600,
              }}
            />
          </StyledTooltip>
        </div>
        <div>{getDollarAmount(aliasPrice)}</div>
      </StyledAliasPriceContainer>
    </StyledAliasPriceRow>
  );
};

export default AliasPrice;
