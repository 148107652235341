import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import isPlainObject from 'lodash/isPlainObject';
import { Actions as Container } from 'modules/assess/ui/page';
import Menu from 'modules/assess/ui/ruleset/menu';
import { ID } from 'modules/id';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import * as Settings from 'modules/assess/models/settings/account';
import { GROWTH_INSIGHTS_FLAG_KEY } from 'Constants';
import Edit from './actions/Edit';
import GrowthInsights from './actions/GrowthInsights';
import Insights from './actions/Insights';
import Delete from './actions/Delete';
import PublishV2 from './actions/PublishV2';
import Save from './actions/Save';

export type Props = {
  dirty: boolean;
  draft?: boolean;
  isCriminal?: boolean;
  kind?: Kind;
  latest?: boolean;
  ruleset?: Ruleset.Type;
  settings?: Settings.Type;
  version?: ID;
  assign: () => void;
  discard: () => void;
  discardDraft: () => void;
  edit: () => void;
  growthInsights: () => void;
  insights: () => void;
  publish: () => void;
  save: () => void;
  versions: () => void;
};

export enum Kind {
  draft = 'draft',
  final = 'final',
}

export const Actions: React.FC<Props> = ({
  dirty,
  discard,
  discardDraft,
  draft,
  kind,
  isCriminal,
  latest,
  ruleset,
  settings,
  version,
  ...dispatch
}) => {
  const growthInsightEnabled =
    useFlag(GROWTH_INSIGHTS_FLAG_KEY)?.variantKey === 'on';

  const actions = [];

  switch (kind) {
    case Kind.draft: {
      if (settings?.assess_insights) {
        actions.push(<Insights key='insights' version={version} />);
        if (isCriminal && growthInsightEnabled) {
          actions.push(<GrowthInsights key='growth_insights' />);
        }
      }
      if (dirty) {
        actions.push(<Delete key='delete' draft ruleset={ruleset} />);
        actions.push(<Save key='save' ruleset={ruleset?.id} />);
      } else {
        actions.push(<Delete key='delete' draft ruleset={ruleset} />);
        if (isPlainObject(ruleset?.draft)) {
          const draft = Object(ruleset?.draft);
          if (draft.has_changes_from_active_version) {
            actions.push(
              <PublishV2 key='publish' ruleset={ruleset} version={version} />,
            );
          }
        }
      }
      break;
    }
    case Kind.final: {
      if (latest) {
        actions.push(
          <Menu
            key='menu'
            kind='options'
            ruleset={ruleset}
            onDelete={discard}
            onDeleteDraft={discardDraft}
          />,
        );
        if (settings?.assess_insights && isCriminal && growthInsightEnabled) {
          actions.push(<GrowthInsights key='growth_insights' />);
        }
        actions.push(<Edit key='edit' onClick={dispatch.edit} />);
      }
      break;
    }
  }

  return <Container>{actions}</Container>;
};

export default Actions;
