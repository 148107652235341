import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useTranslation } from 'react-i18next';
import courtWeights from 'components/AddScreenings/shared/svg/court-weights.svg';
import { namespace } from 'components/BetterOrderExperience/locales';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import { USE_GLOBAL_WATCHLIST_SEARCH_IN_ADDONS } from 'Flags';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import StateCrimSearch from './StateCrimSearch';
import CountyCrimSearch from './CountyCrimSearch';
import FederalCrimSearch from './FederalCrimSearch';
import GlobalWatchlistSearch from './GlobalWatchlistSearch';
import OutOfCountrySearch from './OutOfCountrySearch';
import { hasAllBasicPackageScreenings } from '../../AddonsStep.utils';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  includedScreenings: AddonsT.ScreeningType[];
};

const CriminalSearchesSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  addOnPrices,
  isAdded,
  isIncluded,
  includedScreenings,
}) => {
  const { t } = useTranslation(namespace, { keyPrefix: 'addOns.crimSearch' });
  const showGlobalWatchlist =
    useFlag(USE_GLOBAL_WATCHLIST_SEARCH_IN_ADDONS)?.variantKey === 'on';

  const isCountyCrimIncluded = isIncluded(
    ScreeningProductType.COUNTY_CRIMINAL_SEARCH,
  );

  const isStateCrimIncluded = isIncluded(
    ScreeningProductType.STATE_CRIMINAL_SEARCH,
  );

  const isFederalCrimIncluded = isIncluded(
    ScreeningProductType.FEDERAL_CRIMINAL_SEARCH,
  );

  const isGlobalWatchlistIncluded = isIncluded(
    ScreeningProductType.GLOBAL_WATCHLIST_SEARCH,
  );

  const isOutOfCountryIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH,
  );

  const countyCrimSearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.COUNTY_CRIMINAL_SEARCH,
  );

  const stateCrimSearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.STATE_CRIMINAL_SEARCH,
  );

  const federalCrimSearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.FEDERAL_CRIMINAL_SEARCH,
  );

  const globalWatchlistSearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.GLOBAL_WATCHLIST_SEARCH,
  );

  const outOfCountrySearchPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH,
  );

  // County Crim, State Crim, Fed Crim, Out of Country Screenings are SSN dependent screenings
  // SSN dependent screenings are disabled if all basic package screenings are not added
  const ssnDependentDisabled =
    !hasAllBasicPackageScreenings(includedScreenings);

  return (
    <ScreeningSection
      id='crim-searches'
      name={t('sectionName')}
      icon={courtWeights}
    >
      <StyledFlex flexDirection='column'>
        <CountyCrimSearch
          id={ScreeningProductType.COUNTY_CRIMINAL_SEARCH as string}
          isAdded={isAdded(ScreeningProductType.COUNTY_CRIMINAL_SEARCH)}
          isIncluded={isCountyCrimIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          price={countyCrimSearchPrice}
          disabled={ssnDependentDisabled}
        />
        <StateCrimSearch
          id={ScreeningProductType.STATE_CRIMINAL_SEARCH as string}
          isAdded={isAdded(ScreeningProductType.STATE_CRIMINAL_SEARCH)}
          isIncluded={isStateCrimIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          price={stateCrimSearchPrice}
          hasCountySearch={isIncluded(
            ScreeningProductType.COUNTY_CRIMINAL_SEARCH,
          )}
          disabled={ssnDependentDisabled}
        />
        <FederalCrimSearch
          id={ScreeningProductType.FEDERAL_CRIMINAL_SEARCH as string}
          isAdded={isAdded(ScreeningProductType.FEDERAL_CRIMINAL_SEARCH)}
          isIncluded={isFederalCrimIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          price={federalCrimSearchPrice}
          disabled={ssnDependentDisabled}
        />
        {showGlobalWatchlist && (
          <GlobalWatchlistSearch
            id={ScreeningProductType.GLOBAL_WATCHLIST_SEARCH as string}
            isAdded={isAdded(ScreeningProductType.GLOBAL_WATCHLIST_SEARCH)}
            isIncluded={isGlobalWatchlistIncluded}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            price={globalWatchlistSearchPrice}
          />
        )}
        <OutOfCountrySearch
          id={ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH as string}
          isAdded={isAdded(ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH)}
          isIncluded={isOutOfCountryIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          price={outOfCountrySearchPrice}
          disabled={ssnDependentDisabled}
        />
      </StyledFlex>
    </ScreeningSection>
  );
};

export default CriminalSearchesSection;
