import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.p.attrs({
  className: 'p3',
})`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  line-height: 20px;
`;

export const IconWrapper = styled.div`
  margin-right: 0.75rem;
  padding-top: 2px;
`;

export const Title = styled.div`
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
`;

export const NextSteps = styled.p.attrs({
  className: 'p3',
})`
  padding-left: 1.73rem;
  line-height: 20px;
  margin-top: 4px;
`;
