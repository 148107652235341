const FiltersConfigs = {
  basic: ['Keyword', 'Status', 'Adjudication'],
  dsp: ['Keyword', 'Dob'],
  advanced: [
    'Adjudication',
    'Dob',
    'Tag',
    'Package',
    'Geo',
    'Program',
    'MonitorType',
    'InvitationStatus',
    'ReportSource',
    'ReportTask',
    'IdentityVerificationStatus',
  ],
  assess: ['Assessment'],
  adjudication: ['Adjudication'],
  nodes: ['NodeName', 'NodeID'],
  dates: ['ReportCreated', 'ReportCompleted'],
  canceled: ['IncludesCanceled'],
  get basicWithAssess() {
    return [...this.basic.slice(0, 2), ...this.assess];
  },
  get advanceWithNodes() {
    return [
      ...this.advanced.slice(0, 4),
      ...this.nodes,
      ...this.advanced.slice(4),
    ];
  },
};

export default FiltersConfigs;
