import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { StatusTypes } from '@dashboard-experience/utils';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';

type Props = {
  adjudicationKind: 'negative' | 'positive';
  registration: AssessmentVariantRegistration;
  id: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    info: { checked: boolean; id: string },
  ) => void;
};

const MobilityVariantCheckbox: React.FC<Props> = ({
  adjudicationKind,
  registration,
  id,
  onChange,
}) => {
  const isAdjudicationKind =
    registration.current_variant?.adjudication?.kind === adjudicationKind;
  return (
    <M.Checkbox
      id={id}
      key={registration.id}
      defaultChecked={isAdjudicationKind}
      disabled={isAdjudicationKind}
      onChange={onChange}
      labelText={
        <div>
          <M.StatusBadge
            statusType={StatusTypes.Assess}
            status={registration.current_variant?.assessment_value}
          />{' '}
          {registration.display_label}
        </div>
      }
    />
  );
};

export default MobilityVariantCheckbox;
