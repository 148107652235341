import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import JobRoleAttributeTile from './JobRoleAttributeTile/JobRoleAttributeTile';
import useGetJobRoleAttributes from '../../hooks/useGetJobRoleAttributes';

import {
  Container,
  ResponsibilitiesTitle,
  CheckboxContainer,
  RoleNameText,
  ResponsibilitiesText,
  SelectAllText,
  CardsContainer,
  Divider,
} from './RoleDefinitionSectionStyles';
import { namespace } from '../../locales';

interface RoleDefinitionSectionProps {
  roleName: string;
  roleNumber: number;
  onValidityUpdate?: (isValid: boolean) => void;
  onAttributesUpdate: (roleName: string, attributes: number[]) => void;
}

const RoleDefinitionSection: React.FC<RoleDefinitionSectionProps> = ({
  roleName,
  roleNumber,
  onValidityUpdate,
  onAttributesUpdate,
}) => {
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);

  const { t } = useTranslation(namespace, {
    keyPrefix: 'components.PurchaseRecommendations',
  });

  const { data } = useGetJobRoleAttributes();

  const [isRoleDefinedLater, setIsRoleDefinedLater] = useState<boolean>(false);

  useEffect(() => {
    onAttributesUpdate(roleName, isRoleDefinedLater ? [] : selectedAttributes);
    if (onValidityUpdate) {
      onValidityUpdate(!isRoleDefinedLater && selectedAttributes.length > 0);
    }
  }, []);

  const checkValidityAndUpdate = (
    isDefinedLater: boolean,
    attributes: number[],
  ) => {
    if (onValidityUpdate) {
      onValidityUpdate(isDefinedLater || attributes.length > 0);
    }
  };

  const handleTileClick = useCallback(
    (id: number) => {
      setSelectedAttributes(prevSelected => {
        const updatedSelection = prevSelected.includes(id)
          ? prevSelected.filter(attrId => attrId !== id)
          : [...prevSelected, id];

        onAttributesUpdate(roleName, updatedSelection);

        checkValidityAndUpdate(isRoleDefinedLater, updatedSelection);
        return updatedSelection;
      });
    },
    [isRoleDefinedLater],
  );

  const handleCheckboxChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      const isChecked = event.target.checked;
      setIsRoleDefinedLater(isChecked);
      onAttributesUpdate(roleName, isChecked ? [] : selectedAttributes);
      checkValidityAndUpdate(isChecked, selectedAttributes);
    },
    [selectedAttributes],
  );

  const createHandleTileClick = (id: number) => () => handleTileClick(id);

  let displayedAttributes = [];
  let otherAttributes = [];

  if (data?.job_role_attributes) {
    const jobRoleAttributes = data.job_role_attributes;
    displayedAttributes = jobRoleAttributes.slice(0, 13);
    otherAttributes = jobRoleAttributes.slice(-5);
  }

  return (
    <Container data-testid='container-role-definition-section'>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <ResponsibilitiesTitle>
          {t('roleDefinitionSection.role', { roleNumber })}
        </ResponsibilitiesTitle>
        <CheckboxContainer>
          <label htmlFor={`role-checkbox-${roleNumber}`}>
            {t('roleDefinitionSection.defineThisRoleLater')}
          </label>
          <M.Checkbox
            type='checkbox'
            id={`role-checkbox-${roleNumber}`}
            checked={isRoleDefinedLater}
            onChange={handleCheckboxChange}
          />
        </CheckboxContainer>
      </div>
      <RoleNameText>{roleName}</RoleNameText>
      {!isRoleDefinedLater && (
        <>
          <ResponsibilitiesText>
            {t('roleDefinitionSection.responsibilitiesText', { roleName })}
          </ResponsibilitiesText>
          <SelectAllText>
            {t('roleDefinitionSection.selectAllText')}
          </SelectAllText>
          <CardsContainer>
            {displayedAttributes?.map(
              (attr: { id: number; name: string; svg_s3_url: string }) => (
                <JobRoleAttributeTile
                  key={attr.id}
                  id={attr.id}
                  name={attr.name}
                  icon={attr.svg_s3_url}
                  selected={selectedAttributes.includes(attr.id)}
                  onClick={createHandleTileClick(attr.id)}
                />
              ),
            )}
          </CardsContainer>
          <ResponsibilitiesText>
            {t('roleDefinitionSection.otherRequirementsText', { roleName })}
          </ResponsibilitiesText>
          <SelectAllText>
            {t('roleDefinitionSection.selectAllText')}
          </SelectAllText>
          <CardsContainer>
            {otherAttributes?.map(
              (attr: { id: number; name: string; svg_s3_url: string }) => (
                <JobRoleAttributeTile
                  key={attr.id}
                  id={attr.id}
                  name={attr.name}
                  icon={attr.svg_s3_url}
                  selected={selectedAttributes.includes(attr.id)}
                  onClick={createHandleTileClick(attr.id)}
                />
              ),
            )}
          </CardsContainer>
        </>
      )}
    </Container>
  );
};

export default RoleDefinitionSection;
