import styled from 'styled-components';

export const StyledHeading = styled.div`
  margin-bottom: 15px;
`;

export const StyledDescriptionOne = styled.div`
  margin-bottom: 10px;
  line-height: var(--font-line-height-lg, 20px); /* 142.857% */
  letter-spacing: 0.14px;
`;

export const StyledDescriptionTwo = styled.div`
  line-height: var(--font-line-height-lg, 20px); /* 142.857% */
  letter-spacing: 0.14px;
`;

export const StyledGifContainer = styled.div`
  margin-top: 32px;
  min-height: 96px;

  img {
    width: 100%;
    border-radius: 8px;
  }
`;

export const StyledBold = styled.span`
  font-weight: bold;
`;
