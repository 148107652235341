import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetReport } from 'api/reports';
import { DecisionType, useSetVariantDecision } from 'modules/adjudication/api';
import { namespace } from 'modules/adjudication/locales';
import {
  MobilityVariantCheckboxGroup,
  ModalFooter,
} from 'modules/adjudication/ui/common';
import { Subtype } from 'modules/adjudication/utilities';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';

type Props = {
  selection: Subtype | undefined;
  onClose: () => void;
};

const MobilitySubmit: React.FC<Props> = ({ selection, onClose }) => {
  const { t } = useTranslation();
  const { report } = useGetReport();
  const { call: setDecision, result: decisionResult } = useSetVariantDecision();
  const [selectedRegistrations, setSelectedRegistrations] = useState<
    AssessmentVariantRegistration[]
  >([]);

  const cancel = t(`${namespace}:report.actions.engaged.modal.actions.cancel`);
  const canceledItems = t(
    `${namespace}:report.actions.engaged.modal.submit.canceled_items`,
  );
  const message = t(
    `${namespace}:report.actions.engaged.modal.submit.mobility.message`,
  );
  const submit = t(`${namespace}:report.actions.engaged.modal.actions.submit`);

  const saveEngageDecision = useCallback(() => {
    selectedRegistrations.forEach((registration) => {
      if (registration.current_variant?.adjudication?.kind !== 'positive') {
        setDecision({
          variantId: registration.current_variant!.id,
          decision: 'engaged' as DecisionType,
        });
      }
    });
  }, [setDecision, selectedRegistrations]);

  const close = {
    name: cancel,
    onClick: onClose,
  };
  const next = {
    name: submit,
    onClick: saveEngageDecision,
    disabled: selectedRegistrations.length === 0,
  };

  useEffect(() => {
    if (decisionResult.status === 'success') {
      onClose();
    }
  }, [decisionResult.status, onClose, selectedRegistrations]);

  return (
    <>
      {report.includes_canceled && (
        <div style={{ marginBottom: '1rem' }}>{canceledItems}</div>
      )}
      <div style={{ fontWeight: 'bold', marginBottom: '1rem' }}>{message}</div>
      <MobilityVariantCheckboxGroup
        adjudicationKind='positive'
        onSelectionsChange={setSelectedRegistrations}
      />
      <ModalFooter close={close} next={next} />
    </>
  );
};

export default MobilitySubmit;
