import { M } from '@dashboard-experience/mastodon';
import {
  useGetReport,
  useReportPropertiesForAmplitudeEvents,
} from 'api/reports';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';

import { useRequestCandidateStory } from 'modules/adjudication/api';
import { namespace } from 'modules/adjudication/locales';
import { useDisplayRequestCandidateStory } from 'modules/adjudication/utilities';
import styled from 'styled-components';
import { ConfirmModal } from '../../../../../components';

const StyledExpandableNotification = styled(M.ExpandableNotification)`
  line-height: 23px;
`;

const RequestCandidateStoryBanner = () => {
  const { report } = useGetReport();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const { call, result } = useRequestCandidateStory();
  const trackEvent = useTrackEvent();

  const [modalOpen, setOpenModal] = useState(false);

  const openConfirmModal = useCallback(() => {
    setOpenModal(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Request Candidate Story', Report: reportProperties },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [reportProperties, trackEvent]);

  const hideConfirmModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleConfirm = useCallback(() => {
    call();
    hideConfirmModal();
  }, [call, hideConfirmModal]);

  const { t } = useTranslation();
  const actionAction = {
    label: t('banners.requestCandidateStory.action'),
    action: openConfirmModal,
  };
  const actionTitle = t('banners.requestCandidateStory.title');
  const actionDescription = (
    <div>
      {t('banners.requestCandidateStory.description', {
        firstName: report.candidate?.first_name || 'the candidate',
        interpolation: { escapeValue: false },
      })}
    </div>
  );

  const basePath = `${namespace}:report.actions.candidate_story`;
  const modalHeader = t(`${basePath}.modal.header`);
  const modalMessage = <h3>{t(`${basePath}.modal.msg`)}</h3>;

  const canRequestCandidateStory = useDisplayRequestCandidateStory(true);
  const requestDisabled =
    report?.candidate_stories?.length > 0 ||
    report?.tags?.includes('candidate-story-requested') ||
    (result.data && !result.isError) ||
    report.archived;

  if (!canRequestCandidateStory || requestDisabled) return null;

  // @ts-ignore
  const d = new Date(Date.parse(report.completed_at));
  const displayDate = d.toLocaleString('en-us', {
    // @ts-ignore
    dateStyle: 'medium',
  });

  return (
    <>
      <StyledExpandableNotification
        title={actionTitle}
        secondaryTitle={displayDate}
        bodyNode={actionDescription}
        kind='warning'
        primaryAction={actionAction}
        initialExpanded
        data-testid='request-story-button'
      />
      {/* @ts-ignore */}
      <ConfirmModal
        header={modalHeader}
        open={modalOpen}
        hideModal={hideConfirmModal}
        callback={handleConfirm}
        message={modalMessage}
      />
    </>
  );
};

export default RequestCandidateStoryBanner;
