/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import {
  CurrentUser,
  GenericObject,
  hasPermission,
} from '@dashboard-experience/utils';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  clearUserCache,
  toastError,
  toastSuccess,
  updateAccountState,
} from 'actions';

import { getUser } from 'components/Signup/hooks/useGetUser';
import { useMemo } from 'react';
import { Account } from 'types';
import { Settings } from 'types/report/settings';
import { deleteAccount, updateAccount } from './actions';

interface MutationVariables {
  changedSettings: GenericObject;
  account: Account;
  settings?: GenericObject;
}

export const useDeleteAccount = () => {
  const dispatch = useDispatch();
  const request = (accountId: string) => deleteAccount(accountId);

  const [deleteCall] = useMutation(request, {
    onError: (error: any) => {
      const errRes = error.response.data;
      let errMsg: string = '';
      if (errRes.error) {
        errMsg = errRes.error;
      } else {
        errMsg = errRes.errors.join(', ');
      }
      dispatch(toastError('Error deleting account', errMsg));
    },
  });

  return {
    deleteCall,
  };
};

export const useUpdateAccountSettings = () => {
  const dispatch = useDispatch();
  const request = ({ changedSettings, account, settings }: any) =>
    updateAccount(changedSettings, account, settings);

  const [call, result] = useMutation(request, {
    onError: (err: any) => {
      dispatch(toastError('Error updating account settings'));
    },
    onSuccess: (res: any, variables: any) => {
      const { changedSettings, isModal } = variables;
      dispatch(updateAccountState({ updatedAccount: res }));
      dispatch(clearUserCache());
      if (isModal) {
        changedSettings?.alias_auto_enable
          ? dispatch(
              toastSuccess(
                'Setting updated',
                'Future orders will search aliases, but you can customize each order.',
              ),
            )
          : dispatch(
              toastSuccess(
                'Setting updated',
                "Future orders won't search aliases, but you can customize each order.",
              ),
            );
      } else {
        dispatch(toastSuccess('Account settings updated'));
      }
    },
  });
  return {
    call,
    result,
  };
};

export const useOptimisticallyUpdateAccountSettings = () => {
  const request = async ({
    changedSettings,
    account,
    settings,
  }: MutationVariables) => updateAccount(changedSettings, account, settings);

  const [call, result] = useMutation(request, {
    onMutate: variables => {
      queryCache.setQueryData(
        ['dashboard/report-settings'],
        (previousData: any) => ({
          ...previousData,
          ...variables.changedSettings.report_settings,
        }),
      );
    },
    onError: () => {
      queryCache.removeQueries(['dashboard/report-settings']);
    },
  });

  return { call, result };
};

export const useGetCachedAccountSettings = () =>
  useQuery<Settings, string>(
    'dashboard/report-settings',
    () =>
      queryCache.getQueryData('dashboard/report-settings') ||
      Promise.resolve({} as Settings),
  );

export const useGetUserAccountSettings = (
  user: CurrentUser,
  shouldFetchAccountSettings = true,
) => {
  const canGetUserSettings = hasPermission(user, 'manage_account_users');
  const token = localStorage.getItem('accessToken');
  const request = () => getUser(token);
  const key: AnyQueryKey = ['userAccountSettings', { token }];

  const result = useQuery(key, request, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: canGetUserSettings && shouldFetchAccountSettings,
    staleTime: 0,
  });

  const accountSettings = useMemo(
    () => result?.data?.account || [],
    [result?.data],
  ) as unknown as GenericObject;

  const { error, isLoading, isFetching } = result;

  return {
    accountSettings,
    error,
    isLoading,
    isFetching,
  };
};

export const useGetReportSettings = (account: Account | undefined | null) => {
  const { data: cachedReportSettings } = useGetCachedAccountSettings();
  const accountReportSettings = account?.report_settings;

  return useMemo(() => {
    return {
      ...accountReportSettings,
      ...cachedReportSettings,
    } as Settings;
  }, [cachedReportSettings, accountReportSettings]);
};

export const useUploadError = () => {
  const dispatch = useDispatch();
  const callUploadError = () => {
    dispatch(toastError('Failed to upload file'));
  };
  return {
    callUploadError,
  };
};
