import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { mapPackage } from 'components/AddScreenings/shared/utils';
import { FetchPackageType, PostPackageType } from '@dashboard-experience/utils';
import ConditionalWrapper from '../../ui/ConditionalWrapper';
import UnavailableForManualBulkTooltip from '../../ManualBulkTooltip';
import { RecommendedTagType, BLURBS } from './PackageChoiceCard.enum';
import {
  StyledContainer,
  StyledRecommendedTag,
  StyledBlurb,
  StyledBreakLine,
  StyledShowMoreLinkMobile,
} from './PackageChoiceCard.styles';
import ScreeningListContainer from './ScreeningListContainer';
import RadioContainer from './RadioContainer';
import { isAvailableForManualBulk } from '../../SelectPackageUtils';
import PackagePrice from '../../ui/PackagePrice';

type PackageChoiceCardProps = {
  packageData: FetchPackageType;
  idx: number;
  selected?: boolean;
  showPackagePrice?: boolean;
  onClick?: Function;
  hideMostPopularTag?: boolean;
  isManualBulkOrder: boolean;
  isAllCustom?: boolean;
  update: Function;
  selectedPackage: PostPackageType;
};

const PackageChoiceCard: React.FC<PackageChoiceCardProps> = ({
  packageData,
  idx,
  showPackagePrice = false,
  selected = false,
  onClick = () => {},
  hideMostPopularTag,
  isManualBulkOrder,
  isAllCustom,
  update,
  selectedPackage,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  // In small screens and mobile widths, we hide the screenings is showMoreMobile is false
  const [showMoreMobile, setShowMoreMobile] = useState(false);
  const packageAvailableForManualBulk = isAvailableForManualBulk(
    packageData,
    isManualBulkOrder,
  );

  useEffect(() => {
    if (
      selectedPackage.slug === packageData?.slug &&
      Boolean(packageData) &&
      Boolean(selectedPackage) &&
      packageAvailableForManualBulk
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedPackage, packageData, packageAvailableForManualBulk]);

  const packageBlurb = useMemo(() => {
    if (packageData?.slug === 'basic_+') {
      return BLURBS.BASIC_PLUS;
    }
    if (packageData?.slug === 'essential') {
      return BLURBS.ESSENTIAL_V2;
    }
    if (packageData?.slug === 'checkrdirect_complete_criminal') {
      return BLURBS.COMPLETE_CRIMINAL;
    }
    return '';
  }, [packageData?.slug]);

  const recommendedTag = useMemo(() => {
    if (packageData?.origin === 'customer_via_signup') {
      return RecommendedTagType.RECENTLY_ORDERED;
    }
    if (packageData?.origin === 'customer_via_dashboard') {
      return RecommendedTagType.RECENTLY_CREATED;
    }
    if (packageData?.slug === 'essential' && !hideMostPopularTag) {
      return RecommendedTagType.MOST_POPULAR;
    }
    return RecommendedTagType.EMPTY;
  }, [packageData?.origin, packageData?.slug, hideMostPopularTag]);

  const handleSelect = useCallback(() => {
    const newSelectedPackage = mapPackage(
      packageData,
    ) as unknown as PostPackageType;
    update({
      selectedPackage: newSelectedPackage,
      recommendedPackageTag: recommendedTag,
    });
  }, [packageData, recommendedTag, update]);

  const handleShowMoreMobileClick = useCallback((e: Event) => {
    e.preventDefault();
    setShowMoreMobile(true);
  }, []);

  useEffect(() => {
    if (!selectedPackage.name && selected) {
      handleSelect();
    }
  }, [handleSelect, selected, selectedPackage.name]);

  const handleChoiceCardClick = useCallback(() => {
    if (packageAvailableForManualBulk) {
      setIsChecked(!isChecked);
      handleSelect();
      onClick();
    }
  }, [packageAvailableForManualBulk, isChecked, handleSelect, onClick]);

  return (
    <ConditionalWrapper
      key={packageData.id}
      condition={!packageAvailableForManualBulk}
      wrapper={UnavailableForManualBulkTooltip}
    >
      <StyledContainer
        data-testid={`package-choice-card-button-${idx}`}
        checked={isChecked}
        onClick={handleChoiceCardClick}
        className={`${!packageAvailableForManualBulk ? 'card-disabled' : ''}`}
      >
        <StyledRecommendedTag
          visible={Boolean(recommendedTag !== RecommendedTagType.EMPTY)}
        >
          {recommendedTag}
        </StyledRecommendedTag>
        <RadioContainer
          packageData={packageData}
          availableForManualBulk={packageAvailableForManualBulk}
          checked={isChecked}
        />
        {!isAllCustom && (
          <StyledBlurb className='p4' data-testid={`blurb-${packageData.name}`}>
            {packageBlurb}
          </StyledBlurb>
        )}
        {showPackagePrice && <PackagePrice price={packageData.price} />}
        <StyledBreakLine />
        <ScreeningListContainer
          showMoreMobile={showMoreMobile}
          packageData={packageData}
          idx={idx}
        />

        {!showMoreMobile && (
          <StyledShowMoreLinkMobile onClick={handleShowMoreMobileClick}>
            Show more
            <M.Icon size='18' icon='ChevronDown' />
          </StyledShowMoreLinkMobile>
        )}
      </StyledContainer>
    </ConditionalWrapper>
  );
};

export default PackageChoiceCard;
