import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledSummaryContainer = styled.div`
  width: 100%;
  position: sticky;
  z-index: 9001;
  top: 16px;
  align-self: flex-start;
  height: fit-content;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;

  @media screen and (min-width: 768px) and (max-width: 1399px) {
    margin-left: 20px;
    width: 40%;
  }

  @media screen and (min-width: 1400px) {
    margin-left: 20px;
    min-width: 375px;
    width: auto;
  }
`;

export const StyledPackagePriceSpan = styled.span`
  padding-top: 4px;
  color: ${colors.brandNavy3};
  font-size: 14px;
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSection = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.brandSlate3};
`;

export const StyledRecommendedLabel = styled(M.TagV2)`
  margin-left: 16px !important;
  background-color: #c4f3f8 !important;
`;

export const StyledLinePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const StyledBoldLinePrice = styled(StyledLinePrice)`
  font-size: 14px;
  line-height: unset;
  font-weight: bold;

  @media (max-device-width: 400px) {
    flex-direction: row;
    margin-bottom: 2px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

export const StyledSubtotalHeading = styled.span`
  color: ${colors.brandNavy4};
`;

export const StyledSaveForNextTimeContainer = styled.div`
  margin-top: 16px;
  border-radius: 10px;
  padding: 24px;
  background-color: ${colors.uiNavy50};
  color: ${colors.uiTextPrimaryLight};
  border: 1px solid ${colors.uiGrey200};
  @media (min-width: 672px) {
    flex: 1 1 35%;
  }
`;

export const StyledSavePackageNameContainer = styled.div`
  margin-top: 16px;
`;

export const StyledCheckbox = styled(M.Checkbox)``;
export const StyledTextInput = styled(M.TextInput)``;
