import React from 'react';
import { useTranslation } from 'react-i18next';
import courtWeights from 'components/AddScreenings/shared/svg/court-weights.svg';
import { namespace } from 'components/BetterOrderExperience/locales';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../../DomesticScreeningsList/ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import InternationalGlobalWatchlistSearch from './InternationalGlobalWatchlistSearch';
import InternationalCriminalSearch from './InternationalCriminalSearch';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
};

const InternationalCriminalSearchesSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  isAdded,
  isIncluded,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.international.crimSearch',
  });

  const isInternationalCrimSearchIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH,
  );

  const isAdverseMediaSearchIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_ADVERSE_MEDIA_SEARCH,
  );

  const isInternationalCrimSearchAdded = isAdded(
    ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH,
  );

  const isAdverseMediaSearchAdded = isAdded(
    ScreeningProductType.INTERNATIONAL_ADVERSE_MEDIA_SEARCH,
  );

  const isGlobalWatchlistIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_GLOBAL_WATCHLIST_SEARCH,
  );

  return (
    <ScreeningSection
      id='crim-searches'
      name={t('sectionName')}
      icon={courtWeights}
    >
      <StyledFlex flexDirection='column'>
        <InternationalCriminalSearch
          id={ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH}
          isAdded={isInternationalCrimSearchAdded || isAdverseMediaSearchAdded}
          isIncluded={
            isInternationalCrimSearchIncluded || isAdverseMediaSearchIncluded
          }
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
        <InternationalGlobalWatchlistSearch
          id={ScreeningProductType.INTERNATIONAL_GLOBAL_WATCHLIST_SEARCH}
          isAdded={isAdded(
            ScreeningProductType.INTERNATIONAL_GLOBAL_WATCHLIST_SEARCH,
          )}
          isIncluded={isGlobalWatchlistIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
      </StyledFlex>
    </ScreeningSection>
  );
};

export default InternationalCriminalSearchesSection;
