import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledInfoContainer = styled.section`
  display: flex;
  color: ${colors.uiTextPrimaryLight};
  border-bottom: 1px solid ${colors.brandSlate2};
  padding: 1.5rem 0;
  font-size: 16px;
`;

export const StyledFirstColumn = styled.section`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 70px;
  max-width: 186px;
`;

export const StyledSectionTitleTypography = styled.h3`
  font-size: 16px !important;
  font-weight: 800;
  line-height: 24px;
  word-wrap: break-word;
`;

export const StyledLink = styled(M.Link)`
  font-weight: 800;
  line-height: 24px;
  color: ${colors.brandNavy1};
  cursor: pointer;
`;

export const StyledComponentWrapper = styled.div`
  margin-left: 80px;
  width: 100%;
`;

export const StyledDot = styled.span`
  margin: 0 8px;
  color: ${colors.uiTextDisabledLight};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &::before {
    content: '•';
  }
`;
