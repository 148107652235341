import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import {
  ORDER_BACKGROUND_CHECK_EVENT_NAMES,
  useTrackEvent,
  OrderCompleteModal as AmplitudeOrderCompleteModal,
} from 'components/Packages/Amplitude/analytics';
import {
  CurrentUser,
  hasPermission,
  localStorageFallback,
} from '@dashboard-experience/utils';
import AliasSettingSelection from './AliasSettingSelection';
import { useOrderBackgroundCheckContext } from '../Context';

const ModalStyled = styled(M.ComposedModal)`
  .cds--modal-close {
    display: none;
  }
`;

type SubmitModalProps = {
  open: boolean;
  handleClose: () => void;
  currentUser: CurrentUser;
  bulkInviteEnabled?: boolean;
  manualBulkOrderEnabled?: boolean;
  redirectPage: Function;
};

const SubmitModal: React.FC<SubmitModalProps> = ({
  open,
  handleClose,
  currentUser,
  bulkInviteEnabled,
  manualBulkOrderEnabled,
  redirectPage,
}) => {
  const trackEvent = useTrackEvent();

  const { state } = useOrderBackgroundCheckContext();
  const showDefaultAliasSection =
    state?.aliasesEnabledChanged &&
    localStorageFallback.getItem('hideDefaultAliasModal') !== 'true';

  const aliasEnabled = state?.aliasesEnabled === AliasesEnabledType.ON;

  const trackModalClicks = useCallback(
    (selected: AmplitudeOrderCompleteModal) => {
      trackEvent(
        currentUser,
        ORDER_BACKGROUND_CHECK_EVENT_NAMES.ORDER_SUBMIT_COMPLETED,
        {
          'Order Completion Type': selected,
        },
      );
    },
    [currentUser, trackEvent],
  );

  const handleOrderNew = useCallback(() => {
    trackModalClicks('Order New Check');
    handleClose();
    redirectPage('/order/get-started', true);
  }, [handleClose, redirectPage, trackModalClicks]);

  const handleGoToCandidates = useCallback(() => {
    trackModalClicks('Go to Candidate Page');
    handleClose();
    redirectPage('/candidates', true);
  }, [redirectPage, handleClose, trackModalClicks]);

  const handleViewBulkHistory = useCallback(() => {
    if (hasPermission(currentUser, 'read_account_invoices')) {
      redirectPage('/billing/bulk-history', true);
    } else {
      redirectPage('/bulk-order-history', true);
    }
    handleClose();
  }, [currentUser, handleClose, redirectPage]);

  const btnOrderNewCheck = {
    name: 'Order new check',
    onClick: handleOrderNew,
    'data-testid': 'order-new-check-button',
  };

  const btnGoToCandidates = {
    name: 'Go to Candidates page',
    onClick: handleGoToCandidates,
    'data-testid': 'candidate-page-button',
  };

  const btnViewBulkHistory = {
    name: 'View bulk order history',
    onClick: handleViewBulkHistory,
    'data-testid': 'view-documents-page-button',
  };

  const handleBackdropClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  return (
    <ModalStyled
      data-testid='submit-modal'
      open={open}
      onClose={handleBackdropClick}
    >
      <M.ModalHeader data-testid='submit-modal-header'>
        <h2>Your order was submitted</h2>
      </M.ModalHeader>

      <M.ModalBody data-testid='submit-modal-description'>
        {bulkInviteEnabled ? (
          <>
            <div>
              Your invitations are on the way! This process may take several
              minutes to complete depending on the total number of invitations.
              Once a check is started, you can view its progress in the{' '}
              <strong>Candidates</strong> page.
            </div>
            <div style={{ marginTop: '2rem' }}>
              Previous bulk orders can be viewed in{' '}
              <strong>Payment & billing</strong> {'>'}{' '}
              <strong>Bulk order history</strong>.
            </div>
          </>
        ) : (
          <>
            {manualBulkOrderEnabled ? (
              <>
                Your order is in progress! You can view order details and
                previous bulk orders in <strong>Bulk order history</strong>.
                After a check is started, you can view its progress in the{' '}
                <strong>Candidates page</strong>.
              </>
            ) : (
              <>
                Your candidate links are on the way! After a check starts, you
                can find its progress on the <strong>Candidates</strong> page.
              </>
            )}
          </>
        )}
        {showDefaultAliasSection && (
          <AliasSettingSelection aliasEnabled={aliasEnabled} />
        )}
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons
          btnLeft={
            bulkInviteEnabled || manualBulkOrderEnabled
              ? btnViewBulkHistory
              : btnOrderNewCheck
          }
          btnRight={btnGoToCandidates}
          size='small'
        />
      </M.ModalFooter>
    </ModalStyled>
  );
};

export default SubmitModal;
