import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { TLogsFilters } from '../../types';
import { FilterGroup, FilterLabel } from '../styles';

interface SearchFilterProps {
  search: string;
  setFilters: React.Dispatch<React.SetStateAction<TLogsFilters>>;
}

const SearchFilter: React.FC<SearchFilterProps> = ({ search, setFilters }) => {
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFilters(prev => ({ ...prev, search: value }));
    },
    [setFilters],
  );

  return (
    <FilterGroup>
      <FilterLabel>Search by API Payload</FilterLabel>
      <M.TextInput
        placeholder='Keywords [name, email, phone, or ID]'
        value={search}
        onChange={handleSearchChange}
        data-testid='ats-logs-search-input'
      />
    </FilterGroup>
  );
};

export default SearchFilter;
