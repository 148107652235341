/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import { useMemo } from 'react';
import { AnyQueryKey, queryCache, useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { namespace as candidateNamespace } from 'modules/candidate/locales';
import { toastError, toastSuccess } from 'actions';
import {
  GenericObject,
  Exception,
  Candidate,
  CandidatePostalAddress,
} from 'types';
import { AxiosError } from 'axios';
import { useErrorNotifier } from 'hooks';
import {
  getDashboardCandidate,
  getCandidateExceptions,
  getFullSsn,
  updateCandidate,
  deleteCandidateData,
  updateCandidatePostalAddress,
  getCandidatePostalAddress,
  postEmailUnresolvedExceptions,
} from './actions';

export const useGetFullSsn = (candidateId: string) => {
  const queryKey: string = `${candidateId}/full_ssn`;
  return useQuery(queryKey, getFullSsn, {
    refetchOnWindowFocus: false,
  });
};

export const useGetDashboardCandidateReports = (candidateId: string) => {
  const key: AnyQueryKey = ['candidates/dashboard/reports', { candidateId }];
  const params = {
    include: ['reports', 'account'],
  };
  const request = () => getDashboardCandidate({ candidateId, params });
  const errorNotifier = useErrorNotifier();

  const {
    data: candidate,
    isLoading,
    isError,
    error,
  } = useQuery<Candidate, AnyQueryKey, AxiosError>(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!candidateId,
    onError: (err: AxiosError) => {
      // 409s are a unique error code specifically for PII removed.
      // No toast because we show a unique banner, in the Candidate Provider
      errorNotifier(err, { ignoreCodes: 409 });
    },
  });

  return useMemo(
    () => ({
      reports: candidate?.reports || [],
      isLoading,
      isError,
      error,
    }),
    [candidate, isLoading, isError, error],
  );
};

export const useGetDashboardCandidate = (candidateId: string) => {
  const key: AnyQueryKey = ['candidates/dashboard', { candidateId }];
  const params = { include: ['account', 'geos', 'invitations'] };
  const request = () => getDashboardCandidate({ candidateId, params });
  const errorNotifier = useErrorNotifier();

  const {
    data: candidate,
    isLoading,
    isError,
    error,
  } = useQuery<Candidate, AnyQueryKey, AxiosError>(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!candidateId,
    onError: (err: AxiosError) => {
      // 409s are a unique error code specifically for PII removed.
      // No toast because we show a unique banner, in the Candidate Provider
      errorNotifier(err, { ignoreCodes: 409 });
    },
  });

  const payload = useMemo(
    () => ({
      candidate,
      isLoading,
      isError,
      error,
    }),
    [candidate, isLoading, isError, error],
  );

  return payload;
};

// `field` is the actual field in the database; `displayName` is purely what to show in the Toast
export const useUpdateCandidate = (
  field: string,
  displayName: string,
  successCallback?: (email: string) => void,
) => {
  const dispatch = useDispatch();
  const request = (candidate: GenericObject) =>
    updateCandidate(candidate, field);

  const [updateCandidateCall, updateCandidateResult] = useMutation(request, {
    onMutate: variables => {
      const candidateId = variables.id;

      const originalCandidate = queryCache.getQueryData([
        'candidates/dashboard',
        { candidateId },
      ]);

      // Optimistically update cache
      queryCache.setQueryData(
        ['candidates/dashboard', { candidateId }],
        (previousData: any) => ({
          ...previousData,
          [field]: variables[field],
        }),
      );

      return { originalCandidate };
    },
    onSuccess: (_data, variables) => {
      dispatch(toastSuccess(`Candidate ${displayName} updated.`));
      successCallback && successCallback(variables[field]);
    },
    onError: (error: any, variables, context: any) => {
      dispatch(toastError('Error:', error?.response?.data?.errors?.join()));

      // Revert optimistic update
      if (context?.originalCandidate) {
        const candidateId = variables.id;
        queryCache.setQueryData(
          ['candidates/dashboard', { candidateId }],
          context.originalCandidate,
        );
      }
    },
  });
  return {
    updateCandidateCall,
    updateCandidateResult,
  };
};

export const useGetCandidateExceptions = (
  candidateId: string | undefined,
  disabled: boolean,
) => {
  const request = () => getCandidateExceptions(candidateId);
  const key: AnyQueryKey = ['candidateExceptions', { candidateId }];
  const currentUser = useUser();
  const result = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!candidateId && currentUser.is_internal && !disabled,
    staleTime: 30000,
  });
  const exceptions = useMemo(
    () => result?.data?.exceptions || [],
    [result?.data?.exceptions],
  ) as Exception[];

  return exceptions;
};

export const usePostEmailUnresolvedExceptions = (reportId: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const request = () => postEmailUnresolvedExceptions(reportId);

  const [emailUnresolvedExceptionsCall, emailUnresolvedExceptionsResult] =
    useMutation<GenericObject, void, AxiosError>(request, {
      onSuccess: () => {
        dispatch(
          toastSuccess(
            t(`${candidateNamespace}:report.exceptions.banners.action.success`),
          ),
        );
      },
    });
  return {
    emailUnresolvedExceptionsCall,
    emailUnresolvedExceptionsResult,
  };
};

// Used in the CCPA Modal, to delete a Candidate's data at their request
export const useDeleteCandidateData = (candidate: Candidate) => {
  const dispatch = useDispatch();

  const currentUser = useUser(); // Get the information on the User who is making the request
  const request = () => deleteCandidateData(candidate.id, currentUser);

  const [deleteDataCall, deleteDataResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
  });
  return {
    deleteDataCall,
    deleteDataResult,
  };
};

export const useUpdateCandidatePostalAddress = (
  candidateId: string,
  params: CandidatePostalAddress,
) => {
  const dispatch = useDispatch();
  const request = () => updateCandidatePostalAddress(candidateId, params);

  const [updateCandidatePostalAddressCall, updateCandidatePostalAddressResult] =
    useMutation(request, {
      onSuccess: () => {
        dispatch(
          toastSuccess(
            'Candidate Postal Address information successfully updated',
          ),
        );
      },
      onError: () => {
        dispatch(
          toastError(
            'There was an error updating your Candidate Postal Address information.',
          ),
        );
      },
    });

  return {
    updateCandidatePostalAddressCall,
    updateCandidatePostalAddressResult,
  };
};

export const useGetCandidatePostalAddress = (candidateId: string) => {
  const key: AnyQueryKey = ['candidatePostalAddress', { candidateId }];
  const request = () => getCandidatePostalAddress(candidateId);

  const result = useQuery<CandidatePostalAddress, AnyQueryKey>(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!candidateId,
  });

  return result.data;
};
