import React from 'react';
import moment from 'moment';
import { FieldComponentProps } from '../../types';

const LinkExpires: React.FC<FieldComponentProps> = ({ invitation, testid }) => {
  return (
    <div data-testid={testid}>
      {moment(invitation?.expires_at).format('lll')}
    </div>
  );
};

export default LinkExpires;
