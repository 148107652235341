import React from 'react';

import { FieldComponentProps } from '../../types';
import { ActionableTextField } from './styles';

const ReportID: React.FC<FieldComponentProps> = ({ testid, report }) => {
  return (
    <ActionableTextField
      data-testid={testid}
      initialDisplayValue={report.id}
      initialValue={report.id}
      allowEdit={false}
      tooltipLabel='Copy'
    />
  );
};

export default ReportID;
