import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AdverseAction } from 'types';
import { usePatchAdverseAction } from 'modules/adjudication/api';
import { useAdjudicationChangeAllowed } from 'modules/adjudication/utilities';
import ResolveUndeliverableAdverseModal from './ResolveUndeliverableAdverseModal';

const BodySection = styled.div`
  #copy-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 0.5rem;
    }
  }

  #downloadLinks {
    padding-top: 1rem;

    p {
      margin-bottom: 0px;
    }

    small {
      color: ${colors.uiGrey500};
    }
  }
`;

const FooterManual: React.FC<GenericObject> = ({ resolveClick, t }) => {
  // Make sure the user has permission to modify AA statuses
  const adjudicationChangeAllowed = useAdjudicationChangeAllowed();

  return (
    <M.Restrict authorized={adjudicationChangeAllowed}>
      <M.Button kind='primary' onClick={resolveClick}>
        {t('manual.resolve_button')}
      </M.Button>
    </M.Restrict>
  );
};

type ModalProps = {
  showModal: boolean;
  onClose: () => void;
  adverseAction: AdverseAction;
};
const ResolveUndeliverableManually: React.FC<ModalProps> = ({
  showModal,
  onClose,
  adverseAction,
}) => {
  const {
    pre_adverse_action_pdf_encrypted_url: encrypted_pdf, // PRE-adverse sometimes has an encrypted version, but not always
    pre_adverse_action_pdf_url,
    post_adverse_action_pdf_url,
  } = adverseAction;
  const { t } = useTranslation('', { keyPrefix: 'resolve_undeliverable_aa' });

  // Function for copying instructions to clipboard if desired
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const copyInstructions = useCallback(() => {
    navigator.clipboard.writeText(t('manual.reach_out'));
    setIsCopied(!isCopied);
  }, [isCopied, t]);

  // PATCH is called when the User clicks the button to indicate that they're manually handling this issue.
  const { patchCall, patchResult } = usePatchAdverseAction(adverseAction);
  const resolveClick = useCallback(() => {
    patchCall();
  }, [patchCall]);

  useEffect(() => {
    // Close the modal after getting a response from the server on the PATCH call
    if (patchResult.isSuccess || patchResult.isError) {
      onClose();
      patchResult.reset();
    }
  }, [onClose, patchResult]);

  return (
    <ResolveUndeliverableAdverseModal
      showModal={showModal}
      onClose={onClose}
      footer={<FooterManual resolveClick={resolveClick} t={t} />}
    >
      <BodySection>
        <p>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('manual.pre_aa_reach_out', {
                interpolation: { escapeValue: false },
              }),
            }}
          />{' '}
          <M.Link
            href={encrypted_pdf || pre_adverse_action_pdf_url}
            target='_blank'
          >
            {t('manual.pre_aa_information')}
          </M.Link>
          {encrypted_pdf && <small>{t('manual.encrypted_instructions')}</small>}
        </p>

        <p>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('manual.post_aa_reach_out', {
                interpolation: { escapeValue: false },
              }),
            }}
          />{' '}
          <M.Link href={post_adverse_action_pdf_url} target='_blank'>
            {t('manual.post_aa_information')}
          </M.Link>
        </p>
        <br />
        <M.Button id='copy-button' kind='tertiary' onClick={copyInstructions}>
          <M.Icon icon='CopyFile' size={16} />
          {t(isCopied ? 'copied' : 'copy_instructions')}
        </M.Button>
      </BodySection>
    </ResolveUndeliverableAdverseModal>
  );
};

export default ResolveUndeliverableManually;
