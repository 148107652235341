import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { AtsApiLogSchema } from 'api/atsIntegration';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const StyledModal = styled(M.ComposedModal)`
  .cds--modal-container {
    min-width: 60rem;
  }

  .cds--modal-container-body
    .cds--modal-content.mastodon-modal-body[kind='regular'] {
    padding-bottom: 1.5rem !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

interface Props {
  log: AtsApiLogSchema | null;
  onClose: () => void;
}

const LogDetailsModal: React.FC<Props> = ({ log, onClose }) => {
  if (!log) return null;

  // Type guard to check if data has the required properties
  const hasLogData = (
    data: any,
  ): data is { request_body: any; response_body: any } => {
    return data && ('request_body' in data || 'response_body' in data);
  };

  const logData = hasLogData(log.data) ? log.data : null;

  return (
    <StyledModal
      open={!!log}
      onClose={onClose}
      hasScrollingContent
      data-testid='log-details-modal'
      size='lg'
    >
      <M.ModalHeader closeModal={onClose}>
        <h2>API Log Details</h2>
      </M.ModalHeader>
      <M.ModalBody>
        {logData?.request_body && (
          <div>
            <h4>Request</h4>
            <SyntaxHighlighter language='json'>
              {JSON.stringify(logData.request_body, null, 2)}
            </SyntaxHighlighter>
          </div>
        )}
        {logData?.response_body && (
          <div>
            <h4>Response</h4>
            <SyntaxHighlighter language='json'>
              {JSON.stringify(logData.response_body, null, 2)}
            </SyntaxHighlighter>
          </div>
        )}
        {!logData && <p>No request/response data available</p>}
      </M.ModalBody>
    </StyledModal>
  );
};

export default LogDetailsModal;
