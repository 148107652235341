/* eslint-disable react/jsx-no-bind */
import { useFlag } from '@dashboard-experience/react-flagr';
import UserContext from 'context/CurrentUser';
import { NODE_PAYMENT_METHODS_FLAG_KEY } from 'Flags';
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BillingAddress, BillingEntity } from 'types/Billing';
import { useGetPaymentMethods } from 'api/payment';
import { SelectNode } from 'components/PaymentProfiles/PaymentProfileStyledComponents';
import { NodeBillingFormProps } from './PaymentProfileFormProps';
import PaymentDropdown from './PaymentDropdown';
import { postPaymentProfileValidationSchema } from '../Account/Payment/utils';
import {
  GridCol,
  GridRow,
  StyledInput,
  StyledSelect,
} from '../Account/Payment/PaymentStyledComponents';

type Props = {
  paymentProfile: BillingEntity;
  submit: any;
  modalOpen: boolean;
  hideModal: any;
  isLoading: boolean;
  nodes: any;
  selectedNode: any;
  nodeSelected: any;
  onNodeFilterChange: any;
  nodeIsLoading: boolean;
  nodeFilterIsDirty: boolean;
  disableSelection?: boolean;
  paymentMethodAssignments?: any;
  onPaymentMethodSelected?: (method: any) => void;
};

const NodeBillingForm: React.FC<Props> = ({
  paymentProfile,
  submit,
  modalOpen,
  hideModal,
  isLoading,
  nodes,
  selectedNode,
  nodeSelected,
  onNodeFilterChange,
  nodeIsLoading,
  nodeFilterIsDirty,
  disableSelection = false,
  paymentMethodAssignments = [],
  onPaymentMethodSelected,
}) => {
  const props = NodeBillingFormProps;
  const userData: any = useContext(UserContext);
  const accountId: string = userData?.account?.id;
  const history = useHistory();
  const { t } = useTranslation();

  const { data: paymentMethodsData } = useGetPaymentMethods(accountId);
  const hasPaymentMethods = paymentMethodsData?.payment_methods?.length > 0;

  const goToPaymentMethods = () => {
    hideModal();
    history.push('/billing/payment');
  };

  const initialValues: {
    country: string;
    address?: BillingAddress;
    city: string;
    address_line_1: string;
    name: string;
    address_line_2: string;
    id?: string;
    postal_code: string;
    region: string;
    billable?: boolean;
    email?: string;
  } = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    country: '',
    postal_code: '',
    region: '',
    ...paymentProfile,
    ...paymentProfile.address,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: postPaymentProfileValidationSchema,
    onSubmit: values => {
      submit(values);
      hideModal();
    },
  });

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    formik;

  const showError = (field: string): boolean => {
    return (errors[field as keyof typeof errors] &&
      touched[field as keyof typeof touched]) as boolean;
  };

  const errorMessage = (field: string): string | null => {
    return touched[field as keyof typeof touched]
      ? (errors[field as keyof typeof errors] as string)
      : null;
  };

  const itemToString = (node: { name?: string }) => {
    if (!node?.name) return '';
    return `${node.name}`;
  };

  const showPaymentMethods =
    useFlag(NODE_PAYMENT_METHODS_FLAG_KEY)?.variantKey === 'enabled';

  const defaultPaymentMethod = React.useMemo(() => {
    const defaultAssignment = paymentMethodAssignments?.find(
      (pma: { is_default_payment: boolean }) => pma.is_default_payment === true,
    );
    return defaultAssignment?.payment_method || null;
  }, [paymentMethodAssignments]);

  return (
    <form onSubmit={handleSubmit}>
      <M.ComposedModal open={modalOpen} onClose={hideModal}>
        <M.LoadingSpinner active={isLoading} />
        <M.ModalHeader closeModal={hideModal}>
          <h2>{props.form_label}</h2>
        </M.ModalHeader>
        <M.ModalBody>
          <M.Grid>
            {showPaymentMethods && !hasPaymentMethods && (
              <GridRow>
                <GridCol>
                  <M.ExpandableNotification
                    kind='warning'
                    title='Add a payment method first'
                    bodyNode='You have no payment method on file. If you want to, add one now.'
                    primaryAction={{
                      label: 'Add Payment Method',
                      action: goToPaymentMethods,
                    }}
                    initialExpanded
                  />
                </GridCol>
              </GridRow>
            )}
            <GridRow>
              <SelectNode
                data-testid='node-select'
                nodes={nodes || []}
                selectedNode={selectedNode}
                onChange={nodeSelected}
                onFilterChange={onNodeFilterChange}
                loading={nodeIsLoading || nodeFilterIsDirty}
                itemToString={itemToString}
                disabled={disableSelection}
              />
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  data-testid='email'
                  id='email'
                  name='email'
                  labelText={t(props.form_fields.email.label)}
                  placeholder={t(props.form_fields.email.placeholder)}
                  value={values.email || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('email')}
                  invalidText={errorMessage('email')}
                  disabled={props.form_fields.email.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  data-testid='address_line_1'
                  id='address_line_1'
                  name='address_line_1'
                  labelText={t(props.form_fields.address_line_1.label)}
                  placeholder={t(props.form_fields.address_line_1.placeholder)}
                  value={values.address_line_1 || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('address_line_1')}
                  invalidText={errorMessage('address_line_1')}
                  disabled={props.form_fields.address_line_1.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  data-testid='address_line_2'
                  id='address_line_2'
                  name='address_line_2'
                  labelText={t(props.form_fields.address_line_2.label)}
                  placeholder={t(props.form_fields.address_line_2.placeholder)}
                  value={values.address_line_2 || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('address_line_2')}
                  invalidText={errorMessage('address_line_2')}
                  disabled={props.form_fields.address_line_2.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledInput
                  data-testid='city'
                  id='city'
                  name='city'
                  labelText={t(props.form_fields.city.label)}
                  placeholder={t(props.form_fields.city.placeholder)}
                  value={values.city || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('city')}
                  invalidText={errorMessage('city')}
                  disabled={props.form_fields.city.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol style={{ marginRight: '16px' }}>
                <StyledInput
                  data-testid='region'
                  id='region'
                  name='region'
                  labelText={t(props.form_fields.region.label)}
                  placeholder={t(props.form_fields.region.placeholder)}
                  value={values.region || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('region')}
                  invalidText={errorMessage('region')}
                  disabled={props.form_fields.region.disabled}
                />
              </GridCol>
              <GridCol>
                <StyledInput
                  data-testid='postal_code'
                  id='postal_code'
                  name='postal_code'
                  labelText={t(props.form_fields.postal_code.label)}
                  placeholder={t(props.form_fields.postal_code.placeholder)}
                  value={values.postal_code || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('postal_code')}
                  invalidText={errorMessage('postal_code')}
                  disabled={props.form_fields.postal_code.disabled}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <StyledSelect
                  data-testid='country'
                  id='country'
                  name='country'
                  labelText={t(props.form_fields.country.label)}
                  placeholder={
                    values.country
                      ? ''
                      : t(props.form_fields.country.placeholder)
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('country')}
                  invalidText={errorMessage('country')}
                  defaultValue={values.country || 'placeholder-item'}
                  disabled={props.form_fields.country.disabled}
                >
                  <M.Select.Item
                    disabled
                    value='placeholder-item'
                    text={t(props.form_fields.country.placeholder)}
                  />
                  <M.Select.Item value='US' text='United States' />
                  <M.Select.Item value='CA' text='Canada' />
                </StyledSelect>
              </GridCol>
            </GridRow>
            {showPaymentMethods && (
              <>
                <GridRow style={{ marginBottom: '0' }}>
                  <h5>Payment Information</h5>
                </GridRow>
                <GridRow>
                  <GridCol>
                    <PaymentDropdown
                      onMethodSelected={pm => onPaymentMethodSelected?.(pm)}
                      initialPaymentMethod={defaultPaymentMethod}
                    />
                  </GridCol>
                </GridRow>
              </>
            )}
          </M.Grid>
        </M.ModalBody>
        <M.ModalFooter>
          <M.Button
            onClick={() => {
              formik.resetForm();
              hideModal();
            }}
            kind='secondary'
            data-testid='cancel-btn'
          >
            {t(`billing_address.add_address_form.cancel_btn`)}
          </M.Button>
          <M.Button
            type='submit'
            data-testid='submit-btn'
            disabled={!formik.isValid}
          >
            {t(`billing_address.add_address_form.continue_btn`)}
          </M.Button>
        </M.ModalFooter>
      </M.ComposedModal>
    </form>
  );
};

export default NodeBillingForm;
