import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import './styles.scss';

type Props = {
  isLoading: boolean;
  reports?: GenericObject;
};

const DownloadButton: React.FC<Props> = ({ isLoading, reports }) => {
  if (isLoading || !reports) return null;

  return (
    <div
      className='reports-listf-actions-button-container'
      data-testid='reports-list-filters'
    >
      <M.Button kind='secondary' className='reports-list-actions-button'>
        <M.Icon icon='TableSplit' size='20' />
        <span>Download</span>
      </M.Button>
    </div>
  );
};

export default DownloadButton;
