import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import CIRCLE_CHECKMARK from 'components/Packages/CreatePackage/Context/CIRCLE_CHECKMARK';

export const StyledClickableChoiceCard = styled(M.Button)`
  display: flex;
  align-items: start !important;
  max-width: none !important;
  width: 100% !important;
  padding: 24px !important;
  margin-left: 0 !important;
  margin-bottom: 16px !important;
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  border: ${props =>
    props.checked
      ? `1px solid ${colors.uiNavy200} !important`
      : `1px solid ${colors.uiGrey200} !important`};
  border-radius: 2px !important;
  box-shadow: none !important;

  .mastodon-radio-button,
  .cds--radio-button__appearance {
    transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9);
  }

  #mastodon & {
    &.mastodon-button.primary:has(svg:only-child) {
      padding: 24px !important;
    }
  }
  :hover,
  :hover .mastodon-radio-button,
  :hover .cds--radio-button__appearance {
    background: ${colors.uiNavy100} !important;
  }
  :has(:active .cds--radio-button__appearance) {
    background: ${colors.uiNavy100} !important;
  }
  :hover {
    border: 1px solid ${colors.uiNavy200} !important;
  }

  &.cds--btn--disabled {
    background: ${colors.uiGrey100} !important;
    border-color: ${colors.borderPrimaryLight} !important;
    color: ${colors.uiTextDisabledLight} !important;

    &:active,
    .mastodon-radio-button,
    &:hover .mastodon-radio-button,
    &:hover .cds--radio-button__appearance,
    .cds--radio-button__appearance:before {
      background: ${colors.uiGrey100} !important;
    }

    h3,
    .screening-list li span,
    .screening-list li:before,
    .price * {
      color: ${colors.uiTextDisabledLight};
    }

    .cds--radio-button__label,
    .button-reset {
      cursor: not-allowed !important;
    }
  }

  @media all and (max-width: 940px) {
    width: 100% !important;
  }

  #mastodon & {
    &.mastodon-button.primary:active:enabled {
      background-color: ${colors.uiNavy100} !important;
      border-color: ${colors.uiNavy100} !important;
    }
  }
`;

export const StyledRadioButton = styled(M.RadioButton)`
  margin-right: 0.75rem;
  .cds--radio-button__appearance {
    border: 1px solid ${colors.brandSlate5} !important;
  }
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  .cds--radio-button:focus
    + .cds--radio-button__label
    .cds--radio-button__appearance::after {
    box-shadow: unset !important;
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance::before {
    margin-left: -15px;
    margin-top: -15px;
    content: url(${CIRCLE_CHECKMARK}) !important;
    background-color: ${props =>
      props.checked
        ? `${colors.uiNavy100} !important`
        : `${colors.uiGrey0} !important`};
    width: 100% !important;
    height: 100% !important;
    transform: scale(0.5) !important;
  }
`;

export const StyledPackageNameCell = styled.h5`
  display: flex;
  width: 276px;
  padding-right: 80px;
  font-weight: 700;
  color: ${colors.uiTextPrimaryLight};

  @media all and (max-width: 630px) {
    width: 100%;
  }
`;

export const StyledScreeningListItem = styled.li<{ compact?: boolean }>`
  display: flex;
  &:before {
    content: '✓' !important;
    color: ${colors.brandAqua3};
  }
  font-size: 14px;
  font-weight: 400;
  line-height: ${props => (props?.compact ? '20px' : '25px')};
  color: ${colors.uiTextPrimaryLight};
`;

export const StyledScreeningUl = styled.ul`
  list-style: none !important;
  padding-left: 5px;

  @media all and (max-width: 630px) {
    padding-left: 12px;
  }
`;

export const StyledScreeningType = styled.span`
  padding-left: 5px;
`;

export const StyledScreeningSettings = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

export const StyledScreeningListContainer = styled.div`
  width: 276px;
  ul {
    list-style-type: square;
  }
  ul > li {
    color: ${colors.brandGreen4};
  }

  ul > li > span {
    color: ${colors.uiTextPrimaryLight};
  }

  @media all and (max-width: 630px) {
    width: 100%;

    ul {
      margin-top: 4px;
    }
  }
`;

export const StyledPriceCell = styled.div`
  width: 65px;
  min-width: 65px;
  margin-left: auto;

  @media all and (max-width: 540px) {
    display: none;
  }
`;

export const StyledPackageNameText = styled.div`
  width: 200px;
  overflow-wrap: break-word;
`;

export const StyledPriceCellMobile = styled.div`
  display: none;
  padding-left: 30px;
  padding-bottom: 10px;
  width: 100%;

  @media all and (max-width: 540px) {
    display: flex;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media all and (max-width: 900px) {
    flex-direction: column;
  }
`;
