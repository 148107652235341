import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import Dialog from 'modules/assess/ui/ruleset/apply';
import { useReadOnly } from 'modules/assess/ui/hooks';

type Type = React.ForwardRefRenderFunction<any, Props>;

type Props = {
  closeMenu?: () => void;
  onClick: () => void;
};

export { Dialog };

const MenuItemRef: Type = ({ closeMenu, onClick }, ref) => {
  const { t } = useTranslation('assess');
  const text = t('assess:ruleset.actions.assign');

  if (useReadOnly()) {
    return null;
  }

  return (
    <M.Menu.MenuItem
      data-testid='assess-ruleset-menu-assign-item'
      itemText={text}
      closeMenu={closeMenu}
      onClick={onClick}
      ref={ref}
    />
  );
};

export const MenuItem = React.forwardRef(MenuItemRef);
