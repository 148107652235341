import { useCallback, useEffect, useState } from 'react';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';

import { Namespace } from 'modules/assess/api';
import * as API from 'modules/assess/api/assignables';
import { update } from 'modules/assess/api/ruleset/assignments';
import * as rulesets from 'modules/assess/api/rulesets';
import { ID } from 'modules/entity';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { Base } from 'modules/assess/models/assignables';
import { Category } from 'modules/assess/models/assignables/base';
import {
  SelectedAssignments,
  AssignmentV2,
} from 'modules/assess/models/rulesets/assignment';
import { useNamespace } from '../../router/context';

export const useAssignments = (
  ruleset?: Ruleset.Type,
): [Assignment.List, React.Dispatch<React.SetStateAction<Assignment.List>>] => {
  const [assignments, setAssignments] = useState<Assignment.List>([]);

  useEffect(() => {
    if (ruleset?.assignments) {
      setAssignments(Object.values(ruleset?.assignments));
    }
  }, [ruleset]);

  return [assignments, setAssignments];
};

export const PAGE_SIZE = 5;

export const usePersisted = () => {
  const [persisted, setPersisted] = useState<Assignment.Map>({});
  const [page, setPage] = useState(1);
  const search = useSearch({ page, per_page: PAGE_SIZE });

  const { data = [] } = search.data || {};
  const more = data.length === PAGE_SIZE;

  useEffect(() => {
    if (data.length) {
      const map = data.reduce<Assignment.Map>((m, d) => {
        m[d.id] = d;
        return m;
      }, {});
      setPersisted(state => ({ ...state, ...map }));
    }
    if (more) {
      setPage(state => state + 1);
    }
  }, [data, more]);

  return persisted;
};

export const useSearch = (params?: API.SearchParams) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = API.path(context);
  const key: AnyQueryKey = [namespace, { id: undefined }, params];

  const request = () => {
    return API.search(context, params);
  };

  return useQuery(key, request);
};

export const useSelector = (
  assignables: Assignment.List | undefined,
  setAssignments?: React.Dispatch<React.SetStateAction<Assignment.List>>,
) => {
  const onSelect = useCallback(
    (assignee: string, selected: boolean) => {
      setAssignments?.(state => {
        if (selected) {
          const assignable = assignables?.find(a => a.id === assignee);
          if (assignable) {
            state = state.concat(assignable);
          }
          return state;
        }
        return state.filter(a => a.id !== assignee);
      });
    },
    [assignables, setAssignments],
  );

  return onSelect;
};

export const useUpdate = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;

  const request = (assignments: any) => {
    if (!id) {
      return Promise.reject();
    }
    return update(context, id, assignments);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        const match = Object.values(Namespace)
          .map(n => rulesets.uri(n))
          .includes(ns as string);
        if (!match) {
          return false;
        }

        if (typeof entity !== 'object') {
          return true;
        }

        return !(entity as { id: unknown }).id;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const categorizeAssignment = (
  assignment: Assignment.Type,
): Category[] => {
  const categories: Category[] = [];

  if (Base.Kind.GEO in assignment) {
    categories.push('geo');
  }
  if (Base.Kind.SEGMENT in assignment) {
    categories.push('segment');
  }
  if (Base.Kind.ROLE in assignment) {
    categories.push('role');
  }
  if (Base.Kind.PACKAGE in assignment) {
    categories.push('package');
  }
  if (Base.Kind.STATE in assignment) {
    categories.push('state');
  }
  if (assignment.default) {
    categories.push('account');
  }

  return categories;
};

export const getInitialSelections = (assignments: Assignment.List) => {
  const initiallySelected: SelectedAssignments = {
    account: [],
    geo: [],
    segment: [],
    package: [],
    role: [],
    state: [],
  };

  assignments.forEach((assignment: Assignment.Type) => {
    const categories = categorizeAssignment(assignment);

    categories.forEach(category => {
      const categoryItems = initiallySelected[category];
      const assignmentCategory = (assignment as any)[category];

      if (category === 'account') {
        categoryItems.push(assignment);
      } else if (
        !categoryItems.some(selected => selected.id === assignmentCategory.id)
      ) {
        categoryItems.push(assignmentCategory);
      }
    });
  });

  return initiallySelected;
};

export function isTwoLetterUpperCase(value: any) {
  return /^[A-Z]{2}$/.test(value);
}

export const createAssignmentCombinations = (data: SelectedAssignments) => {
  const { geo, role, package: pkg, segment, state, account } = data;

  const validCombinations: AssignmentV2[] = [];

  const combinationMethod = (
    role: Assignment.Type | null,
    pkg: Assignment.Type | null,
    segment: Assignment.Type | null,
    geo: Assignment.Type | null,
    state: any,
  ) => {
    const combination: Record<string, any> = { metadata: {} };

    if (geo) {
      combination.geo = {
        id: geo.id,
        name: geo.name,
      };
      combination.metadata = {
        ...combination.metadata,
        geo_name: geo.name,
      };
    }
    if (state) {
      const state_id = isTwoLetterUpperCase(state?.id)
        ? state?.id
        : state?.state?.short_name;
      combination.state = {
        id: state_id,
        name: state.name,
      };
      combination.metadata = {
        ...combination.metadata,
        state_name: state.name,
      };
    }
    if (role) {
      combination.role = { id: role?.id, name: role?.name };
      combination.metadata = {
        ...combination.metadata,
        role_name: role.name,
      };
    }
    if (pkg) {
      combination.package = {
        id: pkg.id,
        name: pkg.name,
      };
      combination.metadata = {
        ...combination.metadata,
        package_name: pkg.name,
      };
    }
    if (segment) {
      combination.segment = {
        id: segment.id,
        name: segment.name,
      };
      combination.metadata = {
        ...combination.metadata,
        segment_name: segment.name,
      };
    }
    // Only push if at least one condition was met
    // to avoid pushing empty metadata objects
    if (Object.keys(combination).length > 1) {
      validCombinations.push(combination);
    }
  };

  for (const r of role.length ? role : [null]) {
    for (const p of pkg.length ? pkg : [null]) {
      for (const s of segment.length ? segment : [null]) {
        // Handle GEO & State combinations. They are mutually exclusive
        if (geo.length) {
          for (const g of geo) {
            // Only call if geo exists
            combinationMethod(r, p, s, g, null);
          }
        }
        if (state.length) {
          for (const st of state) {
            // Only call if state exists
            combinationMethod(r, p, s, null, st);
          }
        }
        if (!geo.length && !state.length) {
          // Only call if state or geo does not exist
          combinationMethod(r, p, s, null, null);
        }
      }
    }
  }

  if (account.length > 0 && account[0].id === 'ACCOUNT_DEFAULT_ID') {
    validCombinations.push({ default: true });
  }

  return validCombinations;
};
export const getInitialItem = (
  items: SelectedAssignments,
  type: Base.Kind | undefined,
) => {
  if (!type) return [];
  const filteredAssignments = items[type as keyof SelectedAssignments];
  return mapToItems(Object.values(filteredAssignments));
};

export const mapToItems = (items: { [key: string]: any }) => {
  return items.map((item: { id?: string; name: string }) => ({
    id: item.id?.toString(),
    label: item.name,
  }));
};

export const mapStateToItems = (items: { [key: string]: any }) => {
  return items.map((item: { short_name?: string; name: string }) => ({
    id: item.short_name,
    label: item.name,
  }));
};

export const mapNodesToItems = (items: { [key: string]: any }) => {
  return items.map((item: { custom_id: string; name: string }) => ({
    id: item.custom_id,
    label: item.name,
  }));
};
