import {
  ReportStatuses,
  StatusTypes,
  hasPermission,
  getReportDecision,
} from '@dashboard-experience/utils';
import { FieldKeys, GetFieldsDictParams, FieldsDictValue } from './types';

const fields = ({
  user,
  candidate,
  report,
  invitation,
  reportPrediction,
  reportEstimationGenerated,
  statusType,
  task,
  showPreference,
}: GetFieldsDictParams): Record<FieldKeys, FieldsDictValue> => {
  const hasReport = !!report.id;
  const isInvitation = !!invitation && !hasReport;

  const segmentationEnabled = hasReport && report.segment_stamps?.length > 0;

  // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
  const displayAssessment = hasReport && getReportDecision(report, user);
  const isAssessEnabled = statusType === StatusTypes.Assess;
  const displayAssessmentField =
    hasPermission(user, 'read_assessments') &&
    !!displayAssessment &&
    isAssessEnabled;

  return {
    // General details + Invitation status
    status: {
      getFieldName: () => (isInvitation ? 'Status' : 'Report status'),
      shouldShow: () => isInvitation || hasReport,
    },
    adjudication: {
      getFieldName: () => 'Adjudication',
      shouldShow: () => hasReport && report?.status !== ReportStatuses.EXPIRED,
    },
    report_id: {
      getFieldName: () => 'Report ID',
      shouldShow: () => hasReport,
    },
    assigned_to: {
      getFieldName: () => 'Assigned to',
      shouldShow: () => hasReport && !!task,
    },
    tags: {
      getFieldName: () => 'Tags',
      shouldShow: () => hasReport,
    },
    // Candidate information
    first_name: {
      getFieldName: () => 'First name',
      shouldShow: () => !!candidate?.first_name || showPreference,
    },
    middle_name: {
      getFieldName: () => 'Middle name',
      shouldShow: () => !!candidate?.middle_name || showPreference,
    },
    last_name: {
      getFieldName: () => 'Last name',
      shouldShow: () => !!candidate?.last_name || showPreference,
    },
    date_of_birth: {
      getFieldName: () => 'Date of birth',
      shouldShow: () => !!candidate?.dob || showPreference,
    },
    social_security: {
      getFieldName: () => 'Social Security #',
      shouldShow: () => !!candidate?.ssn || showPreference,
    },
    location: {
      getFieldName: () => 'Location',
      shouldShow: () => !!candidate?.zipcode || showPreference,
    },
    postal_code: {
      getFieldName: () => 'Postal code',
      shouldShow: () =>
        !!candidate?.zipcode ||
        !!candidate?.international_postal_code ||
        showPreference,
    },
    phone: {
      getFieldName: () => 'Phone',
      shouldShow: () => !!candidate?.phone || showPreference,
    },
    email: {
      getFieldName: () => 'Email',
      shouldShow: () => !!candidate?.email || showPreference,
    },
    custom_id: {
      getFieldName: () => 'Custom ID',
      // Show custom_id evem when showPreference is false
      shouldShow: () => !!candidate?.id,
    },
    drivers_license: {
      getFieldName: () => "Driver's license #",
      shouldShow: () =>
        !!candidate?.driver_license_number ||
        !!candidate?.previous_driver_license_number ||
        showPreference,
    },
    candidate_created_at: {
      getFieldName: (isPinnedPanel?: boolean) =>
        isPinnedPanel ? 'Created (Candidate)' : 'Created',
      shouldShow: () => !!candidate?.created_at || showPreference,
    },
    invitation_sent: {
      getFieldName: () => 'Invitation sent',
      shouldShow: () => {
        if (!candidate?.invitations || !report) {
          return false;
        }
        return candidate?.invitations.some(i => i.report_id === report.id);
      },
    },
    // Order Information
    source: {
      getFieldName: () => 'Source',
      shouldShow: () => hasReport && (!!report.source || showPreference),
    },
    work_locations: {
      getFieldName: () => {
        const multipleLocations =
          report.work_locations?.length > 1 ||
          (report.geos && report.geos.length > 1);
        return !multipleLocations ? 'Work Location' : 'Work Locations';
      },
      shouldShow: () => hasReport,
    },
    package: {
      getFieldName: () => 'Package',
      // Both reports and invitations should show package
      shouldShow: () => !!report.id || !!invitation?.id,
    },
    created_at: {
      getFieldName: (isPinnedPanel?: boolean) =>
        isPinnedPanel ? 'Created (Report)' : 'Created',
      shouldShow: () => hasReport && (!!report.created_at || showPreference),
    },
    completed_at: {
      getFieldName: () => 'Completed',
      shouldShow: () => hasReport && (!!report.completed_at || showPreference),
    },
    upgraded_at: {
      getFieldName: () => 'Upgraded',
      shouldShow: () => hasReport && !!report.upgraded_at,
    },
    revised_at: {
      getFieldName: () => 'Revised',
      shouldShow: () => hasReport && !!report.revised_at,
    },
    estimated_completion: {
      getFieldName: () => 'Estimated Completion',
      shouldShow: () => report.status === 'pending' && !!reportPrediction,
    },
    estimated_updated: {
      getFieldName: () => 'Estimated Updated',
      shouldShow: () =>
        report.status === 'pending' && !!reportEstimationGenerated,
    },
    turnaround_time: {
      getFieldName: () => 'Turnaround time',
      shouldShow: () =>
        hasReport && (!!report.turnaround_time || showPreference),
    },
    // Order invitation information
    invitation_link: {
      getFieldName: () => 'Link',
      shouldShow: () => isInvitation,
    },
    invitation_link_expires: {
      getFieldName: () => 'Link expires',
      shouldShow: () => isInvitation,
    },
    // Nodes
    nodes: {
      getFieldName: () => 'Node',
      shouldShow: () => segmentationEnabled,
    },
    // Assess
    mvr_ruleset: {
      getFieldName: () => 'MVR Ruleset',
      shouldShow: () =>
        displayAssessmentField && !!report?.assessment?.mvr_ruleset,
    },
    criminal_ruleset: {
      getFieldName: () => 'Criminal Ruleset',
      shouldShow: () => displayAssessmentField && !!report?.assessment?.ruleset,
    },
  };
};

export default fields;
