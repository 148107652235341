import React, { useState, useCallback } from 'react';
import { useDebouncedCallback } from '@dashboard-experience/utils';

const SEARCH_FILTER_DELAY_MS = 350;

const useSearchFilter = <T>(
  data: T[],
  filterFunction: (item: T, searchFilter: string) => boolean,
) => {
  const [searchParam, setSearchParam] = useState('');
  const [searchFilterValue, setSearchFilterValue] = useState('');

  const debouncedSearch = useDebouncedCallback(value => {
    setSearchFilterValue(value);
  }, SEARCH_FILTER_DELAY_MS);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchParam(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const filteredData = (data || []).filter(item =>
    filterFunction(item, searchFilterValue),
  );

  return { searchParam, searchFilterValue, filteredData, handleSearchChange };
};

export default useSearchFilter;
