/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState, useEffect } from 'react';
import UserContext from 'context/CurrentUser';
import { M, colors } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { QueryResult } from 'react-query';
import { LoadingScreen } from 'components/Account';
import {
  CARD_METHOD_TYPE,
  BANK_ACCOUNT_METHOD_TYPE,
  PaymentMethod,
  useGetPaymentMethods,
} from 'api/payment';
import { IconCard } from 'components/Account/Payment/PaymentStyledComponents';
import BankPaymentMethod from '../../containers/Billing/BankPaymentMethod';

type Props = {
  onMethodSelected?: (method: PaymentMethod) => void;
  initialPaymentMethod?: PaymentMethod | null;
};

const PaymentDropdown: React.FC<Props> = ({
  onMethodSelected,
  initialPaymentMethod = null,
}) => {
  const userData: any = useContext(UserContext);
  const accountId: string = userData?.account?.id;
  const { data, isFetching }: QueryResult<GenericObject> =
    useGetPaymentMethods(accountId);
  const [selectedItem, setSelectedItem] = useState<PaymentMethod | null>(null);

  useEffect(() => {
    if (initialPaymentMethod && !selectedItem) {
      setSelectedItem(initialPaymentMethod);
    }
  }, [initialPaymentMethod, selectedItem]);

  const cardIconClassNames = (brand: string): string => {
    const classes: GenericObject = {
      'icon-card': true,
      'icon-visa': brand === 'Visa',
      'icon-master': brand === 'MasterCard',
      'icon-discover': brand === 'Discover',
      'icon-express': brand === 'American Express',
      'icon-jcb': brand === 'JCB',
      'icon-diners': brand === 'Diners Club',
      'icon-union': brand === 'UnionPay',
    };
    const toBeClasses = Object.keys(classes).map(key =>
      classes[key] === true ? key : '',
    );
    return toBeClasses.join(' ');
  };

  if (isFetching) {
    return <LoadingScreen />;
  }

  const paymentMethods =
    data?.payment_methods?.filter(
      (pm: PaymentMethod) =>
        pm.payment_type === CARD_METHOD_TYPE ||
        pm.payment_type === BANK_ACCOUNT_METHOD_TYPE,
    ) || [];

  const items = [...paymentMethods, { id: 'none', payment_type: 'none' }];

  const itemToElement = (item: PaymentMethod) => {
    if (item.payment_type === 'none' || item?.payment_type === 'none') {
      return <span>None, I don&apos;t want autopay.</span>;
    }

    if (item.payment_type === BANK_ACCOUNT_METHOD_TYPE) {
      return <BankPaymentMethod bankName={item.bank_name} last4={item.last4} />;
    }

    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          fontSize: '15px',
        }}
      >
        <IconCard className={cardIconClassNames(item.brand)} />
        <span>{item.brand}</span>
        <span>••••</span>
        <span>{item.last4}</span>
        <span style={{ fontSize: '13px', color: colors.brandSlate6 }}>
          Exp {item.exp_month}/{item.exp_year}
        </span>
      </span>
    );
  };

  const handleChange = (value: { selectedItem: PaymentMethod }) => {
    setSelectedItem(value.selectedItem);

    if (onMethodSelected) {
      onMethodSelected(value.selectedItem);
    }
  };

  return (
    <div style={{ width: '400px' }}>
      <label htmlFor='payment-method-select'>Select payment method</label>
      <M.Dropdown
        id='payment-method-select'
        items={items}
        label='Selection'
        itemToElement={itemToElement}
        itemToString={itemToElement}
        style={{ width: '100%' }}
        selectedItem={selectedItem}
        onChange={handleChange}
      />
    </div>
  );
};

export default PaymentDropdown;
