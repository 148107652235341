export enum ActionType {
  INITIALIZE_FILTERS = 'initialize filters',
  UPDATE_FILTERS = 'update filters',
  UPDATE_PAGE = 'update page',
  RESET_VIEW = 'reset view',
}

export type Action =
  | { type: ActionType.INITIALIZE_FILTERS }
  | {
      type: ActionType.UPDATE_FILTERS;
      payload: Filters;
    }
  | {
      type: ActionType.UPDATE_PAGE;
      payload: number;
    }
  | {
      type: ActionType.RESET_VIEW;
      payload: View;
    };

export type Dispatch = (action: Action) => void;

// TODO: Enhance filter type
export type Filters = { [filterKey: string]: any };

export type View = {
  filters: Filters;
};

export type State = {
  view?: View;
};
