/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSetPinnedFields } from 'providers/Candidate';
import SortableItem from './sortable-item';
import { FieldKVItem } from '../types';

type Props = {
  pinnedFields: FieldKVItem[];
};

const DraggableList = ({ pinnedFields }: Props) => {
  const [items, setItems] = useState(pinnedFields);
  const setPinnedFields = useSetPinnedFields();

  // Update items when pinnedFields changes
  useEffect(() => {
    setItems(pinnedFields);
  }, [pinnedFields]);

  // Define sensors for dragging
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  // Handle drag end
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems(items => {
        const newItems = arrayMove(
          items,
          items.findIndex(item => item.id === active.id),
          items.findIndex(item => item.id === over.id),
        );

        // Call setPinnedFields with the new order of field keys
        setPinnedFields(newItems.map(item => item.id));

        return newItems;
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(item => item.id)}
        strategy={verticalListSortingStrategy}
      >
        <div>
          {items.map(item => (
            <SortableItem
              key={item.id}
              itemKey={item.itemKey}
              itemValue={item.itemValue}
              id={item.id}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default DraggableList;
