import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled(M.Table)`
  table-layout: fixed;
  width: 100%;
`;

const BaseTableCell = styled(M.TableCell)`
  font-weight: 400;
  line-height: 20px;
  color: black !important;
  font-size: 0.9rem;
  padding: 8px;
  overflow: hidden;
`;

export const TypeCell = styled(BaseTableCell)`
  width: 100px;
  min-width: 100px;
`;

export const StatusCell = styled(BaseTableCell)`
  width: 100px;
  min-width: 100px;
`;

export const UrlCell = styled(BaseTableCell)`
  width: auto;
  max-width: 100%;
  padding: 8px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .http-method {
    flex-shrink: 0;
    font-weight: 700;
  }

  .url-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    direction: rtl;
    text-align: left;
  }
`;

export const CreatedCell = styled(BaseTableCell)`
  width: 200px;
  min-width: 200px;
  max-width: 200px;
`;

export const TableHeader = styled(M.TableHeader)`
  &[data-column='type'] {
    width: 100px;
    min-width: 100px;
  }
  &[data-column='status'] {
    width: 100px;
    min-width: 100px;
  }
  &[data-column='url'] {
    width: auto;
  }
  &[data-column='created'] {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;

    /* Handle sort button styling */
    .mastodon-table-header {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }

    button {
      width: 100%;
      min-width: 200px;
      max-width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 8px;

      .cds--table-sort__flex {
        width: auto;
        min-width: unset;
        max-width: unset;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .cds--table-sort__icon-unsorted,
      .cds--table-sort__icon {
        position: static;
        flex-shrink: 0;
        margin-left: 8px;
      }
    }
  }
`;
