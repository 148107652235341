import { M } from '@dashboard-experience/mastodon';
import { getParamFromUrl } from '@dashboard-experience/utils';
import { toastError } from 'actions';
import { CANDIDATE_REDESIGN } from 'Constants';
import UIContext from 'context/UI';
import { BetaState, useBetaEnabled, useBetaState } from 'providers/Beta';
import { useCandidate } from 'providers/Candidate';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useReport, useReportError, useReportLoading } from './hooks';
import Provider from './Provider';

type MatchParams = {
  candidateId: string;
  invitationId: string;
};

export const LoadingContainer: React.FC<{}> = ({ children }) => {
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );
  const invitationId = invitationMatch?.params.invitationId;
  const isLoading = useReportLoading();
  const isError = useReportError();
  const report = useReport();

  if (isLoading) return <M.LoadingInline />;
  if (isError || (!report?.id && !invitationId))
    return (
      <M.InlineNotification
        hideCloseButton
        kind='error'
        title='Error fetching report'
        subtitle='Please try again later'
      />
    );
  return <>{children}</>;
};

type Props = {
  match: {
    params: {
      reportId: string;
      invitationId: string;
    };
  };
};

type WithReportContainerProps = {
  component: React.FC;
  type?: string;
  loadingContainer?: boolean;
  reportProvider?: boolean;
};

const renderChildren = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);

const withReportContainer = ({
  component: Component,
  type,
  loadingContainer = true,
  reportProvider,
}: WithReportContainerProps) => {
  const WrappedComponent: React.FC<Props> = ({ match }) => {
    const { reportId, invitationId } = match?.params || {};

    const { isIframe, isStandaloneIframe } = useContext(UIContext);
    const candidate = useCandidate();
    const dispatch = useDispatch();
    const report = useReport();

    const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);
    const betaState = useBetaState(CANDIDATE_REDESIGN);

    const [redirect, setRedirect] = useState(false);

    const screenings = getParamFromUrl(window, 'screenings') || '';
    const screeningsArray = useMemo(
      () => (screenings?.length ? screenings.split(',') : []),
      [screenings],
    );

    // Include Report provider by default with the redesign flag off or OPT_OUT from beta
    // Clean this up after the redesign beta phase has been removed
    const withProvider =
      !candidateRedesignEnabled ||
      betaState === BetaState.OPT_OUT ||
      reportProvider;
    const WrapperProvider = withProvider ? Provider : renderChildren;
    const WrapperLoading = loadingContainer ? LoadingContainer : renderChildren;

    const isValidReport =
      report?.candidate_id === candidate.id ||
      report?.candidate?.id === candidate.id;

    const isValidInvitation = useMemo(
      () =>
        candidate?.invitations?.some(
          invitation => invitation.id === invitationId,
        ),
      [candidate?.invitations, invitationId],
    );

    useEffect(() => {
      if (!report.id) return;

      if (type === 'report' && !isIframe && !isStandaloneIframe) {
        if (!isValidReport) {
          const error = `Report ID ${reportId} does not exist for Candidate ID ${candidate.id}`;
          dispatch(toastError('Report ID not found', error));
          setRedirect(true);
        }
      }

      if (type === 'invitation' && !isIframe && !isStandaloneIframe) {
        if (!isValidInvitation) {
          const error = `Invitation ID ${invitationId} does not exist for Candidate ID ${candidate.id}`;
          dispatch(toastError('Invitation ID not found', error));
          setRedirect(true);
        }
      }
    }, [
      candidate,
      report,
      reportId,
      dispatch,
      isValidReport,
      isIframe,
      isStandaloneIframe,
      isValidInvitation,
      invitationId,
    ]);

    if (redirect) {
      return <Redirect to={`/candidates/${candidate.id}`} />;
    }

    return (
      <WrapperProvider reportId={reportId} screenings={screeningsArray}>
        <WrapperLoading>
          <Component />
        </WrapperLoading>
      </WrapperProvider>
    );
  };

  WrappedComponent.displayName = `withReportContainer(${Component.displayName})`;
  return WrappedComponent;
};

export default withReportContainer;
