import moment from 'moment';
import { Exception, GenericObject } from 'types';
import { SCREENING_TITLES } from 'utils';

export type ExceptionWithScreeningName = Exception & { screeningName?: string };

export const capitalizeFirstLetter = (str: string | undefined) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const STATE_DOCUMENT_TYPES = {
  criminal_screening_requirement: {
    'maiden name': {
      documentType: 'Mother’s maiden name',
    },
    gender: {
      documentType: 'gender',
    },
    'additional authorization': {
      documentType: 'additional authorization',
    },
    'address in the Mariana Islands': {
      documentType: 'address in the Mariana Islands',
    },
    'place of birth': {
      documentType: 'place of birth',
    },
    'government id': {
      documentType: 'government ID',
    },
    'current address': {
      documentType: 'current address',
    },
  },
};

export const RESOLVABLE_EXCEPTION_DOCUMENT_TYPES = {
  ssn_confirmation_requested: {
    documentType: 'Social Security number',
  },
  ssn_documentation_requested: {
    documentType: 'Social Security card',
  },
  id_documentation_requested: {
    documentType: 'ID card',
  },
  employment_documentation_requested: {
    documentType: 'employment history',
  },
  education_documentation_requested: {
    documentType: 'education history',
  },
  dl_documentation_requested: {
    documentType: 'Driver’s License',
  },
  previous_dl_documentation_requested: {
    documentType: 'current or previous Driver’s License',
  },
  driving_experience: {
    documentType: 'additional previous licensing information',
  },
  mvr_consent: {
    documentType: 'Driver’s License and provide additional consent',
  },
  occupational_health_missing_paperwork: {
    documentType: 'Health Screening documentation',
  },
  international_documentation_requested: {
    documentType: 'International Search documentation',
  },
  criminal_screening_requirement: {
    documentType: 'State Search documentation',
  },
};

const getStateDocumentTypes = (
  exception: GenericObject,
): string | undefined => {
  const comment = exception?.comment || '';
  const docTypes = STATE_DOCUMENT_TYPES.criminal_screening_requirement;
  let documentTypes;

  const matchedDocumentTypes = Object.keys(docTypes)
    .filter(key => comment.toLowerCase().includes(key))
    .map(key => docTypes[key as keyof typeof docTypes].documentType);

  if (matchedDocumentTypes.length) {
    const lastDocumentType = matchedDocumentTypes.pop()!;

    if (matchedDocumentTypes.length > 1) {
      return `${matchedDocumentTypes.join(', ')}, and ${lastDocumentType}`;
    }
    if (matchedDocumentTypes.length === 1) {
      return `${matchedDocumentTypes[0]} and ${lastDocumentType}`;
    }
    documentTypes = lastDocumentType;
  }
  return documentTypes;
};

const getInternationalDocumentTypes = (
  exception: GenericObject,
): string | undefined => {
  const comment = exception?.comment || '';
  const marker = 'provide the following:';
  const markerIndex = comment.toLowerCase().indexOf(marker);
  let documentTypes;

  if (markerIndex !== -1) {
    let extractedText = comment.slice(markerIndex + marker.length).trim();

    // Remove trailing period or space
    extractedText = extractedText.replace(/\.\s*$/, '');

    documentTypes = extractedText;
  }

  return documentTypes;
};

export const isResolvableExceptionType = (
  exception: GenericObject,
): boolean => {
  if (exception.type === 'international_documentation_requested') {
    return exception.screening_type !== 'MatrixInternationalCriminalSearch';
  }

  return exception.type in RESOLVABLE_EXCEPTION_DOCUMENT_TYPES;
};

export const getExceptionDocumentTypes = (
  exception: GenericObject,
): string | undefined => {
  let documentTypes;

  if (exception.type === 'international_documentation_requested') {
    documentTypes = getInternationalDocumentTypes(exception);
  }

  if (exception.type === 'criminal_screening_requirement') {
    documentTypes = getStateDocumentTypes(exception);
  }

  if (!documentTypes && isResolvableExceptionType(exception)) {
    documentTypes =
      RESOLVABLE_EXCEPTION_DOCUMENT_TYPES[
        exception.type as keyof typeof RESOLVABLE_EXCEPTION_DOCUMENT_TYPES
      ].documentType;
  }

  return documentTypes;
};

export const getDocumentTypesForMultipleExceptions = (
  exceptions: GenericObject[],
): string[] => {
  const documentTypes = exceptions.map(exception => {
    const documentType = getExceptionDocumentTypes(exception);
    return documentType || '';
  });
  const filteredDocumentTypes = documentTypes.flatMap(documentType => {
    if (documentType.length < 1) {
      return [];
    }
    if (/\b, and\b/.test(documentType)) {
      return documentType
        .replace(/\b, and\b/, ', ')
        .split(', ')
        .map(str => str.trim());
    }

    // Check if the document type contains "and" as a standalone word
    if (/\band\b/.test(documentType)) {
      return documentType.split(/\band\b/).map(str => str.trim());
    }

    return [documentType];
  });

  return Array.from(new Set(filteredDocumentTypes));
};

export const getOldestExceptionCreationDate = (
  exceptions: GenericObject[],
): string => {
  const sortedExceptions = exceptions.slice().sort((a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });

  const oldestException = sortedExceptions[0];
  return moment(oldestException.created_at).format('MMM D, YYYY');
};

export const getExpirationDaysMessage = (exceptions: Exception[]): string => {
  let maxExpirationDays = 0;

  for (const exception of exceptions) {
    const createdAt = moment(exception.created_at);
    // Handle edge case where expires_at is null: default to 7 days after the creation date.
    // Assumes the exception is resolvable, as the type has been checked in the ResolveExceptionsBanners component.
    const defaultExpiresAt = createdAt.add(7, 'days');
    const expiresAt = moment(exception.expires_at || defaultExpiresAt);

    if (createdAt.isValid() && expiresAt.isValid()) {
      const differenceInDays = expiresAt.diff(moment(), 'days');

      if (differenceInDays > maxExpirationDays) {
        maxExpirationDays = differenceInDays;
      }
    }
  }
  switch (maxExpirationDays) {
    case 0:
      return 'by today';
    case 1:
      return 'within 1 day';
    default:
      return `within ${maxExpirationDays} days`;
  }
};

const isSsnType = (exception: ExceptionWithScreeningName) => {
  return (
    exception.screening_type === 'StatefulSsnTrace' ||
    (exception.type === 'id_documentation_requested' &&
      exception.context === 'ssn_trace')
  );
};

export const matchExceptionTypesToScreenings = (
  unresolvedExceptions: ExceptionWithScreeningName[],
  screenings: GenericObject[],
) => {
  const matchedExceptions = unresolvedExceptions.filter(exception => {
    // we assume that an unresolved ssn trace exception has led to canceled dependent screenings
    // so we add it to the list of screenings automatically
    if (isSsnType(exception)) {
      (exception as ExceptionWithScreeningName).screeningName = 'SSN Trace';
      return true;
    }
    let screeningTitle;

    const screening = screenings.find(s => {
      let screeningName = s.object;
      if (screeningName === 'state_criminal_search')
        screeningName = 'state_criminal_searches';

      const screeningDetails =
        SCREENING_TITLES[screeningName as keyof typeof SCREENING_TITLES];

      if (exception.context === 'sex_offender_search') {
        exception.screening_type = 'MatrixSexOffenderSearch';
      }

      if (!screeningDetails) return false;

      const screeningExceptionTypes =
        'exceptionScreeningTypes' in screeningDetails
          ? screeningDetails.exceptionScreeningTypes
          : [];

      screeningTitle = screeningDetails.title;
      return screeningExceptionTypes.includes(exception.screening_type || '');
    });

    if (screening) {
      (exception as ExceptionWithScreeningName).screeningName = screeningTitle;
      return true;
    }
    return false;
  });

  return [...matchedExceptions];
};
