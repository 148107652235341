import { useLocation } from 'react-router-dom';
import { AnyQueryKey, useQuery } from 'react-query';
import { useReport } from 'containers/Report';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';
import { getReportAssessmentVariantRegistrations } from './actions';

export const useFetchAssessmentVariantRegitrations = (): any => {
  const report = useReport();
  const { id: reportId } = report;
  const key: AnyQueryKey = ['assessment_variant_registration', reportId];

  const enabled = report?.account?.assess_assessment_variants && !!reportId;

  const request = () => getReportAssessmentVariantRegistrations(reportId);

  return useQuery(key, request, {
    enabled,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

export const useGetDisplayLabel = () => {
  const { currentRegistration } = useSelectedAssessmentVariant();

  return currentRegistration?.display_label;
};

export const useSelectedAssessmentVariant = () => {
  const { search } = useLocation();
  const { data: assessmentVariants, isLoading } =
    useFetchAssessmentVariantRegitrations();

  const assessmentParam = new URLSearchParams(search).get(
    'assessment_variant_registration_id',
  );

  const currentRegistration =
    assessmentParam &&
    (assessmentVariants?.find(
      (registration: AssessmentVariantRegistration) =>
        registration.id === assessmentParam,
    ) ??
      null);

  const currentVariant = currentRegistration?.current_variant || null;
  const currentVariantId = currentVariant?.id || null;

  return {
    currentVariantId,
    currentVariant,
    currentRegistration,
    isLoading,
  };
};
