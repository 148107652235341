import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { TQueryFilters } from './types';

interface PaginationControlsProps {
  logsMetadata: {
    count: number;
    last: number;
    page: number;
    pages: number;
  };
  queryFilters: TQueryFilters;
  setQueryFilters: (filters: TQueryFilters) => void;
}

const FlexContainer = styled.div`
  .displayFlexJustified {
    display: flex;
    justify-content: flex-end;
  }
`;

const PaginationControls: React.FC<PaginationControlsProps> = ({
  logsMetadata,
  queryFilters,
  setQueryFilters,
}) => {
  const handlePageClick = useCallback(
    pageIndex => setQueryFilters({ ...queryFilters, page: pageIndex + 1 }),
    [queryFilters, setQueryFilters],
  );

  return (
    <div data-testid='reports-list-pagination'>
      <FlexContainer>
        <M.Pagination
          pageCount={logsMetadata.pages}
          onPageClick={handlePageClick}
          selectedIndex={logsMetadata.page - 1}
          className='displayFlexJustified'
        />
      </FlexContainer>
    </div>
  );
};

export default PaginationControls;
