import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const OuterContainer = styled.div`
  margin: 32px 0;
`;

export const Container = styled.div`
  display: flex;
  max-width: 760px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Description1 = styled.div`
  height: 20px;
  margin-bottom: 24px;
  flex: 1 0 0;
  color: var(--text-icon-black-tertiary-65, rgba(26, 32, 38, 0.65));
  font-family: var(--font-family-body, 'Public Sans');
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-lg, 20px);
`;

export const Title1 = styled.div`
  height: 20px;
  align-self: stretch;
  margin-bottom: 8px;
  color: var(--text-icon-black-primary, #1a2026);
  font-family: var(--font-family-title, 'National 2');
  font-size: var(--font-size-md, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-line-height-lg, 20px);
  letter-spacing: 0.16px;
`;

export const Label = styled.label`
  display: flex;
  padding: 12px 24px 12px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--ui-border-primary-grey-200, #e1e6eb);
  background: var(--ui-bg-primary-grey-0, #fff);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
`;

export const Description2 = styled.div`
  height: 20px;
  align-self: stretch;
  color: var(--text-icon-black-tertiary-65, rgba(26, 32, 38, 0.65));

  font-family: var(--font-family-body, 'Public Sans');
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-lg, 20px);
`;

export const RoleDefinitionCard = styled.div`
  color: var(--text-icon-white-primary, #fff);
  text-align: center;

  font-family: var(--font-family-body, 'Public Sans');
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-line-height-sm, 14px);
  letter-spacing: 0.14px;
  display: flex;
  height: 32px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  background: var(--aqua-600, #117292);
`;

export const ChoiceCardContainer = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;
`;

export const ChoiceCard = styled(M.ChoiceCard)`
  display: flex;
  padding: 12px 24px 12px 12px;
  align-items: flex-start;
  gap: 8px;
`;
