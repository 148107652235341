import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

/**
 * @name getEventCategory
 * @function
 * @memberOf Adjudication
 * @description Determines the human-friendly category of the event
 * @returns {string}
 * @param {object} timelineEvent - The timeline event object
 */
const eventCategory = 'Adjudication:';

/**
 * @name getEventAction
 * @function
 * @memberOf Adjudication
 * @description Determines the human-friendly event action
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */

const getEventAction = (timelineEvent: TimelineEvent) => {
  let action: string | null;

  switch (timelineEvent.adjudication) {
    case null:
    case undefined:
      action = 'Reset';
      break;
    case 'deferred':
      action = 'Candidate Deferred';
      break;
    case 'engaged':
      action = 'Candidate Engaged';
      break;
    case 'manual':
      action = null;
      break;
    case 'pre_adverse_action':
      action = 'Candidate Pre-Adverse Actioned';
      break;
    case 'post_adverse_action':
      action = 'Candidate Post-Adverse Actioned';
      break;
    default:
      action = null;
      break;
  }

  if (
    action &&
    timelineEvent.adjudication_subtype &&
    timelineEvent.adjudication_subtype.name
  ) {
    action += ` - ${timelineEvent.adjudication_subtype.name}`;
  }

  return action;
};

const Adjudication: React.FC<{ timelineEvent: TimelineEvent }> = ({
  timelineEvent,
}) => {
  const eventAction = getEventAction(timelineEvent);

  if (!eventAction) {
    return null;
  }

  return (
    <M.TableRow data-testid='report-timeline-adjudication-event'>
      <TimelineCell>
        <EventName data-testid='report-timeline-adjudication-event-name'>
          {`${eventCategory} ${eventAction}`}
        </EventName>
      </TimelineCell>
      <TimelineCell data-testid='report-timeline-adjudication-event-user'>
        {timelineEvent.who !== 'system' && timelineEvent.who}
      </TimelineCell>
      <TimelineCell>
        <Timestamp data-testid='report-timeline-adjudication-event-time'>
          {moment(timelineEvent.time).format('llll')}
        </Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default Adjudication;
