import {
  GenericObject,
  Geo,
  PostPackageType,
} from '@dashboard-experience/utils';
import {
  Email,
  InfoMethod,
  InfoSource,
  Invite,
  ManualOrderType,
  WorkLocation,
} from '../GetStartedStep/GetStartedStep.types';
import {
  Alias,
  PricesSummary,
  ScreeningTypeWithPrices,
} from '../AddonsStep/AddonsStep.types';

export const createGetStartedTrackData = (
  geo: Geo,
  infoMethod: InfoMethod | undefined,
  infoSource: InfoSource,
  invites: Invite[],
  location: WorkLocation,
  emails: Email[],
): GenericObject => {
  const trackData: GenericObject = {
    Country: location?.country || geo?.country,
    State: location?.state,
    City: location?.city,
    'Candidate Info Provider': infoSource,
    'Number Of Candidate Email Entered': emails?.length,
  };

  addCandidatesMailPhoneCountToTracking(
    trackData,
    invites,
    infoSource,
    infoMethod,
  );
  addEmailEnteredMethodToTracking(trackData, infoSource, infoMethod);

  return trackData;
};

export const createSubmitTrackData = (
  emails: Email[],
  selectedPackage: PostPackageType,
  newlyCreatedPackage: PostPackageType,
  addedScreeningTypeWithPrices: ScreeningTypeWithPrices[],
  newPackageName: string | undefined,
  aliasAdded: Alias,
  infoSource: InfoSource,
  manualOrderType: ManualOrderType | undefined,
  has_report_or_invitation: boolean,
  infoMethod: InfoMethod | undefined,
  invites: Invite[],
  pricesSummary: PricesSummary,
  recommendedPackageTag: string | undefined,
  promoCode?: string | undefined,
): GenericObject => {
  const trackData: GenericObject = {
    'Checkr Service Agreement Clicked': 'Yes',
    'Certification Button Clicked': 'Yes',
    'Number Of Candidate Email Entered': emails.length,
    'Original Package Name': selectedPackage.name,
    'New Package Name': newPackageName || newlyCreatedPackage?.name,
    'Original Package Screenings': selectedPackage.screenings
      .map(screening => {
        // @ts-ignore Property type does not exist on type OccupationalHealthScreeningPost
        return screening.type;
      })
      .join(', '),
    'Add-Ons Screenings': addedScreeningTypeWithPrices
      .map(addedScreening => addedScreening.screening.type)
      .join(', '),
    'Package Saved': newPackageName ? 'Yes' : 'No',
    'Aliases Enabled': aliasAdded ? 'Yes' : 'No',
    'Order Type': infoSource === 'MYSELF' ? 'Manual' : 'Non-manual',
    'Manual Bulk Upload Type':
      manualOrderType === 'MULTIPLE' ? 'Multiple' : 'Single',
    'Is First Order': has_report_or_invitation ? 'No' : 'Yes',
    'Add-Ons Added': addedScreeningTypeWithPrices.length > 0,
    'Recommended Package Selected': recommendedPackageTag ? 'Yes' : 'No',
    'Recommended Package Type': recommendedPackageTag ?? '',
    'Promo Code': promoCode,
  };

  addCandidatesMailPhoneCountToTracking(
    trackData,
    invites,
    infoSource,
    infoMethod,
  );

  calculatePrices(trackData, pricesSummary, emails.length || 1);
  return trackData;
};

export const calculatePrices = (
  trackData: GenericObject,
  pricesSummary: PricesSummary,
  numberOfCandidates = 1,
): void => {
  const subtotalPerCandidate =
    pricesSummary.packagePrice +
    pricesSummary.addedScreeningTypePrices +
    pricesSummary.aliasPrice;

  const totalOrderPrice = subtotalPerCandidate * numberOfCandidates;

  trackData['Original Package Price'] = pricesSummary.packagePrice;
  trackData['Add-on Price'] = pricesSummary.addedScreeningTypePrices;
  trackData['Alias Price'] = pricesSummary.aliasPrice;
  trackData['Total Order Price'] = totalOrderPrice;
};

export const addCandidatesMailPhoneCountToTracking = (
  trackData: GenericObject,
  invites: Invite[],
  infoSource: InfoSource,
  infoMethod: InfoMethod | undefined,
) => {
  if (
    infoSource === 'MYSELF' ||
    infoMethod === 'UPLOAD' ||
    invites.length === 0
  )
    return;

  const { emailCount, phoneCount } = invites.reduce(
    (acc, invite) => {
      if (invite.email.isValid && invite.email.value !== '') {
        acc.emailCount += 1;
      }
      if (invite.phoneNumber.isValid && invite.phoneNumber.value !== '') {
        acc.phoneCount += 1;
      }
      return acc;
    },
    { emailCount: 0, phoneCount: 0 },
  );

  if (emailCount > 0) {
    trackData['Number Of Candidate Email Entered'] = emailCount;
    trackData['Number Of Candidate Phone Entered'] = phoneCount;
  }
};

export const addEmailEnteredMethodToTracking = (
  trackData: GenericObject,
  infoSource: InfoSource,
  infoMethod: InfoMethod | undefined,
) => {
  if (infoSource === 'MYSELF') return;

  trackData['Email Entered Method'] =
    infoMethod === 'MANUAL' ? 'Enter Manually' : 'Upload CSV';
};
