import React from 'react';
import CustomProvider from 'state/provider';
import { useReportsWithMultiMvr } from 'api/reports';
import { useReportMergedExceptions } from 'hooks';
import Context from './context';
import { getRecordsFilter } from './utils';
import { usePackagesFromApi, useReportEta } from './hooks';

type Props = {
  reportId: string;
  screenings: string[];
};

const Provider: React.FC<Props> = ({ reportId, screenings, children }) => {
  const { report, isLoading, isError } = useReportsWithMultiMvr({ reportId });
  const reportExceptions = useReportMergedExceptions(reportId);
  const { packageObj, packages, packagesLoading, upgradePackages } =
    usePackagesFromApi(report);
  const recordsFilter = getRecordsFilter(report);
  const eta = useReportEta(report);

  return (
    <CustomProvider
      context={Context}
      props={{
        isLoading,
        isError,
        packageObj,
        packages,
        packagesLoading,
        recordsFilter,
        report,
        reportExceptions,
        reportId,
        screenings,
        upgradePackages,
        eta,
      }}
      stateKey='reportPage'
    >
      {children}
    </CustomProvider>
  );
};

export default Provider;
