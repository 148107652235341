import { asEntityVersion } from 'modules/core-types/entity-version';
import { useFetch } from 'modules/assess/ui/mvr/category-sets/instance/version/api';
import { useState } from '../../ruleset/version/store';
import { useConditions } from '../common/rule/editor/conditions-context';
import { ConditionType } from '../common/rule/editor/types';
import { useGetAssessRuleValues } from '../common/rule/editor/conditions/hooks';

export const useInstanceVersion = () => {
  const rsv = useState();
  const ccs = asEntityVersion(
    rsv.category_code_set_version,
    'category_code_set',
  );
  if (ccs) {
    ccs.id = (ccs as any)?.resource_id || ccs?.id;
    const { version } = ccs;
    version.id = (version as any)?.resource_id || version?.id;
  }
  return ccs;
};

export const useInstanceVersionCategories = () => {
  const instance = useInstanceVersion();
  const query = useFetch(instance?.id, instance?.version.id);

  return query.data?.categories;
};

export const useInstanceVersionFactValues = (condition: ConditionType) => {
  const { selectedItemTypes } = useConditions();
  const categories = useInstanceVersionCategories();
  const itemTypes = selectedItemTypes();
  const { data } = useGetAssessRuleValues(condition.fact, itemTypes);

  const factValues =
    condition.fact === 'category'
      ? categories
          ?.filter(category => itemTypes.includes(String(category.type)))
          .map(category => category.name)
      : data?.values;

  return factValues;
};
