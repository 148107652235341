import { M } from '@dashboard-experience/mastodon';
import {
  useGetReportSettings,
  useOptimisticallyUpdateAccountSettings,
} from 'components';
import { useReport } from 'containers/Report';
import { namespace } from 'modules/candidate/locales';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { REPORT_SETTING_EVENT_NAMES, useTrackEvent } from 'utils/analytics';

const ShowAdditionalChargeInformation: React.FC = () => {
  const trackEvent = useTrackEvent();
  const { call: updateAccountCall } = useOptimisticallyUpdateAccountSettings();
  const { t } = useTranslation();
  const { account } = useReport();

  const { show_additional_charge_information: showAdditionalChargeInfo } =
    useGetReportSettings(account);

  const title = t(
    `${namespace}:report.settings.menu.results-page.show-additional-info`,
  );

  const toggleAdditionalChargeInfo = useCallback(() => {
    const newValue = !showAdditionalChargeInfo;
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_REPORT_VIEW_SHOW_ADDITIONAL_CHARGE_INFO_CLICKED,
    );
    account &&
      updateAccountCall({
        changedSettings: {
          report_settings: { show_additional_charge_information: newValue },
        },
        account,
      });
  }, [showAdditionalChargeInfo, trackEvent, account, updateAccountCall]);

  if (showAdditionalChargeInfo === undefined) {
    return null;
  }

  return (
    <M.ActionMenuItem
      title={title}
      kind='toggle'
      initialChecked={showAdditionalChargeInfo}
      onToggle={toggleAdditionalChargeInfo}
      description={t(
        `${namespace}:report.settings.menu.results-page.show-additional-info-description`,
      )}
    />
  );
};

export default ShowAdditionalChargeInformation;
