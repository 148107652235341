import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const ScreeningItemContainer = styled.div`
  display: flex;

  padding: 16px 24px 16px 0px;

  .mastodon-expandable:not(.expanded):hover {
    border-bottom: none !important;
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${colors.uiGrey200};
  }

  #professional-license-verification-id {
    width: 255px !important;

    @media (max-device-width: 400px) {
      width: 125px !important;
    }
  }
`;

export const StyledExpandTitle = styled.div`
  flex: 1 1 0%;
  text-align: left;
`;

export const StyledExpandableTitle = styled(M.Expandable)`
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: regular;
  color: ${colors.brandNavy3};
  width: 50%;

  .expandable-content {
    width: calc(100% + 100px) !important;
    margin-top: 16px;
  }
`;

export const StyledScreeningInfo = styled.p`
  max-width: 472px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.brandSlate6} !important;
`;

export const StyledDescriptionBox = styled.div`
  width: 50%;
  min-height: 100px;
  background: ${colors.brandSlate1};
  border-radius: 8px;
  padding: 16px;
  align-self: stretch;
`;

export const StyledPrice = styled.div`
  text-align: right;
  color: ${colors.brandNavy3};
  flex-basis: 76px;
  margin-right: 40px;

  @media (max-device-width: 530px) {
    flex: 1 100%;
    text-align: left;
    margin: 4px 0px 16px 32px;
  }
`;
