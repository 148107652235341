/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import { BannerProps, AnalyticsStates } from './types';
import { ToggleBanner, ToggleStates } from '../Beta/ToggleBanner';

const PENDO_GUIDE_IDS = {
  overview: 'w_16_9gs6-VWQTQlNQZZR2XDd10',
} as { [key: string]: string | undefined };

export const AnalyticsBanner: FC<BannerProps> = ({
  lookerName,
  state,
  toggle,
}) => {
  const translationKey = `analytics.banner.${lookerName}`;
  const buttonPendoId = `analytics-${lookerName}-${state}`;
  const testId = `${lookerName}-toggle-banner`;

  const feedbackGuideId = PENDO_GUIDE_IDS[lookerName];

  return (
    <ToggleBanner
      iconAlt='version'
      toggle={toggle}
      toggleState={
        state === AnalyticsStates.LEGACY
          ? ToggleStates.OPT_OUT
          : ToggleStates.DEFAULT
      }
      bannerTestId={testId}
      buttonPendoId={buttonPendoId}
      translationKey={translationKey}
      feedbackGuideId={
        state !== AnalyticsStates.LEGACY ? feedbackGuideId : undefined
      }
    />
  );
};
