import React, { useEffect, useState } from 'react';
import PackageScreeningItem from 'components/BetterOrderExperience/SelectPackageStep/PackageScreeningItem';
import type { AddonsT } from 'components/BetterOrderExperience/AddonsStep';
import { StyledFlexContainer } from './CustomizationsInfoSection.styles';
import { ScreeningType } from '../../AddonsStep/AddonsStep.types';
import { ScreeningProductType } from '../../AddonsStep/AddonsStep.enums';

const CustomizationsInfoSection: React.FC<{
  addOns: AddonsT.ScreeningTypeWithPrices[];
  showPrices: boolean;
  additionalProperties: AddonsT.AdditionalProperties;
  viewDetails?: boolean;
}> = ({ addOns, showPrices, additionalProperties, viewDetails = false }) => {
  const [visibleAddons, setVisibleAddons] = useState<
    AddonsT.ScreeningTypeWithPrices[]
  >(addOns.slice(0, 5));

  useEffect(() => {
    setVisibleAddons(viewDetails ? addOns : addOns.slice(0, 5));
  }, [addOns, viewDetails]);

  const checksQuantity = (screeningType: ScreeningType): string | undefined => {
    if (
      screeningType ===
        ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION ||
      screeningType === ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION
    ) {
      return additionalProperties[screeningType]?.required_quantity[1];
    }

    return undefined;
  };

  return (
    <>
      {visibleAddons.map((addOn, idx: number) => {
        const key = `screening-${idx}`;
        const { type } = addOn.screening;
        return (
          <StyledFlexContainer key={key}>
            <PackageScreeningItem
              screening={addOn.screening}
              showScreeningSettings
              quantity={checksQuantity(addOn.screening.type)}
            />
            {showPrices && (
              <div data-testid={`${type}-price`}>{addOn.price}</div>
            )}
          </StyledFlexContainer>
        );
      })}
    </>
  );
};

export default CustomizationsInfoSection;
