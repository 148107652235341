import React from 'react';
import {
  isInternationalMotorVehicleReport,
  getIncludeDrivingHistorySetting,
} from 'components/Packages/PackageScreenings';
import { Screening } from 'types';
import { ScreeningSetting as PostPackageScreeningSetting } from '@dashboard-experience/utils';
import { getPackageScreeningLabel } from '../AddonsStep/AddonsStep.utils';
import {
  StyledScreeningListItem,
  StyledScreeningSettings,
  StyledScreeningType,
} from './styles/PackageCard.styles';
import { getScreeningSetting } from '../utils/PackageScreeningUtils';
import { ScreeningProductType } from '../AddonsStep/AddonsStep.enums';
import type { AddonsT } from '../AddonsStep';
import { STEPS } from '../utils/constants';

type Props = {
  screening: AddonsT.PostPackageScreeningType;
  screeningSettings?: PostPackageScreeningSetting[];
  showScreeningSettings?: boolean;
  compact?: boolean;
  quantity?: string;
};

// TODO: Move this component to the UI folder as it is being used in multiple places
const PackageScreeningItem: React.FC<Props> = ({
  screening = { type: null } as unknown as Screening,
  screeningSettings = [],
  showScreeningSettings = true,
  compact = false,
  quantity,
}) => {
  const isSelectPackageStep = window.location.pathname.includes(
    STEPS.SELECT_YOUR_PACKAGE.path,
  );
  const screeningType = getPackageScreeningLabel(
    screening?.type as ScreeningProductType,
    isSelectPackageStep,
  );

  const screeningSetting = showScreeningSettings
    ? getScreeningSetting(screening as unknown as Screening, screeningSettings)
    : '';

  return (
    <StyledScreeningListItem key={`${screening.type}`} compact={compact}>
      <StyledScreeningType data-testid={`screening-type-${screening.type}`}>
        {quantity && <span>{quantity} x </span>}
        {screeningType}
        {showScreeningSettings && (
          <StyledScreeningSettings>{screeningSetting}</StyledScreeningSettings>
        )}
        {showScreeningSettings &&
          isInternationalMotorVehicleReport(screening.type) && (
            <StyledScreeningSettings>
              {`${getIncludeDrivingHistorySetting(screening)}`}
            </StyledScreeningSettings>
          )}
      </StyledScreeningType>
    </StyledScreeningListItem>
  );
};
export default PackageScreeningItem;
