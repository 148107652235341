import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';

export const getRoles = async (industryId: number) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.get(
    `${CHECKR_API_BASE_URL}/industries/${industryId}/job_roles`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

function useGetRoles(industryId: number) {
  return useQuery(['Roles'], () => getRoles(industryId), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}

export default useGetRoles;
