import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useMutation } from 'react-query';
import useGetUser from './useGetUser';

const updateAccountIndustry = async (accountId: string, industryId: number) => {
  const token = localStorage.getItem('accessToken');

  const body = {
    industry_id: industryId,
  };

  const response = await axios.put(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

function useUpdateAccountIndustry() {
  const { data: user } = useGetUser();

  const [mutate, { data, isLoading, error }] = useMutation(
    async (industryId: number) => {
      if (!user) {
        throw new Error('User information is not available');
      }

      localStorage.setItem('industry_id', industryId.toString());

      return updateAccountIndustry(user.account.id, industryId);
    },
  );

  return {
    mutate,
    data,
    isLoading,
    error,
  };
}

export default useUpdateAccountIndustry;
