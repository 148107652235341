import { PR_US_STATE_KEY } from 'Constants';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { M } from '@dashboard-experience/mastodon';
import { US_STATES } from '../StatesOfUS';
import { Item } from '../../SharedItems';
import {
  useOrderBackgroundCheckContext,
  actionTypes,
  ADD_CHECKS,
} from '../../Context';

const PR = { name: 'Puerto Rico', abbreviation: 'PR', id: 'PR' };

const StateDropdown = styled(M.ComboBox)`
  margin-right: 20px !important;
  width: 280px;
  margin: 10px 0;
`;

const stateItemToString = (item: Item) => item?.name ?? '';

const SelectState: React.FC<any> = ({
  states = US_STATES,
  disabled = false,
}) => {
  const [showInvalidText, setShowInvalidText] = useState(false);
  const withPr = useFlag(PR_US_STATE_KEY)?.variantKey === 'true' || false;

  const { state, dispatch } = useOrderBackgroundCheckContext();
  const fromReviewAndSubmitPage = !!state.basePackage.name;

  const selectableStates = useMemo(
    () => (withPr ? [...states.slice(0, 39), PR, ...states.slice(39)] : states),
    [withPr, states],
  );

  const handleStateChange = useCallback(
    (selectedItem: Item | null) => {
      // Handle null, which indicates the user cleared the selection
      if (!selectedItem) {
        dispatch({
          type: actionTypes.ADD_LOCATION,
          payload: {
            location: { ...state.location, state: '', city: '' },
          },
        });
        return;
      }

      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: {
          location: { ...state.location, state: selectedItem, city: '' },
        },
      });

      // Reset if user comes back to page and changes location
      if (
        state.location?.state?.abbreviation !== selectedItem &&
        fromReviewAndSubmitPage
      ) {
        dispatch({
          type: actionTypes.RESET_ALL_EXCEPT_LOCATION,
          payload: {},
        });
      }
    },
    [dispatch, state.location, fromReviewAndSubmitPage],
  );

  const onDropdownChange = useCallback(
    ({ selectedItem }: { selectedItem: Item | null }) => {
      handleStateChange(selectedItem);
    },
    [handleStateChange],
  );

  useEffect(() => {
    const showInvalid =
      state.workflow === ADD_CHECKS && state.location.state === '' && !disabled;
    setShowInvalidText(showInvalid);
  }, [state.workflow, state.location.state, disabled]);

  return (
    <>
      {state.location.country === 'US' && (
        <StateDropdown
          data-testid='state-dropdown-testid'
          label='State'
          items={selectableStates}
          disabled={disabled}
          itemToString={stateItemToString}
          onChange={onDropdownChange}
          invalidText='State is Required'
          placeholder='State'
          invalid={showInvalidText}
          selectedItem={
            state.location.state !== '' ? state.location.state : null
          }
        />
      )}
    </>
  );
};

export default SelectState;
