import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';

export const getJobRoleAttributes = async () => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.get(
    `${CHECKR_API_BASE_URL}/job_role_attributes`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

function useGetJobRoleAttributes() {
  return useQuery(['attributes'], () => getJobRoleAttributes(), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}

export default useGetJobRoleAttributes;
