/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  useReducer,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useRumAction,
  useRumError,
  hasPermission,
  GenericObject,
} from '@dashboard-experience/utils';
import { Candidate } from 'types';
import * as utils from 'components/AddScreenings/shared/utils';
import {
  FOUR,
  MODAL_STEPS,
  ONE,
  THREE,
  TWO,
} from 'components/AddScreenings/shared/ModalComponents';
import { useCreateCandidateInvitation } from 'api/invitations';
import { useUser } from 'context/CurrentUser';
import create from 'components/Invitations/New/create';
import { useFetchAllNodes } from 'api/nodes/hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Context from 'components/Packages/context';
import { useList } from 'api/geos';
import { useFlag } from '@dashboard-experience/react-flagr';
import { INVITE_PAGE_ADD_SCREENINGS } from 'Flags';
import { useAddPackage } from 'api/packages';
import { getInvitationParams } from 'components/Candidate/NewCandidateInvitation';
import { useGetDashboardCandidateReports } from 'api/candidate/hooks';
import { useOrderNewReport, useReportsWithMultiMvr } from 'api/reports';
import { getOrderParams } from 'components/Candidate/OrderNewReport';
import Breadcrumbs, {
  BREADCRUMB_STEPS,
  STEP_NUMBER,
  STEP_NAME,
  Steps,
  GET_STARTED_PAGE_KEY,
  ADD_CHECKS_PAGE_KEY,
  SELECT_A_PACKAGE_PAGE_KEY,
  REVIEW_AND_SUBMIT_PAGE_KEY,
} from './Breadcrumbs';
import GetStarted from './GetStarted';
import OrderBackgroundCheckContext from '../OrderBackgroundCheck/Context/context';
import {
  reducer,
  initialState,
  actionTypes,
  ADD_CHECKS,
} from '../OrderBackgroundCheck/Context';
import { AccountDetails } from '../OrderBackgroundCheck/GetStarted/GetStarted';
import SelectPackage from './SelectPackage';
import ChecksAddons from './CheckAddons';
import ReviewAndSubmitOrder from './ReviewAndSubmit';
import { getStateByAbbreviation } from '../OrderBackgroundCheck/GetStarted/StatesOfUS';
import {
  City,
  MAJOR_CITIES,
} from '../OrderBackgroundCheck/GetStarted/MajorCities';
import SubmitModal from './SubmitModal';
import {
  ADD_CHECKS_EVENT_NAMES,
  ADD_CHECKS_EVENT_PROPERTIES,
  useTrackEvent,
} from '../Amplitude/analytics';
import { Node } from '../OrderBackgroundCheck/SharedItems';
import { isAddOnsEnabled } from '../shared/utils';
import AutoStartReport, { AutoStartReportProps } from './AutoStartReport';
import { getReportPackageScreenings } from './helper';

type FooterProps = {
  currentStep: number;
};

const Footer = styled(M.ModalFooter)<FooterProps>`
  display: flex;
  justify-content: ${props =>
    props.currentStep !== 1 ? 'space-between' : 'right'};
`;

const Container = styled.div`
  z-index: 1000000 !important;
  .cds--modal-container {
    width: calc(100% - 2rem) !important;
    height: calc(100% - 2rem) !important;
    max-height: none !important;
    max-width: none !important;
    background-color: ${colors.bgPrimaryLight};
  }

  .mastodon-modal-body {
    background-color: ${colors.bgPrimaryLight};
    padding-bottom: 0;
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    width: 80%;
  }

  .mastodon-modal-footer {
    box-shadow: 0px -10px 10px ${colors.uiGrey100};
  }

  @media screen and (max-width: 768px) {
    .cds--modal-container {
      height: 100% !important;
      width: 100% !important;
    }
    .cds--modal-content mastodon-modal-body {
      padding: 1.5rem !important;
    }
  }
`;

const Margin32 = styled.div`
  margin-top: 32px;
`;

const BodyContainer = styled.div`
  min-height: 692px;
  height: auto;
`;

const Ref = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
`;

const SKIP_NAME = 'SKIP_NAME_DO_NOT_SUBMIT_00q5wq58';

export const checkIfShouldRefresh = (
  source?: string | null,
  toRefresh?: boolean,
  hierarchyPresent?: boolean,
  nodes?: Node[],
) => {
  const shouldRefreshForHierarchy =
    !hierarchyPresent || (hierarchyPresent && nodes);
  if (source !== 'addChecks') return false;
  if (!toRefresh) return false;
  return !!shouldRefreshForHierarchy;
};

const AddChecks: React.FC<{
  candidate: Candidate;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}> = ({ candidate, modalOpen, setModalOpen }) => {
  const reportMatch = useRouteMatch(
    '/candidates/:candidateId/reports/:reportId',
  );
  const { report } = useReportsWithMultiMvr({
    reportId: reportMatch?.params.reportId,
  });
  const { reports } = useGetDashboardCandidateReports(candidate.id);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loadingPackages, setLoadingPackages] = useState(true);
  const currentUser = useUser();
  const account = currentUser?.account;
  const { hierarchy_present, segmentation_enabled, geos_required } =
    account as AccountDetails;
  const [hasNoSelectionError, setHasNoSelectionError] = useState(false);
  const addLoadAction = useRumAction('component-load/mount');
  const addUnloadAction = useRumAction('component-unload/unmount');
  const addDataDogError = useRumError();
  const providerState = useMemo(
    () => ({
      state,
      dispatch,
      addLoadAction,
      addUnloadAction,
      addDataDogError,
    }),
    [addDataDogError, addLoadAction, addUnloadAction, state],
  );

  const [currentPage, setCurrentPage] = useState(BREADCRUMB_STEPS.GET_STARTED);
  const [currentStep, setCurrentStep] = useState(GET_STARTED_PAGE_KEY as Steps);
  const [selectedNode, setSelectedNode] = useState({});
  const bodyRef = useRef<HTMLDivElement>(null);
  const [orderCertified, setOrderCertified] = useState(false);
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false);
  const [toRefresh, setToRefresh] = useState(true); // Marker to prevent modal from opening prematurely when ordering multiple addons
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const source = params.get('source');
  const trackEvent = useTrackEvent();
  const [createReportView, setCreateReportView] = useState(false);
  const { call: callOrderNewReport, result: resultOrderNewReport } =
    useOrderNewReport(true);

  const requiresFMCSACompliance = state.basePackage?.requires_fmcsa_compliance;
  const isAddOnsFlagEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';

  const hasManageAddOnsPermission = hasPermission(
    currentUser,
    'manage_order_add_ons',
  );

  const addOnsEnabled = isAddOnsEnabled(
    isAddOnsFlagEnabled,
    requiresFMCSACompliance,
    hasManageAddOnsPermission,
  );

  const trackEvents = useCallback(
    (
      eventName: ADD_CHECKS_EVENT_NAMES,
      eventProperties?: ADD_CHECKS_EVENT_PROPERTIES,
    ) => {
      trackEvent(currentUser, eventName, eventProperties);
    },
    [trackEvent, currentUser],
  );

  const { call, result } = useCreateCandidateInvitation();

  const { call: addPackage, result: addPackageResult } = useAddPackage({
    accountId: currentUser.account.id,
  });

  const buildPostBodyWithAddOns = useCallback(() => {
    const packageName = state.basePackage.name
      ? `${state.basePackage.name} with addons`
      : `Standalone Screenings`;

    const basePackage = state.basePackage.name
      ? state.basePackage
      : {
          name: SKIP_NAME,
          screenings: [],
        };

    return utils.buildPostBodyWithAddOns({
      ...state,
      basePackage,
      packageName,
      setSlug: false,
      isPrivate: true,
    });
  }, [state]);

  const createNewPackage = useCallback(async () => {
    const body = buildPostBodyWithAddOns();
    const response = await addPackage(body);
    return response;
  }, [buildPostBodyWithAddOns, addPackage]);

  const handleSubmit = useCallback(() => {
    const {
      node,
      geo,
      program,
      location,
      basePackage,
      paymentProfile,
      emails,
      addedScreeningTypes,
    } = state;
    const basePackagePrice = state.basePackage.price ?? 0;
    const addonsPrice = state.addOnsPrice ?? 0;

    const selection = {
      node,
      geo,
      program,
      country: location.country,
      city: location.city,
      state: location.state,
      package: basePackage,
      paymentProfile,
    };

    const orderSelection = {
      ...selection,
      geo: Object.keys(geo).length !== 0 ? geo : null,
    };

    const autoData = getOrderParams({
      candidateId: candidate.id,
      selection: orderSelection,
    });

    const params = getInvitationParams({
      candidateId: candidate.id,
      selection,
    });

    const analyticsProperties = {
      'Package Selected': state.basePackage.name ?? 'Not selected',
      'Package Price':
        basePackagePrice - addonsPrice > 0
          ? (basePackagePrice - addonsPrice) / 100
          : 0,
      'Add-on Screenings': state.addedScreeningTypes.join(', '),
      'Add-on Price': addonsPrice / 100,
      'Total Order Price': basePackagePrice / 100,
    };

    if (createReportView && !resultOrderNewReport.isError) {
      if (!autoData) return;
      callOrderNewReport(autoData);
      trackEvents(
        ADD_CHECKS_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_COMPLETED,
        analyticsProperties,
      );
    } else {
      create({
        selection,
        emails: [emails],
        account: currentUser.account,
        addons: addedScreeningTypes,
        call,
        setGeoError: () => {},
        setPermissionError: () => {},
        bulkInviteEnabled: false,
        csv: null,
        currentUser,
        uploadCall: () => {},
        createCandidateInvitation: params,
      });
      trackEvents(
        ADD_CHECKS_EVENT_NAMES.REVIEW_AND_SUBMIT_INVITE_SENT,
        analyticsProperties,
      );
    }
  }, [
    state,
    candidate.id,
    createReportView,
    resultOrderNewReport.isError,
    callOrderNewReport,
    currentUser,
    call,
    trackEvents,
  ]);

  const showNodes = hierarchy_present;
  const showWorkLocation = segmentation_enabled;
  const { nodes, isLoading, count, isFetching } = useFetchAllNodes({ account });

  const accountId = account?.id || '';
  const {
    data,
    isSuccess: listSuccess,
    refetch,
  } = useList({
    accountId,
  });

  const contextValue = useMemo(
    () => ({
      account: undefined,
      geos: data,
      anyLoading: false,
      isSingleNode: count === 1,
      nodes,
      nodeIsLoading: false,
      nodePackages: [],
      onNodeChange: () => {},
      onNodeFilterChange: () => {},
      selectedNode,
      packages: [],
      programs: [],
      payment_profiles: [],
      billing_entity_enabled: false,
    }),
    [count, selectedNode, listSuccess],
  );

  const updateFlow = useCallback((nextStep: Steps) => {
    scrollToTopOfBody();
    setCurrentPage(STEP_NUMBER[nextStep]);
    setCurrentStep(nextStep);
    // updateParentWindowUrl({
    //   contextId,
    //   path: STEP_PATH[nextStep],
    //   reload: false,
    // });
    // history.push(STEP_PATH[nextStep]);
  }, []);

  const resetFlow = useCallback(() => {
    dispatch({
      type: actionTypes.RESET_ALL_EXCEPT_LOCATION,
      payload: {},
    });
    updateFlow(GET_STARTED_PAGE_KEY);
  }, [updateFlow]);

  const handleClose = useCallback(
    e => {
      setModalOpen(false);
      trackEvents(ADD_CHECKS_EVENT_NAMES.CANCEL_OR_EXIT_CLICKED, {
        'Exit Type':
          e.target?.value === 'cancel-button' ? 'Cancel Button' : 'Exit Mark',
      });
      resetFlow();
    },
    [setModalOpen, trackEvents, resetFlow],
  );

  const handleNextClick = useCallback(async () => {
    if (Object.values(state.basePackage).length > 0) {
      dispatch({
        type: actionTypes.SET_PACKAGE_NAME,
        payload: {
          name: 'Empty Add Checks Package',
        },
      });
    }

    switch (currentStep) {
      case 1:
        trackEvents(ADD_CHECKS_EVENT_NAMES.GET_STARTED_COMPLETED);
        break;
      case 2:
        trackEvents(ADD_CHECKS_EVENT_NAMES.SELECT_PACKAGE_COMPLETED);
        break;
      case 3: {
        const noScreeningsSelected = state.addedScreeningTypes.length === 0;
        const hasBasePackageNoScreenings =
          noScreeningsSelected && state.basePackage?.name;

        if (noScreeningsSelected) {
          setHasNoSelectionError(true);
        } else {
          setHasNoSelectionError(false);
        }
        if (state.addedScreeningTypes.length > 0 || hasBasePackageNoScreenings)
          trackEvents(ADD_CHECKS_EVENT_NAMES.ADD_ADD_ONS_COMPLETED);
        break;
      }
      default:
        break;
    }

    if (currentStep === ADD_CHECKS_PAGE_KEY) {
      if (state.addedScreeningTypes.length === 0 && !state.basePackage?.name) {
        setHasNoSelectionError(true);
        return;
      }

      // If package selected & new screenings added
      if (state.addedScreeningTypes.length > 0) {
        const response = await createNewPackage();
        const responseData = utils.mapPackage(response);

        if (response.slug) {
          dispatch({
            type: actionTypes.SET_OLD_BASE_PACKAGE,
            payload: {
              oldBasePackage: state.basePackage.name
                ? state.basePackage
                : { name: SKIP_NAME, screenings: [] },
            },
          });

          dispatch({
            type: actionTypes.SET_BASE_PACKAGE,
            payload: { basePackage: { ...responseData, id: response.id } },
          });

          dispatch({
            type: actionTypes.RESET_PACKAGE_NAME,
            payload: {},
          });
        }
      }

      setHasNoSelectionError(false);
    }
    if (currentStep === SELECT_A_PACKAGE_PAGE_KEY) {
      if (state.basePackage.slug !== state.selectedPackage.slug) {
        dispatch({
          type: actionTypes.RESET_ADDONS,
          payload: {},
        });
      }
      dispatch({
        type: actionTypes.SET_SELECTED_PACKAGE,
        payload: { selectedPackage: state.basePackage },
      });
    }
    if (currentStep === SELECT_A_PACKAGE_PAGE_KEY && !addOnsEnabled) {
      updateFlow(REVIEW_AND_SUBMIT_PAGE_KEY);
    } else {
      updateFlow((currentStep + 1) as Steps);
    }
  }, [
    state.basePackage,
    state.selectedPackage,
    state.addedScreeningTypes.length,
    currentStep,
    updateFlow,
    trackEvents,
    createNewPackage,
    addOnsEnabled,
  ]);

  const handleBackClick = useCallback(
    (stepNumber: number) => {
      trackEvents(ADD_CHECKS_EVENT_NAMES.BACK_BUTTON_CLICKED, {
        'Page Name': STEP_NAME[currentStep],
      });
      if (currentStep === REVIEW_AND_SUBMIT_PAGE_KEY) {
        dispatch({
          type: actionTypes.SET_BASE_PACKAGE,
          payload: { basePackage: state.selectedPackage },
        });
        dispatch({
          type: actionTypes.SET_OLD_BASE_PACKAGE,
          payload: { basePackage: state.packageOnReport },
        });
      }
      if (
        stepNumber === SELECT_A_PACKAGE_PAGE_KEY ||
        (currentStep === REVIEW_AND_SUBMIT_PAGE_KEY && !addOnsEnabled)
      ) {
        updateFlow(SELECT_A_PACKAGE_PAGE_KEY);
      } else {
        updateFlow((currentStep - 1) as Steps);
      }
    },
    [
      currentStep,
      state.packageOnReport,
      state.oldBasePackage,
      trackEvents,
      updateFlow,
      addOnsEnabled,
    ],
  );

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_CURRENT_WORKFLOW,
      payload: { workflow: ADD_CHECKS },
    });
  }, [modalOpen]);

  useEffect(() => {
    dispatch({
      type: actionTypes.ADD_EMAILS,
      payload: { emails: candidate?.email },
    });
  }, [candidate?.email, modalOpen]);

  useEffect(() => {
    const geoLocation = report?.geos ? report?.geos[0] : null;
    const showGeos = !account.segmentation_enabled && data?.geos?.length > 0;
    const workLocation = report?.work_locations
      ? report?.work_locations[0]
      : null;

    if (listSuccess && showGeos && geoLocation) {
      dispatch({
        type: actionTypes.SET_GEO,
        payload: {
          geo: geoLocation,
        },
      });
    }

    const country = workLocation?.country ?? geoLocation?.country ?? 'US';

    const geoState = getStateByAbbreviation(
      workLocation?.state ?? geoLocation?.state ?? '',
    );

    const city =
      MAJOR_CITIES.find(
        (c: City) =>
          c.state ===
            (workLocation?.state || state.location.state?.abbreviation) &&
          c.name === (workLocation?.city || geoLocation?.city),
      ) ?? '';
    const shouldUpdate =
      (country !== 'US' && typeof state.location.state === 'string') ||
      // compare country
      country !== state.location.country ||
      (typeof state.location.state === 'string' &&
        typeof geoState !== 'string');

    if (shouldUpdate) {
      // Assigning to a variable for debugging purpose
      const location = {
        country,
        state: geoState,
        city,
      };

      dispatch({
        type: actionTypes.ADD_LOCATION,
        payload: { location },
      });
    }
  }, [
    report?.geos,
    state.geo,
    state.location,
    listSuccess,
    report?.work_locations,
  ]);

  useEffect(() => {
    const selectedNode =
      report?.cost_center?.length > 0 && report?.cost_center?.split('|').pop();

    let currentNode: any = {};
    // If there is only one node on the account and the report does not have a node, select it automatically
    if (isEmpty(selectedNode) && nodes?.length === 1) {
      currentNode = nodes[0];
    } else if (selectedNode && nodes) {
      currentNode = nodes?.find(
        (node: { name: string }) => node.name === selectedNode,
      );

      // If selected node is not in the list of nodes, select the first node
      if (currentNode === undefined && nodes?.length === 1) {
        currentNode = nodes[0];
      } else if (currentNode === undefined) {
        return;
      }
    }

    if (isEmpty(currentNode)) {
      return;
    }

    if (showNodes && count >= 1) {
      setSelectedNode(currentNode);
      dispatch({
        type: actionTypes.SET_NODE,
        payload: { node: currentNode },
      });
    }
  }, [count, report]);

  const scrollToTopOfBody = () => {
    if (bodyRef.current) {
      bodyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleSubmissionModalClose = useCallback(() => {
    setSubmissionModalOpen(false);
    resetFlow();
  }, [resetFlow]);

  useEffect(() => {
    // Handles closing Add Checks modal & opening submission modal after successful POST /candidate_invitations
    if (result.isSuccess && result.data) {
      setModalOpen(false);
      setSubmissionModalOpen(true);
    }

    if (resultOrderNewReport.isSuccess && resultOrderNewReport.data) {
      setModalOpen(false);
      setSubmissionModalOpen(true);
    }
  }, [
    result.isSuccess,
    result.data,
    setModalOpen,
    setSubmissionModalOpen,
    resultOrderNewReport.isSuccess,
    resultOrderNewReport.data,
  ]);

  useEffect(() => {
    if (state.basePackage?.name !== '' && hasNoSelectionError) {
      setHasNoSelectionError(false);
    }
  }, [state.basePackage]);

  useEffect(() => {
    if (state.addedScreeningTypes.length > 0 && hasNoSelectionError) {
      setHasNoSelectionError(false);
    }
  }, [state.addedScreeningTypes]);

  useEffect(() => {
    if (hasNoSelectionError) {
      scrollToTopOfBody();
    }
  }, [hasNoSelectionError]);

  useEffect(() => {
    const shouldRefresh = checkIfShouldRefresh(
      source,
      toRefresh,
      account.hierarchy_present,
      nodes,
    );
    if (shouldRefresh) {
      setModalOpen(true);
      // Reset to remove params
      history.replace(history.location.pathname);
      // Reset marker
      setToRefresh(true);
    }
  }, [
    source,
    setModalOpen,
    history,
    toRefresh,
    nodes,
    selectedNode,
    account.hierarchy_present,
  ]);

  if (!account || !report) {
    return <div>loading...</div>;
  }

  const autoStartReportProps: AutoStartReportProps = {
    accountId,
    candidateId: candidate.id,
    packageId: state.basePackage.id,
  };

  return (
    <>
      <SubmitModal
        handleClose={handleSubmissionModalClose}
        open={submissionModalOpen}
        setToRefresh={setToRefresh}
        createReportView={createReportView}
        hasReportCreationError={resultOrderNewReport.isError}
      />
      <Container data-testid='add-checks-modal-container'>
        <OrderBackgroundCheckContext.Provider value={providerState}>
          <Context.Provider value={contextValue}>
            <M.ComposedModal
              data-testid='add-checks-modal'
              open={modalOpen}
              preventCloseOnClickOutside
            >
              <M.ModalHeader
                data-testid='add-checks-modal-header'
                closeModal={handleClose}
              >
                <h2>Add checks</h2>
              </M.ModalHeader>
              <M.ModalBody>
                <Ref ref={bodyRef} />
                <BodyContainer>
                  <Breadcrumbs
                    currentStepNumber={currentStep}
                    currentPage={currentPage}
                    addOnsEnabled={addOnsEnabled}
                  />
                  <Margin32 />
                  {currentStep === GET_STARTED_PAGE_KEY && (
                    <GetStarted
                      segmentation_enabled={segmentation_enabled}
                      hierarchy_present={hierarchy_present}
                      geos_required={geos_required}
                      goBackClicked={false}
                      emails={[candidate.email ?? '']}
                    />
                  )}
                  {currentStep === SELECT_A_PACKAGE_PAGE_KEY && (
                    <SelectPackage
                      segmentation_enabled={segmentation_enabled}
                      hierarchy_present={hierarchy_present}
                      reportPackageSlug={report?.package || undefined}
                      reportPackageName={report?.package_name || undefined}
                      setLoadingPackages={setLoadingPackages}
                      reports={reports}
                    />
                  )}
                  {currentStep === ADD_CHECKS_PAGE_KEY && (
                    <ChecksAddons
                      account={account}
                      hasNoSelectionError={hasNoSelectionError}
                      screeningsAlreadyOnReport={getReportPackageScreenings(
                        report,
                      )}
                    />
                  )}
                  {currentStep === REVIEW_AND_SUBMIT_PAGE_KEY && (
                    <ReviewAndSubmitOrder
                      orderCertified={orderCertified}
                      setOrderCertified={setOrderCertified}
                      candidateName={`${candidate.first_name} ${candidate.last_name}`}
                      handleEditClick={handleBackClick}
                      handleLearnMoreClick={() => {}}
                      handleServiceAgreementClick={() => {}}
                      setCreateReportView={setCreateReportView}
                      autoStartReportProps={autoStartReportProps}
                      hasReportCreationError={resultOrderNewReport?.isError}
                    />
                  )}
                </BodyContainer>
              </M.ModalBody>
              {MODAL_STEPS[currentStep] === ONE && (
                <Footer currentStep={currentStep}>
                  <div>
                    <M.Button
                      kind='tertiary'
                      size='sm'
                      data-testid='add-checks-modal-cancel-button'
                      onClick={handleClose}
                      value='cancel-button'
                    >
                      Cancel
                    </M.Button>
                    <M.Button
                      kind='primary'
                      size='sm'
                      data-testid='add-checks-modal-continue-button'
                      disabled={
                        segmentation_enabled &&
                        hierarchy_present &&
                        isEmpty(state.node)
                      }
                      onClick={handleNextClick}
                    >
                      Continue
                    </M.Button>
                  </div>
                </Footer>
              )}
              {MODAL_STEPS[currentStep] === TWO && (
                <Footer currentStep={currentStep}>
                  <M.Button
                    kind='secondary'
                    size='sm'
                    data-testid='add-checks-modal-back-button'
                    onClick={handleBackClick}
                  >
                    Back
                  </M.Button>
                  <div>
                    <M.Button
                      kind='tertiary'
                      size='sm'
                      data-testid='add-checks-modal-cancel-button'
                      onClick={handleClose}
                      value='cancel-button'
                    >
                      Cancel
                    </M.Button>
                    <M.Button
                      kind='primary'
                      size='sm'
                      data-testid='add-checks-modal-skip-button'
                      disabled={loadingPackages}
                      onClick={handleNextClick}
                    >
                      {Object.values(state.basePackage).length > 0
                        ? 'Continue'
                        : 'Skip'}
                    </M.Button>
                  </div>
                </Footer>
              )}
              {MODAL_STEPS[currentStep] === THREE && (
                <Footer currentStep={currentStep}>
                  <M.Button
                    kind='secondary'
                    size='sm'
                    data-testid='add-checks-modal-back-button'
                    onClick={handleBackClick}
                  >
                    Back
                  </M.Button>
                  <div>
                    <M.Button
                      kind='tertiary'
                      size='sm'
                      data-testid='add-checks-modal-cancel-button'
                      onClick={handleClose}
                      value='cancel-button'
                    >
                      Cancel
                    </M.Button>
                    <M.Button
                      kind='primary'
                      size='sm'
                      data-testid='add-checks-modal-next-button'
                      onClick={handleNextClick}
                      disabled={hasNoSelectionError}
                    >
                      Continue
                    </M.Button>
                  </div>
                </Footer>
              )}
              {MODAL_STEPS[currentStep] === FOUR && (
                <Footer currentStep={currentStep}>
                  <M.Button
                    kind='secondary'
                    size='sm'
                    data-testid='add-checks-modal-back-button'
                    onClick={handleBackClick}
                  >
                    Back
                  </M.Button>
                  <div>
                    <M.Button
                      kind='tertiary'
                      size='sm'
                      data-testid='add-checks-modal-cancel-button'
                      onClick={handleClose}
                      value='cancel-button'
                    >
                      Cancel
                    </M.Button>
                    <M.Button
                      kind='primary'
                      size='sm'
                      data-testid='add-checks-modal-close-button'
                      onClick={handleSubmit}
                      disabled={
                        result.isError || result.isLoading || !orderCertified
                      }
                    >
                      {resultOrderNewReport.isError || !createReportView
                        ? 'Send invitation link'
                        : 'Submit order'}
                    </M.Button>
                  </div>
                </Footer>
              )}
            </M.ComposedModal>
          </Context.Provider>
        </OrderBackgroundCheckContext.Provider>
      </Container>
    </>
  );
};

export default AddChecks;
