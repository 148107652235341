import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Icon = styled.img`
  padding: 6px;
  border-radius: 25px;
  background: ${colors.brandSlate2};
  margin: 2px 16px 0 0;
`;

export const Accordion = styled(M.Accordion)`
  #mastodon && {
    .cds--accordion__wrapper {
      padding: 0px;
  }
      
  .cds--accordion__item--active > .cds--accordion__heading {
    border-bottom: 1px solid ${colors.uiGrey200} !important;

    .cds--accordion__arrow {
      transform: rotate(-90deg) scale(1.5) !important;
    }
  }

  .cds--accordion__heading {
    height: 64px;
    &:hover ${Icon} {
      background: ${colors.uiGrey0};
    }
  }
`;

export const AccordionItem = styled(M.AccordionItem)`
  &.cds--accordion__item {
    .cds--accordion__arrow {
      transform: rotate(-270deg) scale(1.5) !important;
      margin: 8px 24px 0px 0px !important;
    }
  }

  &.cds--accordion__item--active {
    .cds--accordion__arrow {
      transform: rotate(-90deg) scale(1.5) !important;
    }
  }

  .cds--accordion__heading .cds--accordion__title {
    z-index: 0 !important;
  }

  border-left: 2px solid ${colors.brandNavy1};
  border-right: 1px solid ${colors.uiGrey200};
  border-bottom: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${colors.uiGrey0};

  .cds--accordion__content {
    padding: 0px 10px 0px 45px !important;
  }

  @media (max-device-width: 530px) {
    .cds--accordion__content {
      padding-left: 8px !important;
    }
  }
`;

export const AccordionContent = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
