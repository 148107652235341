import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const Card = styled.div`
  display: flex;
  background: ${colors.uiGrey0};
  margin: 0 16px 16px 0;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
`;

export const IconBox = styled.div`
  padding: 8px;
  color: ${colors.uiGrey500};
  background: ${colors.uiGrey50};
  height: 40px;
  width: 40px;
  border-radius: 6px 0 0 6px;
  border: 1px solid ${colors.borderPrimaryLight};
  border-right: none;

  ${Card}:hover & {
    color: ${colors.uiNavy600};
  }

  ${Card}.selected & {
    background: ${colors.uiNavy100};
    border-color: ${colors.uiNavy200};
    color: ${colors.uiNavy600};
  }

  ${Card}.selected:hover & {
    color: ${colors.uiNavy700};
  }

  svg {
    width: 24px;
    height: 24px;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const TextBox = styled.div`
  padding: 10px 16px;
  line-height: 18px;
  border-radius: 0 6px 6px 0;
  border: 1px solid ${colors.borderPrimaryLight};
  border-left: none;
  color: ${colors.uiTextSecondaryLight};
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;

  ${Card}:hover & {
    color: ${colors.uiTextPrimaryLight};
  }

  ${Card}.selected & {
    background: ${colors.uiNavy50};
    border-color: ${colors.uiNavy200};
  }
`;
