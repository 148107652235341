import React from 'react';
import { DataTableHeader, DataTableRow } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Assignment } from 'modules/assess/models/rulesets';
import { SelectedAssignments } from 'modules/assess/models/rulesets/assignment';
import AssignedStatus from './AssignedStatus';
import DraftLink from './DraftLink';
import Menu from './RulesetMenu';
import RulesetName from './RulesetName';
import UnassignedStatus from './UnassignedStatus';
import { getInitialSelections } from '../ruleset/apply/hooks';

export type Header = DataTableHeader;

export type Row = DataTableRow & {
  draft: React.ReactNode;
  menu: React.ReactNode;
  ruleset: React.ReactNode;
  assignments: React.ReactNode;
  reports: React.ReactNode;
  updated_at: React.ReactNode;
};

type Item = {
  itemKey: string;
  itemValue: string;
};

const keys = [
  'ruleset',
  'assignments',
  'reports',
  'updated_at',
  'draft',
  'menu',
];

export const useHeaders = () => {
  const { t } = useTranslation('assess');

  const headers = keys.map(key => ({
    key,
    header: t(`assess:rulesets.table.headers.${key}`, ''),
  }));

  return headers;
};

export const useRows = (rulesets: Array<Ruleset.Type>): Array<Row> => {
  const rows = rulesets.map(
    (r): Row => ({
      id: r.id || '',
      draft: getDraft(r),
      menu: <Menu ruleset={r} rulesets={rulesets} />,
      ruleset: <RulesetName ruleset={r} isDefault={getDefault(r)} />,
      assignments: getAssignments(r),
      reports: getReports(r),
      updated_at: getTime(r),
    }),
  );

  return rows;
};

function getAssignments(ruleset: Ruleset.Type): React.ReactNode {
  if (!ruleset.active) {
    return null;
  }
  const assignments = Object.values(ruleset.assignments).length;
  if (!assignments) {
    return <UnassignedStatus />;
  }
  return (
    <AssignedStatus
      count={assignments}
      keyValueList={getAssignmentKeyValueList(ruleset)}
    />
  );
}

export function getDefault(ruleset: Ruleset.Type): boolean {
  const assigned = Object.values(ruleset.assignments).find(
    (a: Assignment.Type) =>
      a?.default === true || a?.id === 'ACCOUNT_DEFAULT_ID',
  );
  return !!assigned;
}

function getDraft(ruleset: Ruleset.Type): React.ReactNode {
  const action = ruleset.draft ? 'view' : 'create';
  return <DraftLink ruleset={ruleset} action={action} />;
}

function getTime(ruleset: Ruleset.Type): React.ReactNode {
  let time = ruleset.updated_at?.toLocaleString();
  const { active } = ruleset;
  if (active && typeof active === 'object' && active.published_at) {
    time = new Date(active.published_at).toLocaleString();
  }
  return time;
}

function getAssignmentKeyValueList(ruleset: Ruleset.Type): Item[] {
  const assignments: Assignment.List =
    Array.isArray(ruleset?.assignments) && ruleset?.assignments
      ? ruleset.assignments
      : [];

  const filteredAssignments: SelectedAssignments = getInitialSelections(
    Array.isArray(assignments) ? assignments : [],
  );

  return Object.keys(filteredAssignments)
    .filter(
      key => filteredAssignments[key as keyof SelectedAssignments].length > 0,
    )
    .map(key => {
      const type = key as keyof SelectedAssignments;
      const items = filteredAssignments[type];

      const itemValues = items.map(item => item?.name).join(', ');

      return {
        itemKey:
          key === 'account'
            ? 'Default:'
            : `${type.charAt(0).toUpperCase() + type.slice(1)}s:`,
        itemValue: key === 'account' ? 'True' : itemValues,
      };
    });
}

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Warning = (): React.ReactElement => {
  return (
    <FlexRow>
      <M.StatusIcon icon='warning' />
      <span style={{ paddingLeft: '0.5em' }}>0</span>
    </FlexRow>
  );
};

function getReports(ruleset: Ruleset.Type): React.ReactNode {
  const { stats } = ruleset;
  const {
    num_eligible_reports = 0,
    num_review_reports = 0,
    num_escalated_reports = 0,
    num_clear_reports = 0,
  } = stats || ({} as Ruleset.Stats);
  const total =
    num_eligible_reports +
    num_review_reports +
    num_escalated_reports +
    num_clear_reports;

  return <div>{total || <Warning />}</div>;
}
