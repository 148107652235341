import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import { FilterGroup, FilterLabel, DateFilterWrapper } from '../styles';
import { TLogsFilters } from '../../types';

interface DateFilterProps {
  fromDate: string;
  toDate: string;
  setFilters: React.Dispatch<React.SetStateAction<TLogsFilters>>;
}

const DateFilter: React.FC<DateFilterProps> = ({
  fromDate,
  toDate,
  setFilters,
}) => {
  const handleFromDateChange = useCallback(
    (dates: Date[]) => {
      const formattedDate =
        dates?.length > 0 ? moment(dates[0]).format('MM/DD/YYYY') : '';
      setFilters((prev: TLogsFilters) => ({
        ...prev,
        created_after: formattedDate,
      }));
    },
    [setFilters],
  );

  const handleToDateChange = useCallback(
    (dates: Date[]) => {
      const formattedDate =
        dates?.length > 0 ? moment(dates[0]).format('MM/DD/YYYY') : '';
      setFilters((prev: TLogsFilters) => ({
        ...prev,
        created_before: formattedDate,
      }));
    },
    [setFilters],
  );

  return (
    <FilterGroup>
      <DateFilterWrapper>
        <div>
          <FilterLabel>From</FilterLabel>
          <M.DatePicker
            id='logs-from-date'
            dateFormat='m/d/Y'
            onChange={handleFromDateChange}
            datePickerType='single'
            value={fromDate}
          >
            <M.DatePickerInput
              id='logs-from-date-input'
              placeholder='MM/DD/YYYY'
              autoComplete='off'
            />
          </M.DatePicker>
        </div>
        <div>
          <FilterLabel>To</FilterLabel>
          <M.DatePicker
            id='logs-to-date'
            dateFormat='m/d/Y'
            onChange={handleToDateChange}
            datePickerType='single'
            value={toDate}
          >
            <M.DatePickerInput
              id='logs-to-date-input'
              placeholder='MM/DD/YYYY'
              autoComplete='off'
            />
          </M.DatePicker>
        </div>
      </DateFilterWrapper>
    </FilterGroup>
  );
};

export default DateFilter;
