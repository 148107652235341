import { colors } from '@dashboard-experience/mastodon';
import { dateWithTimezone } from '@dashboard-experience/utils';
import { InternationalCriminalSearchV2, TaskResult } from 'types';
import { Substatus } from './types';

export enum IdvMessageType {
  FAILED = 'failed',
  EXPIRED = 'expired',
  OPTED_OUT = 'opted_out',
  NONE = 'none',
}

export const buildSubstatus = (
  substatus: string,
  completed_at: string,
  task_result?: null | TaskResult,
): Substatus => {
  const idv_method = task_result?.method_of_identification;
  const is_digital =
    idv_method === 'DIGITAL' || idv_method === null || idv_method === undefined;

  const idv = is_digital
    ? 'Digital Identity Verification'
    : 'Identity Verification';

  const ts = completed_at ? `on ${dateWithTimezone(completed_at)}` : '';
  let statusInfo;

  switch (substatus) {
    case 'in_progress':
      statusInfo = {
        icon: 'Renew',
        color: colors.uiGrey800,
        title: `${idv} in progress`,
      };
      break;
    case 'successful':
      statusInfo = {
        icon: 'CheckmarkFilled',
        color: colors.uiGreen400,
        title: `${idv} completed ${ts}`,
      };
      break;
    case 'canceled':
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `User opted out of ${idv} ${ts}`,
      };
      break;
    case 'expired':
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `${idv} expired ${ts}`,
      };
      break;
    case 'failed':
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `${idv} not successful ${ts}`,
      };
      break;
    default:
      statusInfo = {
        icon: 'WarningFilled',
        color: colors.uiPink600,
        title: `${idv} has status: ${substatus} ${ts}`,
      };
  }

  return statusInfo;
};

export const getSearchHasCompletedSuccessfully = (
  search?: InternationalCriminalSearchV2,
): boolean => {
  return Boolean(
    search?.status === 'complete' ||
      (search?.status !== 'pending' &&
        search?.status !== 'suspended' &&
        search?.status !== 'canceled'),
  );
};

export const getIdvStatus = (
  search?: InternationalCriminalSearchV2,
  task_status?: string,
  taskResultStatus?: string,
): string => {
  const searchHasCompletedSuccessfully =
    getSearchHasCompletedSuccessfully(search);

  if (
    (search?.status === 'canceled' || search?.status === 'suspended') &&
    (taskResultStatus === 'expired' ||
      taskResultStatus === 'failed' ||
      task_status === 'canceled')
  )
    return 'canceled';
  if (task_status === 'in_progress') return 'pending';
  if (
    (task_status === 'completed' && searchHasCompletedSuccessfully) ||
    taskResultStatus === 'successful'
  )
    return 'clear';
  return '';
};

export const getIdvUnsuccessfulMessageType = (
  search?: InternationalCriminalSearchV2,
  task_status?: string,
  taskResultStatus?: string,
  task_result?: TaskResult | null,
): IdvMessageType => {
  const searchHasCompletedSuccessfully =
    getSearchHasCompletedSuccessfully(search);

  if (!searchHasCompletedSuccessfully) {
    if (
      taskResultStatus === 'failed' &&
      task_result &&
      !!task_result.vendor_failure_reason
    ) {
      return IdvMessageType.FAILED;
    }

    if (taskResultStatus === 'expired') {
      return IdvMessageType.EXPIRED;
    }

    if (task_status === 'canceled') {
      return IdvMessageType.OPTED_OUT;
    }
  }

  return IdvMessageType.NONE;
};
