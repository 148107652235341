import StyledComponent from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = StyledComponent.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 98rem;
  margin: 0 auto;
`;
