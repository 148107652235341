import React from 'react';
import { FieldKeys, SectionProps } from '../types';
import { ExpandablePanel, KeyValueList } from '../styles';

export const fieldKeys: FieldKeys[] = ['nodes'];

const Nodes: React.FC<SectionProps> = ({ fieldKeysToItems }) => {
  const items = fieldKeysToItems(fieldKeys);

  return items.length > 0 ? (
    <ExpandablePanel
      initialExpanded
      data-pendo-id='nodes-expandable-panel'
      title='Nodes'
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList items={items} data-testid='overview-nodes' />
    </ExpandablePanel>
  ) : null;
};

export default Nodes;
