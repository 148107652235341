import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  useTrackEvent,
  scrollToTop,
} from 'utils';
import {
  useAdjudicable,
  useReportPropertiesForAmplitudeEvents,
} from 'api/reports';
import { namespace } from '../../../locales';
import Modal from '../../adverse-action/initiate/flow/modal';

const DisqualifyButton: React.FC = () => {
  const { isDisqualifiable, isLoading, isError } = useAdjudicable();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Disqualify', Report: reportProperties },
    );
    scrollToTop();
  }, [reportProperties, trackEvent]);

  if (isLoading || isError || !isDisqualifiable) {
    return null;
  }

  const text = t(`${namespace}:report.actions.disqualify.action`);

  return (
    <>
      <M.Button
        data-testid='disqualify-button'
        kind='secondary-destructive'
        onClick={handleOpen}
      >
        {text}
      </M.Button>
      {open && (
        <Modal
          onClose={handleClose}
          open={open}
          initialScreen='DisqualifyConfirmation'
        />
      )}
    </>
  );
};

export default DisqualifyButton;
