import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '@dashboard-experience/mastodon';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import DefaultDescription from './DefaultDescription';
import List from './List';
import RulesetLabel from '../../rulesets/RulesetLabel';
import { getInitialSelections } from '../apply/hooks';

type Props = {
  assignments?: Assignment.List | Assignment.Map;
  isdefaultAssignment?: boolean;
  withDetails?: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  margin-top: 2em;

  > * {
    margin: 0 2em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  > * {
    margin: 1em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  .p3-secondary {
    color: ${colors.uiTextSecondaryLight};
  }
`;

const Spacer = styled.span`
  margin: 0 0.25em;
`;

export const Container: React.FC<Props> = ({
  assignments,
  isdefaultAssignment,
  withDetails,
}) => {
  const { t } = useTranslation('assess');
  const header = t('assess:ruleset_assignments.header.applies_to');

  const filteredAssignments = getInitialSelections(
    Array.isArray(assignments) ? assignments : [],
  );
  const enableAccountDefault = isdefaultAssignment || false;

  const geos = filteredAssignments.geo;
  const packages = filteredAssignments.package;
  const roles = filteredAssignments.role;
  const segments = filteredAssignments.segment;
  const states = filteredAssignments.state;

  const totalAssignments =
    geos.length +
    packages.length +
    roles.length +
    segments.length +
    states.length;
  return (
    <Wrapper>
      {withDetails && (
        <h5 style={{ margin: 0 }}>
          {header}
          <Spacer />
          <RulesetLabel isDefault={isdefaultAssignment} />
        </h5>
      )}
      <DefaultDescription enabled={enableAccountDefault} />
      <StyledContainer>
        {geos.length > 0 && (
          <List assignments={geos} noun='ruleset_assignments.geo.noun_plural' />
        )}
        {packages.length > 0 && (
          <List
            assignments={packages}
            noun='ruleset_assignments.package.noun_plural'
          />
        )}
        {roles.length > 0 && (
          <List
            assignments={roles}
            noun='ruleset_assignments.role.noun_plural'
          />
        )}
        {segments.length > 0 && (
          <List
            assignments={segments}
            noun='ruleset_assignments.segment.noun_plural'
          />
        )}
        {states.length > 0 && (
          <List
            assignments={states}
            noun='ruleset_assignments.state.noun_plural'
          />
        )}
        {withDetails && !totalAssignments && (
          <div className='p3 p3-secondary'>No current assignments</div>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default Container;
