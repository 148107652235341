import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject, emailIsValid } from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AdverseAction } from 'types';
import { useCandidate } from 'providers/Candidate';
import { useUpdateCandidate } from 'api/candidate';
import ResolveUndeliverableAdverseModal from './ResolveUndeliverableAdverseModal';

const EntrySection = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  #email-section {
    display: flex;
    align-items: flex-end;

    .email-input {
      width: 50%;

      label {
        font-size: 14px;
      }
    }

    .mastodon-button.primary {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`;

const ConfirmFooter: React.FC<GenericObject> = ({ submitEmail, newEmail }) => {
  const { t } = useTranslation();

  return (
    <M.BinaryButtons
      btnRight={{
        kind: 'primary',
        name: t('buttons.submit'),
        onClick: submitEmail,
        disabled: !newEmail,
      }}
    />
  );
};

const EntryPage: React.FC<GenericObject> = ({
  firstName,
  changeEmail,
  isValid,
  submitted,
  newEmail,
}) => {
  const { t } = useTranslation();

  return (
    <EntrySection>
      <p>
        {t('resolve_undeliverable_aa.email.bad_email', {
          firstName,
        })}
      </p>
      <div id='email-section'>
        <M.TextInput
          id='new-email-input'
          className='email-input'
          type='email'
          placeholder={t('resolve_undeliverable_aa.email.placeholder')}
          labelText={t('resolve_undeliverable_aa.email.enter_email')}
          onChange={changeEmail}
          invalid={submitted && !isValid}
          invalidText={t('resolve_undeliverable_aa.email.invalid_entry')}
          defaultValue={newEmail}
        />
      </div>
      <p>{t('resolve_undeliverable_aa.email.resend')}</p>
    </EntrySection>
  );
};

type ModalProps = {
  showModal: boolean;
  onClose: () => void;
  adverseAction: AdverseAction;
};
const ResolveUndeliverableWithEmail: React.FC<ModalProps> = ({
  showModal,
  onClose,
  adverseAction,
}) => {
  const { t } = useTranslation();
  const candidate = useCandidate();
  const { id, email } = candidate;

  const [newEmail, setNewEmail] = useState<string>(email || '');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const changeEmail = useCallback((event: any) => {
    setNewEmail(event.target.value);
  }, []);
  const newEmailIsValid = emailIsValid(newEmail);

  const { updateCandidateCall, updateCandidateResult } = useUpdateCandidate('email', 'Email'); // prettier-ignore

  const submitEmail = useCallback(() => {
    setSubmitted(true);
    if (newEmailIsValid) {
      updateCandidateCall({ id, email: newEmail });
    }
  }, [id, newEmail, newEmailIsValid, updateCandidateCall]);

  useEffect(() => {
    if (updateCandidateResult.isSuccess || updateCandidateResult.isError) {
      // Only close the modal for successes, since people with errors may want to review what they entered
      if (updateCandidateResult.isSuccess) {
        onClose();
      }

      updateCandidateResult.reset();
    }
  }, [onClose, updateCandidateResult]);

  return (
    <ResolveUndeliverableAdverseModal
      showModal={showModal}
      onClose={onClose}
      reason={adverseAction.aa_delivery_state_reason}
      footer={<ConfirmFooter newEmail={newEmail} submitEmail={submitEmail} />}
      header={t('resolve_undeliverable_aa.heading_with_first_name', {
        firstName: candidate.first_name,
      })}
    >
      <EntryPage
        firstName={candidate.first_name}
        isValid={newEmailIsValid}
        changeEmail={changeEmail}
        submitted={submitted}
        newEmail={newEmail}
      />
    </ResolveUndeliverableAdverseModal>
  );
};

export default ResolveUndeliverableWithEmail;
