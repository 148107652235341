import React, { useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Assignment, Ruleset } from 'modules/assess/models/rulesets';
import {
  AssignmentV2,
  SelectedAssignments,
} from 'modules/assess/models/rulesets/assignment';
import Edit from './Edit';
import AppliesTo from './AppliesTo';
import { getInitialSelections } from './hooks';

const Container = styled.div`
  height: 700px;
  > * {
    margin: 2em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  assignments?: Assignment.List;
  mode?: 'edit' | 'publish';
  rulesets?: Array<Ruleset.Type>;
  setAssignmentPayload?: React.Dispatch<React.SetStateAction<AssignmentV2[]>>;
  setIsTouched?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Content: React.FC<Props> = ({
  assignments = [],
  mode,
  rulesets,
  setAssignmentPayload,
  setIsTouched,
}) => {
  const [currentItems, setCurrentItems] = useState<SelectedAssignments>({
    account: [],
    geo: [],
    segment: [],
    package: [],
    role: [],
    state: [],
  });

  const hasAssignments = assignments.length > 0;
  const isNotAccountDefault =
    assignments.length > 1 || assignments[0]?.id !== 'ACCOUNT_DEFAULT_ID';

  const showAssignToSection = hasAssignments && isNotAccountDefault;

  useEffect(() => {
    const currentItems = getInitialSelections(assignments);

    setCurrentItems(currentItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {showAssignToSection && (
        <>
          <AppliesTo currentItems={currentItems} />
          <M.Divider />
        </>
      )}
      <Edit
        currentItems={currentItems}
        mode={mode}
        rulesets={rulesets}
        setAssignmentPayload={setAssignmentPayload}
        setCurrentItems={setCurrentItems}
        setIsTouched={setIsTouched}
      />
    </Container>
  );
};

export default Content;
