import React, { useCallback, useEffect, useState } from 'react';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useUpdateAccountSettings } from 'components/AccountSettings';
import {
  ALIASES,
  useTrackEvent,
} from 'components/Packages/Amplitude/analytics';
import { StyledContainer } from './AliasSettingSelection.styles';
import CanUpdateAccountContent from './CanUpdateAccountContent';
import CannotUpdateAccountContent from './CannotUpdateAccountContent';

const AliasSettingSelection: React.FC<{
  aliasEnabled: boolean;
}> = ({ aliasEnabled }) => {
  const currentUser = useUser();
  const { account } = currentUser;
  const [aliasAutoEnable, setAliasAutoEnable] = useState(aliasEnabled);
  const {
    call: updateAccountSettings,
    result: { isLoading },
  } = useUpdateAccountSettings();
  const trackEvent = useTrackEvent();
  const [trackingEventsSent, setTrackingEventsSent] = useState(false);

  const onSave = useCallback(() => {
    const changedSettings = {
      alias_auto_enable: aliasAutoEnable,
    };

    updateAccountSettings({ changedSettings, account, isModal: true });
    trackEvent(
      currentUser,
      ALIASES.CHECKR_DASHBOARD_DEFAULT_ALIAS_ACCOUNT_SETTING_UPDATED,
      {
        Source: 'Modal',
        Selection: aliasAutoEnable ? 'On' : 'Off',
      },
    );
  }, [
    account,
    aliasAutoEnable,
    currentUser,
    trackEvent,
    updateAccountSettings,
  ]);

  const handleUpdateSettingClick = useCallback(event => {
    setAliasAutoEnable(event);
  }, []);

  const canUpdateSettings = hasPermission(
    currentUser,
    'manage_account_settings',
  );

  useEffect(() => {
    if (!trackingEventsSent) {
      trackEvent(currentUser, ALIASES.CHECKR_DASHBOARD_ALIAS_MODAL_VIEWED, {
        'User Type': canUpdateSettings ? 'admin' : 'non-admin',
      });
      setTrackingEventsSent(true);
    }
  }, [canUpdateSettings, currentUser, trackEvent, trackingEventsSent]);

  return (
    <StyledContainer>
      {canUpdateSettings && (
        <CanUpdateAccountContent
          handleUpdateSettingClick={handleUpdateSettingClick}
          onSave={onSave}
          aliasAutoEnableSetting={aliasAutoEnable}
          aliasEnabled={aliasEnabled}
          isLoading={isLoading}
        />
      )}
      {!canUpdateSettings && !isLoading && (
        <CannotUpdateAccountContent
          aliasAutoEnableSetting={aliasAutoEnable}
          aliasEnabled={aliasEnabled}
        />
      )}
    </StyledContainer>
  );
};

export default AliasSettingSelection;
