import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { usePreference } from 'api/dashboardPreferences';
import { View } from 'modules/reports-list/ui/state/types';
import './styles.scss';

type Props = {
  view: View;
  isLoading: boolean;
};

const DropdownTrigger = () => {
  return (
    <div className='reports-list-actions-button-container reports-list-actions-save-button'>
      <M.Button kind='secondary' className='reports-list-actions-button '>
        <span>Save view</span>
        <M.Icon icon='ChevronDown' size='16' />
      </M.Button>
    </div>
  );
};

const SaveButton: React.FC<Props> = ({ view, isLoading }) => {
  const [, setSavedViews] = usePreference('searchViews');

  const saveView = useCallback(() => {
    // const id = callSaveView();

    // Remove once we use api
    const viewWithoutPage = {
      ...view,
      filters: { ...view.filters, page: undefined },
    };
    setSavedViews(viewWithoutPage);
    //
  }, [view, setSavedViews]);

  return (
    <M.ActionMenu trigger={DropdownTrigger()} position='bottom' size='md'>
      <div className='reports-list-actions-menu'>
        <div>Save</div>
        <M.Button
          kind='secondary'
          className='reports-list-actions-button reports-list-actions-save-button'
          onClick={saveView}
        >
          <span>Save view</span>
        </M.Button>
      </div>
    </M.ActionMenu>
  );
};

export default SaveButton;
