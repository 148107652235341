import React from 'react';

const BankIcon = () => (
  <svg
    width='40'
    height='24'
    viewBox='0 0 40 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='39' height='23' rx='1.5' fill='#F6F6F7' />
    <g clipPath='url(#clip0_732_64829)'>
      <path
        d='M16.334 10.667H15.0007V15.3337H16.334V10.667ZM20.334 10.667H19.0007V15.3337H20.334V10.667ZM26.0007 16.667H13.334V18.0003H26.0007V16.667ZM24.334 10.667H23.0007V15.3337H24.334V10.667ZM19.6673 6.17366L23.1407 8.00033H16.194L19.6673 6.17366ZM19.6673 4.66699L13.334 8.00033V9.33366H26.0007V8.00033L19.6673 4.66699Z'
        fill='#334559'
      />
    </g>
    <rect x='0.5' y='0.5' width='39' height='23' rx='1.5' stroke='#CBD2DB' />
    <defs>
      <clipPath id='clip0_732_64829'>
        <rect width='16' height='16' fill='white' transform='translate(12 4)' />
      </clipPath>
    </defs>
  </svg>
);

interface BankPaymentMethodProps {
  bankName: string;
  last4: string;
}

const BankPaymentMethod: React.FC<BankPaymentMethodProps> = ({
  bankName,
  last4,
}) => {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '15px',
      }}
    >
      <BankIcon />
      <span>{bankName}</span>
      <span>•••••{last4}</span>
    </span>
  );
};

export default BankPaymentMethod;
