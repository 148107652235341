import React, { useRef } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useDebouncedCallback,
  useEffectAfterInitialized,
} from '@dashboard-experience/utils';
import { useSearchState, useDispatch } from '../../state/hooks';
import { ActionType } from '../../state/types';

const StyledTextInput = styled(M.TextInput)`
  .cds--list-box__field,
  .cds--text-input__field-wrapper {
    input::placeholder {
      color: ${colors.uiGrey700} !important;

      font-style: normal !important;
    }
  }

  .cds--date-picker-container {
    .cds--label {
      color: ${colors.uiGrey600} !important;
    }
  }
`;

const SearchBar: React.FC = () => {
  const dispatch = useDispatch();
  const state = useSearchState();
  const { q } = state.view?.filters || { q: '' };
  const ref = useRef(null);

  // TODO: track event

  useEffectAfterInitialized(() => {
    (ref as any).current.value = q || '';
  }, [q]);

  const handleChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (value.length === 1 || value.length === 2) return;

      dispatch({
        type: ActionType.UPDATE_FILTERS,
        payload: { q: event.target.value },
      });
    },
    300,
    [dispatch],
  );

  return (
    <StyledTextInput
      defaultValue={q || ''}
      onChange={handleChange}
      ref={ref}
      placeholder='Keywords (name, email, phone, or ID)'
      data-dd-privacy='mask'
    />
  );
};

export default SearchBar;
