import { useQuery } from 'react-query';
import { AccountsClient as client } from 'api';
import { AddonPricesQueryData } from '../utils/PricingUtils';

const useGetPackageWithAddonsPrice = (
  cacheKey: any,
  account: any,
  basePackage: any,
  onSuccess: (data: AddonPricesQueryData) => void = () => {},
  enabled: boolean = true,
) => {
  return useQuery(
    cacheKey,
    async () => {
      if (basePackage.screenings.length === 0) {
        return {
          // TODO: revisit
          bundled_price: 0,
          fixed_price: 0,
          passthrough_fee_range: [0, 0],
          service_fee_range: [0, 0],
          items: [],
        };
      }
      const path = `${account.id}/packages/calculate_prices`;
      const resp = await client.put(path, basePackage);
      return resp;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data: AddonPricesQueryData) => {
        onSuccess(data);
      },
      enabled,
    },
  );
};

export default useGetPackageWithAddonsPrice;
