import { camelCase } from 'lodash';
import { Document } from 'types';
import {
  GenericObject,
  Report,
  getDocument,
  getInternationalDocument,
} from '@dashboard-experience/utils';

const PDFS = [
  'pdf_report_encrypted',
  'pdf_report',
  'pdf_health_report',
  'pdf_credit_report',
  'pdf_wisconsin_doj',
  'pdf_state_criminal_search',
  'pdf_self_disclosure',
  'pa_child_abuse_result_certificate',
  'dot_employment_authorization',
  'pdf_international_report',
  'pdf_professional_license_verification_report',
  'pdf_drug_alcohol_clearinghouse_report',
  'order_summary_pdf',
  'cdlis_report',
];
const useGetDocuments = (documents: string[], report: Report) => {
  const docs = {} as GenericObject;

  documents.forEach((document: string) => {
    if (
      report?.documents &&
      report.documents.length > 1 &&
      document === 'pdf_international_report'
    ) {
      docs[camelCase(document)] = getInternationalDocument(report, document);
    } else {
      docs[camelCase(document)] = getDocument(report, document);
    }
  });

  return docs;
};

type GroupedDocument = {
  historical: Document[];
  current: Document[];
};

type GroupedDocuments = {
  [key: string]: GroupedDocument;
};

/**
 Returns a Object that holds Document Types
 {
 pdfReport: {
 historical: [{document_snapshot}, {document_snapshot}]
 latest: [{document_snapshot}]
 },
 healthReport: {
 historical: [{document_snapshot}, {document_snapshot}]
 latest: [{document_snapshot}]
 },
 }
 */
export const groupDocumentsByType = (report: Report): any => {
  // @ts-ignore
  return (report.documents || ([] as Document[])).reduce(
    (map: GenericObject, d: Document) => {
      if (!PDFS.includes(d.type)) {
        return map;
      }

      const camelDocType = camelCase(d.type);

      if (!map[camelDocType])
        map[camelDocType] = {
          historical: [],
          current: [],
        };
      if (d.historical) {
        map[camelDocType].historical.push(d);
      } else {
        map[camelDocType].current.push(d);
      }

      return map;
    },
    {} as GroupedDocuments,
  );
};

export default useGetDocuments;
