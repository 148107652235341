import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import {
  getDashboardUrl,
  updateParentWindowUrl,
  useTrackEvent,
  BETTER_ORDER_EVENT_NAMES,
} from 'utils';
import UIContext from 'context/UI';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { useManualBulkOrder, useUploadCSV } from 'api/packages';
import { useCreateCandidate } from 'api/invitations';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import { isAddOnsEnabled } from 'components/Packages/shared/utils';
import {
  accountHasPermission,
  GenericObject,
  hasPermission,
  PostPackageType,
  PostScreening,
} from '@dashboard-experience/utils';
import { toastError } from 'actions';
import { useDispatch } from 'react-redux';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import { INVITE_PAGE_ADD_SCREENINGS, ALIAS_AS_AN_ADDON } from 'Flags';
import { includesAlias } from 'components/BetterOrderExperience/utils/PackageScreeningUtils';
import { StyledFooter, StyledStepContainer } from '../ui';
import { getGeoWorkLocation, getWorkLocation } from './ReviewAndSubmitUtils';
import { namespace } from '../locales';
import { InfoSection } from './InfoSection';
import { SelectedPackageInfoSection } from './SelectedPackageInfoSection';
import { CandidateInfoSection } from './CandidateInfoSection';
import { StyledInfoText } from './CandidateInfoSection/CandidateInfoSection.styles';
import { PricingSection } from './PricingSection';
import {
  StyledLink,
  StyledOrderCertifiedCheckbox,
  StyledOrderCertifiedText,
  StyledSubtitle,
} from './ReviewAndSubmitStep.styles';
import { CustomizationsInfoSection } from './CustomizationsInfoSection';
import createInvitation, {
  CreateInvitationParams,
} from '../utils/createInvitation';
import { SubmitModal } from './SubmitModal';
import ReviewAndSubmitSubtitle from './ReviewAndSubmitSubtitle';
import { createSubmitTrackData } from '../utils/AmplitudeUtils';
import { STEPS } from '../utils/constants';
import {
  PromoCodeResType,
  useRedeemPromotionCode,
  useValidatePromotionCode,
} from '../../../api/promotionCodes';

const ReviewAndSubmitStep: React.FC<StepProps> = () => {
  const isAddOnsFlagEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';
  const trackEvent = useTrackEvent();
  const { t } = useTranslation(namespace, { keyPrefix: 'reviewAndSubmit' });
  const toastDispatch = useDispatch();

  const [orderCertified, setOrderCertified] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const currentUser = useUser();
  const dispatch = useDispatch();

  const {
    geo,
    location,
    manualOrderType,
    infoMethod,
    infoSource,
    localeType,
    selectedPackage,
    newlyCreatedPackage,
    node,
    program,
    paymentProfile,
    emails,
    invites,
    manualBulkUploadData,
    csv,
    addedScreeningTypeWithPrices,
    newPackageName,
    aliasAdded,
    pricesSummary,
    recommendedPackageTag,
    reset,
    additionalProperties,
  } = useBetterOrderExperienceContext();

  const [initialAliasAddedState] = useState(aliasAdded);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeValid, setPromoCodeValid] = useState<boolean | undefined>(
    undefined,
  );

  const workLocationValue = !currentUser?.account?.segmentation_enabled
    ? getGeoWorkLocation(geo)
    : getWorkLocation(location);

  const { call: createCandidateCall, result: createCandidateResult } =
    useCreateCandidate();
  const { uploadCall, uploadResult } = useUploadCSV();
  const { call: manualBulkCall, result: manualBulkResult } =
    useManualBulkOrder();

  const hasCandidateInfo =
    manualOrderType === 'MULTIPLE' || infoSource === 'CANDIDATE';

  const showPrices =
    accountHasPermission(currentUser, 'show_package_price') &&
    currentUser?.account?.package_price_state !== 'disabled via partner' &&
    localeType !== 'INTERNATIONAL';

  const { contextId } = useContext(UIContext);

  const closeModal = useCallback(() => {
    setShowSuccessModal(false);
  }, []);

  const goToAddOns = useCallback(() => {
    trackEvent(BETTER_ORDER_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_EDITED, {
      'Edit Section': 'Select add-ons',
    });
    const path = '/order-experience/add-ons';

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path, { from: STEPS.REVIEW_AND_SUBMIT.path });
    }
  }, [contextId, history, trackEvent]);

  const goToGetStarted = useCallback(() => {
    trackEvent(BETTER_ORDER_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_EDITED, {
      'Edit Section': 'Work Location',
    });
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history, trackEvent]);

  const handleSelectedPackageEdit = useCallback(() => {
    trackEvent(BETTER_ORDER_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_EDITED, {
      'Edit Section': 'Select Your Package',
    });
    const path = '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history, trackEvent]);

  const handleOrderCertifiedClick = useCallback(() => {
    setOrderCertified(!orderCertified);
  }, [orderCertified]);

  const handleBack = useCallback(() => {
    const requiresFMCSACompliance =
      selectedPackage?.requires_fmcsa_compliance || false;

    const hasManageAddOnsPermission = hasPermission(
      currentUser,
      'manage_order_add_ons',
    );

    const addOnsEnabled = isAddOnsEnabled(
      isAddOnsFlagEnabled,
      requiresFMCSACompliance,
      hasManageAddOnsPermission,
    );

    let path = '';

    if (manualOrderType === 'MULTIPLE') {
      path = '/order-experience/bulk-upload';
    } else {
      path = addOnsEnabled
        ? '/order-experience/add-ons'
        : '/order-experience/select-your-package';
    }
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path, { from: STEPS.REVIEW_AND_SUBMIT.path });
    }
  }, [
    contextId,
    currentUser,
    history,
    isAddOnsFlagEnabled,
    manualOrderType,
    selectedPackage?.requires_fmcsa_compliance,
  ]);

  const packageForSelection = newlyCreatedPackage?.slug
    ? newlyCreatedPackage
    : selectedPackage;
  const packageForSelectionWithAlias = {
    ...packageForSelection,
    aliases_enabled: aliasAdded,
  } as PostPackageType;

  const promoCodeValidation = useValidatePromotionCode(
    {
      account_id: currentUser?.account?.id,
      package_attributes: packageForSelectionWithAlias,
      promo_code: promoCode,
    },
    (data: { promotional_campaign: PromoCodeResType }) => {
      if (
        data?.promotional_campaign?.errors &&
        data.promotional_campaign.errors.length > 0
      ) {
        trackEvent(BETTER_ORDER_EVENT_NAMES.PROMO_CODE_INVALID, {
          'Error Triggered': data.promotional_campaign.errors[0],
          'Promo Code Attempted': data.promotional_campaign.promo_code,
        });
        setPromoCodeValid(false);
      } else {
        trackEvent(BETTER_ORDER_EVENT_NAMES.PROMO_CODE_VALID, {
          'Promo Code': data.promotional_campaign.promo_code,
        });
        setPromoCodeValid(true);
      }
    },
    (error: any) => {
      dispatch(
        toastError(
          t(
            `reviewAndSubmit.infoSection.pricingSummary.promoDiscount.failedToValidate`,
          ),
          error?.response?.data,
        ),
      );
      setPromoCodeValid(false);
    },
  );

  const redeemParams = {
    account_id: currentUser?.account?.id ?? '',
    package_attributes: packageForSelectionWithAlias,
    promo_code: promoCode ?? '',
  };
  const { call: redeemPromoCode } = useRedeemPromotionCode(
    redeemParams,
    () => {
      submitOrder();
      setPromoCode('');
    },
    () => {
      toastDispatch(
        toastError(
          `${t('infoSection.pricingSummary.promoDiscount.redeemError')}`,
        ),
      );
      promoCodeValidation.reset();
    },
  );

  const submitOrder = useCallback(() => {
    setIsSubmitting(true);

    const eventProps: GenericObject = createSubmitTrackData(
      emails,
      selectedPackage,
      newlyCreatedPackage,
      addedScreeningTypeWithPrices,
      newPackageName,
      aliasAdded,
      infoSource,
      manualOrderType,
      currentUser?.account.has_report_or_invitation,
      infoMethod,
      invites,
      pricesSummary,
      recommendedPackageTag,
      promoCodeValid ? promoCode.toUpperCase() : undefined,
    );

    trackEvent(
      BETTER_ORDER_EVENT_NAMES.REVIEW_AND_SUBMIT_ORDER_COMPLETED,
      eventProps,
    );

    const manualBulkOrderEnabled = manualOrderType === 'MULTIPLE';
    const bulkInviteEnabled: boolean = !!csv.fileStorageKey;

    if (infoSource === 'MYSELF' && !manualBulkOrderEnabled) {
      const path = '/order-experience/manual-entry';
      if (contextId) {
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      } else {
        history.push(path);
      }
      sessionStorage.removeItem('betterOrderExperience');
      return;
    }

    const packageForSelection = newlyCreatedPackage?.slug
      ? newlyCreatedPackage
      : selectedPackage;

    const selection = {
      node,
      geo,
      program,
      country: location.country,
      city: location.city,
      state: location.state,
      package: { ...packageForSelection, aliases_enabled: aliasAdded },
      payment_profile: paymentProfile,
    };

    const addons = addedScreeningTypeWithPrices.map(screeningType => {
      return screeningType.screening.type;
    });

    // If the a new package public package was created, do not pass screenings.
    // The newly added screenings will be part of the newlyCreatedPackage
    const savedAddons =
      newlyCreatedPackage?.slug &&
      !newlyCreatedPackage?.name?.includes('with addons') // TODO: Hacky way to check if package is public, potentially move saveForLater value to context
        ? []
        : addons;

    createInvitation({
      selection,
      emails,
      csv,
      call: createCandidateCall,
      uploadCall,
      currentUser,
      bulkInviteEnabled,
      addons: savedAddons,
      manualBulkOrderEnabled,
      manualBulkCall,
      manualBulkUploadData,
      invites,
    } as CreateInvitationParams);
  }, [
    emails,
    selectedPackage,
    newlyCreatedPackage,
    addedScreeningTypeWithPrices,
    newPackageName,
    aliasAdded,
    infoSource,
    manualOrderType,
    currentUser,
    infoMethod,
    invites,
    pricesSummary,
    recommendedPackageTag,
    trackEvent,
    csv,
    node,
    geo,
    program,
    location.country,
    location.city,
    location.state,
    paymentProfile,
    createCandidateCall,
    uploadCall,
    manualBulkCall,
    manualBulkUploadData,
    contextId,
    history,
    promoCode,
    promoCodeValid,
  ]);

  const handleSubmitOrder = useCallback(() => {
    if (promoCode && promoCode.length > 0 && promoCodeValid) {
      redeemPromoCode();
    } else {
      submitOrder();
    }
  }, [promoCode, promoCodeValid, redeemPromoCode, submitOrder]);

  const handleSuccessfulSubmit = useCallback(() => {
    setIsSubmitting(false);
    setShowSuccessModal(true);
    sessionStorage.removeItem('betterOrderExperience');
    reset();
  }, [reset]);

  useEffect(() => {
    if (uploadResult.isError) {
      setIsSubmitting(false);
      toastDispatch(toastError(`ERROR: ${uploadResult?.error?.message}`));
      return;
    }

    if (uploadResult.isSuccess) {
      handleSuccessfulSubmit();
    }
  }, [handleSuccessfulSubmit, toastDispatch, uploadResult]);

  useEffect(() => {
    if (createCandidateResult.isError) {
      setIsSubmitting(false);
      toastDispatch(
        toastError(`ERROR: ${createCandidateResult?.error?.message}`),
      );
      createCandidateResult.reset();
      return;
    }

    if (createCandidateResult.isSuccess) {
      handleSuccessfulSubmit();

      createCandidateResult.reset();
    }
  }, [createCandidateResult, handleSuccessfulSubmit, toastDispatch]);

  useEffect(() => {
    // Fail safe for if a user navigates to this step via a back button and no package was selected
    if (!selectedPackage?.name) {
      history.replace(STEPS.GET_STARTED.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manualBulkResult.isError) {
      setIsSubmitting(false);
      toastDispatch(toastError(`ERROR: ${manualBulkResult?.error?.message}`));
      return;
    }

    if (manualBulkResult.isSuccess) {
      handleSuccessfulSubmit();

      manualBulkResult.reset();
    }
  }, [handleSuccessfulSubmit, manualBulkResult, toastDispatch]);

  const submitButtonDisabled = !orderCertified || isSubmitting;

  useEffect(() => {
    if (uploadResult.isSuccess) {
      setShowSuccessModal(true);
    }

    if (createCandidateResult.isSuccess) {
      setShowSuccessModal(true);
      createCandidateResult.reset();
    }

    if (manualBulkResult.isSuccess) {
      setShowSuccessModal(true);
      manualBulkResult.reset();
    }
  }, [createCandidateResult, manualBulkResult, uploadResult.isSuccess]);

  const bulkOrderHistoryUrl = hasPermission(
    currentUser,
    'read_account_invoices',
  )
    ? getDashboardUrl(`/billing/bulk-history`)
    : getDashboardUrl(`/bulk-order-history`);

  const [viewPackageDetails, setViewPackageDetails] = useState(false);
  const [viewAddonsDetails, setViewAddonsDetails] = useState(false);
  const handleViewPackageDetailsClick = useCallback(
    () => setViewPackageDetails(!viewPackageDetails),
    [viewPackageDetails],
  );
  const handleViewAddonsDetailsClick = useCallback(
    () => setViewAddonsDetails(!viewAddonsDetails),
    [viewAddonsDetails],
  );

  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';
  const hasAliasSkus = !!selectedPackage?.use_alias_skus;
  const packageAlreadyIncludesAlias =
    aliasFlag &&
    includesAlias({
      screenings: selectedPackage.screenings as PostScreening[],
      use_alias_skus: hasAliasSkus,
    });

  return (
    <StyledStepContainer data-testid='review-and-submit-step-container'>
      <h4>{t('header')}</h4>
      <StyledSubtitle>
        {t('subtitle')}{' '}
        <ReviewAndSubmitSubtitle
          infoSource={infoSource}
          manualOrderType={manualOrderType}
          infoMethod={infoMethod}
          bulkHistoryUrl={bulkOrderHistoryUrl}
        />
      </StyledSubtitle>

      {showSuccessModal && (
        <SubmitModal
          currentUser={currentUser}
          handleClose={closeModal}
          contextId={contextId}
          open={showSuccessModal}
          csv={csv}
          manualOrderType={manualOrderType}
          aliasAdded={initialAliasAddedState}
        />
      )}
      {hasCandidateInfo && (
        <>
          <StyledInfoText>{t(`infoSection.candidateInfoText`)}</StyledInfoText>
          <InfoSection section='candidateInfo' onEditClick={goToGetStarted}>
            <CandidateInfoSection />
          </InfoSection>
        </>
      )}
      {workLocationValue && (
        <InfoSection section='workLocation' onEditClick={goToGetStarted}>
          {workLocationValue}
        </InfoSection>
      )}
      <InfoSection
        section='selectedPackage'
        onEditClick={handleSelectedPackageEdit}
        showDetailsSection
        handleViewDetailsClick={handleViewPackageDetailsClick}
        viewDetails={viewPackageDetails}
      >
        <SelectedPackageInfoSection
          selectedPackage={selectedPackage}
          aliasPrice={pricesSummary.aliasPrice}
          newCreatedPackageName={newlyCreatedPackage?.name}
          viewDetails={viewPackageDetails}
          packageAlreadyIncludesAlias={packageAlreadyIncludesAlias}
        />
      </InfoSection>
      {addedScreeningTypeWithPrices.length > 0 && (
        <InfoSection
          section='customizations'
          onEditClick={goToAddOns}
          showDetailsSection={addedScreeningTypeWithPrices.length > 5}
          handleViewDetailsClick={handleViewAddonsDetailsClick}
          viewDetails={viewAddonsDetails}
        >
          <CustomizationsInfoSection
            addOns={addedScreeningTypeWithPrices}
            showPrices={showPrices}
            additionalProperties={additionalProperties}
            viewDetails={viewAddonsDetails}
          />
        </InfoSection>
      )}
      {showPrices && (
        <PricingSection
          packageAlreadyIncludesAlias={packageAlreadyIncludesAlias}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          promoCodeValidation={promoCodeValidation}
        />
      )}
      <StyledOrderCertifiedCheckbox
        id='review-and-submit-order-legal-check'
        data-testid='order-certified-checkbox'
        checked={orderCertified}
        onChange={handleOrderCertifiedClick}
        labelText={
          <StyledOrderCertifiedText>
            {manualOrderType === 'MULTIPLE'
              ? `${t('manualBulkOrderCertified')}`
              : `${t('orderCertified')}`}
            <StyledLink
              data-testid='checkr-services-agreement-link'
              href='https://checkr.com/customer-agreement'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('checkrServiceAgreement')}
            </StyledLink>
          </StyledOrderCertifiedText>
        }
      />
      <StyledFooter>
        <M.Button
          data-testid='review-step-back-btn'
          kind='secondary'
          type='button'
          onClick={handleBack}
        >
          {t('footerButtons.goBack')}
        </M.Button>
        <M.Button
          type='button'
          data-testid='review-submit-order-btn'
          disabled={submitButtonDisabled}
          onClick={handleSubmitOrder}
        >
          {t('footerButtons.submitOrder')}
        </M.Button>
      </StyledFooter>
    </StyledStepContainer>
  );
};

export default ReviewAndSubmitStep;
