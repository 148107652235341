import React from 'react';
import { exam_reorder_modal_body } from 'components/Report/Screenings/DrugHealthScreenings/locales';
import { useTranslation } from 'react-i18next';
import { AddExamError } from '../../../types';

type Props = {
  error: AddExamError;
};

const Description: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  const description = t(`${exam_reorder_modal_body}.states.error`);
  return (
    <p>
      <strong>{error?.title}</strong>
      <br />
      {error?.message}
      <br />
      <br />
      {description}
    </p>
  );
};

export default Description;
