import React, { useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  GenericObject,
  getStatusTypeForUser,
  StatusTypes,
} from '@dashboard-experience/utils';
import { PARAMS } from 'components/Search';
import { useUser } from 'context/CurrentUser';
import { useGetReportsList } from 'modules/reports-list/utilities';

import PaginationContainer from './pagination-container';
import ActionsBar from './actions-bar';
import Table from './table';
import { View } from '../state/types';

type Props = {
  view: View;
  page: number;
};

const ViewContent: React.FC<Props> = ({ view, page }) => {
  // TODO: move setCancelSource, reports state handling to useGetReportsList

  const [reports, setReports] = useState<GenericObject>();
  const [, setCancelSource] = useState<any | null>(null);

  const currentUser = useUser();
  const { account } = currentUser;
  const userType = getStatusTypeForUser(currentUser);
  const assessEnabled = userType === StatusTypes.Assess;

  // useGetReportsList calls setReport on success
  // This allows us to retain the previous reports data while a refetch is being made

  const { isLoading } = useGetReportsList(
    view,
    page,
    currentUser,
    account,
    setReports,
    setCancelSource,
  );

  useEffect(() => {
    if (account) {
      PARAMS.updateGeneralParams({
        assessEnabled,
        account,
      });
    }
  }, [account, assessEnabled]);

  return (
    <>
      <ActionsBar isLoading={isLoading} view={view} reports={reports} />
      {!!reports && (
        <>
          <Table candidates={reports} currentUser={currentUser} />
          <PaginationContainer
            count={reports?.count}
            pageNum={page}
            perPage={view.filters.per_page}
          />
        </>
      )}
      {!reports && isLoading && <M.LoadingInline description='Loading...' />}
    </>
  );
};

export default ViewContent;
