import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetAnalyticsDashboard } from 'api/analytics';
import { M } from '@dashboard-experience/mastodon';
import { useFlag, FlagrContext } from '@dashboard-experience/react-flagr';
import { usePreference } from 'api/dashboardPreferences';
import { ANALYTICS_REDESIGN, ANALYTICS_OVERVIEW_LAUNCH } from 'Flags';
import { AnalyticsIframeProps, AnalyticsStates } from './types';
import { AnalyticsBanner } from './AnalyticsBanner';
import { StyledIframe } from './StyledComponents';

const NEW_VERSION_FLAGRS = {
  // Redesign flagr must be on, and the individual tab must have a flag set here and have variantKey: 'on'
  // lookerName: flagKey
  overview: ANALYTICS_OVERVIEW_LAUNCH,
} as { [key: string]: string | undefined };

type FlagrState = {
  flags?: {
    [key: string]:
      | {
          variantKey?: string;
        }
      | undefined;
  };
};

export const shouldShowToggle = (
  lookerName: string,
  redesignFlagrEnabled: boolean,
  flagState: FlagrState,
) => {
  if (!redesignFlagrEnabled) return false;

  const tabFlagr = NEW_VERSION_FLAGRS[lookerName];
  if (!tabFlagr) return false;

  return flagState?.flags?.[tabFlagr]?.variantKey === 'on';
};

export const getVersion = (
  showNewVersionToggle: boolean,
  toggle: AnalyticsStates,
) =>
  showNewVersionToggle && toggle !== AnalyticsStates.LEGACY
    ? AnalyticsStates.V2
    : AnalyticsStates.LEGACY;

export const AnalyticsIframe: React.FC<AnalyticsIframeProps> = ({
  lookerName,
}) => {
  const [showLoader, setShowLoader] = useState(true);
  const redesignEnabled = useFlag(ANALYTICS_REDESIGN)?.variantKey === 'on';
  const flags = useContext(FlagrContext) as FlagrState;

  const [toggle, setToggle] = usePreference(`analytics:${lookerName}`);

  const updateToggle = useCallback(() => {
    setToggle(
      toggle === AnalyticsStates.LEGACY
        ? AnalyticsStates.V2
        : AnalyticsStates.LEGACY,
    );
  }, [toggle, setToggle]);

  const showNewVersionToggle = useMemo(
    () => shouldShowToggle(lookerName, redesignEnabled, flags),
    [lookerName, redesignEnabled, flags],
  );

  const version =
    showNewVersionToggle && toggle !== AnalyticsStates.LEGACY
      ? AnalyticsStates.V2
      : AnalyticsStates.LEGACY;

  useEffect(() => {
    setShowLoader(true);
  }, [version]);

  const {
    url: lookerUrl,
    isLoading: lookerUrlIsLoading,
    isError: lookerUrlIsError,
  } = useGetAnalyticsDashboard({
    lookerName,
    includePermutationArray: true,
    version,
  });

  const hideLoader = useCallback(() => {
    setShowLoader(false);
  }, [setShowLoader]);

  return lookerUrl && !lookerUrlIsLoading && !lookerUrlIsError ? (
    <div>
      {showNewVersionToggle && (
        <AnalyticsBanner
          state={version}
          toggle={updateToggle}
          lookerName={lookerName}
        />
      )}
      {showLoader && <M.LoadingInline description='Loading...' />}
      <StyledIframe
        src={lookerUrl}
        className={lookerName}
        data-testid='analytics-iframe'
        data-pendo-id={`${lookerName}-analytics-iframe`}
        onLoad={hideLoader}
      />
    </div>
  ) : null;
};
