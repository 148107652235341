import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { localStorageFallback } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import {
  StyledHeader,
  StyledDescription,
  StyledInfoContainer,
  StyledButtonContainer,
  StyledCheckboxContainer,
} from './AliasSettingSelection.styles';
import { namespace } from '../../locales';

interface CannotUpdateAccountContentProps {
  aliasAutoEnableSetting: boolean;
  aliasEnabled: boolean;
}

const CannotUpdateAccountContent: React.FC<CannotUpdateAccountContentProps> = ({
  aliasAutoEnableSetting,
  aliasEnabled,
}) => {
  const { t } = useTranslation(namespace);
  const [isHideDefaultAliasChecked, setIsHideDefaultAliasChecked] =
    useState(false);

  const handleCheck = useCallback(() => {
    setIsHideDefaultAliasChecked(!isHideDefaultAliasChecked);
    localStorageFallback.setItem(
      'hideDefaultAliasModal',
      (!isHideDefaultAliasChecked).toString(),
    );
  }, [isHideDefaultAliasChecked]);

  return (
    <>
      <StyledInfoContainer>
        <StyledHeader className='h5'>{t('aliasSetting.header')}</StyledHeader>
        <StyledDescription>
          <p>
            {t('reviewAndSubmit.aliasSetting.description.you')}{' '}
            <span data-testid='alias-setting-selection-bold-text-1'>
              {aliasEnabled
                ? t('reviewAndSubmit.aliasSetting.description.added')
                : t('reviewAndSubmit.aliasSetting.description.removed')}
            </span>{' '}
            {t('reviewAndSubmit.aliasSetting.description.aliasSearches')}{' '}
            {aliasEnabled
              ? t('reviewAndSubmit.aliasSetting.description.to')
              : t('reviewAndSubmit.aliasSetting.description.from')}{' '}
            this order. {t('aliasSetting.description.adminCanUpdate')}
          </p>
        </StyledDescription>
      </StyledInfoContainer>
      <StyledButtonContainer>
        <StyledCheckboxContainer id='hide-modal-checkbox-container-unauthorized'>
          <M.Checkbox
            data-testid='hide-modal-checkbox'
            id='hide-modal-checkbox'
            labelText={
              <p
                style={{ paddingLeft: '12px' }}
                data-testid='hide-modal-checkbox-label'
              >
                {t('reviewAndSubmit.aliasSetting.buttons.dontShowAgain')}
              </p>
            }
            kind='primary'
            onChange={handleCheck}
            checked={isHideDefaultAliasChecked}
          />
        </StyledCheckboxContainer>
      </StyledButtonContainer>
    </>
  );
};

export default CannotUpdateAccountContent;
