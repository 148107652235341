import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { SCREENING_NAME_MAP } from 'Constants';
import { countCandidates } from 'components/Signup/utils/candidates';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ALIAS_IN_SIGNUP } from 'Flags';
import { useTranslation } from 'react-i18next';
import PromoCodeBanner from 'components/BetterOrderExperience/ReviewAndSubmitStep/PricingSection/PromoCodeBanner';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { PromoCodeResType } from 'api/promotionCodes';
import {
  Container,
  Content,
  SectionTitle,
  Screening,
  ScreeningPrice,
  NoneAdded,
  Section,
  SectionItem,
  EmptyPrice,
  PromoCodeContainer,
  PromoCodeApplyButton,
  PromoCodeTextInput,
  PromoCodeBannerContainer,
  DiscountedPriceContainer,
  StrikethroughPrice,
  PromoCodeWithClear,
  PromoCodeClearIcon,
} from './styles';
import { getDollarAmount } from '../../../AddScreenings/shared/utils';
import { AddOnsType, Candidate } from '../../types';
import { LoadingBlock } from '../Alias/styles';

export type ValidatePromoCodeType = {
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  reset: () => void;
  call: () => Promise<any>;
  data?: { promotional_campaign?: PromoCodeResType };
};

type Props = {
  packageName: string;
  customPackagePrice: string | null;
  basePackagePrice: string | null;
  screenings: AddOnsType[];
  candidates: Candidate[];
  screeningPrices: { [key: string]: number };
  isInternational?: boolean;
  isAliasEnabled: string | null;
  aliasPrice: string | null;
  subtotal: string | null;
  showPromoCodeField?: boolean;
  promoCode?: string | undefined;
  setPromoCode?: Dispatch<SetStateAction<string | undefined>>;
  promoCodeValid?: boolean | undefined;
  promoCodeValidation?: ValidatePromoCodeType;
};

const OrderSummary = ({
  packageName,
  screenings,
  candidates,
  screeningPrices,
  customPackagePrice,
  basePackagePrice,
  isInternational = false,
  isAliasEnabled,
  aliasPrice,
  subtotal,
  showPromoCodeField = false,
  promoCode,
  setPromoCode = () => {},
  promoCodeValid,
  promoCodeValidation,
}: Props) => {
  const {
    call: validatePromoCode,
    data: promoCodeValidationData,
    isIdle: isPromoCodeValidIdle,
    isLoading: isPromoCodeValidLoading,
    isSuccess: isPromoCodeValidSuccess,
    reset: resetPromoCodeValidation,
  } = promoCodeValidation ?? {
    call: () => Promise.resolve({}),
    result: { data: undefined },
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    reset: () => {},
  };
  const { t } = useTranslation('signup');
  const numOfCandidates = countCandidates(candidates);
  const aliasEnabledFlag =
    useFlag(ALIAS_IN_SIGNUP)?.variantKey === 'on' || false;
  const trackEvent = useTrackEvent();

  const handlePromoCodeChange = useCallback(
    e => {
      setPromoCode(e.target.value);
    },
    [setPromoCode],
  );

  const handlePromoCodeApplyClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_APPLY_PROMO_CODE_CLICKED);
    validatePromoCode();
  }, [trackEvent, validatePromoCode]);

  const handlePromoCodeClearClick = useCallback(() => {
    resetPromoCodeValidation();
    setPromoCode(undefined);
  }, [resetPromoCodeValidation, setPromoCode]);

  const handlePromoCodeInputClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_APPLY_PROMO_CODE_CLICKED);
  }, [trackEvent]);

  const discountAmount =
    promoCodeValidationData?.promotional_campaign?.total_discount;

  const newPrice = Number(subtotal?.replace('$', '')) - Number(discountAmount);

  const promoCodeError = isPromoCodeValidSuccess
    ? promoCodeValidationData?.promotional_campaign?.errors?.[0]
    : t('components.OrderSummary.promoCode.error');

  return (
    <Container data-testid='order-summary'>
      <h4 className='mb-0'>{t('components.OrderSummary.orderSummary')}</h4>
      <Content>
        <Section data-testid='order-summary-package-details'>
          <SectionTitle>{t('components.OrderSummary.package')}</SectionTitle>
          <SectionItem data-testid='order-summary-package'>
            <Screening data-testid='order-summary-package-name'>
              {packageName}
            </Screening>
            <ScreeningPrice data-testid='order-summary-package-price'>
              {basePackagePrice === null ? <LoadingBlock /> : basePackagePrice}
            </ScreeningPrice>
          </SectionItem>
        </Section>
        {!isInternational && (
          <Section data-testid='order-summary-add-ons'>
            <SectionTitle>
              {t('components.OrderSummary.customize')}
            </SectionTitle>
            {screenings.length > 0 ? (
              screenings.map(screening => (
                <SectionItem key={screening}>
                  <Screening data-testid='order-summary-addons-name'>
                    {SCREENING_NAME_MAP[screening]}
                  </Screening>
                  <ScreeningPrice>
                    {getDollarAmount(screeningPrices[screening].toString())}
                  </ScreeningPrice>
                </SectionItem>
              ))
            ) : (
              <SectionItem>
                <NoneAdded data-testid='screening-none-added'>
                  {t('components.OrderSummary.noneAdded')}
                </NoneAdded>
                <EmptyPrice>—</EmptyPrice>
              </SectionItem>
            )}
          </Section>
        )}
        {aliasEnabledFlag && isAliasEnabled === 'true' && (
          <Section>
            <SectionItem>
              <Screening data-testid='order-summary-alias'>
                {t('components.OrderSummary.aliasSearches')}
              </Screening>
              <ScreeningPrice data-testid='order-summary-alias-price'>
                {aliasPrice === null ? <LoadingBlock /> : aliasPrice}
              </ScreeningPrice>
            </SectionItem>
          </Section>
        )}
        {showPromoCodeField && (
          <Section>
            <PromoCodeContainer data-testid='promo-code-container'>
              {isPromoCodeValidIdle ? (
                <PromoCodeTextInput
                  data-testid='promo-code-input-field'
                  placeholder={t('components.OrderSummary.promoCode.addPromo')}
                  onChange={handlePromoCodeChange}
                  onClick={handlePromoCodeInputClick}
                />
              ) : (
                <PromoCodeWithClear>
                  {promoCode}
                  <PromoCodeClearIcon
                    icon='Close'
                    size='16'
                    onClick={handlePromoCodeClearClick}
                  />
                </PromoCodeWithClear>
              )}

              <PromoCodeApplyButton
                kind='tertiary'
                data-testid='promo-code-apply-btn'
                id='promo-code-apply-btn'
                onClick={handlePromoCodeApplyClick}
                disabled={
                  !promoCode || !isPromoCodeValidIdle || isPromoCodeValidLoading
                }
              >
                {t('components.OrderSummary.promoCode.apply')}
              </PromoCodeApplyButton>
            </PromoCodeContainer>

            {!isPromoCodeValidIdle && !isPromoCodeValidLoading && (
              <PromoCodeBannerContainer>
                <PromoCodeBanner
                  type={promoCodeValid ? 'success' : 'error'}
                  text={
                    promoCodeValid
                      ? t('components.OrderSummary.promoCode.success')
                      : promoCodeError
                  }
                />
              </PromoCodeBannerContainer>
            )}
          </Section>
        )}
        <Section data-testid='order-summary-recipients'>
          {numOfCandidates > 0 && (
            <SectionTitle>
              {numOfCandidates} {t('components.OrderSummary.candidates')} ×{' '}
              {customPackagePrice === null ? (
                <LoadingBlock />
              ) : (
                customPackagePrice
              )}{' '}
              {t('components.OrderSummary.perReport')}
            </SectionTitle>
          )}
          <SectionItem data-testid='order-summary-package'>
            <Screening data-testid='order-summary-package-name'>
              {t('components.OrderSummary.subtotal')}
            </Screening>
            {subtotal === null && <LoadingBlock />}

            {promoCodeValid && subtotal && discountAmount ? (
              <DiscountedPriceContainer>
                <StrikethroughPrice data-testid='order-summary-subtotal-price-strikethrough'>
                  {subtotal}
                </StrikethroughPrice>{' '}
                <ScreeningPrice
                  data-testid='order-summary-package-new-price'
                  discounted
                  className='order-summary-package-new-price'
                >
                  {getDollarAmount((newPrice * 100).toString())}
                </ScreeningPrice>
              </DiscountedPriceContainer>
            ) : (
              <ScreeningPrice data-testid='order-summary-subtotal-price'>
                {subtotal}
              </ScreeningPrice>
            )}
          </SectionItem>
        </Section>
      </Content>
    </Container>
  );
};

export default OrderSummary;
