import { reduce } from 'lodash';
import { FetchPackageType } from '@dashboard-experience/utils';
import i18n from 'i18next';
import { GetStartedT } from '../GetStartedStep';
import type { SelectPackageT } from '.';
import {
  namespace,
  DEFAULT_HIGHEST_PRICE_PACKAGE_NAME,
  DEFAULT_PACKAGE_NAMES,
} from '../locales';

const basePath = `${namespace}:selectPackage`;

export const filterPackages = (
  packages: FetchPackageType[],
): SelectPackageT.FilteredPackages =>
  reduce(
    packages,
    (acc: SelectPackageT.FilteredPackages, pkg: FetchPackageType) => {
      pkg.international_only
        ? acc.international.push(pkg)
        : acc.domestic.push(pkg);
      return acc;
    },
    {
      domestic: [],
      international: [],
    },
  );

export const filteredBasePackageScreenings = (
  basePackage: FetchPackageType,
) => {
  // makes sure adverse media is not shown as a separate line item for international_only packages
  if (basePackage.international_only) {
    const filteredScreenings = basePackage.screenings?.filter(
      screening => screening.type !== 'international_adverse_media_search',
    );
    return filteredScreenings;
  }
  return basePackage?.screenings;
};

export const getNoPackageFoundMessage = (
  hierarchy_present: boolean,
  node: GetStartedT.Node | null,
  localeType: GetStartedT.Locale,
) => {
  const locale = localeType === 'DOMESTIC' ? 'Domestic' : 'International';
  if (hierarchy_present && node) {
    return `${i18n.t(`${basePath}.no${locale}NodePackages`)} ${
      node?.custom_id
    }, ${node?.name}.`;
  }

  return `${i18n.t(`${basePath}.no${locale}Packages`)}`;
};

export const getSelectablePackages = (
  fetchedPackages: FetchPackageType[],
  hierarchy_present: boolean,
  node: GetStartedT.Node,
  nodePackages: string[],
) => {
  if (hierarchy_present && node) {
    return fetchedPackages?.filter(pkg => {
      return nodePackages?.includes(pkg.slug);
    });
  }

  return fetchedPackages;
};

export const isAvailableForManualBulk = (
  basePackage: FetchPackageType,
  isManualBulkOrder: boolean,
): boolean => {
  const screenings = basePackage?.screenings || [];

  return (
    !isManualBulkOrder ||
    screenings.every(screening => availableForManualBulk.has(screening.type))
  );
};

export const availableForManualBulk = new Set([
  'county_criminal_search',
  'state_criminal_search',
  'federal_criminal_search',
  'county_civil_search',
  'federal_civil_search',
  'facis_search',
  'global_watchlist_search',
  'ssn_trace',
  'sex_offender_search',
  'national_criminal_search',
]);

export const getHighestPricedPackageName = (
  recommendedPackages: FetchPackageType[],
  isManualBulkOrder: boolean,
  isAvailableForManualBulk: (
    basePackage: FetchPackageType,
    isManualBulkOrder: boolean,
  ) => boolean,
) => {
  const customPackages = recommendedPackages?.filter(
    pkg =>
      !DEFAULT_PACKAGE_NAMES.includes(pkg.name) &&
      isAvailableForManualBulk(pkg, isManualBulkOrder),
  );
  const hasCustomPackages = customPackages.length > 0;

  if (!hasCustomPackages) {
    const essentialPackage = recommendedPackages.find(
      pkg => pkg.name === DEFAULT_HIGHEST_PRICE_PACKAGE_NAME,
    );
    if (essentialPackage) return DEFAULT_HIGHEST_PRICE_PACKAGE_NAME;
  }

  const highestCustomPackage = customPackages.sort(
    (a, b) => (b.price ?? 0) - (a.price ?? 0),
  )[0];

  return highestCustomPackage?.name ?? DEFAULT_HIGHEST_PRICE_PACKAGE_NAME; // Return highest custom package or 'Essential' if none found
};
