import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import ReportModal from 'components/Report/ReportModal';

const ResolveModal = styled(ReportModal)`
  h3 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }

  p#paused-reason {
    margin-bottom: revert;
  }
`;

type ModalProps = {
  showModal: boolean;
  onClose: () => void;
  reason?: string;
  footer?: any;
  header?: string;
};

// Modal skeleton with common structure to be used for the ResolveUndeliverableManually and ResolveUndeliverableWithEmail components
const ResolveUndeliverableAdverseModal: React.FC<ModalProps> = ({
  showModal,
  onClose,
  reason,
  footer,
  header,
  children,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'resolve_undeliverable_aa' });

  return (
    <ResolveModal open={showModal} onClose={onClose}>
      <M.ModalHeader closeModal={onClose}>
        <h2>{header || t('heading')}</h2>
      </M.ModalHeader>

      <M.ModalBody padding='0'>{children}</M.ModalBody>

      <M.ModalFooter>{footer}</M.ModalFooter>
    </ResolveModal>
  );
};

export default ResolveUndeliverableAdverseModal;
