import React, {
  useMemo,
  useState,
  useCallback,
  ReactNode,
  useEffect,
} from 'react';
import { Geo, PostPackageType } from '@dashboard-experience/utils';
import { BillingEntity } from 'types/Billing';
import type {
  GetStartedT,
  AddonsT,
  BulkUploadT,
} from 'components/BetterOrderExperience';
import { useHistory } from 'react-router-dom';
import { IMPERSONATING } from 'Constants';
import BetterOrderExperienceContext, { INITIAL_VALUES } from './context';

type Props = {
  children: ReactNode;
};

const Provider: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  // GET STARTED
  const [infoSource, setInfoSource] = useState<GetStartedT.InfoSource>(
    INITIAL_VALUES.infoSource,
  );
  const [infoMethod, setInfoMethod] = useState<
    GetStartedT.InfoMethod | undefined
  >(INITIAL_VALUES.infoMethod);
  const [paymentProfile, setPaymentProfile] = useState<BillingEntity>(
    INITIAL_VALUES.paymentProfile,
  );
  const [geo, setGeo] = useState<Geo>(INITIAL_VALUES.geo);
  const [location, setLocation] = useState<GetStartedT.WorkLocation>(
    INITIAL_VALUES.location,
  );
  const [node, setNode] = useState<GetStartedT.Node>(INITIAL_VALUES.node);
  const [program, setProgram] = useState<GetStartedT.Program>(
    INITIAL_VALUES.program,
  );
  const [invites, setInvites] = useState<GetStartedT.Invite[]>(
    INITIAL_VALUES.invites,
  );
  const [csv, setCsv] = useState<GetStartedT.BulkInviteCSV>(INITIAL_VALUES.csv);
  const [emails, setEmails] = useState<GetStartedT.Email[]>(
    INITIAL_VALUES.emails,
  );
  const [manualBulkUploadType, setManualBulkUploadType] =
    useState<GetStartedT.ManualOrderType>(INITIAL_VALUES.manualOrderType);
  const [localeType, setLocaleType] = useState<GetStartedT.Locale>(
    INITIAL_VALUES.localeType,
  );
  const [manualOrderType, setManualOrderType] = useState<
    GetStartedT.ManualOrderType | undefined
  >(INITIAL_VALUES.manualOrderType);

  // SELECT PACKAGE
  const [selectedPackage, setSelectedPackage] = useState(
    INITIAL_VALUES.selectedPackage,
  );
  const [recommendedPackageTag, setRecommendedPackageTag] = useState(
    INITIAL_VALUES.recommendedPackageTag,
  );

  // ADD-ONS
  const [additionalProperties, setAdditionalProperties] =
    useState<AddonsT.AdditionalProperties>(INITIAL_VALUES.additionalProperties);

  const [addedScreeningTypeWithPrices, setAddedScreeningTypeWithPrices] =
    useState<AddonsT.ScreeningTypeWithPrices[]>(
      INITIAL_VALUES.addedScreeningTypeWithPrices,
    );

  const [newlyCreatedPackage, setNewlyCreatedPackage] =
    useState<PostPackageType>(INITIAL_VALUES.newlyCreatedPackage);

  const [aliasAdded, setAliasAdded] = useState<AddonsT.Alias>(
    INITIAL_VALUES.aliasAdded,
  );
  const [saveForNextTime, setSaveForNextTime] = useState<boolean>(
    INITIAL_VALUES.saveForNextTime,
  );
  const [newPackageName, setNewPackageName] = useState<string>(
    INITIAL_VALUES.newPackageName,
  );
  const [manualBulkUploadData, setManualBulkUploadData] =
    useState<BulkUploadT.ManualBulkUploadData>(
      INITIAL_VALUES.manualBulkUploadData,
    );
  const [pricesSummary, setPricesSummary] = useState<AddonsT.PricesSummary>(
    INITIAL_VALUES.pricesSummary,
  );

  const updateSessionStorage = useCallback(values => {
    const boxValues = sessionStorage.getItem('betterOrderExperience');
    let updatedValues = {};
    if (!boxValues) updatedValues = values;
    else {
      const parsedBoxValues = JSON.parse(boxValues);
      updatedValues = { ...parsedBoxValues, ...values };
    }

    sessionStorage.setItem(
      'betterOrderExperience',
      JSON.stringify(updatedValues),
    );
  }, []);

  // Remove 'betterOrderExperience' from sessionStorage if user navigates away from BOX or when impersonating
  useEffect(() => {
    const BOX_ROUTES = [
      '/order-experience/get-started',
      '/order-experience/select-your-package',
      '/order-experience/add-ons',
      '/order-experience/review-and-submit',
      '/order-experience/bulk-upload',
      '/order-experience/manual-entry',
    ];

    const stopListening = history.listen((location: { pathname: string }) => {
      const currentPath = location.pathname;
      if (
        !BOX_ROUTES.includes(currentPath) ||
        Object.keys(IMPERSONATING).length !== 0
      ) {
        sessionStorage.removeItem('betterOrderExperience');
      }
    });

    return () => {
      stopListening();
    };
  }, [history]);

  const update = useCallback(
    values => {
      if (values.infoSource) setInfoSource(values.infoSource);
      if (values.paymentProfile) setPaymentProfile(values.paymentProfile);
      if (values.geo) setGeo(values.geo);
      if (values.location) setLocation(values.location);
      if (values.node) setNode(values.node);
      if (values.program) setProgram(values.program);
      if (values.localeType) setLocaleType(values.localeType);
      if (values.invites) setInvites(values.invites);
      if (values.csv) setCsv(values.csv);
      if (values.emails) setEmails(values.emails);
      if (values.manualBulkUploadType)
        setManualBulkUploadType(values.manualBulkUploadType);
      if (values.selectedPackage) setSelectedPackage(values.selectedPackage);
      if (values.recommendedPackageTag)
        setRecommendedPackageTag(values.recommendedPackageTag);
      if (values.addedScreeningTypeWithPrices)
        setAddedScreeningTypeWithPrices(values.addedScreeningTypeWithPrices);
      if (values.newlyCreatedPackage)
        setNewlyCreatedPackage(values.newlyCreatedPackage);
      if (values.aliasAdded) setAliasAdded(values.aliasAdded);
      if (values.pricesSummary) setPricesSummary(values.pricesSummary);
      if (values.saveForNextTime) setSaveForNextTime(values.saveForNextTime);
      if (values.newPackageName) setNewPackageName(values.newPackageName);
      if (values.manualBulkUploadData)
        setManualBulkUploadData(values.manualBulkUploadData);
      if (values.additionalProperties)
        setAdditionalProperties(values.additionalProperties);
      if (values.addedScreeningTypeWithPrices?.length)
        setAddedScreeningTypeWithPrices(values.addedScreeningTypeWithPrices);

      updateSessionStorage(values);
    },

    [updateSessionStorage, setPaymentProfile],
  );

  const updateInfoSource = useCallback(
    values => {
      setInfoSource(values.infoSource);
      setInfoMethod(values.infoMethod);
      setManualOrderType(values.manualOrderType);

      updateSessionStorage(values);
    },
    [updateSessionStorage],
  );

  const reset = useCallback(() => {
    setInfoSource(INITIAL_VALUES.infoSource);
    setInfoMethod(INITIAL_VALUES.infoMethod);
    setPaymentProfile(INITIAL_VALUES.paymentProfile);
    setGeo(INITIAL_VALUES.geo);
    setLocation(INITIAL_VALUES.location);
    setNode(INITIAL_VALUES.node);
    setProgram(INITIAL_VALUES.program);
    setLocaleType(INITIAL_VALUES.localeType);
    setInvites(INITIAL_VALUES.invites);
    setCsv(INITIAL_VALUES.csv);
    setEmails(INITIAL_VALUES.emails);
    setManualBulkUploadType(INITIAL_VALUES.manualOrderType);
    setSelectedPackage(INITIAL_VALUES.selectedPackage);
    setRecommendedPackageTag(INITIAL_VALUES.recommendedPackageTag);
    setAddedScreeningTypeWithPrices(
      INITIAL_VALUES.addedScreeningTypeWithPrices,
    );
    setNewlyCreatedPackage(INITIAL_VALUES.newlyCreatedPackage);
    setAliasAdded(INITIAL_VALUES.aliasAdded);
    setPricesSummary(INITIAL_VALUES.pricesSummary);
    setSaveForNextTime(INITIAL_VALUES.saveForNextTime);
    setNewPackageName(INITIAL_VALUES.newPackageName);
    setManualBulkUploadData(INITIAL_VALUES.manualBulkUploadData);
    setAdditionalProperties(INITIAL_VALUES.additionalProperties);
    setAddedScreeningTypeWithPrices(
      INITIAL_VALUES.addedScreeningTypeWithPrices,
    );
  }, []);

  useEffect(() => {
    const boxValues = sessionStorage.getItem('betterOrderExperience');
    // Set initial default values to sessionStorage
    if (!boxValues) {
      updateInfoSource({
        infoSource: 'CANDIDATE',
        infoMethod: 'MANUAL',
        manualOrderType: undefined,
      });
    }
  }, [updateInfoSource]);

  const providerValue = useMemo(
    () => ({
      infoSource,
      infoMethod,
      paymentProfile,
      geo,
      location,
      node,
      program,
      localeType,
      invites,
      csv,
      emails,
      manualBulkUploadType,
      manualOrderType,
      selectedPackage,
      recommendedPackageTag,
      addedScreeningTypeWithPrices,
      additionalProperties,
      aliasAdded,
      saveForNextTime,
      newPackageName,
      manualBulkUploadData,
      newlyCreatedPackage,
      pricesSummary,
      update,
      updateInfoSource,
      reset,
    }),
    [
      infoSource,
      infoMethod,
      paymentProfile,
      geo,
      location,
      node,
      program,
      localeType,
      invites,
      csv,
      emails,
      manualBulkUploadType,
      manualOrderType,
      selectedPackage,
      recommendedPackageTag,
      addedScreeningTypeWithPrices,
      additionalProperties,
      aliasAdded,
      saveForNextTime,
      newPackageName,
      manualBulkUploadData,
      newlyCreatedPackage,
      pricesSummary,
      update,
      updateInfoSource,
      reset,
    ],
  );

  return (
    <BetterOrderExperienceContext.Provider value={providerValue}>
      {children}
    </BetterOrderExperienceContext.Provider>
  );
};

export default Provider;
