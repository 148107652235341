import { M } from '@dashboard-experience/mastodon';
import {
  AssessStatuses,
  Report,
  StatusTypes,
  CurrentUser as User,
  getChargeAssessmentDecision,
} from '@dashboard-experience/utils';
import { useGetReportSettings } from 'components/AccountSettings';
import React, { useCallback, useMemo } from 'react';
import { GenericObject } from 'types';
import { getLicenseReportItems, getViolationItems } from './Helpers';

type ReportProps = {
  licenseReportOrCharge: GenericObject;
  assessEnabled: boolean;
  currentUser: User;
  report: Report;
  panelType: string;
};

type RulesProps = {
  rules: GenericObject[];
};

const Title = () => {
  return (
    <span>
      <span style={{ marginRight: '0.5rem' }}>Additional information </span>
      <M.TooltipDefinition
        role='additionalInfoTooltip'
        align='top'
        definition='This information is not part of the consumer report and is provided for context only.'
        style={{
          display: 'inline',
          verticalAlign: 'middle',
        }}
      >
        <M.Icon icon='HelpFilled' />
      </M.TooltipDefinition>
    </span>
  );
};

const RulesList: React.FC<RulesProps> = ({ rules }) => {
  return (
    <ul>
      {rules.map((rule: GenericObject) => {
        return (
          <li data-testid='customer-rule-activated-value' key={rule.name}>
            {rule.name}
          </li>
        );
      })}
    </ul>
  );
};

const AdditionalInfoPanel: React.FC<ReportProps> = ({
  licenseReportOrCharge,
  report,
  assessEnabled,
  currentUser,
  panelType,
}) => {
  const { show_additional_charge_information: showAdditionalInfo } =
    useGetReportSettings(report.account);

  // Fall back to displaying 'review' (for reports that are not using decision aliases)
  const status =
    getChargeAssessmentDecision(licenseReportOrCharge, report, currentUser) ||
    AssessStatuses.REVIEW;

  const rules = licenseReportOrCharge?.assessment?.display?.rules;

  const categoryName =
    licenseReportOrCharge?.assessment?.display?.category_name;

  const assessMvrEnabled = report.account?.assess_mvr_enabled;

  const assessItems = useMemo(
    () => [
      {
        itemKey: 'Customer assessment:',
        itemValue: (
          <M.StatusBadge
            data-testid='customer-assessment-status-badge'
            statusType={StatusTypes.Assess}
            status={status}
          />
        ),
      },
    ],
    [status],
  );

  const rulesItems = useMemo(
    () => [
      {
        itemKey: 'Customer rule activated:',
        itemValue: <RulesList rules={rules} />,
      },
    ],
    [rules],
  );

  const ruleCategoryName = useMemo(
    () => [
      {
        itemKey: 'Category name:',
        itemValue: categoryName,
      },
    ],
    [categoryName],
  );

  const groupItems = useMemo(
    () => [
      {
        itemKey: 'Group',
        itemValue: licenseReportOrCharge.group,
      },
    ],
    [licenseReportOrCharge],
  );

  const getMVRItems = useCallback(() => {
    switch (panelType) {
      case 'licenseReport':
        return getLicenseReportItems(licenseReportOrCharge) as {
          itemKey: string | null;
          itemValue: any;
        }[];
      case 'violations':
        return getViolationItems(licenseReportOrCharge, assessMvrEnabled);
      case 'accidents':
        return groupItems;
      case 'suspensions':
        return groupItems;
      default:
        return [];
    }
  }, [panelType, groupItems, licenseReportOrCharge, assessMvrEnabled]);

  const mvrItems = getMVRItems();
  const showCondensedItems = licenseReportOrCharge.assessment && assessEnabled;
  const jointAssessAndRulesItems = useMemo(
    () => assessItems.concat(rulesItems),
    [assessItems, rulesItems],
  );
  const mvrAssessItems = mvrItems.concat(jointAssessAndRulesItems);
  const condensedItems = categoryName
    ? mvrAssessItems.concat(ruleCategoryName)
    : mvrAssessItems;

  if (!showAdditionalInfo) {
    return null;
  }

  return (
    <M.AdditionalInfo
      data-testid='additional-info-box'
      title={<Title />}
      items={!showCondensedItems ? mvrItems : condensedItems}
      type='shadow'
      style={{ marginTop: '1rem' }}
    />
  );
};

export default AdditionalInfoPanel;
