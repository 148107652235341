import React from 'react';
import isEqual from 'lodash/isEqual';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { usePreference } from 'api/dashboardPreferences';

import { View } from '../../state/types';
// import ColumnsButton from './columns-button';
import DownloadButton from './download-button';
import FiltersButton from './filters-button';
import ResetButton from './reset-button';
import SaveButton from './save-button';
import SearchBar from './search-bar';
import './styles.scss';

type Props = {
  isLoading: boolean;
  view: View;
  reports?: GenericObject;
};

const ActionsBar: React.FC<Props> = ({ isLoading, view, reports }) => {
  // TODO: get savedView from the backend
  const [savedView] = usePreference('searchViews');

  // Filters must match excluding page, Columns much match exactly
  const canSave =
    !savedView ||
    !isEqual({ ...savedView.filters, page: 1 }, { ...view.filters, page: 1 });

  return (
    <>
      {view && (
        <div className='reports-list-actions-flex-container'>
          <div className='reports-list-actions-search-bar'>
            <SearchBar />
          </div>

          <div className='reports-list-actions-flex-row'>
            <div className='reports-list-actions-left'>
              <FiltersButton isLoading={isLoading} />
              {/* <ColumnsButton /> */}
              {canSave && (
                <>
                  <div className='reports-list-actions-divider' />
                  <SaveButton view={view} isLoading={isLoading} />
                  <ResetButton savedView={savedView} />
                </>
              )}
            </div>
            <div className='reports-list-actions-right'>
              {isLoading && <M.LoadingInline description='Searching...' />}
              <DownloadButton isLoading={isLoading} reports={reports} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionsBar;
