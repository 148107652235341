export enum Screens {
  DisqualifyConfirmation = 'DisqualifyConfirmation',
  Subtypes = 'Subtypes',
  AdverseItems = 'AdverseItems',
  FairChanceForm = 'FairChanceForm',
  FairChance = 'FairChance',
  PreviewEmail = 'PreviewEmail',
  ConfigOptions = 'ConfigOptions',
  Confirm = 'Confirm',
  SuccessMessage = 'SuccessMessage',
}

type TransitionState = {
  transitions: {
    [action: string]: Screens;
  };
};

type TransitionStates = {
  [K in Screens]: TransitionState;
};

const preAAtransitionStates: TransitionStates = {
  [Screens.DisqualifyConfirmation]: {
    transitions: {
      adverse_items: Screens.AdverseItems,
      subtypes: Screens.Subtypes,
    },
  },
  [Screens.Subtypes]: {
    transitions: {
      next: Screens.AdverseItems,
    },
  },
  [Screens.AdverseItems]: {
    transitions: {
      subtypes: Screens.Subtypes,
      fair_chance_form: Screens.FairChanceForm,
      fair_chance: Screens.FairChance,
      preview_email: Screens.PreviewEmail,
      config_options: Screens.ConfigOptions,
    },
  },
  [Screens.ConfigOptions]: {
    transitions: {
      adverse_items: Screens.AdverseItems,
      fair_chance: Screens.FairChance,
      fair_chance_form: Screens.FairChanceForm,
      preview_email: Screens.PreviewEmail,
    },
  },
  [Screens.FairChance]: {
    transitions: {
      adverse_items: Screens.AdverseItems,
      config_options: Screens.ConfigOptions,
      fair_chance_form: Screens.FairChanceForm,
      preview_email: Screens.PreviewEmail,
    },
  },
  [Screens.FairChanceForm]: {
    transitions: {
      adverse_items: Screens.AdverseItems,
      config_options: Screens.ConfigOptions,
      fair_chance: Screens.FairChance,
      preview_email: Screens.PreviewEmail,
    },
  },
  [Screens.PreviewEmail]: {
    transitions: {
      adverse_items: Screens.AdverseItems,
      config_options: Screens.ConfigOptions,
      fair_chance: Screens.FairChance,
      fair_chance_form: Screens.FairChanceForm,
      previous: Screens.AdverseItems,
      next: Screens.Confirm,
    },
  },
  [Screens.Confirm]: {
    transitions: {
      previous: Screens.PreviewEmail,
      next: Screens.SuccessMessage,
    },
  },
  // TODO: Verify if success selector is needed
  [Screens.SuccessMessage]: {
    transitions: {},
  },
};

export default preAAtransitionStates;
