import { colors } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { useGetActiveEnrollmentsCount } from 'api/continuousChecks';
import PostHireContainer from 'containers/PostHire/PostHireContainer';
import useHasPostHire from 'containers/PostHire/useHasPostHire';
import { useUser } from 'context/CurrentUser';
import { namespace } from 'modules/candidate/locales';
import { useCandidate } from 'providers/Candidate';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Content from '../content';
import EntriesPhaseTwo from '../entries-phase-two';

const NotificationDot = styled.span<{ active: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${({ active }) =>
    active ? colors.uiGreen500 : colors.uiGrey300};
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 1px;
`;

type TabLabel = {
  title: string;
  statusIndicator?: React.ReactNode;
  activeEnrollmentsCount?: number;
};

type Tab = {
  label: TabLabel;
  content: React.ReactNode;
};

const trans_path = `${namespace}:report.tabs`;

const useGetTabs = (): Tab[] => {
  const user = useUser();
  const { t } = useTranslation();
  const canViewReportLogs = hasPermission(user, 'read_report_logs');
  const hasPostHire = useHasPostHire();
  const candidate = useCandidate();

  const activeEnrollmentsCount = useGetActiveEnrollmentsCount(candidate.id);

  const resultsTab = useMemo(
    () => ({
      label: { title: t(`${trans_path}.results`) },
      content: <Content />,
    }),
    [t],
  );

  const reportLogTab = useMemo(
    () => ({
      label: { title: t(`${trans_path}.report-log`) },
      content: <EntriesPhaseTwo />,
    }),
    [t],
  );

  const postHireTab = useMemo(
    () => ({
      label: {
        title: t(`${trans_path}.post-hire`),
        statusIndicator: <NotificationDot active={!!activeEnrollmentsCount} />,
        activeEnrollmentsCount,
      },
      content: (
        <div style={{ padding: '32px 40px' }}>
          <PostHireContainer />
        </div>
      ),
    }),
    [t, activeEnrollmentsCount],
  );

  return useMemo(
    () => [
      resultsTab,
      ...(canViewReportLogs ? [reportLogTab] : []),
      ...(hasPostHire ? [postHireTab] : []),
    ],
    [resultsTab, reportLogTab, postHireTab, canViewReportLogs, hasPostHire],
  );
};

export default useGetTabs;
