import React, { useState, useCallback, useEffect } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ADJUDICATION_VALUES } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import {
  useAdverseActionable,
  useAdjudicable,
  useGetReport,
  useReportPropertiesForAmplitudeEvents,
} from 'api/reports';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';
import Modal from '../../../adverse-action/initiate/flow';

const { PRE_ADVERSE_ACTION, POST_ADVERSE_ACTION } = ADJUDICATION_VALUES;
const HIDEABLE_ERRORS = [
  /clearinghouse report/i,
  /report must not be (canceled|pending|suspended)/i,
  /report.*consider.*(result|status)/i,
  /report.*eligible.*assessment/i,
  /requires.*adverse item/i,
  /screenings.*canceled/i,
];
const isHideableError = (error: string) => {
  return HIDEABLE_ERRORS.some((regex) => regex.test(error));
};

const PreAAButton = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    trackEvent(
      CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
      { Action: 'Pre Adverse Action', Report: reportProperties },
    );
    // TODO: remove once customer is standalone app
    scrollToTop();
  }, [reportProperties, trackEvent]);

  // TODO: Follow up on error handling
  const { adverseActionable, isLoading } = useAdverseActionable();
  const { isDisqualifiable } = useAdjudicable();
  const text = t(`${namespace}:report.actions.pre_adverse.action`);

  const { enabled, errors } = adverseActionable;

  const hideButton =
    report?.adjudication === PRE_ADVERSE_ACTION ||
    report?.adjudication === POST_ADVERSE_ACTION ||
    report?.archived ||
    isDisqualifiable ||
    (!enabled && (!errors?.length || errors?.some(isHideableError))) ||
    false;

  useEffect(() => {
    if (hideButton) {
      setOpen(false);
    }
  }, [hideButton]);

  if (isLoading && !isDisqualifiable)
    return (
      <M.LoadingInline style={{ marginRight: '1rem', marginLeft: '1rem' }} />
    );
  if (hideButton) return null;

  return (
    <>
      <Button disabled={!enabled} errors={errors} onClick={handleOpen}>
        {text}
      </Button>
      {open && <Modal onClose={handleClose} open={open} />}
    </>
  );
};

interface ButtonProps {
  disabled: boolean;
  errors: string[] | null;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  errors,
  onClick,
}) => {
  const button = (
    <M.Button
      data-testid='pre-adverse-action-button'
      disabled={disabled}
      kind='secondary-destructive'
      onClick={onClick}
    >
      {children}
    </M.Button>
  );

  if (disabled && errors?.length) {
    const tooltip = `${errors.join('. ')}.`;
    return (
      <M.Tooltip align='left' highlighted={false} label={tooltip}>
        {button}
      </M.Tooltip>
    );
  }

  return button;
};

export default PreAAButton;
