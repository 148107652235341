import { useMemo } from 'react';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import findLast from 'lodash/findLast';
import { toastError, toastSuccess } from 'actions';
import { adjudicationClient } from 'modules/case-management/api/clients';
import { TASKS_PATH, USERS_PATH } from 'modules/case-management/api/paths';
import TaskStatusSlugs from 'modules/case-management/utilities/task/Status';
import { TaskType } from 'modules/case-management/types';
import { Report } from 'types';
import { useCanViewCaseManagement } from 'modules/report';

export type TaskSearchParams = {
  assignee_id?: string;
  page?: string;
  report_assessment?: string;
  report_id?: string;
  report_package?: string;
  sort?: string;
  status?: string;
  task_type?: string;
};

export const useTasks = (params: TaskSearchParams, enabled: boolean = true) => {
  const key: AnyQueryKey = ['cm/tasks', params];
  const request = () => adjudicationClient.get(TASKS_PATH, { params });

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled,
  });
};

export type LastReportTaskParams = {
  report: Report;
};

export const useLastReportTask = ({
  report,
}: LastReportTaskParams): TaskType | undefined => {
  const enabled = useCanViewCaseManagement(report) && !!report.id;

  const report_id = report?.id;
  const { data } = useTasks({ report_id }, enabled);
  const tasks = data?.data || [];

  return findLast(
    tasks,
    (task: TaskType) => task.status.slug !== TaskStatusSlugs.CLOSED,
  );
};

export const useTaskSearchParams = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const reportAssessment = searchParams.get('report_assessment');
  const reportPackage = searchParams.get('report_package');
  const assigneeId = searchParams.get('assignee_id');
  const page = searchParams.get('page') || '1';
  const sort = searchParams.get('sort');
  const taskType = searchParams.get('task_type');
  const taskParams: TaskSearchParams = {
    page,
    status: 'open,in_progress',
  };
  if (assigneeId) {
    taskParams.assignee_id = assigneeId;
  }
  if (reportAssessment) {
    taskParams.report_assessment = reportAssessment;
  }
  if (reportPackage) {
    taskParams.report_package = reportPackage;
  }
  if (sort) {
    taskParams.sort = sort;
  }
  if (taskType) {
    taskParams.task_type = taskType;
  }

  return taskParams;
};

export const useUsers = () => {
  const key: AnyQueryKey = [TASKS_PATH];
  const request = () => adjudicationClient.get(USERS_PATH);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};

export const useSavedFilters = () => {
  const key: AnyQueryKey = ['cm/tasks/saved_filters'];
  const request = () => adjudicationClient.get(`${TASKS_PATH}/saved_filters`);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
  });
};

type TaskTypeCountData = {
  [key: string]: number;
};

export const useGetTaskTypeCounts = () => {
  const key: AnyQueryKey = ['cm/tasks/task_type_counts'];
  const request = () =>
    adjudicationClient.get(`${TASKS_PATH}/task_type_counts`);

  return useQuery<TaskTypeCountData, AnyQueryKey, Error>(key, request, {
    refetchOnWindowFocus: false,
  });
};

export const useUpdateTask = (successCallback: { (response: any): void }) => {
  const dispatch = useDispatch();
  const request = (params: { taskID: string; assigneeID: string | null }) =>
    adjudicationClient.put(`${TASKS_PATH}/${params.taskID}`, {
      assignee_id: params.assigneeID,
    });
  const [call, result] = useMutation(request, {
    onSuccess: (data: any) => {
      queryCache.invalidateQueries('cm/tasks/saved_filters');
      const assigned = !!data.assignee;
      successCallback(data);
      dispatch(
        toastSuccess(
          `Task successfully ${assigned ? 'assigned' : 'unassigned'}`,
        ),
      );
    },
    onError: () => {
      dispatch(toastError('Unable to reassign task'));
    },
  });
  return { call, result };
};

export const useUpdateTasks = () => {
  const dispatch = useDispatch();
  const request = (tasks: any) => {
    return adjudicationClient.patch(`${TASKS_PATH}`, {
      tasks,
    });
  };
  const [call, result] = useMutation(request, {
    onSuccess: (data: any) => {
      window.location.reload();
      dispatch(toastSuccess(`Tasks assignee successfully updated`));
    },
    onError: () => {
      dispatch(toastError('Unable to reassign task'));
    },
  });
  return { call, result };
};
