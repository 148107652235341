import React, { useCallback, useState, useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import CustomProvider from 'state/provider';
import UIContext from 'context/UI';
import {
  useGetCandidateExceptions,
  useGetDashboardCandidate,
} from 'api/candidate';
import { usePreference } from 'api/dashboardPreferences';
import { PiiRemovedBanner } from 'components/Candidate/Banners/PiiRemovedBanner/PiiRemovedBanner';
import { Tabs } from 'modules/report';
import CandidateContext from './context';
import { Context, IframeWindow } from './types';
import { useSetWindowTitle } from './hooks';

type Props = {
  candidateId: string;
  components: Array<string>;
};

export const CandidateCustomProvider: React.FC<Context> = ({
  candidateExceptions,
  candidateId,
  candidate,
  components,
  showReportLog,
  setShowReportLog,
  iframeWindow,
  setIframeWindow,
  reportTabs,
  pinnedFields,
  setPinnedFields,
  children,
}) => (
  <CustomProvider
    context={CandidateContext}
    props={{
      candidateExceptions,
      candidateId,
      candidate,
      components,
      showReportLog,
      setShowReportLog,
      iframeWindow,
      setIframeWindow,
      reportTabs,
      pinnedFields,
      setPinnedFields,
    }}
    stateKey='candidate'
  >
    {children}
  </CustomProvider>
);

// show loading state, only provide candidate if it exists
const LoadingProvider: React.FC<Props> = ({
  candidateId,
  components,
  children,
}) => {
  const { candidate, isLoading, isError, error } =
    useGetDashboardCandidate(candidateId);
  const { isIframe, isStandaloneIframe } = React.useContext(UIContext);
  const [showReportLog, setShowReportLog] = useState(false);
  const [iframeWindow, setIframeWindow] = useState<IframeWindow>(null);
  const [tab, setTab] = useState(Tabs.BACKGROUND_REPORT);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [customerViewPreference, setCustomerViewPreference] =
    usePreference('customerView');
  const [customerView, setCustomerViewState] = useState(customerViewPreference);
  const setCustomerView = (newPreference: any) => {
    setCustomerViewState(newPreference);
    setCustomerViewPreference(newPreference);
  };

  useSetWindowTitle(candidate);

  const disabled =
    isIframe &&
    !isStandaloneIframe &&
    !components.includes('candidate_exceptions');
  const candidateExceptions = useGetCandidateExceptions(candidateId, disabled);

  const reportTabs = useMemo(
    () => ({
      tab,
      setTab,
      selectedIndex,
      setSelectedIndex,
      customerView,
      setCustomerView,
    }),
    [
      tab,
      setTab,
      customerView,
      selectedIndex,
      setSelectedIndex,
      setCustomerView,
    ],
  );

  const [pinnedFieldsPreference, setPinnedFieldsPreference] =
    usePreference('pinnedFields');
  const [pinnedFields, setPinnedFieldsState] = useState(
    pinnedFieldsPreference || [],
  );
  const setPinnedFields = useCallback(
    (newPreference: any) => {
      setPinnedFieldsState(newPreference);
      setPinnedFieldsPreference(newPreference);
    },
    [setPinnedFieldsState, setPinnedFieldsPreference],
  );

  // TODO: Should we abstract away this loading/error pattern elsewhere for reuse
  if (isLoading) {
    return <M.LoadingSpinner />;
  }

  // Special case for PII deletion. Anyone who ISN'T an Internal Admin receives this unique status code
  if (error?.response?.status === 409) {
    // And in that scenario, the ONLY thing that shows in the report page is the PII removal banner
    return <PiiRemovedBanner />;
  }

  if (isError || !candidate?.id) {
    return (
      <M.InlineNotification
        hideCloseButton
        kind='error'
        title='Error fetching candidate'
        subtitle='Please try again later'
      />
    );
  }

  return (
    <CandidateCustomProvider
      candidateExceptions={candidateExceptions}
      candidateId={candidateId}
      candidate={candidate}
      components={components}
      showReportLog={showReportLog}
      setShowReportLog={setShowReportLog}
      iframeWindow={iframeWindow}
      setIframeWindow={setIframeWindow}
      reportTabs={reportTabs}
      pinnedFields={pinnedFields}
      setPinnedFields={setPinnedFields}
    >
      {children}
    </CandidateCustomProvider>
  );
};

const CandidateProvider: React.FC<Props> = ({
  candidateId,
  components,
  children,
}) => {
  return (
    <LoadingProvider candidateId={candidateId} components={components}>
      {children}
    </LoadingProvider>
  );
};

export default CandidateProvider;
