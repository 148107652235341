import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const Container = styled.div.attrs({
  className: 'order-summary-container',
})`
  display: flex;
  width: 33.3%;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  background: ${colors.brandSlate1};
  min-height: 400px;
  border-radius: 6px;

  @media (max-width: 992px) {
    width: 50%;
  }

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const Content = styled.div.attrs({
  className: 'order-summary-content',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.borderPrimaryLight};
  }
`;

export const SectionTitle = styled.span.attrs({
  className: 'order-summary-section-title p4',
})`
  color: ${colors.uiTextTertiaryLight};
  display: flex;
`;

export const SectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Screening = styled.h5.attrs({
  className: 'order-summary-screening mb-0',
})`
  color: ${colors.uiTextSecondaryLight} !important;
`;

type ScreeningPriceProps = { newPrice?: boolean; discounted?: boolean };

export const ScreeningPrice = styled.p.attrs<ScreeningPriceProps>(
  ({ discounted }) => ({
    className: `p3 ${
      discounted
        ? 'order-summary-screening-price-discounted'
        : 'order-summary-screening-price'
    }`,
  }),
)<ScreeningPriceProps>`
  color: ${({ discounted = false }) =>
    discounted ? colors.uiGreen600 : colors.uiGrey900} !important;
  font-weight: 700 !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;

  &::after {
    content: ${({ discounted = false }) => (discounted ? 'none' : "'*'")};
    color: ${colors.uiTextDisabledLight};
    padding-left: 1px;
  }
`;

export const StrikethroughPrice = styled(ScreeningPrice)`
  color: ${colors.uiTextTertiaryLight} !important;
  text-decoration: line-through;
  margin-right: 4px;

  &::after {
    content: none;
  }
`;

export const DiscountedPriceContainer = styled.div`
  display: flex;
`;

export const EmptyPrice = styled.span`
  color: ${colors.uiTextDisabledLight};
`;

export const NoneAdded = styled.h5.attrs({
  className: 'order-summary-none-added mb-0',
})`
  color: ${colors.uiTextDisabledLight} !important;
`;

export const PromoCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  #mastodon && {
    .mastodon-text-input {
      margin-bottom: 0px !important;
    }

    .mastodon-button.tertiary:focus:enabled {
      border: none !important;
      box-shadow: none !important;
    }
  }
`;

export const PromoCodeTextInput = styled(M.TextInput)`
  width: 100% !important;

  .cds--text-input {
    height: 36px !important;
  }
`;

export const PromoCodeApplyButton = styled(M.Button)`
  padding: 6px;
`;

export const PromoCodeBannerContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const PromoCodeWithClear = styled.div`
  display: flex;
  height: 36px;
  padding: 8px 16px;
  width: 100%;
  color: ${colors.uiGrey700}
  line-height: 36px;
  border-bottom: 1px solid ${colors.uiGrey500};
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
`;

export const PromoCodeClearIcon = styled(M.Icon)`
  color: ${colors.uiGrey900};
`;
