import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledSubNote = styled.div<{ $showBorder?: boolean }>`
  border-top: ${({ $showBorder }) =>
    $showBorder ? `1px solid ${colors.uiTextPrimaryLight}` : 'none'};
  padding-top: 16px;
`;

export const StyledSubNoteText = styled.div`
  color: ${colors.uiTextPrimaryLight};
  line-height: 16px;
  font-size: 12px;
`;

export const StyledLink = styled(M.Link)`
  font-size: 12px;
`;

export const StyledPricingContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: end;
  margin: 1.5rem 0 calc(1.5rem - 5px) 0;
`;

export const StyledItemPricing = styled.p`
  color: ${colors.uiTextPrimaryLight};
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
`;

export const StyledSubtotalPricing = styled.p`
  color: ${colors.uiTextPrimaryLight};
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
`;

export const StyledLoaderStyled = styled(M.LoadingSpinner)`
  margin: 0 auto;
  vertical-align: center;
`;

export const StyledPromoCodeInputContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 9px;
  display: flex;
  gap: 16px;
  align-items: flex-end;
  width: 248px;
  justify-content: space-between;

  .cds--btn {
    height: 36px !important;
    margin-bottom: 8px !important;
  }

  .cds--text-input {
    height: 36px !important;
  }
`;

export const StyledPromoCodeBannerContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const StyledAppliedPromoCode = styled.div`
  display: flex;
  height: 36px;
  padding: 8px 16px;
  width: 100%;
  color: ${colors.uiGrey700}
  line-height: 36px;
  border-bottom: 1px solid ${colors.uiGrey500};
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
`;
