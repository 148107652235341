import { useCallback } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  GenericObject,
  hasPermission,
  getParamFromUrl,
} from '@dashboard-experience/utils';
import { SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY } from 'Constants';
import { useGetConsentLink } from 'api/documents';
import { getUniqueReports, getInternationalReportPdfButtonText } from 'utils';
import { useReport } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import useGetDocuments from './useGetDocuments';
import CreditReportUtils from '../../../../../utilities/CreditReportUtils';

const PDFS = [
  'pdf_report_encrypted',
  'pdf_report',
  'pdf_health_report',
  'pdf_credit_report',
  'pdf_wisconsin_doj',
  'pdf_state_criminal_search',
  'pdf_self_disclosure',
  'pa_child_abuse_result_certificate',
  'dot_employment_authorization',
  'pdf_international_report',
  'pdf_professional_license_verification_report',
  'pdf_drug_alcohol_clearinghouse_report',
  'order_summary_pdf',
  'cdlis_report',
];

const useGetPdfItems = () => {
  const report = useReport();
  const currentUser = useUser();
  const pdfItems = [] as GenericObject[];

  const isDocQa = useCallback(() => {
    const requestedDocView =
      getParamFromUrl(window, 'view') === 'doc_view' &&
      hasPermission(currentUser, 'doc_view');
    const enforcedDocView =
      hasPermission(currentUser, 'doc_view') &&
      !hasPermission(currentUser, 'read_full_reports');
    return requestedDocView || enforcedDocView;
  }, [currentUser]);

  const {
    pdfReport,
    pdfReportEncrypted,
    pdfHealthReport,
    pdfWisconsinDoj,
    pdfStateCriminalSearch,
    pdfCreditReport,
    pdfSelfDisclosure,
    dotEmploymentAuthorization,
    pdfInternationalReport,
    paChildAbuseResultCertificate,
    pdfDrugAlcoholClearinghouseReport,
    pdfProfessionalLicenseVerificationReport,
    orderSummaryPdf,
    cdlisReport,
    // @ts-ignore
  } = useGetDocuments(PDFS, report);

  const isPdfReport = pdfReportEncrypted || pdfReport;

  const { consentLink = null, internationalConsentLink = null } =
    useGetConsentLink(report.id, isDocQa(), currentUser);

  const selfDislosureAvailableWhilePending =
    useFlag(SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY)
      ?.variantKey === 'enabled';

  const creditReportUtils = new CreditReportUtils(report);

  if (hasPermission(currentUser, 'read_reports') && consentLink) {
    pdfItems.push({
      link: consentLink,
      text: 'Download Consent',
      docType: 'consent',
    });
  }

  if (hasPermission(currentUser, 'read_reports') && internationalConsentLink) {
    pdfItems.push({
      link: internationalConsentLink,
      text: 'Download International Consent',
      docType: 'consent',
    });
  }

  if (isPdfReport) {
    pdfItems.push({
      link: isPdfReport.download_uri,
      text: 'Report PDF',
      type: isPdfReport.type,
      isPdfReport: true,
      disabled: report.status === 'pending',
    });
  }

  if (pdfHealthReport) {
    pdfItems.push({
      link: pdfHealthReport.download_uri,
      text: 'Health Screening PDF',
      docType: 'health_report',
      disabled: report.status === 'pending',
    });
  }

  if (pdfWisconsinDoj) {
    pdfItems.push({
      link: pdfWisconsinDoj.download_uri,
      text: 'Health Screening PDF',
      docType: 'wisconsin_doj',
      disabled: report.status === 'pending',
    });
  }

  if (pdfStateCriminalSearch) {
    pdfItems.push({
      link: pdfStateCriminalSearch.download_uri,
      text: 'State Criminal PDF',
      docType: 'state_criminal_search',
      disabled: report.status === 'pending',
    });
  }

  if (creditReportUtils.isInternationalHasNonEmptyRecords()) {
    const internationalCreditRecord =
      creditReportUtils.findInternationalRecords();

    pdfItems.push({
      link: internationalCreditRecord.pdf_url,
      text: 'Credit Report PDF',
      docType: 'credit_report',
    });
  }

  if (pdfCreditReport) {
    pdfItems.push({
      link: pdfCreditReport.download_uri,
      text: 'Credit Report PDF',
      docType: 'credit_report',
    });
  }

  if (
    pdfSelfDisclosure &&
    (selfDislosureAvailableWhilePending || report.status !== 'pending')
  ) {
    pdfItems.push({
      link: pdfSelfDisclosure.download_uri,
      text: 'Self Disclosure PDF',
      docType: 'self_disclosure',
    });
  }

  if (dotEmploymentAuthorization) {
    pdfItems.push({
      link: dotEmploymentAuthorization.download_uri,
      text: 'DOT Employment Authorizations',
      docType: 'dot_emplooyment_authorization',
      disabled: report.status === 'pending',
    });
  }

  if (pdfInternationalReport) {
    if (Array.isArray(pdfInternationalReport)) {
      let localeDocCount = 0;
      let nullLocalDocCount = 0;
      const reportData = getUniqueReports(pdfInternationalReport);
      reportData.forEach((pdfReport: any) => {
        const docLocale = pdfReport?.locale ? pdfReport.locale : null;
        const buttonText = getInternationalReportPdfButtonText(pdfReport);
        if (docLocale) {
          pdfItems.push({
            link: pdfReport.download_uri,
            text: buttonText,
            docType: 'international_report',
            disabled: report.status === 'pending',
          });
          localeDocCount += 1;
        } else if (localeDocCount === 0 && nullLocalDocCount === 0) {
          pdfItems.push({
            link: pdfReport.download_uri,
            text: buttonText,
            docType: 'international_report',
            disabled: report.status === 'pending',
          });
          nullLocalDocCount += 1;
        }
      });
    } else {
      pdfItems.push({
        link: pdfInternationalReport.download_uri,
        text: getInternationalReportPdfButtonText(pdfInternationalReport),
        docType: 'international_report',
        disabled: report.status === 'pending',
      });
    }
  }

  if (paChildAbuseResultCertificate) {
    pdfItems.push({
      link: paChildAbuseResultCertificate.download_uri,
      text: 'PA State Registry Certificate',
      docType: 'pdf_pa_child_abuse_certificate',
    });
  }

  if (pdfDrugAlcoholClearinghouseReport) {
    pdfItems.push({
      link: pdfDrugAlcoholClearinghouseReport.download_uri,
      text: 'Drug and Alcohol Clearinghouse Report PDF',
      docType: 'pdf_drug_alcohol_clearinghouse_report',
      disabled: report.status === 'pending',
    });
  }

  if (pdfProfessionalLicenseVerificationReport) {
    pdfItems.push({
      link: pdfProfessionalLicenseVerificationReport.download_uri,
      text: 'Professional License Report PDF',
      docType: 'professional_license_verification_report',
      disabled: report.status === 'pending',
    });
  }

  if (orderSummaryPdf) {
    pdfItems.push({
      link: orderSummaryPdf.download_uri,
      text: 'Order Summary PDF',
      docType: 'order_summary_pdf',
      disabled: report.status === 'pending',
    });
  }

  if (cdlisReport) {
    pdfItems.push({
      link: cdlisReport.download_uri,
      text: 'CDLIS Report PDF',
      docType: 'cdlis_report',
      disabled: report.status === 'pending',
    });
  }

  const showPdfEncryptionNote: boolean =
    isPdfReport && isPdfReport.type === 'pdf_report_encrypted';

  return { pdfItems, showPdfEncryptionNote };
};
export default useGetPdfItems;
