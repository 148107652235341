import { useFlag } from '@dashboard-experience/react-flagr';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { SHOW_REPORT_SUMMARY, SHOW_ADDITIONAL_CHARGE_INFORMATION } from 'Flags';
import { namespace } from 'modules/candidate/locales';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionLabel } from '../styles';
import ShowReportSummary from './show-report-summary';
import ShowResolvedExceptions from './show-resolved-exceptions';
import ShowAdditionalChargeInformation from './show-additional-charge-information';

const ResultsPage = () => {
  const { t } = useTranslation();
  const showReportSummary = useFlag(SHOW_REPORT_SUMMARY)?.variantKey === 'on';
  const showAdditionalChargeInformation =
    useFlag(SHOW_ADDITIONAL_CHARGE_INFORMATION)?.variantKey === 'on';

  const currentUser = useUser();

  const label = t(`${namespace}:report.settings.menu.results-page.label`);

  const canManageAccountSettings = hasPermission(
    currentUser,
    'manage_account_settings',
  );

  return (
    <>
      <SectionLabel>{label}</SectionLabel>
      {showReportSummary && canManageAccountSettings && <ShowReportSummary />}
      {showAdditionalChargeInformation && canManageAccountSettings && (
        <ShowAdditionalChargeInformation />
      )}
      <ShowResolvedExceptions />
    </>
  );
};

export default ResultsPage;
