import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  Ref,
} from 'react';
import UIContext from 'context/UI';
import { scrollToTopIfIframe } from 'utils';
import { useIframeWindow, IframeWindow } from 'providers/Candidate';

type ContentContainerProps = {
  children: React.ReactNode;
  setIsScrolled?: (scrolled: boolean) => void;
  isScrolled?: boolean;
  showReportLog?: boolean;
};

const calculateHeight = (
  ref: Ref<HTMLDivElement>,
  iframeWindow: IframeWindow,
) => {
  // @ts-ignore
  const rect = ref?.current?.getBoundingClientRect();
  const scrollY = iframeWindow?.scrollY ? iframeWindow.scrollY : window.scrollY;
  const innerHeight = iframeWindow?.innerHeight
    ? iframeWindow.innerHeight
    : window.innerHeight;

  const topPosition = (rect?.top || 0) + scrollY;
  const viewportHeight = innerHeight;

  return viewportHeight - topPosition;
};

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  setIsScrolled,
  isScrolled,
  showReportLog,
  ...props
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const { isIframe } = useContext(UIContext);
  const { iframeWindow } = useIframeWindow();

  useEffect(() => {
    if (showReportLog && componentRef.current) {
      if (window.self !== window.top) {
        scrollToTopIfIframe();
      } else {
        componentRef.current.scrollTop = 0;
      }
    }
  }, [showReportLog, componentRef]);

  const updateHeight = useCallback(() => {
    if (!componentRef.current) return;

    const newHeight = calculateHeight(componentRef, iframeWindow);

    if (newHeight === height) return;

    // 300 for candidate-header transition time, 1000 allows content load on initial page render
    const timeOut = isScrolled ? 300 : 1000;

    setTimeout(() => {
      const updatedHeight = calculateHeight(componentRef, iframeWindow);
      setHeight(updatedHeight);
    }, timeOut);
  }, [setHeight, isScrolled, iframeWindow]);

  const handleScroll = useCallback(() => {
    if (componentRef.current && setIsScrolled) {
      const { scrollTop } = componentRef.current;
      // approximate differrence between scrolled and unscrolled header
      setIsScrolled(scrollTop > 70);
    }
  }, [setIsScrolled]);

  useEffect(() => {
    if (!isIframe) {
      window.addEventListener('resize', updateHeight);

      if (componentRef.current) {
        componentRef.current.addEventListener('scroll', handleScroll);
      }
    }

    return () => {
      window.removeEventListener('resize', updateHeight);
      if (componentRef.current) {
        componentRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isIframe, updateHeight, handleScroll]);

  useEffect(() => {
    updateHeight();
  }, [isScrolled, updateHeight]);

  return (
    <div
      data-testid='candidate-content'
      {...props}
      ref={componentRef}
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height,
      }}
    >
      {children}
    </div>
  );
};

export default memo(ContentContainer);
