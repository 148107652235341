import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import moment from 'moment';
import {
  EventName,
  TimelineCell,
  Timestamp,
} from '../TimelineStyledComponents';
import { TimelineEvent } from '../types';

/**
 * @name getEventName
 * @function
 * @memberOf Communicator
 * @description Determines the human-friendly event name
 * @returns {string or null}
 * @param {object} timelineEvent - The timeline event object
 */
const getEventName = (timelineEvent: TimelineEvent) => {
  let eventName;
  if (timelineEvent.campaign_id === 'candidate_invitation') {
    eventName = `Invitation ${timelineEvent.event}`;
  } else if (timelineEvent.campaign_id === 'pre_adverse_action_notice') {
    eventName = `Adjudication: Pre-adverse action ${timelineEvent.event}`;
  } else if (timelineEvent.campaign_id === 'post_adverse_action_notice') {
    eventName = `Adjudication: Post-adverse action ${timelineEvent.event}`;
  } else if (timelineEvent.campaign_id === 'request_candidate_story') {
    const isInitialNotice = timelineEvent.extra_info?.[0]?.initial || false;
    eventName = `Candidate story ${
      isInitialNotice ? 'notice' : 'reminder'
    } sent`;
  }
  if (timelineEvent.source === 'sms') {
    eventName += ' via SMS';
  }

  return eventName;
};

const Communicator: React.FC<{
  timelineEvent: TimelineEvent;
}> = ({ timelineEvent }) => {
  return (
    <M.TableRow>
      <TimelineCell>
        <EventName>{getEventName(timelineEvent)}</EventName>
      </TimelineCell>
      {timelineEvent.source === 'sms' ? (
        <TimelineCell />
      ) : (
        <TimelineCell>Recipient: {timelineEvent.recipient}</TimelineCell>
      )}
      <TimelineCell>
        <Timestamp>{moment(timelineEvent.time).format('llll')}</Timestamp>
      </TimelineCell>
    </M.TableRow>
  );
};

export default Communicator;
