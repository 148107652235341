import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { localStorageFallback } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import AliasRadioSelection from './AliasRadioSelection';
import {
  StyledHeader,
  StyledDescription,
  StyledBold,
  StyledInfoContainer,
  StyledButtonContainer,
  StyledCheckboxContainer,
  StyledLoadingInline,
} from './AliasSettingSelection.styles';
import { namespace } from '../../locales';

interface CanUpdateAccountContentProps {
  aliasAutoEnableSetting: boolean;
  aliasEnabled: boolean;
  handleUpdateSettingClick: (aliasAutoEnableSetting: boolean) => void;
  onSave: () => void;
  isLoading?: boolean | string;
}

const CanUpdateAccountContent: React.FC<CanUpdateAccountContentProps> = ({
  aliasAutoEnableSetting,
  aliasEnabled,
  handleUpdateSettingClick,
  onSave,
  isLoading,
}) => {
  const { t } = useTranslation(namespace);
  const [isHideDefaultAliasChecked, setIsHideDefaultAliasChecked] =
    useState(false);

  const handleCheck = useCallback(() => {
    setIsHideDefaultAliasChecked(!isHideDefaultAliasChecked);
    localStorageFallback.setItem(
      'hideDefaultAliasModal',
      (!isHideDefaultAliasChecked).toString(),
    );
  }, [isHideDefaultAliasChecked]);

  return (
    <>
      <StyledInfoContainer>
        <StyledHeader className='h5'>
          {t('reviewAndSubmit.aliasSetting.header')}
        </StyledHeader>
        <StyledDescription>
          <p>
            {t('reviewAndSubmit.aliasSetting.description.you')}{' '}
            <StyledBold data-testid='alias-setting-selection-bold-text-1'>
              {aliasEnabled
                ? t('reviewAndSubmit.aliasSetting.description.added')
                : t('reviewAndSubmit.aliasSetting.description.removed')}
            </StyledBold>{' '}
            {t('reviewAndSubmit.aliasSetting.description.aliasSearches')}{' '}
            {aliasEnabled
              ? t('reviewAndSubmit.aliasSetting.description.to')
              : t('reviewAndSubmit.aliasSetting.description.from')}{' '}
            {t('reviewAndSubmit.aliasSetting.description.thisOrder')}
          </p>
          <p>{t('reviewAndSubmit.aliasSetting.description.changeDecision')}</p>
        </StyledDescription>
        <AliasRadioSelection
          aliasAutoEnableSetting={aliasAutoEnableSetting}
          setDefaultAlias={handleUpdateSettingClick}
          source='Modal'
        />
      </StyledInfoContainer>
      <StyledButtonContainer>
        {!isLoading && (
          <M.Button
            kind='secondary'
            onClick={onSave}
            data-testid='update-setting-button'
          >
            {t('reviewAndSubmit.aliasSetting.buttons.updateSetting')}
          </M.Button>
        )}
        {isLoading && (
          <StyledLoadingInline id='loading-button' description='Updating...' />
        )}
        <StyledCheckboxContainer>
          <M.Checkbox
            data-testid='hide-modal-checkbox'
            id='hide-modal-checkbox'
            kind='primary'
            labelText={
              <p
                style={{ paddingLeft: '12px' }}
                data-testid='hide-modal-checkbox-label'
              >
                {t('reviewAndSubmit.aliasSetting.buttons.dontShowAgain')}
              </p>
            }
            onChange={handleCheck}
            checked={isHideDefaultAliasChecked}
          />
        </StyledCheckboxContainer>
      </StyledButtonContainer>
    </>
  );
};

export default CanUpdateAccountContent;
