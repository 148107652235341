/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import AuthRoute from 'utils/AuthRoute';
import {
  accountHasPermission,
  hasPermission,
  useRumError,
} from '@dashboard-experience/utils';
import DeauthorizeContainer from 'containers/Oauth/DeauthorizeContainer';
import CaseManagement from 'modules/case-management/ui/router';
import ReportsListPage, {
  useReportsListRedesignPhaseOne,
} from 'modules/reports-list';
import DashboardLandingPage from './DashboardLandingPage';
import { AccountRoutes } from './Account';
import AnalyticsPage from './AnalyticsPage';
import { ManualOrderPage, NewInvitationPage } from './Invitations';
import BulkActionPage from './BulkActionPage';
import AdverseActionsPage from './AdverseActionsPage';
import Assess from './Assess';
import CandidatePage from './CandidatePage';
import { ContinuousServicesCsvExportsPage, CsvPage } from './Csv';
import FairChancePage from './FairChancePage';
import { BillingPage, BulkOrderHistorySinglePage } from './Billing';
import LogsRoutes from './Logs';
import NotFoundPage from './NotFoundPage';
import ReportPage from './ReportPage';
import SearchPage from './SearchPage';
import SegmentsPage from './SegmentsPage';
import TimelinePage from './TimelinePage';
import ContinuousServicesPage from './ContinuousServicesPage';
import { CreatePackagePage, EditPackagesPage, PackagesPage } from './Packages';
import ScreeningSettingPage from './ScreeningSettingPage';
import FormI9Page from './FormI9Page';
import OrderBackgroundCheckPage from './OrderBackgroundCheckPage';
import BetterOrderExperiencePage from './BetterOrderExperience/BetterOrderExperiencePage';
import {
  CHECKR_DIRECT_B_CLIENT_ID,
  CHECKR_DIRECT_WEBSITE_CLIENT_ID,
  CHECKR_GOODHIRE_WEBSITE_CLIENT_ID,
  I9_ALLOWED_USER_ROLES,
  OAUTH_URL,
  ORDER_REDESIGN_KEY,
  PARS_UI_COMPONENTS,
  DONT_SHOW_FORM_I9_FLAG_KEY,
} from '../Constants';
import DeauthorizedBannerPage from './DeauthorizedBannerPage';
import FormI9OrderPage from './FormI9OrderPage';
import { AdminSingleSignOnPage } from './Admin';
import { RandomizerCohortPage, RandomizerPage } from './RandomTestingProgram';
import { PARSPage } from './PARSProgram';

export const Router = () => {
  const user = useUser();
  const location = useLocation();

  const showReportsList = useReportsListRedesignPhaseOne();

  const isI9FormDisabled =
    useFlag(DONT_SHOW_FORM_I9_FLAG_KEY)?.variantKey === 'on';

  const showI9 =
    !isI9FormDisabled &&
    I9_ALLOWED_USER_ROLES.some(r => user?.roles?.includes(r));

  const showHealthScreeningProgams =
    user?.account?.health_screenings_random_testing_setup;
  const showParsPageFlag = useFlag(PARS_UI_COMPONENTS)?.variantKey === 'on';

  const showDashboardLandingPage = accountHasPermission(
    user,
    'is_self_service_account',
  );

  // There is a default permission `openid` which is true for all the users. So to make proper assesment we need to check if the user have manage_post_hire_drug as well as the openid permission making total upto 2.
  const hasOnlyPostHireDrugPermission =
    hasPermission(user, 'manage_post_hire_drug') &&
    !hasPermission(user, 'view_candidates_list');

  // Has update_billing permission, but not read_candidate_details, aka billing users but not admin/restricted admin et al
  const billingOnlyPermission =
    hasPermission(user, 'update_billing') &&
    !hasPermission(user, 'view_candidates_list');

  let initialRedirectPath;
  const addDataDogError = useRumError();

  if (hasOnlyPostHireDrugPermission) {
    initialRedirectPath = '/random_testing_program';
  } else if (showDashboardLandingPage) {
    initialRedirectPath = '/home';
  } else if (billingOnlyPermission) {
    initialRedirectPath = '/billing/invoices';
  } else if (hasPermission(user, 'view_candidates_list')) {
    initialRedirectPath = '/candidates';
  } else {
    initialRedirectPath = '/404';
    addDataDogError('User did not have a initialRedirectPath', { user });
  }

  const showNewOrderFlow =
    useFlag(ORDER_REDESIGN_KEY)?.variantKey === 'enabled';
  /**
   * For some reason (perhaps 3rd party auth flow?) users are getting routed to a path that begins with a double-slash instead of single
   * This will just strip that extra slash out and redirect them to the correct page, to avoid unhandled errors.
   */
  const { pathname, search } = window.location;
  if (pathname.startsWith('//')) {
    const fixedPath = pathname.substring(1);
    return <Redirect to={`${fixedPath}${search}`} />;
  }

  if (user?.account?.oauth_progress) {
    const account_oauth_progress = user.account.oauth_progress;

    if (account_oauth_progress) {
      try {
        localStorage.removeItem('currentUser');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      const { amplitude_experiment_bucket, step, client_id } =
        account_oauth_progress;

      const isFoxSignup =
        amplitude_experiment_bucket === 'ab-07-fox-signup-treatment' ||
        amplitude_experiment_bucket === 'ab-08-fox-v2-treatment';
      const isMissingPaymentMethod = step !== 'complete';
      const isGoodhire = client_id === CHECKR_GOODHIRE_WEBSITE_CLIENT_ID;

      if (isFoxSignup && isMissingPaymentMethod) {
        let pathname = '/signup/payment';

        if (isGoodhire) {
          pathname += '?isGoodhire=true';
        }

        return <Redirect to={{ pathname, search: location.search }} />;
      }

      if (!isFoxSignup && account_oauth_progress.step !== 'complete') {
        switch (account_oauth_progress.client_id) {
          case CHECKR_DIRECT_WEBSITE_CLIENT_ID:
            window.location.replace(`${OAUTH_URL}/checkr/signin`);
            return null;
          case CHECKR_DIRECT_B_CLIENT_ID:
            window.location.replace(`${OAUTH_URL}/checkr/signin`);
            return null;
          case CHECKR_GOODHIRE_WEBSITE_CLIENT_ID:
            window.location.replace(`${OAUTH_URL}/checkr-goodhire/signin`);
            return null;
          default:
            break;
        }
      }
    }
  }

  return (
    <Switch>
      <Redirect exact path='/' to={initialRedirectPath} />
      <Route // AccountRoutes handles permissions
        path='/account'
        component={AccountRoutes}
      />
      <Route
        exact
        path='/admin/:accountId/:accountName/single-sign-on'
        component={AdminSingleSignOnPage}
      />
      <Route exact path='/deauthorized' component={DeauthorizedBannerPage} />
      <AuthRoute
        exact
        path='/adverse_actions'
        component={AdverseActionsPage}
        authorized={
          hasPermission(user, 'perform_adjudication') &&
          (accountHasPermission(user, 'adverse_action_pause') ||
            !accountHasPermission(user, 'offline_adverse_action_enabled'))
        }
      />
      <AuthRoute
        exact
        path='/analytics/:category'
        component={AnalyticsPage}
        authorized={hasPermission(user, 'read_analytics')}
      />
      <Route exact path='/bulk_action/new' component={BulkActionPage} />
      <AuthRoute
        exact
        path='/invitations/new'
        component={
          showNewOrderFlow ? OrderBackgroundCheckPage : NewInvitationPage
        }
        authorized={
          user &&
          accountHasPermission(user, 'allow_dashboard_report_ordering') &&
          accountHasPermission(user, 'see_full_dashboard') &&
          hasPermission(user, 'manage_invitations')
        }
      />
      <AuthRoute
        exact
        path='/reports/new'
        component={ManualOrderPage}
        authorized={
          accountHasPermission(user, 'allow_dashboard_report_ordering') &&
          accountHasPermission(user, 'manual_orders_enabled') &&
          accountHasPermission(user, 'see_full_dashboard') &&
          hasPermission(user, 'create_manual_orders')
        }
      />
      <Route // LogsRoutes handles permissions
        path={['/logs', '/api_logs', '/webhook_logs']}
        component={LogsRoutes}
      />
      <Route exact path='/search' component={SearchPage} />
      <AuthRoute
        exact
        path='/candidates'
        // TODO: add flag to conditionally render reports lists
        component={SearchPage}
        authorized={!!user && !hasOnlyPostHireDrugPermission}
      />
      {/* Temporary path to test reports list progress */}
      {showReportsList && (
        <AuthRoute
          exact
          path='/reports-list'
          component={ReportsListPage}
          authorized={!!user && !hasOnlyPostHireDrugPermission}
        />
      )}
      <AuthRoute
        exact
        path='/home'
        component={DashboardLandingPage}
        authorized={showDashboardLandingPage}
      />
      <AuthRoute
        path={[
          '/candidates/:candidateId/reports/:reportId',
          '/candidates/:candidateId/invitations/:invitationId',
          '/candidates/:candidateId',
        ]}
        component={CandidatePage}
        authorized={
          hasPermission(user, 'read_candidate_details') &&
          accountHasPermission(user, 'see_full_dashboard')
        }
      />
      <AuthRoute
        exact
        path='/reports/:reportId'
        component={ReportPage}
        authorized={
          hasPermission(user, 'read_candidate_details') &&
          accountHasPermission(user, 'see_full_dashboard')
        }
      />
      <Route path='/timeline' component={TimelinePage} />
      <AuthRoute
        path='/assess'
        component={Assess}
        authorized={
          user &&
          (user.roles?.includes('admin') || // migrated verbatim, TODO: do not use role checks
            user.roles?.includes('internal_admin') ||
            hasPermission(user, 'assess_view'))
        }
      />
      <AuthRoute
        path='/cm'
        component={CaseManagement}
        authorized={
          accountHasPermission(user, 'case_management') &&
          (hasPermission(user, 'case_management_read_all') ||
            hasPermission(user, 'case_management_manage_all'))
        }
      />
      <Route
        exact
        path='/:parentContext/:parentId/segments'
        component={SegmentsPage}
      />
      <AuthRoute
        exact
        path='/continuous_services'
        component={ContinuousServicesPage}
        authorized={hasPermission(user, 'read_dashboard_continuous_services')}
      />
      <AuthRoute
        path='/continuous_services/csv_exports/:exportId'
        component={ContinuousServicesCsvExportsPage}
        authorized={hasPermission(user, 'read_dashboard_continuous_services')}
      />
      <AuthRoute
        exact
        path='/csv_exports/:id'
        component={CsvPage}
        authorized={!!user}
      />
      <AuthRoute
        exact
        path='/billing/:context'
        component={BillingPage}
        authorized={hasPermission(user, 'read_account_invoices')}
      />
      <Route exact path='/packages' component={PackagesPage} />
      <AuthRoute
        exact
        path='/packages/:packageId/edit'
        component={EditPackagesPage}
        authorized={hasPermission(user, 'manage_packages')}
      />
      <Route path='/package/' component={CreatePackagePage} />
      <AuthRoute
        path='/order/'
        component={OrderBackgroundCheckPage}
        authorized={hasPermission(user, 'manage_invitations')}
      />
      <AuthRoute
        path='/order-experience/'
        component={BetterOrderExperiencePage}
        authorized={hasPermission(user, 'manage_invitations')}
      />
      <Route
        path='/oauth/deauthorize/:clientId'
        component={DeauthorizeContainer}
      />
      <Route path='/fair_chance' component={FairChancePage} />
      {showI9 && <Route exact path='/i-9' component={FormI9Page} />}
      {showI9 && <Route exact path='/i-9/order' component={FormI9OrderPage} />}

      {showHealthScreeningProgams && (
        <AuthRoute
          exact
          path='/random_testing_program'
          component={RandomizerPage}
          authorized={hasPermission(user, 'manage_post_hire_drug')}
        />
      )}

      {showHealthScreeningProgams && (
        <AuthRoute
          exact
          path='/random_testing_program/pool/:cohortId'
          component={RandomizerCohortPage}
          authorized={hasPermission(user, 'manage_post_hire_drug')}
        />
      )}

      {showParsPageFlag && showHealthScreeningProgams && (
        <AuthRoute
          exact
          path='/pars_program'
          component={PARSPage}
          authorized={hasPermission(user, 'manage_post_hire_drug')}
        />
      )}

      <Redirect exact path='/screenings/packages' to='/screening/packages' />
      <Route // ScreeningSettings handles permissions
        path='/screening/:category/:programId?'
        component={ScreeningSettingPage}
      />
      <Route
        path='/bulk-order-history'
        component={BulkOrderHistorySinglePage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default Router;
