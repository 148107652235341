import React, { useCallback, useContext } from 'react';
import {
  CurrentUser,
  hasPermission,
  localStorageFallback,
} from '@dashboard-experience/utils';
import { IS_IN_IFRAME } from 'Constants';
import { updateParentWindowUrl } from 'utils';
import { useTranslation } from 'react-i18next';
import type { GetStartedT } from 'components/BetterOrderExperience/GetStartedStep';
import { useHistory } from 'react-router-dom';

import AccountContext from 'pages/Account/AccountContext';
import {
  StyledBinaryButtons,
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
} from './SubmitModal.styles';
import { namespace } from '../../locales';
import AliasSettingSelection from './AliasSettingSelection';

export type SubmitModalProps = {
  open: boolean;
  handleClose: () => void;
  currentUser: CurrentUser;
  contextId: string | null;
  csv: GetStartedT.BulkInviteCSV;
  manualOrderType?: GetStartedT.ManualOrderType;
  aliasAdded: string;
};

const SubmitModal: React.FC<SubmitModalProps> = ({
  open,
  handleClose,
  currentUser,
  contextId,
  csv,
  manualOrderType,
  aliasAdded,
}) => {
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const { account } = useContext(AccountContext);

  const showDefaultAliasSection =
    ((account.alias_auto_enable && aliasAdded === 'off') ||
      (!account.alias_auto_enable && aliasAdded === 'on')) &&
    localStorageFallback.getItem('hideDefaultAliasModal') !== 'true';

  const isAliasEnabled = aliasAdded === 'on';

  const bulkInviteEnabled: boolean = !!csv.fileStorageKey;
  const manualBulkOrderEnabled = manualOrderType === 'MULTIPLE';

  const redirectPage = useCallback(
    (path: string, reload: boolean) => {
      if (IS_IN_IFRAME && contextId) {
        updateParentWindowUrl({
          contextId,
          path,
          reload,
        });
      } else {
        history.push(path);
      }
    },
    [contextId, history],
  );

  const handleOrderNew = useCallback(() => {
    handleClose();
    redirectPage('/order-experience/get-started', true);
  }, [handleClose, redirectPage]);

  const handleGoToCandidates = useCallback(() => {
    handleClose();
    redirectPage('/candidates', true);
  }, [redirectPage, handleClose]);

  const handleViewBulkHistory = useCallback(() => {
    if (hasPermission(currentUser, 'read_account_invoices')) {
      redirectPage('/billing/bulk-history', true);
    } else {
      redirectPage('/bulk-order-history', true);
    }
    handleClose();
  }, [currentUser, handleClose, redirectPage]);

  const btnOrderNewCheck = {
    name: 'Order new check',
    onClick: handleOrderNew,
    'data-testid': 'order-new-check-button',
  };

  const btnGoToCandidates = {
    name: 'Go to Candidates page',
    onClick: handleGoToCandidates,
    'data-testid': 'candidate-page-button',
  };

  const btnViewBulkHistory = {
    name: 'View bulk order history',
    onClick: handleViewBulkHistory,
    'data-testid': 'view-documents-page-button',
  };

  const handleBackdropClick = useCallback((e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);
  return (
    <StyledModal
      data-testid='submit-modal'
      open={open}
      onClose={handleBackdropClick}
    >
      <StyledModalHeader data-testid='submit-modal-header'>
        <h2>{t(`submitModal.header`)}</h2>
      </StyledModalHeader>

      <StyledModalBody data-testid='submit-modal-description'>
        {bulkInviteEnabled ? (
          <>
            <div>
              {t(`submitModal.bulkInvite.message`)}
              <strong> {t(`submitModal.bulkInvite.candidates`)}</strong>
            </div>
            <div style={{ marginTop: '2rem' }}>
              {t(`submitModal.bulkInvite.previousOrders`)}
              <strong>{t(`submitModal.bulkInvite.paymentAndBilling`)}</strong>
              {'>'}{' '}
              <strong>{t(`submitModal.bulkInvite.bulkOrderHistory`)}</strong>
            </div>
          </>
        ) : (
          <>
            {manualBulkOrderEnabled ? (
              <>
                {t(`submitModal.manualBulk.message`)}
                <strong>{t(`submitModal.manualBulk.bulkOrderHistory`)}</strong>
                {t(`submitModal.manualBulk.messageSecondPart`)}
                <strong>{t(`submitModal.manualBulk.candidates`)}</strong>
              </>
            ) : (
              <>
                {t(`submitModal.bulkInvite.success`)}
                <strong>{t(`submitModal.bulkInvite.candidates`)}</strong>
              </>
            )}
          </>
        )}
        {showDefaultAliasSection && (
          <AliasSettingSelection aliasEnabled={isAliasEnabled} />
        )}
      </StyledModalBody>
      <StyledModalFooter>
        <StyledBinaryButtons
          btnLeft={
            bulkInviteEnabled || manualBulkOrderEnabled
              ? btnViewBulkHistory
              : btnOrderNewCheck
          }
          btnRight={btnGoToCandidates}
          size='small'
        />
      </StyledModalFooter>
    </StyledModal>
  );
};

export default SubmitModal;
