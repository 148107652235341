import { M } from '@dashboard-experience/mastodon';
import { useShowReportLog } from 'providers/Candidate/hooks';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ContentContainer } from '../../components';
import ReturnToLegacyBannerPhaseTwo from '../return-to-legacy-banner-phase-two';
import * as Styles from '../styles';
import useGetTabs from './get-tabs';
import TabContent from './tab-content';

type Props = {
  setShowOverview: Dispatch<SetStateAction<boolean>>;
  showOverview: boolean;
  setIsScrolled: Dispatch<SetStateAction<boolean>>;
  isScrolled: boolean;
};

const MainSection: React.FC<Props> = ({
  showOverview,
  setShowOverview = () => {},
  setIsScrolled,
  isScrolled,
}) => {
  const tabs = useGetTabs();
  const [selectedTab, setSelectedTab] = useState(tabs[0].label.title);
  // Render tabs lazily only when they are selected for the first time
  const [renderedTabs, setRenderedTabs] = useState([tabs[0].label.title]);

  const icon = showOverview ? 'SidePanelOpen' : 'SidePanelClose';
  const label = showOverview
    ? 'Hide additional information'
    : 'Show additional information';
  const toggleShowOverview = useCallback(
    () => setShowOverview(s => !s),
    [setShowOverview],
  );

  const showReportLog = useShowReportLog();

  const handleTabClick = useCallback(
    (labelTitle: string) => () => {
      setSelectedTab(labelTitle);
      setRenderedTabs(prev =>
        prev.includes(labelTitle) ? prev : [...prev, labelTitle],
      );
    },
    [setSelectedTab, setRenderedTabs],
  );

  return (
    <Styles.TabsSection data-testid='candidate-tabs-section'>
      <div data-testid='candidate-tabs-header'>
        <div
          style={{
            alignItems: 'center',
            marginRight: 'auto',
            paddingTop: '.25rem',
            paddingLeft: '1.25rem',
            width: '100%',
          }}
        >
          <M.Tabs>
            <Styles.TabsList>
              {tabs.map(({ label }) => (
                <TabContent
                  key={label.title}
                  label={label.title}
                  {...label}
                  handleTabClick={handleTabClick}
                />
              ))}
              <ReturnToLegacyBannerPhaseTwo
                overViewContainerOpen={showOverview}
              />
            </Styles.TabsList>
          </M.Tabs>
        </div>
        <Styles.ButtonContainer
          showOverview={showOverview}
          data-testid='candidate-tabs-toggle'
        >
          <M.TooltipButton
            data-pendo-id='candidate-tabs-toggle'
            align='bottom-right'
            label={label}
            kind='secondary'
            onClick={toggleShowOverview}
          >
            <M.Icon data-icon={icon} icon={icon} size={20} />
          </M.TooltipButton>
        </Styles.ButtonContainer>
      </div>
      <div
        style={{
          background: 'linear-gradient(180deg, #F7F9FA 20%, #F2F5F7 100%)',
        }}
      >
        <ContentContainer
          setIsScrolled={setIsScrolled}
          isScrolled={isScrolled}
          showReportLog={showReportLog}
        >
          {renderedTabs.map((labelTitle: string) => (
            <div
              key={labelTitle}
              style={{
                display: labelTitle === selectedTab ? 'block' : 'none',
              }}
            >
              {tabs.find(tab => tab.label.title === labelTitle)?.content}
            </div>
          ))}
        </ContentContainer>
      </div>
    </Styles.TabsSection>
  );
};

export default MainSection;
