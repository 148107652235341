import fields from './fields';
import { FieldKeys, ConfigureFieldKeysToItemsParams } from './types';
import { buildFieldItemsList } from './utils';

const configureFieldKeysToItems = ({
  user,
  candidate,
  report,
  invitation,
  reportPrediction,
  reportEstimationGenerated,
  statusType,
  task,
  showPreference,
  pinnedFields,
  pinnedFieldsFlagEnabled,
}: ConfigureFieldKeysToItemsParams) => {
  const fieldsDict = fields({
    user,
    candidate,
    report,
    invitation,
    reportPrediction,
    reportEstimationGenerated,
    statusType,
    task,
    showPreference,
  });

  return (fieldKeys: FieldKeys[], isPinnedPanel: boolean = false) => {
    const validKeys = Object.keys(fieldsDict);

    const fieldsToShow = fieldKeys
      .filter(key => {
        return validKeys.includes(key);
      })
      .map(key => ({
        ...fieldsDict[key],
        fieldKey: key,
      }))
      .filter(item => {
        const canShowField = item.shouldShow();
        const showInCurrentPanel =
          !pinnedFieldsFlagEnabled ||
          isPinnedPanel === pinnedFields.includes(item.fieldKey);

        return canShowField && showInCurrentPanel;
      });

    return buildFieldItemsList({
      user,
      candidate,
      report,
      invitation,
      statusType,
      fields: fieldsToShow,
      isPinnedPanel,
    });
  };
};

export default configureFieldKeysToItems;
