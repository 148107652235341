import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { orderByKey } from '@dashboard-experience/utils';
import {
  IDENTITY_VERIFICATION_REDESIGN,
  INTL_CRIM_SEARCH_DOC_TYPES,
  STATUS_ORDER,
} from 'Constants';
import React, { useMemo } from 'react';
import {
  Document as CandidateDocument,
  ReportProps,
  InternationalCriminalSearchV2 as Search,
} from 'types';
import { SCREENING_TITLES } from 'utils';
import Document from '../Document';
import { useCandidateDocuments } from '../Document/hooks';
import InternationalCriminalSearchV2 from './InternationalCriminalSearchV2';
import { transformSearchStatuses } from './utils';

const InternationalCriminalSearchesV2: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_criminal_searches_v2 } = report;
  const { documents } = useCandidateDocuments(report?.candidate?.id as string);
  const filteredDocuments = useMemo(
    () =>
      documents.filter(
        (document: CandidateDocument) =>
          INTL_CRIM_SEARCH_DOC_TYPES.includes(document.type) &&
          document.context_attributes?.report_id?.toString() === report.id,
      ),
    [documents, report.id],
  );

  const idvRedesignFlagEnabled =
    useFlag(IDENTITY_VERIFICATION_REDESIGN)?.variantKey === 'on';

  const searches: false | Search[] = useMemo(
    () =>
      !!international_criminal_searches_v2 &&
      (orderByKey(
        international_criminal_searches_v2,
        'status',
        STATUS_ORDER,
      ) as Search[]),
    [international_criminal_searches_v2],
  );

  // TODO: Remove after change is made in backend to update screening status
  // from 'canceled' to  'suspended' when IDV is failed or expired
  const transformedSearches = useMemo(
    () => transformSearchStatuses(searches, idvRedesignFlagEnabled),
    [searches, idvRedesignFlagEnabled],
  );

  return transformedSearches && transformedSearches.length > 0 ? (
    <M.Screening
      title='International Criminal Searches'
      statusType={reportStatusType}
      screening={transformedSearches}
      id={SCREENING_TITLES.international_criminal_searches_v2.id}
    >
      {transformedSearches.map((search: any, i: number) => (
        <React.Fragment key={search.id}>
          <InternationalCriminalSearchV2
            report={report}
            search={search}
            statusType={reportStatusType}
          />
          {i < transformedSearches.length - 1 && <M.Divider />}
        </React.Fragment>
      ))}
      {filteredDocuments.map((document: CandidateDocument) => (
        <Document key={document.id} document={document} />
      ))}
    </M.Screening>
  ) : null;
};

export default InternationalCriminalSearchesV2;
