import React, { useCallback } from 'react';
import { BulletPoint } from 'components/AddScreenings/Domestic/shared/SummaryStyles';
import { StyledScreeningListItem } from 'components/BetterOrderExperience/SelectPackageStep/styles/PackageCard.styles';
import {
  StyledFlexWithMargin,
  ScreeningType,
  StyledFlex,
  StyledPriceLabel,
} from './CustomizationScreeningItem.styles';
import type { AddonsT } from '../..';
import {
  getScreeningLabel,
  getAdditionalPropertiesLabel,
} from '../../AddonsStep.utils';
import LearnMoreLink from '../../../../AddScreenings/shared/LearnMoreLink';

const CustomizationScreeningItem: React.FC<{
  additionalProperties: AddonsT.AdditionalProperties;
  addedScreeningTypeWithPrices: AddonsT.ScreeningTypeWithPrices;
}> = ({ additionalProperties, addedScreeningTypeWithPrices }) => {
  const { screening } = addedScreeningTypeWithPrices;

  const screeningTypeLabel = getScreeningLabel(screening.type);

  const additionalPropertyLabels = getAdditionalPropertiesLabel(
    screening.type,
    additionalProperties,
  ) as string[];

  const getLabelText = useCallback((label: string) => {
    return label.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
  }, []);

  return (
    <>
      <StyledScreeningListItem data-testid='customization-screening-item'>
        <StyledFlex>
          <ScreeningType>{screeningTypeLabel}</ScreeningType>
          <StyledPriceLabel data-testid='price-label'>
            {addedScreeningTypeWithPrices.price === 'Price varies' ? (
              <LearnMoreLink
                linkText='Price varies'
                href='https://checkr.com/pricing/international'
              />
            ) : (
              addedScreeningTypeWithPrices.price
            )}
          </StyledPriceLabel>
        </StyledFlex>
      </StyledScreeningListItem>
      {additionalPropertyLabels.map((label: string) => (
        <StyledFlexWithMargin data-testid='price-flex-with-margin' key={label}>
          <BulletPoint />
          {getLabelText(label)}
        </StyledFlexWithMargin>
      ))}
    </>
  );
};

export default CustomizationScreeningItem;
