export const PARAMS = {
  date: [
    'dob',
    'reported_after',
    'reported_before',
    'reports_completed_after',
    'reports_completed_before',
  ],
  noneSelectValues: ['tags'],
  general: [
    'adjudication',
    'adjudication_subtype',
    'geo_ids',
    'invitation_status',
    'monitor_type',
    'packages',
    'program_id',
    'q',
    'segment_custom_id',
    'segment_name',
    'status',
    'tags',
    'includes_canceled',
    'report_source',
    'idv_status',
  ],
  default: {
    page: 1,
    per_page: 25,
    order_by: 'created_at',
    sort: 'desc',
    // TODO: enable this once the ENABLE_ARCHIVE_REPORTS (https://flagr-ui.checkrhq.net/#/flags/907) flag is deprecated
    // archived: 'false'
  },
  get base() {
    return [...this.general, ...this.date];
  },
  whitelisted() {
    return [
      'account',
      'driver_license_state',
      'match_with_newest',
      'order_by',
      'page',
      'per_page',
      'archived',
      'report_adjudicated_after',
      'report_adjudicated_before',
      'report_adjudicator_email',
      'report_revised_after',
      'report_revised_before',
      'saved_search_id',
      'screenings',
      'sort',
      ...this.base,
    ];
  },
  updateGeneralParams({
    assessEnabled,
    account,
  }: {
    assessEnabled: boolean;
    account: any;
  }) {
    if (assessEnabled) {
      this.general.push('assessment');
    }
    if (account?.collaboration_suite) {
      this.general.push('report_tasks');
    }
  },
};
export const REPORT_STATUS_HELP_CENTER_URL =
  'https://help.checkr.com/hc/en-us/articles/18703268599959-Assessments';
