import React from 'react';
import moment from 'moment';
import { FieldComponentProps } from '../../types';

const InvitationSent: React.FC<FieldComponentProps> = ({
  candidate,
  testid,
  report,
}) => {
  if (!candidate?.invitations || !report?.id) {
    return null;
  }

  const invitation = candidate.invitations.find(i => i.report_id === report.id);

  if (!invitation) {
    return null;
  }

  const timezone = moment.tz.guess();

  // This will put the date in the format of "Apr 8, 2024, 1:50 PM PDT"
  return (
    <div data-testid={testid}>
      {moment.tz(invitation.created_at, timezone).format('lll z')}
    </div>
  );
};

export default InvitationSent;
