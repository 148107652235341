import React, { useCallback, useState } from 'react';
import { hasPermission } from '@dashboard-experience/utils';
import { Candidate, GenericObject } from 'types';
import { useUser } from 'context/CurrentUser';
import { useGetSuppressions } from 'api/communicator/hooks';
import { renderModal } from 'modules/candidate';
import SuppressionModal from 'components/Report/CandidateInformation/fields/SuppressionModal';
import { ActionableTextField } from '../styles';

const noop = () => {};

type Props = {
  candidate: Candidate;
  allowEdit: boolean;
  displayEmail: string;
  onEmailEditSubmit: (newEmailValue: string) => Promise<void>;
};

const Email: React.FC<Props> = ({
  candidate,
  allowEdit,
  displayEmail,
  onEmailEditSubmit,
}) => {
  const user = useUser();
  const allowManageSuppressions = hasPermission(user, 'manage_communicator');

  const { data } = useGetSuppressions(candidate, allowManageSuppressions);

  const suppressions: undefined | GenericObject[] = data?.suppressions;
  const candidateEmailSuppressed = !!suppressions?.length;

  const [displaySuppressionModal, setDisplaySuppressionModal] =
    useState<boolean>(false);

  const openSuppressionListModal = useCallback(() => {
    setDisplaySuppressionModal(true);
  }, []);

  // Function specifically for managing the Migrated modal
  const closeSuppressionModal = useCallback(() => {
    setDisplaySuppressionModal(false);
  }, []);

  const withSupressions = allowManageSuppressions && candidateEmailSuppressed;
  const tooltipLabel = withSupressions ? 'Email undeliverable' : 'Copy';

  return (
    <>
      <ActionableTextField
        allowEdit={allowEdit}
        ariaLabel='candidate-email'
        initialValue={displayEmail}
        initialDisplayValue={displayEmail || '-'}
        submitCallback={onEmailEditSubmit}
        tooltipLabel={tooltipLabel}
        onAlert={withSupressions ? openSuppressionListModal : noop}
        kind={withSupressions ? 'alert' : 'default'}
      />
      {displaySuppressionModal &&
        // @ts-ignore
        renderModal(SuppressionModal, {
          candidate,
          showModal: displaySuppressionModal,
          onClose: closeSuppressionModal,
          allowEdit,
        })}
    </>
  );
};

export default Email;
