import { Client as client } from 'api';
import { PATH } from './constants';
import { PromoCodeReqParamsType } from './types';

export const validatePromotionCode = (
  req: PromoCodeReqParamsType,
): Promise<{ [key: string]: any }> => {
  return client.post(`/${PATH}/validate`, req);
};

export const redeemPromotionCode = (
  req: PromoCodeReqParamsType,
): Promise<{ [key: string]: any }> => {
  return client.post(`/${PATH}/redeem`, req);
};
