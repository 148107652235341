import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  getStatusTypeForUser,
  StatusTypes,
  CurrentUser,
  GenericObject,
} from '@dashboard-experience/utils';

import {
  getCellConfigType,
  getSearchConfig,
} from 'components/Search/SearchTableContent/Config';
import TableBody from './table-body';
import TableHead from './table-head';

type SearchTableProps = {
  candidates: GenericObject;
  currentUser: CurrentUser;
};

type HeaderElem = {
  key: string;
  header: string;
};

const Table = ({ candidates, currentUser }: SearchTableProps) => {
  // processingStatusData local storage is used in the TableBody
  const [, setUpdateProcessingStatusState] = useState({
    updateProcessingStatus: false,
  });
  useEffect(() => {
    const listener = () => {
      // When local storage changes, update the processing status state
      setUpdateProcessingStatusState(prevState => ({
        ...prevState,
        updateProcessingStatus: true,
      }));
    };
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);

  const CELL_CONFIG = getCellConfigType();

  const getTableContent = useCallback(
    ({ getRowProps, headers, rows }) => {
      const columns = headers.map(({ header }: HeaderElem) => {
        return CELL_CONFIG[header as unknown as keyof typeof CELL_CONFIG];
      });

      const isDSP = getStatusTypeForUser(currentUser) === StatusTypes.DSP;

      return (
        <M.TableContainer
          noScroll
          style={{ paddingBottom: '2rem', overflowX: 'scroll' }}
          className='newSearchTable'
        >
          <M.Table>
            <TableHead
              currentUser={currentUser}
              columns={columns}
              isDSP={isDSP}
            />
            <TableBody
              currentUser={currentUser}
              candidates={candidates}
              rows={rows}
              columns={columns}
              getRowProps={getRowProps}
              isDSP={isDSP}
            />
          </M.Table>
        </M.TableContainer>
      );
    },
    [candidates, currentUser, CELL_CONFIG],
  );

  const columnConfig = getSearchConfig(currentUser, false, []);

  // DataTable expects an Object with key and header values; just create one based off of the string values coming in
  const headers: HeaderElem[] = useMemo(() => {
    return columnConfig.map((name: string) => {
      return { key: name, header: name };
    });
  }, [columnConfig]);

  return (
    <M.DataTable
      headers={headers}
      rows={candidates.data}
      render={getTableContent}
    />
  );
};

export default Table;
