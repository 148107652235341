import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

// eslint-disable-next-line import/prefer-default-export
export const PromoCodeBannerContainer = styled.div<{
  type: 'success' | 'error';
}>`
  width: 100%;
  padding: 4px 8px;
  display: flex;
  gap: 6px;

  background: ${props =>
    props.type === 'error' ? `${colors.uiOrange100}` : `${colors.uiGreen100}`};

  border-left: 2px solid
    ${props =>
      props.type === 'error'
        ? `${colors.uiOrange500}`
        : `${colors.uiGreen500}`};
`;
