import React, { FC, MouseEventHandler, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { GenericObject } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { Actions, Banner, Column, Content } from './StyledComponents';
import { Icon } from './Icon';

declare global {
  interface Window {
    pendo?: {
      isGuideShown: () => boolean;
      hideGuides(): () => void;
      updateOptions: (options: GenericObject) => void;
      showGuideById: (guideId: string, options?: GenericObject) => void;
    };
  }
}

export enum ToggleStates {
  DEFAULT = 'default',
  OPT_OUT = 'opt_out',
}

export const Description: FC<{
  translationKey: string;
  toggleState: ToggleStates;
  learnMoreLink?: string;
}> = ({ translationKey, learnMoreLink, toggleState }) => {
  const { t } = useTranslation();
  const toggleStateKey =
    toggleState === ToggleStates.OPT_OUT
      ? `${translationKey}.opted_out.`
      : `${translationKey}.`;

  return (
    <Column>
      <div className='p3 bold'>{t(`${toggleStateKey}title`)}</div>
      <div className='p3'>
        {t(`${toggleStateKey}subtitle`)}{' '}
        {learnMoreLink && (
          <M.Link
            href={learnMoreLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            {t(`${translationKey}.learn_more`)}
          </M.Link>
        )}
      </div>
    </Column>
  );
};

export const Button: FC<{
  toggle: MouseEventHandler<HTMLButtonElement>;
  toggleState: ToggleStates;
  translationKey: string;
  buttonPendoId?: string;
}> = ({ toggle, toggleState, translationKey, buttonPendoId }) => {
  const { t } = useTranslation();

  const buttonKey =
    toggleState === ToggleStates.OPT_OUT
      ? `${translationKey}.opted_out.button`
      : `${translationKey}.button`;
  return (
    <M.Button kind='secondary' onClick={toggle} data-pendo-id={buttonPendoId}>
      {t(buttonKey)}
    </M.Button>
  );
};

export const FeedbackButton: FC<{
  feedbackGuideId: string;
  translationKey: string;
}> = ({ feedbackGuideId, translationKey }) => {
  const { t } = useTranslation();
  const provideFeedback = useCallback(() => {
    feedbackGuideId &&
      window.pendo &&
      window.pendo.showGuideById(feedbackGuideId);
  }, [feedbackGuideId]);

  return (
    <M.Link onClick={provideFeedback} data-testid='provide-feedback'>
      {t(`${translationKey}.provide_feedback`)}
    </M.Link>
  );
};

export const ToggleBanner: FC<{
  translationKey: string;
  toggle: MouseEventHandler<HTMLButtonElement>;
  toggleState: ToggleStates;
  iconAlt?: string;
  learnMoreLink?: string;
  buttonPendoId?: string;
  bannerTestId?: string;
  feedbackGuideId?: string;
}> = ({
  translationKey,
  learnMoreLink,
  toggleState,
  toggle,
  iconAlt,
  buttonPendoId,
  bannerTestId,
  feedbackGuideId,
}) => (
  <Banner data-testid={bannerTestId}>
    <Content>
      <Icon alt={iconAlt} />
      <Description
        toggleState={toggleState}
        translationKey={translationKey}
        learnMoreLink={learnMoreLink}
      />
    </Content>
    <Actions>
      {feedbackGuideId && (
        <FeedbackButton
          feedbackGuideId={feedbackGuideId}
          translationKey={translationKey}
        />
      )}
      <Button
        toggle={toggle}
        buttonPendoId={buttonPendoId}
        translationKey={translationKey}
        toggleState={toggleState}
      />
    </Actions>
  </Banner>
);
