import React, { useCallback } from 'react';
import {
  Card,
  IconBox,
  TextBox,
  StyledIcon,
} from './JobRoleAttributeTileStyles';

interface Props {
  id: number;
  name: string;
  icon?: string;
  selected?: boolean;
  onClick: (id: number) => void;
}

const JobRoleAttributeTile: React.FC<Props> = ({
  id,
  name,
  icon,
  selected = false,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Card
      data-testid={`job-role-attribute-tile-${id}`}
      className={`job-role-attribute-tile ${selected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <IconBox>
        {icon && !selected && <img src={icon} alt={name} />}
        {selected && <StyledIcon icon='CheckmarkFilled' />}
      </IconBox>
      <TextBox>{name}</TextBox>
    </Card>
  );
};

export default JobRoleAttributeTile;
