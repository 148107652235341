import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  useGetReportSettings,
  useGetUserAccountSettings,
  useUpdateAccountSettings,
} from 'components';
import { useUser } from 'context/CurrentUser';
import { SHOW_ADDITIONAL_CHARGE_INFORMATION, SHOW_REPORT_SUMMARY } from 'Flags';

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { REPORT_SETTING_EVENT_NAMES, useTrackEvent } from 'utils/analytics';

const StyledForm = styled(M.Form)`
  color: ${colors.uiTextPrimaryLight};

  p {
    max-width: 659px;
  }
`;

const StyledCheckboxBox = styled.div`
  .cds--checkbox-wrapper {
    margin-top: 0.75rem;
  }
`;

const ReportSettings: React.FC = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const { account } = user;
  const showReportSummarySetting =
    useFlag(SHOW_REPORT_SUMMARY)?.variantKey === 'on';
  const showAdditionalChargeInformation =
    useFlag(SHOW_ADDITIONAL_CHARGE_INFORMATION)?.variantKey === 'on';

  const { accountSettings, isLoading, isFetching } = useGetUserAccountSettings(
    user,
    showReportSummarySetting || showAdditionalChargeInformation,
  );

  const {
    show_summary: initialShowReportSummary,
    show_additional_charge_information: initialShowAdditionalChargeInfo,
  } = useGetReportSettings(accountSettings);

  const [showReportSummary, setShowReportSummary] = useState(false);
  const [showAdditionalChargeInfo, setShowAdditionalChargeInfo] =
    useState(false);

  useEffect(() => {
    setShowReportSummary(initialShowReportSummary);
    setShowAdditionalChargeInfo(initialShowAdditionalChargeInfo);
  }, [initialShowReportSummary, initialShowAdditionalChargeInfo]);

  const { call: updateAccountCall } = useUpdateAccountSettings();

  const toggleReportSummary = useCallback(() => {
    const newValue = !showReportSummary;
    setShowReportSummary(newValue);
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_ACCOUNT_SETTINGS_TAB_SHOW_REPORT_SUMMARY_CLICKED,
      {
        'Show Report Summary Clicked': newValue,
      },
    );
    const changedSettings = { report_settings: { show_summary: newValue } };
    updateAccountCall({ changedSettings, account });
  }, [showReportSummary, trackEvent, updateAccountCall, account]);

  const toggleShowAdditionalInfo = useCallback(() => {
    const newValue = !showAdditionalChargeInfo;
    setShowAdditionalChargeInfo(newValue);
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_ACCOUNT_SETTINGS_TAB_SHOW_ADDITIONAL_CHARGE_INFO_CLICKED,
      {
        'Show Additional Info Clicked': newValue,
      },
    );
    const changedSettings = {
      report_settings: { show_additional_charge_information: newValue },
    };
    updateAccountCall({ changedSettings, account });
  }, [showAdditionalChargeInfo, trackEvent, updateAccountCall, account]);

  return (
    <StyledForm id='report-settings' data-testid='report-settings-form'>
      <h3>Manage Report View</h3>
      {isLoading || isFetching ? (
        <M.LoadingInline description='Loading...' />
      ) : (
        <>
          {showReportSummarySetting && (
            <StyledCheckboxBox>
              <M.Checkbox
                onChange={toggleReportSummary}
                name='showReportSummary'
                labelText='Show report summary'
                disabled={false}
                hideLabel={false}
                id='showReportSummaryAccountSetting'
                checked={showReportSummary}
                data-testid='show-report-summary-checkbox'
              />
            </StyledCheckboxBox>
          )}
          {showAdditionalChargeInformation && (
            <StyledCheckboxBox>
              <M.Checkbox
                onChange={toggleShowAdditionalInfo}
                name='showAdditionalChargeInfo'
                labelText='Show additional information'
                disabled={false}
                hideLabel={false}
                id='showAdditionalChargeInfoAccountSetting'
                checked={showAdditionalChargeInfo}
                data-testid='show-additional-charge-info-checkbox'
              />
            </StyledCheckboxBox>
          )}
        </>
      )}
    </StyledForm>
  );
};

export default ReportSettings;
