import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import '../styles.scss';

type Props = {
  isLoading: boolean;
};

const DropdownTrigger = (
  <div className='reports-list-actions-button-container'>
    <M.Button kind='secondary' className='reports-list-actions-button'>
      <M.Icon icon='Filter' size='20' />
      <span>Filters</span>
      <M.Icon icon='ChevronDown' size='20' />
    </M.Button>
  </div>
);

const FiltersButton: React.FC<Props> = ({ isLoading }) => {
  return (
    <M.ActionMenu trigger={DropdownTrigger} position='bottom' size='l'>
      <div className='reports-list-actions-menu'>
        <div>Filters</div>
        <div>
          Focus on items relevant to you. Changes you make are temporary unless
          you save them.
        </div>
      </div>
    </M.ActionMenu>
  );
};

export default FiltersButton;
