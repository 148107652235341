import * as Account from 'modules/core-types/account';
import {
  clearUserCache,
  toastError,
  toastSuccess,
  updateAccountState,
} from 'actions';
import { useDispatch } from 'react-redux';
import { AnyQueryKey, useQuery, useMutation } from 'react-query';
import { Account as CurrentAccount } from 'types/Packages/Account';

import {
  adjudicationSubtypes,
  cities,
  reportMetrics,
  tags,
  createTrackedModal,
  getTrackedModals,
  updateDismissAddonEnablementBanner,
  requestManualOrders,
} from './actions';

export const useAdjudicationSubtypes = (account: Account.Type) => {
  const key: AnyQueryKey = [
    'accounts/adjudication_subtypes',
    { id: account.id },
  ];

  const request = () => adjudicationSubtypes(account);

  return useQuery(key, request, {
    enabled: account.adjudication_subtypes_enabled,
    refetchOnWindowFocus: false,
  });
};

export const useCities = () => {
  const key: AnyQueryKey = ['accounts/cities', { id: undefined }];

  const request = () => cities();

  return useQuery(key, request, { refetchOnWindowFocus: false });
};

export const useReportMetrics = () => {
  const request = ({
    accountId,
    lookBackDays,
  }: {
    accountId: string;
    lookBackDays?: number;
  }) => reportMetrics(accountId, lookBackDays);
  const [call, result] = useMutation(request);

  return {
    call,
    result,
  };
};

export const useTags = ({
  accountId,
  touched,
}: {
  accountId: string;
  touched: boolean;
}) => {
  const key: AnyQueryKey = ['accounts/tags', { id: accountId }];

  const request = () => tags({ accountId });

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: touched,
  });
};

export const useLimitedTags = () => {
  const request = (params: { accountId: string; name: string }) => tags(params);

  const [call, result] = useMutation(request);

  return {
    call,
    result,
  };
};

export const useGetTrackedModals = ({ accountId }: { accountId: string }) => {
  const key: AnyQueryKey = ['accounts/tracked_modals/get', { accountId }];

  const request = () => getTrackedModals({ accountId });
  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: accountId,
  });
};

export const useCreateTrackedModal = () => {
  const dispatch = useDispatch();

  const request = ({ accountId, name }: { accountId: string; name: string }) =>
    createTrackedModal({ accountId, name });

  const [createTrackedModalCall, createTrackedModalResult] = useMutation(
    request,
    {
      onError: () => {
        dispatch(
          toastError('ERROR:', 'Something went wrong, please try again later.'),
        );
      },
    },
  );

  return {
    createTrackedModalCall,
    createTrackedModalResult,
  };
};

export const useDismissAddonEnablementBanner = () => {
  const dispatch = useDispatch();

  const request = ({
    accountId,
    dismissStatus,
  }: {
    accountId: string;
    dismissStatus: boolean;
  }) => updateDismissAddonEnablementBanner({ accountId, dismissStatus });

  const [call, result] = useMutation(request, {
    onSuccess: (updatedAccount: CurrentAccount) => {
      dispatch(clearUserCache());
      dispatch(updateAccountState({ updatedAccount }));
    },
    onError: () => {
      dispatch(
        toastError(
          'ERROR:',
          'There was a problem updating the dismiss addon banner enablement setting.',
        ),
      );
    },
  });

  return { call, result };
};

export const useRequestManualOrders = () => {
  const dispatch = useDispatch();

  const request = ({ accountId }: { accountId: string }) =>
    requestManualOrders({ accountId });

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(clearUserCache());
      dispatch(toastSuccess('Manual orders successfully requested.'));
      dispatch(
        updateAccountState({
          updatedAccount: {
            manual_orders_review: 'requested',
          },
        }),
      );
    },
    onError: () => {
      dispatch(
        toastError('ERROR:', 'There was a problem requesting manual orders.'),
      );
    },
  });

  return { call, result };
};
