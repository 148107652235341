import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Card, IconBox, TextBox } from './IndustryTile.styles';

interface Props {
  id: number;
  name: string;
  icon?: string;
  selected?: boolean;
  onClick: (industry: { id: number; name: string }) => void;
}

const IndustryTile: React.FC<Props> = ({
  id,
  name,
  icon,
  selected,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick({ id, name });
  }, [id, name, onClick]);

  return (
    <Card
      data-testid={`industry-tile-${id}`}
      className={`industry-tile ${selected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      <IconBox>
        {icon && !selected && <img src={icon} alt={name} />}
        {selected && <M.Icon icon='CheckmarkFilled' />}
      </IconBox>
      <TextBox>{name}</TextBox>
    </Card>
  );
};

export default IndustryTile;
