import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../../locales';
import {
  StyledBold,
  StyledDescriptionOne,
  StyledDescriptionTwo,
  StyledGifContainer,
  StyledHeading,
} from './AliasInfoModal.styles';

const aliasDemoGif = require('../../../../assets/alias-demo.gif');

type AliasInfoModalProps = {
  isOpen?: boolean;
  handleCloseModal: () => void;
};

const AliasInfoModal = ({
  isOpen = false,
  handleCloseModal,
}: AliasInfoModalProps) => {
  const { t } = useTranslation(namespace);
  const basePath = `${namespace}:addOns.alias.infoModal`;

  const buttonProps = {
    name: t(`${basePath}.btnRightName`),
    onClick: handleCloseModal,
    'data-testid': 'got-it-button',
  };

  return (
    <M.ComposedModal open={isOpen} preventCloseOnClickOutside>
      <M.ModalHeader
        data-testid='submit-modal-header'
        closeModal={handleCloseModal}
      >
        {t(`${basePath}.header`)}
      </M.ModalHeader>
      <M.ModalBody>
        <StyledHeading className='h5'>
          {t(`${basePath}.bodyHeading`)}
        </StyledHeading>
        <StyledDescriptionOne>
          {t(`${basePath}.descriptionOne`)}
        </StyledDescriptionOne>
        <StyledDescriptionTwo>
          {t(`${basePath}.descriptionTwo.ptOne`)}{' '}
          <StyledBold>{t(`${basePath}.descriptionTwo.ptTwo`)}</StyledBold>{' '}
          {t(`${basePath}.descriptionTwo.ptThree`)}
        </StyledDescriptionTwo>
        <StyledGifContainer>
          {' '}
          <img src={aliasDemoGif} alt='alias demo' />
        </StyledGifContainer>
      </M.ModalBody>
      <M.ModalFooter>
        <M.BinaryButtons btnLeft={null} btnRight={buttonProps} size='small' />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default AliasInfoModal;
