import axios from 'axios';
import { CHECKR_API_BASE_URL } from 'Constants';
import { useQuery } from 'react-query';

export const getAccountRoles = async (
  accountId: number,
  getAttributes?: boolean,
) => {
  const token = localStorage.getItem('accessToken');
  const response = await axios.get(
    `${CHECKR_API_BASE_URL}/accounts/${accountId}/job_roles${
      getAttributes ? '?include_attributes=true' : ''
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

function useGetAccountRoles(accountId: number, getAttributes?: boolean) {
  return useQuery(['Roles'], () => getAccountRoles(accountId, getAttributes), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}

export default useGetAccountRoles;
