import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';
import {
  Geo,
  hasPermission,
  PostPackageType,
  getParamFromUrl,
} from '@dashboard-experience/utils';
import {
  isInternationalCountry,
  updateParentWindowUrl,
  BETTER_ORDER_EVENT_NAMES,
  useTrackEvent,
} from 'utils';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import AccountContext from 'pages/Account/AccountContext';
import { BillingEntity } from 'types/Billing';
import { useValidateCSV, ValidateCSVPayload } from 'api/packages';
import { isEmpty } from 'lodash';
import { StyledFooter, StyledStepContainer } from '../ui';
import InfoSourceRadios from './InfoSourceSection/InfoSourceRadios';
import { ManualOrder } from './ManualOrderSection';
import type { GetStartedT } from '.';
import {
  NodesAndWorkLocationSection,
  GeosAndProgramsSection,
} from './LocationSection';
import LocationSectionHeader from './LocationSection/LocationSectionHeader';
import { CandidateContactInfoSection } from './CandidateContactInfoSection';
import { BillingSection } from './BillingSection';
import { namespace } from '../locales';
import { StyledCandidateDetailsHeader } from './GetStartedStep.styles';
import { isContinueDisabled, removeEmptyInvites } from './GetStartedStepUtils';
import AddPaymentDetailsModal from './AddPaymentDetailsModal/AddPaymentDetailsModal';
import { STEPS } from '../utils/constants';
import { createGetStartedTrackData } from '../utils/AmplitudeUtils';

const GetStartedStep: React.FC<StepProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation(namespace);
  const trackEvent = useTrackEvent();
  const [selectedGeo, setSelectedGeo] = useState<Geo>({} as Geo);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<BillingEntity>({} as BillingEntity);
  const [selectedLocation, setSelectedLocation] =
    useState<GetStartedT.WorkLocation>({} as GetStartedT.WorkLocation);
  const [selectedNode, setSelectedNode] = useState<GetStartedT.Node>();
  const [selectedProgram, setSelectedProgram] =
    useState<GetStartedT.Program | null>(null);
  const [enteredInvites, setEnteredInvites] = useState<GetStartedT.Invite[]>(
    [],
  );
  const [uploadedCsv, setUploadedCsv] = useState<GetStartedT.BulkInviteCSV>(
    {} as GetStartedT.BulkInviteCSV,
  );
  const { validateCall } = useValidateCSV();
  const [parsedEmails, setParsedEmails] = useState<GetStartedT.Email[]>([]);
  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
    useState(false);
  const [isPaymentDetailsAdded, setIsPaymentDetailsAdded] = useState(false);

  const history = useHistory();
  const { contextId } = useContext(UIContext);

  const currentUser = useUser();
  const {
    update,
    updateInfoSource,
    infoSource,
    infoMethod,
    manualOrderType,
    selectedPackage,
    geo,
    node,
    program,
    invites,
    paymentProfile,
    csv,
    emails,
  } = useContext(BetterOrderExperienceContext);

  const {
    account,
    geos,
    nodes,
    nodesIsLoading,
    handleNodeSearchTextChange,
    nodeSearchText,
    programs,
    payment_profiles,
    billing_entity_enabled,
    billing_preferences,
  } = useContext(AccountContext);

  const {
    segmentation_enabled,
    geos_required,
    programs_enabled,
    manual_orders_review,
    partner_billing_origin,
    manual_orders_enabled,
  } = account;

  const { has_valid_payment_method } = billing_preferences || {};
  const manualOrdersEnabledForUserAndAccount =
    manual_orders_enabled && hasPermission(currentUser, 'create_manual_orders');

  const selectablePrograms = useMemo(() => {
    if (selectedGeo?.id && selectedGeo?.id !== 'None') {
      return programs.filter(program => {
        return program.geo_ids.includes(selectedGeo?.id);
      });
    }
    return [];
  }, [programs, selectedGeo?.id]);

  const disabled = isContinueDisabled({
    account,
    nodes,
    selectedGeo,
    selectedLocation,
    selectedNode,
    selectedProgram,
    selectedInfoMethod: infoMethod,
    enteredInvites,
    uploadedCsv,
    selectedInfoSource: infoSource,
    programsRequiredForGeo: selectablePrograms.length > 0,
  });

  const countries = useMemo(() => {
    const filteredCountries = uniq(
      geos.filter(geo => geo.country).map(geo => geo.country),
    );

    if (!filteredCountries.includes('US')) {
      filteredCountries.push('US');
    }

    return filteredCountries;
  }, [geos]);

  const isInternational = useMemo(() => {
    const country = segmentation_enabled
      ? selectedLocation?.country
      : selectedGeo?.country;

    return isInternationalCountry(country);
  }, [segmentation_enabled, selectedGeo?.country, selectedLocation?.country]);

  const showAddPaymentModal =
    partner_billing_origin &&
    !(has_valid_payment_method || isPaymentDetailsAdded);

  const continueToNextStep = useCallback(() => {
    if (showAddPaymentModal) {
      setIsPaymentDetailsModalOpen(true);
      return;
    }

    trackEvent(
      BETTER_ORDER_EVENT_NAMES.GET_STARTED_COMPLETED,
      createGetStartedTrackData(
        selectedGeo,
        infoMethod,
        infoSource,
        enteredInvites,
        selectedLocation,
        parsedEmails,
      ),
    );

    const { path } = STEPS.SELECT_YOUR_PACKAGE;
    update({
      location: selectedLocation,
      localeType: isInternational ? 'INTERNATIONAL' : 'DOMESTIC',
      infoSource,
      infoMethod,
      manualOrderType,
      node: selectedNode,
      geo: selectedGeo,
      program: selectedProgram,
      invites: removeEmptyInvites(enteredInvites),
      paymentProfile: selectedPaymentProfile,
      csv: uploadedCsv,
      emails: parsedEmails,
    });

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }

    setCurrentStep(STEPS.SELECT_YOUR_PACKAGE);
  }, [
    showAddPaymentModal,
    trackEvent,
    selectedGeo,
    infoMethod,
    infoSource,
    enteredInvites,
    selectedLocation,
    parsedEmails,
    update,
    isInternational,
    manualOrderType,
    selectedNode,
    selectedProgram,
    selectedPaymentProfile,
    uploadedCsv,
    contextId,
    setCurrentStep,
    history,
  ]);

  const handleContinue = useCallback(() => {
    if (
      infoSource === 'CANDIDATE' &&
      infoMethod === 'UPLOAD' &&
      !isEmpty(uploadedCsv)
    ) {
      const validatePayload: ValidateCSVPayload = {
        accountId: currentUser.account.id,
        uploaded_list_type: '/bulk_order/',
        raw_csv_file_s3_path: uploadedCsv.fileStorageKey,
        upload_params: {
          geo_id: geo.id,
          fileDisplayName: uploadedCsv.fileDisplayName,
        },
      };
      validateCall({
        validatePayload,
        onSuccessCallback: continueToNextStep,
      });
    } else continueToNextStep();
  }, [
    currentUser.account.id,
    geo,
    continueToNextStep,
    infoMethod,
    infoSource,
    uploadedCsv,
    validateCall,
  ]);

  useEffect(() => {
    // The following persists the values on refresh & when going back and forth from steps
    // Geos, Nodes, and Location are set in the components themselves
    setSelectedProgram(program);
    setEnteredInvites(invites);
    setSelectedPaymentProfile(paymentProfile);
    setUploadedCsv(csv);
    setParsedEmails(emails);
  }, [
    infoSource,
    manualOrderType,
    program,
    paymentProfile,
    invites,
    csv,
    emails,
    setEnteredInvites,
    setUploadedCsv,
    setParsedEmails,
  ]);

  useEffect(() => {
    if (showAddPaymentModal) {
      setIsPaymentDetailsModalOpen(true);
    }
  }, [showAddPaymentModal]);

  useEffect(() => {
    // Clear the selected package if one was previously saved
    if (selectedPackage?.name) {
      update({
        selectedPackage: {} as PostPackageType,
      });
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, '', window.location.href);
      };
    }
  }, [selectedPackage?.name, update]);

  useEffect(() => {
    const params = getParamFromUrl(window, 'from');
    if (!params) return;

    const navigatedSource =
      params === 'button' ? 'Order BGC Button Candidate Page' : 'Order BGC Tab';
    trackEvent(BETTER_ORDER_EVENT_NAMES.GET_STARTED_VIEWED, {
      Source: navigatedSource,
    });
  }, [trackEvent]);

  useEffect(() => {
    setCurrentStep(STEPS.GET_STARTED);
  });

  return (
    <StyledStepContainer data-testid='get-started-step-container'>
      {showAddPaymentModal && (
        <AddPaymentDetailsModal
          isPaymentDetailsModalOpen={isPaymentDetailsModalOpen}
          setIsPaymentDetailsModalOpen={setIsPaymentDetailsModalOpen}
          hasPermission={hasPermission(currentUser, 'update_billing')}
          setIsPaymentDetailsAdded={setIsPaymentDetailsAdded}
        />
      )}

      {billing_entity_enabled && (
        <BillingSection
          payment_profiles={payment_profiles}
          selectedPaymentProfile={selectedPaymentProfile}
          setSelectedPaymentProfile={setSelectedPaymentProfile}
        />
      )}
      {geos.length > 0 && <LocationSectionHeader />}
      {segmentation_enabled ? (
        <NodesAndWorkLocationSection
          node={node}
          countries={countries as string[]}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          nodes={nodes}
          nodesIsLoading={nodesIsLoading}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          nodeSearchText={nodeSearchText}
          handleNodeSearchTextChange={handleNodeSearchTextChange}
        />
      ) : (
        <GeosAndProgramsSection
          geos={geos}
          selectedGeo={selectedGeo}
          setSelectedGeo={setSelectedGeo}
          programs={selectablePrograms}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          geos_required={geos_required}
          programs_enabled={programs_enabled}
          updateInfoSource={updateInfoSource}
        />
      )}

      {manualOrdersEnabledForUserAndAccount && (
        <>
          <StyledCandidateDetailsHeader>
            {t('candidateDetails.header')}
          </StyledCandidateDetailsHeader>

          <InfoSourceRadios
            isInternational={isInternational}
            manual_orders_review={manual_orders_review}
            infoSource={infoSource}
            updateInfoSource={updateInfoSource}
            accountId={account.id ?? ''}
          />
        </>
      )}

      {infoSource === 'CANDIDATE' && infoMethod && (
        <CandidateContactInfoSection
          infoMethod={infoMethod}
          updateInfoSource={updateInfoSource}
          enteredInvites={enteredInvites}
          setEnteredInvites={setEnteredInvites}
          uploadedCsv={uploadedCsv}
          setUploadedCsv={setUploadedCsv}
          setParsedEmails={setParsedEmails}
        />
      )}
      {infoSource === 'MYSELF' && (
        <ManualOrder
          currentUser={currentUser}
          manualOrderType={manualOrderType}
          updateInfoSource={updateInfoSource}
        />
      )}

      <StyledFooter>
        <M.Button
          data-testid='get-started-continue'
          type='button'
          disabled={disabled}
          onClick={handleContinue}
        >
          {t('getStarted.footerButtons.continue')}
        </M.Button>
      </StyledFooter>
    </StyledStepContainer>
  );
};

export default GetStartedStep;
