import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { removeParamFromUrl } from '@dashboard-experience/utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LearnMoreModal from './LearnMoreModal';
import { useTrackEvent, I9_EVENT_NAMES } from '../../utils';
import { namespace } from './locales';

const FlexGrid = styled.div`
  display: flex;
  flex: 1;
  gap: 2rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const FlexCol = styled.div`
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.5rem;
  display: flex;
  padding: 1.5rem;
  max-width: 35rem;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const SubTitle = styled.p`
  font-size: 1.125rem !important;
  line-height: 1.5rem;
  margin: 1rem auto 3rem !important;
`;

const TextWrap = styled.div`
  font-size: 1rem !important;
  line-height: 1.5rem;
  margin-left: 1.5rem;
  text-align: left;

  @media screen and (min-width: 1024px) {
    margin-left: 0;
    max-width: 16rem;
  }
`;

const SubHeading = styled.h5`
  color: ${colors.uiTextPrimaryLight};
  font-size: 1.125rem;
  line-height: 1;
`;

const ParagraphUpdated = styled.p`
  font-size: 1rem !important;
  line-height: 1.5rem;
`;

const IconWrap = styled.div`
  background-color: ${colors.uiNavy100};
  border-radius: 0.5rem;
  color: ${colors.uiNavy500};
  height: 2.5rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  width: 2.5rem;
`;

const BtnWrap = styled.div`
  margin-top: 3rem;
`;

interface AccountGetStartedProps {
  onContinueClick: () => void;
}

interface SectionProps {
  icon: string;
  titleKey: string;
  descriptionKey: string;
}

const Section = ({ icon, titleKey, descriptionKey }: SectionProps) => (
  <FlexCol>
    <IconWrap>
      <M.Icon icon={icon} size={24} />
    </IconWrap>
    <TextWrap>
      <SubHeading>{titleKey}</SubHeading>
      <ParagraphUpdated>{descriptionKey}</ParagraphUpdated>
    </TextWrap>
  </FlexCol>
);

const AccountGetStarted = ({ onContinueClick }: AccountGetStartedProps) => {
  const trackEvent = useTrackEvent();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { t } = useTranslation(namespace);
  const path = `${namespace}:landing`;

  const setModalOpen = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const hideModal = useCallback((): void => {
    setShowModal(false);
  }, []);

  // Track event on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sourceParam = searchParams.get('source');
    let navigatedSource = 'Other';

    if (sourceParam) {
      const sourceMappings: { [key: string]: string } = {
        tab: 'Left tab',
        order: 'Order submission',
        report: 'Candidate page order new report',
      };
      navigatedSource = sourceMappings[sourceParam];
      removeParamFromUrl(window, 'source');
    }
    trackEvent(I9_EVENT_NAMES.I9_ACCOUNT_SETUP_GET_STARTED_VIEWED, {
      'Page Type': 'Account Setup',
      'I9 Page Type': 'Account Setup Get Started page',
      'I9 Page Source': navigatedSource,
    });
  }, [location.search, trackEvent]);

  return (
    <>
      <h2>{t(`${path}.title`)}</h2>
      <SubTitle>{t(`${path}.subTitleOne`)}</SubTitle>
      <FlexGrid>
        <Section
          icon='ArrowsHorizontal'
          titleKey={t(`${path}.sectionOne.title`)}
          descriptionKey={t(`${path}.sectionOne.description`)}
        />
        <Section
          icon='CheckmarkOutline'
          titleKey={t(`${path}.sectionTwo.title`)}
          descriptionKey={t(`${path}.sectionTwo.description`)}
        />
        <Section
          icon='Settings'
          titleKey={t(`${path}.sectionThree.title`)}
          descriptionKey={t(`${path}.sectionThree.description`)}
        />
      </FlexGrid>
      <SubTitle>{t(`${path}.subTitleTwo`)}</SubTitle>
      <BtnWrap>
        <M.Button kind='tertiary' onClick={setModalOpen} size='sm'>
          {t(`${path}.btnSecondary`)}
        </M.Button>
        <M.Button kind='primary' onClick={onContinueClick} size='sm'>
          {t(`${path}.btnPrimary`)}
        </M.Button>
      </BtnWrap>
      {showModal && (
        <LearnMoreModal
          open={showModal}
          hideModal={hideModal}
          data-testid='modal-i9-info'
        />
      )}
    </>
  );
};

export default AccountGetStarted;
