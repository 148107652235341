import React, { useCallback, useState, useMemo } from 'react';
import { AtsApiLogSchema } from 'api/atsIntegration';
import { M } from '@dashboard-experience/mastodon';
import {
  TypeCell,
  StatusCell,
  UrlCell,
  CreatedCell,
  TableWrapper,
  StyledTable,
  TableHeader,
} from './styles';
import { TQueryFilters } from '../types';
import LogDetailsModal from './LogDetailsModal';

type Props = {
  logs: AtsApiLogSchema[] | undefined;
  queryFilters: TQueryFilters;
  setQueryFilters: (filters: TQueryFilters) => void;
};

const LogsTable: React.FC<Props> = ({
  logs,
  queryFilters,
  setQueryFilters,
}) => {
  const [selectedLog, setSelectedLog] = useState<AtsApiLogSchema | null>(null);

  const handleSort = useCallback(() => {
    let newSortDirection: 'asc' | 'desc' | undefined;
    queryFilters.sort === 'asc'
      ? (newSortDirection = 'desc')
      : (newSortDirection = 'asc');
    setQueryFilters({ ...queryFilters, sort: newSortDirection });
  }, [queryFilters, setQueryFilters]);

  const formatDate = (date: string) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(new Date(date));
  };

  const formatHttpStatus = (code: number) => {
    return code >= 200 && code <= 299 ? 'Success' : 'Failure';
  };

  const handleRowClick = useCallback((log: AtsApiLogSchema) => {
    setSelectedLog(log);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedLog(null);
  }, []);

  const rowClickHandlers = useMemo(() => {
    return logs?.reduce((acc, log) => {
      acc[log.created_at] = () => handleRowClick(log);
      return acc;
    }, {} as { [key: string]: () => void });
  }, [logs, handleRowClick]);

  return (
    <>
      <TableWrapper data-testid='ats-logs-table'>
        <StyledTable>
          <M.TableHead>
            <M.TableRow>
              <TableHeader data-column='type'>Type</TableHeader>
              <TableHeader data-column='status'>Status</TableHeader>
              <TableHeader data-column='url'>URL</TableHeader>
              <TableHeader
                data-column='created'
                isSortable
                isSortHeader
                onClick={handleSort}
                sortDirection={queryFilters?.sort?.toUpperCase() || 'DESC'}
              >
                Created
              </TableHeader>
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {logs?.map(log => (
              <M.TableRow
                key={log.created_at}
                onClick={rowClickHandlers?.[log.created_at]}
                style={{ cursor: 'pointer' }}
              >
                <TypeCell style={{ textTransform: 'capitalize' }}>
                  {log.kind}
                </TypeCell>
                <StatusCell>
                  {formatHttpStatus(log.http_status_code)}
                </StatusCell>
                <UrlCell>
                  <div>
                    <span className='http-method'>{log.http_method}</span>
                    <span className='url-text'>{log.url}</span>
                  </div>
                </UrlCell>
                <CreatedCell>{formatDate(log.created_at)}</CreatedCell>
              </M.TableRow>
            ))}
          </M.TableBody>
        </StyledTable>
      </TableWrapper>

      <LogDetailsModal log={selectedLog} onClose={handleCloseModal} />
    </>
  );
};

export default LogsTable;
