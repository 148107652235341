import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'modules/reports-list/ui/state/hooks';
import { ActionType, View } from 'modules/reports-list/ui/state/types';

type Props = {
  savedView: View;
};

const ResetButton: React.FC<Props> = ({ savedView }) => {
  const dispatch = useDispatch();

  const resetView = useCallback(() => {
    dispatch({
      type: ActionType.RESET_VIEW,
      payload: savedView,
    });
  }, [dispatch, savedView]);

  return (
    <M.Button kind='tertiary' onClick={resetView}>
      Reset
    </M.Button>
  );
};

export default ResetButton;
