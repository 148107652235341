import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDebouncedCallback } from '@dashboard-experience/utils';
import moment from 'moment';
import { FilterContainer, ClearFiltersText } from './styles';
import { TQueryFilters, TLogsFilters } from '../types';
import {
  SearchFilter,
  URLFilter,
  StatusFilter,
  DirectionFilter,
  DateFilter,
} from './filters';

interface LogsFiltersProps {
  setQueryFilters: (filters: TQueryFilters) => void;
}

const defaultFilters: TLogsFilters = {
  search: '',
  created_after: '',
  created_before: '',
  url: '',
  status: 'all',
  direction: 'all',
};

const LogsFilters: React.FC<LogsFiltersProps> = ({ setQueryFilters }) => {
  const [filters, setFilters] = useState<TLogsFilters>(defaultFilters);

  const debouncedSetQueryFilters = useDebouncedCallback(
    (filters: TLogsFilters) => {
      const queryFilters: TQueryFilters = {};

      // convert filter form state to query filters that match expected API params
      if (filters.search) {
        queryFilters.search = filters.search;
      }
      if (filters.created_after) {
        queryFilters.created_after = moment(filters.created_after, 'MM/DD/YYYY')
          .startOf('day')
          .utc()
          .format();
      }
      if (filters.created_before) {
        queryFilters.created_before = moment(
          filters.created_before,
          'MM/DD/YYYY',
        )
          .endOf('day')
          .utc()
          .format();
      }
      if (filters.url) {
        queryFilters.url = filters.url;
      }
      if (filters.status !== 'all') {
        if (filters.status === 'success') {
          queryFilters.http_status_code_gte = 200;
          queryFilters.http_status_code_lte = 299;
        } else if (filters.status === 'failure') {
          queryFilters.http_status_code_gte = 400;
          queryFilters.http_status_code_lte = 599;
        }
      }
      if (filters.direction !== 'all') {
        queryFilters.kind = filters.direction;
      }

      setQueryFilters(queryFilters);
    },
    350,
    [],
  );

  useEffect(() => {
    debouncedSetQueryFilters(filters);
  }, [filters, debouncedSetQueryFilters]);

  const handleClearFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [setFilters]);

  const hasFilters = useMemo(() => {
    let hasFilters: boolean = false;

    for (const [key, value] of Object.entries(filters)) {
      if (
        value !== '' &&
        ['search', 'created_after', 'created_before', 'url'].includes(key)
      ) {
        hasFilters = true;
      } else if (value !== 'all' && ['status', 'direction'].includes(key)) {
        hasFilters = true;
      }
    }

    return hasFilters;
  }, [filters]);

  return (
    <>
      <FilterContainer data-testid='ats-logs-filters'>
        <SearchFilter search={filters.search} setFilters={setFilters} />
        <DateFilter
          fromDate={filters.created_after}
          toDate={filters.created_before}
          setFilters={setFilters}
        />
        <StatusFilter status={filters.status} setFilters={setFilters} />
        <URLFilter url={filters.url} setFilters={setFilters} />
        <DirectionFilter
          direction={filters.direction}
          setFilters={setFilters}
        />
      </FilterContainer>
      {hasFilters && (
        <ClearFiltersText
          onClick={handleClearFilters}
          data-testid='ats-logs-clear-filters'
        >
          <M.Icon icon='Close' />
          Clear filters
        </ClearFiltersText>
      )}
    </>
  );
};

export default LogsFilters;
