import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import ManualOrderModal from 'components/Packages/OrderBackgroundCheck/GetStarted/ManualOrderModal';
import { useRequestManualOrders } from 'api/accounts';
import { UpdateInfoSource } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import type { GetStartedT } from '..';
import { ManualOrdersReview } from '../GetStartedStep.enums';
import InfoSourceRadioMyself from './InfoSourceRadioMyself';
import { namespace } from '../../locales';
import { StyledChoiceCardWrap } from '../GetStartedStep.styles';
import { StyledChoiceCardGroup } from './InfoSourceRadios.styles';

type InfoSourceRadiosProps = {
  infoSource: GetStartedT.InfoSource;
  isInternational: boolean;
  updateInfoSource: (values: UpdateInfoSource) => void;
  manual_orders_review: GetStartedT.ManualOrdersReviewStatus;
  accountId: string;
};

const InfoSourceRadios = ({
  infoSource,
  isInternational,
  updateInfoSource,
  manual_orders_review,
  accountId,
}: InfoSourceRadiosProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('better_order_experience');
  const basePath = `${namespace}:candidateDetails.infoSourceRadios`;
  const showManualOrderText =
    manual_orders_review === ManualOrdersReview.APPROVED &&
    infoSource === 'MYSELF';
  const { call: requestManualOrdersCall } = useRequestManualOrders();

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleManualOrderSubmit = useCallback(() => {
    requestManualOrdersCall({ accountId });
  }, [accountId, requestManualOrdersCall]);

  const handleRadioClick = useCallback(
    e => {
      const infoMethod = e.target.value === 'CANDIDATE' ? 'MANUAL' : undefined;
      const manualOrderType =
        e.target.value === 'MYSELF' ? 'SINGLE' : undefined;
      updateInfoSource({
        infoSource: e.target.value,
        infoMethod,
        manualOrderType,
      });
    },
    [updateInfoSource],
  );

  return (
    <>
      <StyledChoiceCardGroup>
        <StyledChoiceCardWrap>
          <M.ChoiceCard
            key='candidate'
            data-testid='info-source-radio-group-candidate'
            title={t(`${basePath}.candidate.title`)}
            description={t(`${basePath}.candidate.description`)}
            value='CANDIDATE'
            name='CANDIDATE'
            onClick={handleRadioClick}
            checked={infoSource === 'CANDIDATE'}
          />
        </StyledChoiceCardWrap>

        <InfoSourceRadioMyself
          isInternational={isInternational}
          manual_orders_review={manual_orders_review}
          handleModalOpen={handleModalOpen}
          onClick={handleRadioClick}
          checked={infoSource === 'MYSELF'}
        />
      </StyledChoiceCardGroup>
      {showManualOrderText && (
        <p>
          {t(`${basePath}.myself.subtext`)}{' '}
          <M.Link href='#' onClick={handleModalOpen}>
            {t(`${basePath}.myself.subtextLink`)}
          </M.Link>
        </p>
      )}
      <ManualOrderModal
        show={modalOpen}
        handleClose={handleModalClose}
        handleSubmit={handleManualOrderSubmit}
        manualOrdersReviewStatus={manual_orders_review as ManualOrdersReview}
      />
    </>
  );
};

export default InfoSourceRadios;
