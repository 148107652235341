import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  AssessStatuses,
  getResultOrStatus,
  ReportStatuses,
} from '@dashboard-experience/utils';
import { useGetReportSettings } from 'components/AccountSettings';
import { useUser } from 'context/CurrentUser';
import { AI_CHARGE_EXPLAINER, SENTENCE_READABILITY } from 'Flags';
import { CHARGES_DESCRIPTION_MAP } from 'modules/assess/models/charge-category';
import React from 'react';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import chargeSentenceReadability from 'utils/ChargeSentenceReadability';
import { BaseRecord } from 'types/report/screenings/record';
import { handleAiClick } from '../../../../api/aiExplainr';
import getBodyText from './getBodyText';

const { CONSIDER } = ReportStatuses;

const NationalCriminalSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { national_criminal_search: natCrim } = report;
  const currentUser = useUser();
  const { account } = report;
  const status = getResultOrStatus(natCrim || {});
  const isConsider = status === CONSIDER;
  const useScreeningStatusOverAssessment =
    natCrim?.assessment?.display?.decision === AssessStatuses.ELIGIBLE;

  const aiChargeExplainerFlagEnabled =
    useFlag(AI_CHARGE_EXPLAINER)?.variantKey === 'on';

  const sentenceReadabilityFlagEnabled =
    useFlag(SENTENCE_READABILITY)?.variantKey === 'on';

  const { show_additional_charge_information: showChargeExplanationPanel } =
    useGetReportSettings(account);

  return natCrim ? (
    <M.Screening
      title='National Search'
      screening={natCrim}
      statusType={reportStatusType}
      id={SCREENING_TITLES.national_criminal_search.id}
      useScreeningStatusOverAssessment={useScreeningStatusOverAssessment}
    >
      {isConsider && getBodyText(report)}
      {isConsider &&
        natCrim?.records?.map((record: BaseRecord) => {
          const handleClickWithExplainer = handleAiClick(
            record.state || '',
            record.county || '',
          );

          if (sentenceReadabilityFlagEnabled) {
            record.charges = record.charges?.map(c => {
              c.sentence = chargeSentenceReadability(c.sentence);
              return c;
            });
          }

          return (
            <M.Record
              key={record.id}
              record={record}
              currentUser={currentUser}
              report={report}
              reportStatusType={reportStatusType}
              chargeDescriptionMap={CHARGES_DESCRIPTION_MAP}
              displayChargeExplanation={showChargeExplanationPanel}
              chargesExplainer={
                aiChargeExplainerFlagEnabled ? handleClickWithExplainer : null
              }
            />
          );
        })}
    </M.Screening>
  ) : null;
};

export default NationalCriminalSearch;
