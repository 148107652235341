import React from 'react';
import withReportContentContainer from '../report-content-container';
import { KeyValueList, ExpandablePanel } from '../styles';
import { FieldKeys, SectionProps } from '../types';

export const fieldKeys: FieldKeys[] = ['mvr_ruleset', 'criminal_ruleset'];

const AssessGuidelines: React.FC<SectionProps> = ({ fieldKeysToItems }) => {
  const assessFields = fieldKeysToItems(fieldKeys);

  return assessFields.length > 0 ? (
    <ExpandablePanel
      initialExpanded
      data-pendo-id='report-assess-guidelines-panel'
      title='Assess guidelines'
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={assessFields}
        data-testid='overview-assess-guidelines'
      />
    </ExpandablePanel>
  ) : null;
};

export default withReportContentContainer(AssessGuidelines);
