import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { getResultOrStatus, ReportStatuses } from '@dashboard-experience/utils';
import { ReportProps } from 'types';
import { SCREENING_TITLES } from 'utils';
import SearchPDF from './SearchPDF';
import Substatus from './SubstatusCA-BC';
import CriminalRecord from '../Components/CriminalRecord';
import PicVSRegulatoryRequirements from './Components/PicVSRegulatoryRequirements';
import IDVerification from './IDVerification';

const InternationalCanadaBCEnhancedCriminalSearch: React.FC<ReportProps> = ({
  report,
  reportStatusType,
}) => {
  const { international_canada_bc_enhanced_criminal_search: search } = report;

  if (!search) return null;

  const status = getResultOrStatus(search);
  const pic_status = search.canada_police_information_check_exception_status;
  const substatus = search.result_substatus;

  return (
    <M.Screening
      id={SCREENING_TITLES.international_canada_bc_enhanced_criminal_search.id}
      title='Canada BC Enhanced Criminal Search'
      statusType={reportStatusType}
      screening={search}
    >
      <M.SubScreening
        title='British Columbia, Canada (CA)'
        screening={search}
        statusType={reportStatusType}
        hideCompletedAt
        status={
          search.international_canada_bc_enhanced_criminal_standalone_status
        }
      >
        {search.identity_verifications?.map(verification => (
          <IDVerification
            key={verification.task_id}
            id_verification={verification}
            statusType={reportStatusType}
          />
        ))}
        {substatus && <Substatus substatus={substatus} />}
        {status === ReportStatuses.CONSIDER &&
          search.criminal_records &&
          search.criminal_records.map(record => (
            <CriminalRecord
              key={record.id}
              record={record}
              report={report}
              statusType={reportStatusType}
            />
          ))}
        {search.pdf_url && (
          <SearchPDF
            url={search.pdf_url}
            text='View British Columbia, Canada (CA) enhanced criminal report'
          />
        )}
      </M.SubScreening>
      {pic_status && (
        <M.SubScreening
          title='Police Information Check with Vulnerable Sector Screening (PIC-VS)'
          statusType={reportStatusType}
          status={pic_status}
        >
          {pic_status === ReportStatuses.PENDING && (
            <div>
              <div>
                Candidate must request PIC-VS from their local police department
                and upload a copy of their file to the Checkr portal for review.
              </div>
            </div>
          )}
          {search.canada_police_information_check_document_uris &&
            search.canada_police_information_check_document_uris?.length ===
              1 && (
              <SearchPDF
                url={search.canada_police_information_check_document_uris[0]}
                text='View additional PIC-VS document provided by the candidate'
              />
            )}
          {search.canada_police_information_check_document_uris &&
            search.canada_police_information_check_document_uris?.length > 1 &&
            search.canada_police_information_check_document_uris.map(
              (url, index) => (
                <SearchPDF
                  key={url}
                  url={url}
                  text={`View PIC-VS document provided by the candidate. Part ${
                    index + 1
                  }`}
                />
              ),
            )}
        </M.SubScreening>
      )}
      <PicVSRegulatoryRequirements />
    </M.Screening>
  );
};

export default InternationalCanadaBCEnhancedCriminalSearch;
