import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  getDisplayPrice,
  getServiceAndPassthroughFees,
} from 'components/BetterOrderExperience/utils/PricingUtils';
import {
  hasPermission,
  PostPackageType,
  PostScreening,
} from '@dashboard-experience/utils';
import { ALIAS_AS_AN_ADDON } from 'Flags';
import { useUser } from 'context/CurrentUser';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import ThirdPartyFees from 'components/AddScreenings/shared/ThirdPartyFees';
import { isPackageNameInvalid } from 'components/Packages/CreatePackage/CreatePackageReducer';
import { buildPostBodyWithAddOns } from 'components/AddScreenings/shared/utils';
import useGetPackageWithAddonsPrice from 'components/BetterOrderExperience/hooks/useGetPackageWithAddonsPrice';
import {
  includesAlias,
  aliasScreenings,
} from 'components/BetterOrderExperience/utils/PackageScreeningUtils';
import {
  StyledSection,
  StyledSummaryContainer,
  StyledBoldLinePrice,
  StyledSubtotalHeading,
  StyledCheckbox,
  StyledSaveForNextTimeContainer,
  StyledSavePackageNameContainer,
  StyledTextInput,
} from './OrderSummary.styles';
import type { AddonsT } from '..';
import SelectedPackageSection from './Sections/SelectedPackageSection';
import CustomizationsSection from './Sections/CustomizationsSection';
import AliasCheckbox from './Sections/Alias/AliasCheckbox';
import AliasPrice from './Sections/Alias/AliasPrice';
import { namespace } from '../../locales';

const OrderSummary: React.FC<{
  selectedPackage: PostPackageType;
  setPricesSummary: Dispatch<SetStateAction<AddonsT.PricesSummary>>;
  aliasIsChecked: boolean;
  setAliasIsChecked: Dispatch<SetStateAction<boolean>>;
  newPackageName: string | undefined;
  setNewPackageName: (name: string) => void;
  saveForNextTime: boolean;
  setSaveForNextTime: (save: boolean) => void;
  addedScreeningTypeWithPrices: AddonsT.ScreeningTypeWithPrices[];
  additionalProperties: AddonsT.AdditionalProperties;
}> = ({
  selectedPackage,
  setPricesSummary,
  aliasIsChecked,
  setAliasIsChecked,
  newPackageName,
  setNewPackageName,
  saveForNextTime,
  setSaveForNextTime,
  addedScreeningTypeWithPrices,
  additionalProperties,
}) => {
  const { t } = useTranslation(namespace);
  const currentUser = useUser();
  const canManagePackages = hasPermission(currentUser, 'manage_packages');

  const [newPackageNameInvalid, setNewPackageNameInvalid] = useState(false);

  const addedScreeningTypes = addedScreeningTypeWithPrices.map(
    s => s.screening.type,
  );

  const aliasFlag = useFlag(ALIAS_AS_AN_ADDON)?.variantKey === 'on';
  const hasAliasSkus = !!selectedPackage?.use_alias_skus;
  const displayAliasCheckbox =
    aliasFlag &&
    !hasAliasSkus &&
    selectedPackage?.screenings?.some(p =>
      aliasScreenings.includes((p as PostScreening).type),
    );
  const displayAliasPrice =
    aliasFlag &&
    includesAlias({
      screenings: selectedPackage?.screenings as PostScreening[],
      use_alias_skus: hasAliasSkus,
    });
  const prices = addedScreeningTypeWithPrices.map(p => {
    if (p.price === 'Price varies') {
      return 0;
    }
    if (typeof p.price === 'string') {
      return parseFloat(p.price.replace('$', '')) * 100;
    }
    return p.price;
  });
  const addedScreeningTypePrices = prices.reduce(
    (price, sum) => price + sum,
    0,
  );
  const queryPackage = (aliasEnabled: AddonsT.Alias) => {
    return buildPostBodyWithAddOns({
      basePackage: {
        ...selectedPackage,
        aliases_enabled: aliasEnabled,
      },
      addedScreeningTypes,
      additionalProperties,
      packageName: selectedPackage.name,
      setSlug: saveForNextTime,
      isPrivate: !saveForNextTime,
    });
  };
  const pendingAddOnPricesQuery = useGetPackageWithAddonsPrice(
    [
      'pending-addon-on-prices',
      selectedPackage.name,
      addedScreeningTypes,
      additionalProperties,
      true,
    ],
    currentUser?.account,
    queryPackage('off'),
  );
  const pendingAddOnPricesQueryWithAlias = useGetPackageWithAddonsPrice(
    [
      'pending-addon-on-prices-with-alias',
      selectedPackage.name,
      addedScreeningTypes,
      additionalProperties,
      true,
    ],
    currentUser?.account,
    queryPackage('on'),
  );

  const addOnPricesData =
    aliasIsChecked && aliasFlag
      ? pendingAddOnPricesQueryWithAlias?.data
      : pendingAddOnPricesQuery?.data;
  const aliasPrice =
    (pendingAddOnPricesQueryWithAlias?.data?.fixed_price ?? 0) -
    (pendingAddOnPricesQuery?.data?.fixed_price ?? 0);

  const fees = useMemo(() => {
    return getServiceAndPassthroughFees(addOnPricesData);
  }, [addOnPricesData]);
  const packagePrice = selectedPackage.price;

  const handlePackageNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewPackageName(e.target.value);
      setNewPackageNameInvalid(isPackageNameInvalid(e.target.value));
    },
    [setNewPackageName],
  );

  const handleSaveForNextTimeCheckboxClick = useCallback(() => {
    setSaveForNextTime(!saveForNextTime);
  }, [saveForNextTime, setSaveForNextTime]);

  useEffect(() => {
    const minTotalFees = fees.passthroughFeeMin + fees.serviceFeeMin;
    const maxTotalFees = fees.passthroughFeeMax + fees.serviceFeeMax;

    // TODO: Move this setPricesSummary outside of useEffect to prevent rerenders
    setPricesSummary({
      packagePrice,
      addedScreeningTypePrices,
      minTotalFees,
      maxTotalFees,
      subtotal: addOnPricesData?.fixed_price ?? 0,
      aliasPrice: aliasIsChecked && aliasFlag ? aliasPrice : 0,
    });
  }, [
    addOnPricesData?.fixed_price,
    addedScreeningTypePrices,
    aliasIsChecked,
    aliasPrice,
    fees.passthroughFeeMax,
    fees.passthroughFeeMin,
    fees.serviceFeeMax,
    fees.serviceFeeMin,
    packagePrice,
    pendingAddOnPricesQuery?.data?.fixed_price,
    pendingAddOnPricesQueryWithAlias?.data?.fixed_price,
    setPricesSummary,
    aliasFlag,
  ]);

  return (
    <StyledSummaryContainer>
      <h4 className='mb-0'>{t('orderSummary.header')}</h4>
      <SelectedPackageSection
        selectedPackage={selectedPackage}
        packagePrice={
          displayAliasPrice ? packagePrice - aliasPrice : packagePrice
        }
      />

      {addedScreeningTypeWithPrices.length > 0 && (
        <CustomizationsSection
          addedScreeningTypeWithPrices={addedScreeningTypeWithPrices}
          additionalProperties={additionalProperties}
        />
      )}

      {displayAliasCheckbox && (
        <StyledSection>
          <AliasCheckbox
            aliasIsChecked={aliasIsChecked}
            setAliasIsChecked={setAliasIsChecked}
            aliasPrice={aliasPrice}
          />
        </StyledSection>
      )}

      <StyledSection>
        {displayAliasPrice && <AliasPrice aliasPrice={aliasPrice} />}
        <StyledBoldLinePrice className='bold-line-price'>
          <StyledSubtotalHeading>
            {t('orderSummary.subtotal')}
          </StyledSubtotalHeading>
          <StyledSubtotalHeading
            className='subtotal-heading'
            data-testid='summary-section-subtotal-price'
          >
            {/* TODO rewrite LearnMoreLink in BOX */}
            {selectedPackage.international_only ? (
              <LearnMoreLink
                linkText='Price varies'
                href='https://checkr.com/pricing/international'
                data-testid='learn-more-link-international-price-varies'
              />
            ) : (
              getDisplayPrice(addOnPricesData?.fixed_price)
            )}
          </StyledSubtotalHeading>
        </StyledBoldLinePrice>
      </StyledSection>
      {/* TODO rewrite ThirdPartyFees in BOX */}
      <ThirdPartyFees
        serviceFeeMin={fees.serviceFeeMin}
        serviceFeeMax={fees.serviceFeeMax}
        passthroughFeeMin={fees.passthroughFeeMin}
        passthroughFeeMax={fees.passthroughFeeMax}
      />
      {canManagePackages && (
        <StyledSaveForNextTimeContainer data-testid='save-for-next-time-container'>
          <StyledCheckbox
            data-testid='save-for-next-time-checkbox'
            id='save-for-next-time-checkbox'
            checked={saveForNextTime}
            labelText={t('orderSummary.saveForNextTime')}
            onChange={handleSaveForNextTimeCheckboxClick}
          />

          {saveForNextTime && (
            <StyledSavePackageNameContainer>
              <StyledTextInput
                data-testid='name-your-package-textinput'
                id='name-your-package-textinput'
                labelText={t('orderSummary.nameYourPackage')}
                value={newPackageName}
                invalid={newPackageNameInvalid}
                onChange={handlePackageNameChange}
                invalidText={t('orderSummary.invalidNameText')}
              />
            </StyledSavePackageNameContainer>
          )}
        </StyledSaveForNextTimeContainer>
      )}
    </StyledSummaryContainer>
  );
};

export default OrderSummary;
