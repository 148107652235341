import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { TLogsFilters } from '../../types';
import { FilterGroup, FilterLabel } from '../styles';

interface URLFilterProps {
  url: string;
  setFilters: React.Dispatch<React.SetStateAction<TLogsFilters>>;
}

const URLFilter: React.FC<URLFilterProps> = ({ url, setFilters }) => {
  const handleUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFilters(prev => ({ ...prev, url: value }));
    },
    [setFilters],
  );

  return (
    <FilterGroup>
      <FilterLabel>URL</FilterLabel>
      <M.TextInput
        placeholder='https://unifibgc-us.production.env.icims.tools/*'
        value={url}
        onChange={handleUrlChange}
        data-testid='ats-logs-url-input'
      />
    </FilterGroup>
  );
};

export default URLFilter;
