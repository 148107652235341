import React from 'react';
import { Document } from 'types';
import { M } from '@dashboard-experience/mastodon';
import { Report } from '@dashboard-experience/utils';
import PdfItem from './pdf-item';
import { groupDocumentsByType } from './useGetDocuments';

type PdfItemsProps = {
  report: Report;
  closeFn: Function;
};

const PdfItems: React.FC<PdfItemsProps> = ({ report, closeFn }) => {
  const pdfsForType = groupDocumentsByType(report);

  const snapshots: any[] = [];
  Object.entries(pdfsForType || {}).forEach(([pdfType, pdfs]: any) => {
    const { historical } = pdfs;

    historical.forEach((snapshotDocument: Document) => {
      snapshots.push(
        <PdfItem
          pdfType={pdfType}
          report={report}
          document={snapshotDocument}
          closeFn={closeFn}
        />,
      );
    });
  });

  if (snapshots.length === 0) return null;

  return (
    <M.ActionSubMenuItem position='left-bottom' title='Historical PDFs'>
      {snapshots}
    </M.ActionSubMenuItem>
  );
};

PdfItems.displayName = 'PdfItems';
export default PdfItems;
