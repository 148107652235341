import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import { hasPermission } from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import { BETTER_DASHBOARD_ORDER_EXPERIENCE } from 'Constants';
import { CtaCard } from './shared';

const DesktopCta = styled(CtaCard)`
  display: none;
  align: right;

  @media (min-width: 42rem) {
    display: block;
    margin-top: -5rem;
  }
`;

const MobileCta = styled(M.Button)`
  @media (min-width: 42rem) {
    display: none;
  }

  margin-top: 1rem !important;

  svg {
    margin-left: 0.5rem;
  }
`;

const OrderBackgroundCheck: React.FC = () => {
  const { t } = useTranslation('dashboard_landing');
  const history = useHistory();
  const currentUser = useUser();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');
  const useBetterOrderExperienceFlow =
    useFlag(BETTER_DASHBOARD_ORDER_EXPERIENCE)?.variantKey === 'on';

  const clickHandler = useCallback(() => {
    const path = useBetterOrderExperienceFlow
      ? '/order-experience/get-started'
      : '/order/get-started';

    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.ORDER_BGC_CLICKED);
    }

    history.push(path);
  }, [history, isSignup, trackEvent]);

  return (
    <>
      <DesktopCta
        data-testid='order_background_check_desktop'
        translationKey='ctaOrderBackgroundCheck'
        ctaColor='navy'
        className='order-background-check'
        onCardClick={clickHandler}
        isShown={hasPermission(currentUser, 'manage_invitations')}
      />
      {hasPermission(currentUser, 'manage_invitations') && (
        <MobileCta
          kind='secondary'
          size='sm'
          onClick={clickHandler}
          data-testid='order_background_check_mobile'
        >
          {t(`ctaCards.ctaOrderBackgroundCheck.title`)}
          <M.Icon icon='ArrowRight' size={16} />
        </MobileCta>
      )}
    </>
  );
};

export default OrderBackgroundCheck;
