/* eslint-disable import/prefer-default-export */
import { ReportsClient } from 'api';

export const getReportAssessmentVariantRegistrations = (
  reportId: any,
): Promise<any> => {
  return ReportsClient.get(
    `${reportId}/assessment_variant_registrations`,
  ) as Promise<any>;
};
