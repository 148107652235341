import React, { useEffect, useReducer, useMemo } from 'react';
import { CurrentUser } from '@dashboard-experience/utils';
import { usePreference } from 'api/dashboardPreferences';
import useSearchURLParams from 'components/Search/useSearchURLParams';

import CustomProvider from 'state/provider';
import { newView } from './constants';
import context from './context';
import reducer from './reducer';
import { ActionType } from './types';

type Props = {
  currentUser: CurrentUser;
};

const Provider: React.FC<Props> = ({ currentUser, children }) => {
  // TODO: Assess if we need to eventually migrate PARAMS.updateGeneralParams call once user is available

  const [savedView, setSavedView] = usePreference('searchViews');
  // Clear the preference if it does not match the expected type
  if (
    savedView !== undefined &&
    typeof savedView !== 'object' &&
    !savedView.filters
  )
    setSavedView(undefined);

  const initialState = {
    view: savedView || newView(),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // On initial pageload, populate the state with querystring params
  // Provided params should override saved params
  useEffect(() => {
    dispatch({ type: ActionType.INITIALIZE_FILTERS });
    // Non-exhaustive deps because we ONLY want to run on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Populate the url
  useSearchURLParams(state.view?.filters || {});

  const props = useMemo(
    () => ({ currentUser, state, dispatch }),
    [currentUser, state],
  );

  return (
    <CustomProvider context={context} props={props} stateKey='reportsList'>
      {children}
    </CustomProvider>
  );
};

export default Provider;
