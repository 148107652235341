import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSection } from '../OrderSummary.styles';
import { namespace } from '../../../locales';
import { AddonsT } from '../..';
import CustomizationScreeningItem from './CustomizationScreeningItem';
import { StyledScreeningList } from './CustomizationScreeningItem.styles';
import { ScreeningProductType } from '../../AddonsStep.enums';

const CustomizationsSection: React.FC<{
  addedScreeningTypeWithPrices: AddonsT.ScreeningTypeWithPrices[];
  additionalProperties: AddonsT.AdditionalProperties;
}> = ({ addedScreeningTypeWithPrices, additionalProperties }) => {
  const { t } = useTranslation(namespace);

  return (
    <StyledSection>
      <h5>{t('orderSummary.customizations')}</h5>
      <StyledScreeningList data-testid='screening-list'>
        {addedScreeningTypeWithPrices.map(item => {
          return item.screening.type !==
            ScreeningProductType.INTERNATIONAL_ADVERSE_MEDIA_SEARCH ? (
            <CustomizationScreeningItem
              key={item.screening.type}
              addedScreeningTypeWithPrices={item}
              additionalProperties={additionalProperties}
            />
          ) : null;
        })}
      </StyledScreeningList>
    </StyledSection>
  );
};

export default CustomizationsSection;
