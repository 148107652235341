import React, { Dispatch, SetStateAction } from 'react';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { getDollarAmount } from 'components/BetterOrderExperience/utils/PricingUtils';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';
import { PROMO_CODE_FIELD } from 'Flags';
import { namespace } from '../../locales';
import {
  StyledItemPricing,
  StyledLink,
  StyledPricingContainer,
  StyledSubNote,
  StyledSubNoteText,
  StyledSubtotalPricing,
} from './PricingSection.styles';
import PromoCodeInput, { PromoCodeValidationType } from './PromoCodeInput';

type PricingSectionType = {
  packageAlreadyIncludesAlias?: boolean;
  promoCode?: string;
  setPromoCode: Dispatch<SetStateAction<string>>;
  promoCodeValidation: PromoCodeValidationType;
};

const PricingSection = ({
  packageAlreadyIncludesAlias = false,
  promoCode = '',
  setPromoCode,
  promoCodeValidation,
}: PricingSectionType) => {
  const { selectedPackage, pricesSummary } = useBetterOrderExperienceContext();
  const { t } = useTranslation(namespace);
  const shouldShowPromoCodeField =
    useFlag(PROMO_CODE_FIELD)?.variantKey === 'on';
  const packagePrice = packageAlreadyIncludesAlias
    ? pricesSummary.packagePrice - pricesSummary.aliasPrice
    : pricesSummary.packagePrice;

  return (
    <>
      <StyledPricingContainer data-testid='summary-of-pricing'>
        <StyledItemPricing data-testid='summary-base-package-price'>
          {t(`reviewAndSubmit.infoSection.pricingSummary.package`, {
            price: getDollarAmount(packagePrice),
          })}
        </StyledItemPricing>
        <StyledItemPricing data-testid='summary-addons-price'>
          {t(`reviewAndSubmit.infoSection.pricingSummary.customizations`, {
            price: getDollarAmount(pricesSummary.addedScreeningTypePrices),
          })}
        </StyledItemPricing>
        {pricesSummary.aliasPrice !== 0 && (
          <StyledItemPricing data-testid='summary-aliases-price'>
            {t(`reviewAndSubmit.infoSection.pricingSummary.aliases`, {
              price: getDollarAmount(pricesSummary.aliasPrice),
            })}
          </StyledItemPricing>
        )}
        <StyledSubtotalPricing data-testid='summary-subtotal-price'>
          {t(`reviewAndSubmit.infoSection.pricingSummary.subtotal`, {
            price: getDollarAmount(pricesSummary.subtotal),
          })}
        </StyledSubtotalPricing>
        {shouldShowPromoCodeField && (
          <PromoCodeInput
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            promoCodeValidation={promoCodeValidation}
          />
        )}
        {pricesSummary.maxTotalFees > 0 && (
          <StyledItemPricing data-testid='summary-third-party-price'>
            {t(`reviewAndSubmit.infoSection.pricingSummary.thirdPartyFees`, {
              minFee: getDollarAmount(pricesSummary.minTotalFees),
              maxFee: getDollarAmount(pricesSummary.maxTotalFees),
            })}
          </StyledItemPricing>
        )}
      </StyledPricingContainer>
      <StyledSubNote $showBorder={selectedPackage.price !== 0}>
        <StyledSubNoteText>
          {t(`reviewAndSubmit.infoSection.pricingSection.excludes`)}{' '}
          <StyledLink
            href='https://checkr.com/pricing/pass-through-fees'
            data-testid='footer-learn-more'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t(`reviewAndSubmit.infoSection.pricingSection.passthroughFees`)}
          </StyledLink>
          {t(`reviewAndSubmit.infoSection.pricingSection.remainingPricingText`)}
        </StyledSubNoteText>
      </StyledSubNote>
    </>
  );
};

export default PricingSection;
