import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const StyledManualInviteEntriesContainer = styled.div`
  margin: 30px 0;
`;

export const StyledEnterManuallyOrPaste = styled.p`
  margin-bottom: 12px !important;
`;

export const StyledInviteEntryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  width: 100%;
  padding: 16px;
`;

export const StyledInviteFormField = styled(M.TextInput)`
  flex-grow: 1;
  flex-basis: 0;
`;

export const StyledInviteBox = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

export const StyledInviteComboBox = styled(M.ComboBox)`
  &.custom-role .cds--list-box__menu-item:last-child:not(.cds--list-box__menu-item--active) {
    .cds--list-box__menu-item__option::before {
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      position: absolute;
      content: url('data:image/svg+xml;utf8,<svg fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true" class="mastodon-icon" xmlns="http://www.w3.org/2000/svg"><path d="M17 15L17 8 15 8 15 15 8 15 8 17 15 17 15 24 17 24 17 17 24 17 24 15z"></path></svg>');
    }
`;
