import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { IDENTITY_VERIFICATION_REDESIGN } from 'Flags';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IdentityVerification,
  InternationalCriminalSearchV2 as Search,
} from 'types';
import styled from 'styled-components';
import { StatusTypes } from '@dashboard-experience/utils';

import {
  Container,
  Header,
  IconWrapper,
  NextSteps,
  Title,
} from './styledComponents';

import {
  buildSubstatus,
  getIdvUnsuccessfulMessageType,
  getIdvStatus,
  IdvMessageType,
} from './utils';
import { Substatus } from './types';

const IDVerificationTitle = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.31rem;
  margin-right: 0.8rem;
  margin-top: 0;
  color: ${colors.uiGrey900};
`;

const IDVerification: React.FC<{
  id_verification: IdentityVerification;
  search?: Search;
  statusType: StatusTypes;
}> = ({ id_verification, search, statusType }) => {
  const { task_status, completed_at, task_result } = id_verification;
  const { t } = useTranslation();
  const idvRedesignEnabled =
    useFlag(IDENTITY_VERIFICATION_REDESIGN)?.variantKey === 'on' &&
    search?.country === 'GB';

  const taskResultStatus = task_result?.status.toLowerCase();

  const idvStatus = useMemo(
    () => getIdvStatus(search, task_status, taskResultStatus),
    [search, taskResultStatus, task_status],
  );

  const unsuccessfulMessageType = useMemo(
    () =>
      getIdvUnsuccessfulMessageType(
        search,
        task_status,
        taskResultStatus,
        task_result,
      ),
    [search, task_status, taskResultStatus, task_result],
  );

  const showUnsuccessfullMessage =
    unsuccessfulMessageType !== IdvMessageType.NONE;

  const unsuccessfulMessage = useCallback(() => {
    switch (unsuccessfulMessageType) {
      case IdvMessageType.EXPIRED:
        return t(`idVerification.expiredMessage`);
      case IdvMessageType.FAILED:
        return t(
          `idVerification.failureReasons.${task_result!.vendor_failure_reason}`,
        );
      case IdvMessageType.OPTED_OUT:
        return t('idVerification.candidateOptOutMessage');
      default:
        return '';
    }
  }, [unsuccessfulMessageType, t, task_result]);

  const substatus =
    task_status === 'completed' && task_result
      ? task_result.status.toLowerCase()
      : task_status;

  const statusInfo =
    showUnsuccessfullMessage && idvRedesignEnabled
      ? ({
          title:
            unsuccessfulMessageType === IdvMessageType.EXPIRED
              ? ''
              : t('idVerification.notSuccessfulTitle'),
          unsuccessfulMessage: unsuccessfulMessage(),
          icon: 'warning',
        } as Substatus)
      : buildSubstatus(substatus, completed_at, task_result);

  if (!substatus) return null;

  if (idvRedesignEnabled) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IDVerificationTitle>ID Verification</IDVerificationTitle>
            <M.CompletedAt screening={id_verification} />
          </div>
          <M.StatusBadge status={idvStatus} statusType={statusType} />
        </div>
        {showUnsuccessfullMessage && (
          <Container>
            <Header style={{ marginBottom: 0 }}>
              <IconWrapper>
                <M.StatusIcon icon={statusInfo.icon} />
              </IconWrapper>
              <Title className='title'>{`${statusInfo?.title} ${statusInfo.unsuccessfulMessage}`}</Title>
            </Header>
            <NextSteps>{t('idVerification.nextSteps')}</NextSteps>
          </Container>
        )}
      </div>
    );
  }

  return (
    <Container>
      <Header>
        <M.Icon
          icon={statusInfo.icon}
          size={16}
          style={{ fill: statusInfo.color, marginRight: '0.5rem' }}
        />
        <div className='title'>{statusInfo.title}</div>
      </Header>
    </Container>
  );
};

export default IDVerification;
