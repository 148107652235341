import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledContainer = styled.div`
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 30px;
  margin: 24px auto;
  box-shadow: 0px 4px 32px -16px rgba(26, 32, 38, 0.1);
`;

export const StyledHeader = styled.div`
  margin-bottom: 20px;
`;

export const StyledDescription = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 20px !important;
`;

export const StyledBold = styled.span`
  font-weight: bold;
`;

export const StyledInfoContainer = styled.div`
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid ${colors.brandSlate3};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  margin-left: auto;
  &#hide-modal-checkbox-container-unauthorized {
    margin-left: 0;
  }
  p {
    margin-right: 12px;
  }
  .cds--checkbox-label .cds--checkbox-label-text {
    padding-left: 0 !important;
  }
`;

export const StyledLoadingInline = styled(M.LoadingInline)`
  width: unset !important;
  svg {
    fill: #fff !important;
  }
`;
