import React from 'react';
import { IconCard } from '../../components/Account/Payment/PaymentStyledComponents';
import BankPaymentMethod from './BankPaymentMethod';

const brandClasses: Record<string, string> = {
  Visa: 'icon-visa',
  MasterCard: 'icon-master',
  Discover: 'icon-discover',
  'American Express': 'icon-express',
  JCB: 'icon-jcb',
  'Diners Club': 'icon-diners',
  UnionPay: 'icon-union',
};

interface PaymentMethodProps {
  brand?: string;
  last4?: string;
  bankName?: string;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  brand,
  last4,
  bankName,
}) => {
  if (bankName && last4) {
    return <BankPaymentMethod bankName={bankName} last4={last4} />;
  }

  if (!brand || !last4) {
    return <span>No card on file</span>;
  }

  const classNames = `icon-card ${brandClasses[brand]}`;

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <IconCard className={classNames} />
      &nbsp;••••&nbsp;
      {last4}
    </span>
  );
};

export default PaymentMethod;
