import React, { useCallback } from 'react';
import { emailIsValid } from '@dashboard-experience/utils';
import { useUpdateCandidate } from 'api/candidate';
import { FieldComponentProps } from '../../../types';
import RedesignEmail from './redesign-email';

const Email: React.FC<FieldComponentProps> = ({
  fieldKey,
  allowCandidateEdit,
  candidate,
  testid,
}) => {
  const { email, id: candidateId } = candidate;

  const displayEmail = email || '';

  const { updateCandidateCall } = useUpdateCandidate(fieldKey, 'email');

  const onEmailEditSubmit = useCallback(
    (newEmailValue: string) =>
      new Promise<void>(resolve => {
        updateCandidateCall({
          id: candidateId,
          email: newEmailValue,
        });
        if (!emailIsValid(newEmailValue)) {
          return;
        }
        resolve();
      }),
    [candidateId, updateCandidateCall],
  );

  return (
    <div data-testid={testid}>
      <RedesignEmail
        candidate={candidate}
        allowEdit={allowCandidateEdit || false}
        displayEmail={displayEmail}
        onEmailEditSubmit={onEmailEditSubmit}
      />
    </div>
  );
};

export default Email;
