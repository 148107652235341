import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import {
  AdjudicationStatuses,
  AssessStatuses,
  formatDate,
  InvitationStatuses,
  ReportStatuses,
} from '@dashboard-experience/utils';

const Tooltip = styled(M.Tooltip)`
  border: none !important;

  #mastodon && {
    .cds--popover-content {
      max-width: 20rem;
    }
  }
`;

export const getStatusTooltip = (status: any, isReport: boolean) => {
  if (isReport) {
    switch (status) {
      case ReportStatuses.CLEAR:
        return 'The completed report has nothing for you to review';
      case AssessStatuses.ELIGIBLE:
        return 'All records in the report are not relevant based on your Assess guidelines';
      case AssessStatuses.REVIEW:
        return 'The completed report has information for you to review';
      case AssessStatuses.ESCALATED:
        return 'Your Assess Premium guidelines flagged at least one record';
      case AssessStatuses.CONSIDER:
        return 'The completed report has information for you to review';
      case ReportStatuses.QUEUED:
        return 'The check starts after Checkr verifies your business';
      case ReportStatuses.PENDING:
        return 'The report is processing';
      case ReportStatuses.DISPUTE:
        return 'The candidate is disputing part of the completed report';
      case ReportStatuses.SUSPENDED:
        return 'The report stopped processing and will continue with more information';
      case ReportStatuses.CANCELED:
        return "The report stopped processing and won't continue";
      default:
        return `The report is in the ${status} state`;
    }
  }

  switch (status) {
    case InvitationStatuses.INVITATION_SENT:
      return "Checkr sent the invitation link, but the candidate hasn't responded";
    case InvitationStatuses.EXPIRED:
      return 'The invitation has expired';
    default:
      return `The invitation is in the ${status} state`;
  }
};

const truncateText = (text: string, maxLength: number = 16): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
};

export const formatAdjudication = (adjudication: string) => {
  switch (adjudication) {
    case AdjudicationStatuses.ENGAGED:
      return 'Engaged';
    case AdjudicationStatuses.PRE_ADVERSE_ACTION:
      return 'Pre-adverse action';
    case AdjudicationStatuses.POST_ADVERSE_ACTION:
      return 'Post-adverse action';
    case AdjudicationStatuses.MANUAL:
      return 'Manual';
    case AdjudicationStatuses.DEFERRED:
      return 'Deferred';
    default:
      return adjudication;
  }
};

export const DotDivider: React.FC<{}> = () => (
  <M.Icon icon='DotMark' size='8px' color='rgba(26, 32, 38, 0.48)' />
);

type DateLabelProps = {
  isReport: boolean;
  createdAt: string;
};

export const DateLabel: React.FC<DateLabelProps> = ({
  isReport,
  createdAt,
}) => (
  <span className='report-label' data-testid='report-information-expanded-date'>
    Created: {formatDate(createdAt, 'MMM D, YYYY')}
  </span>
);

type StatusBadgeProps = {
  isReport: boolean;
  status: string;
};

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  isReport,
  status,
}) => (
  <Tooltip
    data-testid='candidate-header-status-badge'
    align='bottom-left'
    label={getStatusTooltip(status, isReport)}
  >
    <M.StatusBadge status={status} data-pendo-id='overall-report-assessment' />
  </Tooltip>
);

type PackageLabelProps = {
  pkg: string;
};

export const PackageLabel: React.FC<PackageLabelProps> = ({ pkg }) => (
  <Tooltip align='bottom-left' label={`${pkg} package`}>
    <span
      className='report-label'
      data-testid='report-information-expanded-package'
    >
      {truncateText(pkg)}
    </span>
  </Tooltip>
);

type AdjudicationLabelProps = {
  adjudication?: string;
};

export const AdjudicationLabel: React.FC<AdjudicationLabelProps> = ({
  adjudication,
}) =>
  adjudication ? (
    <Tooltip align='bottom-left' label='Your team took this action'>
      <span
        className='report-label'
        data-testid='report-information-expanded-adjudication'
      >
        {formatAdjudication(adjudication)}
      </span>
    </Tooltip>
  ) : (
    <span className='report-information-expanded-adjudication'>No action</span>
  );

export const AssessmentVariantLabel: React.FC<{
  label: string;
}> = ({ label }) => {
  return (
    <span className='report-information-expanded-assessment-variant-label'>
      {label}
    </span>
  );
};
