import { createContext } from 'use-context-selector';
import { Context } from './types';

export default createContext<Context>({
  candidateExceptions: [],
  candidate: { id: '' },
  candidateId: '',
  components: [],
  showReportLog: false,
  setShowReportLog: (show: boolean) => {},
  iframeWindow: null,
  setIframeWindow: () => {},
  reportTabs: {
    tab: 'Background Report',
    setTab: () => {},
    selectedIndex: 0,
    setSelectedIndex: () => {},
    customerView: true,
    setCustomerView: () => {},
  },
  pinnedFields: [],
  setPinnedFields: (pinnedFields: string[]) => {},
});
