import React from 'react';
import { kebabCase } from 'lodash';
import { M } from '@dashboard-experience/mastodon';
import { CurrentUser } from '@dashboard-experience/utils';
import TableHeaderWithSort from './table-header-with-sort';

type Props = {
  currentUser: CurrentUser;
  columns: any;
  isDSP: boolean;
};

const TableHead: React.FC<Props> = ({ currentUser, columns, isDSP }) => {
  // TODO: confirm if these geos are teh same as the onex from useSearchState
  const { geos } = currentUser;

  return (
    <M.TableHead data-testid='search-table-head'>
      <M.TableRow style={{ fontSize: '12px' }}>
        <M.TableHeader style={{ width: '2%' }} />
        {columns.map((column: any) => {
          const { sortable, header, key } = column;
          const hasPermission = column.hasPermission({
            currentUser,
            geos,
          });
          const tableHeaderDataTestId = `search-table-column-header-${kebabCase(
            header,
          )}`;

          return (
            hasPermission && (
              <React.Fragment key={`column-header-wrapper-${key}`}>
                {sortable ? (
                  <TableHeaderWithSort
                    key={`column-header-${key}`}
                    currentUser={currentUser}
                    column={column}
                    isDSP={isDSP}
                  />
                ) : (
                  <M.TableHeader
                    key={`table-column-header-${key}`}
                    data-testid={tableHeaderDataTestId}
                    style={column.headerStyle}
                  >
                    {header}
                  </M.TableHeader>
                )}
              </React.Fragment>
            )
          );
        })}
      </M.TableRow>
    </M.TableHead>
  );
};

export default TableHead;
