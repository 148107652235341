import { useQuery } from 'react-query';
import axios from 'axios';
import { useFlag } from '@dashboard-experience/react-flagr';
import { CurrentUser, GenericObject } from '@dashboard-experience/utils';
import { ENABLE_ARCHIVE_REPORTS, REPORTS_LIST_REDESIGN } from 'Flags';
import { getCandidates } from 'api/search';
import { getSearchQuery, searchParamsWithArchived } from './utils';
import { View } from '../ui/state/types';

export const useReportsListRedesignPhaseOne = () => {
  return useFlag(REPORTS_LIST_REDESIGN)?.variantKey === 'on';
};
const cancelToken = axios.CancelToken;

export const useGetReportsList = (
  view: View,
  page: number,
  currentUser: CurrentUser,
  account: any,
  setReports: React.Dispatch<React.SetStateAction<GenericObject | undefined>>,
  setCancelSource: React.Dispatch<any>,
) => {
  const archiveReportOn = useFlag(ENABLE_ARCHIVE_REPORTS).variantKey === 'on';
  const { filters } = view;

  return useQuery(
    ['reportsList', filters, account, archiveReportOn],
    async () => {
      // Cancel in-flight search requests and generate a new cancel token before refetching
      const newCancelSource = cancelToken.source();
      const { token } = newCancelSource;
      setCancelSource((oldCancelSource: any | null) => {
        if (oldCancelSource) oldCancelSource.cancel();
        return newCancelSource;
      });

      const params = archiveReportOn
        ? searchParamsWithArchived(currentUser, filters)
        : filters;

      const query = getSearchQuery(params, account);
      const url = `/search?${query}`;

      return getCandidates({ url, cancelToken: token });
    },
    {
      enabled: !!account && Object.keys(filters).length > 0,
      onSuccess: data => {
        setReports(data as GenericObject);
      },
      refetchOnWindowFocus: false,
    },
  );
};
