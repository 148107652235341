import React, { useCallback, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from '../../../../utils';

const InfoButton = styled.div`
  #mastodon && {
    .mastodon-button.tertiary svg {
      fill: rgba(26, 32, 38, 0.78);
    }
  }
`;

const IconButton = styled(M.Button)`
  border: none !important;
`;

interface ContainerProps {
  showModal: boolean;
  wasOpened: boolean;
}

const slideIn = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 2rem;
  }
`;

const slideOut = keyframes`
  from {
    right: 2rem;
  }
  to {
    right: -100%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<ContainerProps>`
  position: absolute;
  right: 0;
  top: 0;
  width: 500px;
  height: 100%;
  padding: 48px 48px 48px 8px;
  background: linear-gradient(
    270deg,
    rgba(135, 147, 161, 0.25) 29.23%,
    rgba(135, 147, 161, 0) 100%
  );
  z-index: 999;
  display: ${({ wasOpened }) => (wasOpened ? 'block' : 'none')};
  pointer-events: none;
  animation: ${({ showModal }) =>
    showModal
      ? css`
          ${fadeIn} 0.5s forwards
        `
      : css`
          ${fadeOut} 0.5s forwards
        `};
`;

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 50%;
  right: ${({ showModal }) => (showModal ? '2rem' : '-100%')};
  transform: translateY(-50%);
  width: 500px;
  max-height: calc(100% - 6rem);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: ${({ wasOpened }) => (wasOpened ? 'flex' : 'none')};
  flex-direction: column;
  animation: ${({ showModal }) =>
    showModal
      ? css`
          ${slideIn} 0.5s forwards
        `
      : css`
          ${slideOut} 1s forwards
        `};

  #mastodon && {
    h5 {
      margin-top: 1rem;
    }

    h5:not(.mb-0) {
      margin-bottom: 0.5rem;
    }

    h5 + p {
      margin-top: 0;
    }

    p {
      color: rgba(26, 32, 38, 0.78);
      margin-bottom: 0;
      margin-top: 0;
    }

    .mastodon-button.tertiary svg {
      fill: rgba(26, 32, 38, 0.78);
    }
  }
`;

export const CloseButton = styled(M.Button)`
  border: none !important;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 48px 32px 20px 32px;
`;

const Footer = styled.div`
  padding: 16px 28px 16px 32px;
  border-top: 1px solid ${colors.uiGrey200};
  box-shadow: 0 -4px 4px -3px rgba(72, 82, 94, 0.1);
`;

const FooterFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const StyledTooltipWrapper = styled.div`
  .cds--popover--caret {
    --cds-popover-offset: 0.25rem;
  }

  .cds--popover-caret {
    visibility: hidden;
  }
`;

const InformationModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  // Don't render modal until first opened.
  // This prevents the modal from sliding off the screen when the component first loads
  const [wasOpened, setWasOpened] = useState(false);

  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  const openModal = useCallback(() => {
    setShowModal(true);
    setWasOpened(true);

    trackEvent(CANDIDATE_REPORT_EVENT_NAMES.VIEW_ADVERSE_ACTION_INFO, {
      Report: reportProperties,
    });
  }, [reportProperties, trackEvent]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <div className='adjudication-information-modal'>
      <StyledTooltipWrapper>
        <M.TooltipDefinition
          align='bottom'
          highlighted={false}
          definition='Documenting decisions info'
        >
          <InfoButton>
            <IconButton
              kind='tertiary'
              size='small'
              onClick={openModal}
              data-testid='adjudication-information-modal-open'
            >
              <M.Icon icon='Information' data-testid='test' />
            </IconButton>
          </InfoButton>
        </M.TooltipDefinition>
      </StyledTooltipWrapper>
      <Overlay showModal={showModal} wasOpened={wasOpened} />
      <Container
        showModal={showModal}
        wasOpened={wasOpened}
        data-testid='adjudication-information-modal'
      >
        <Content>
          <CloseButton kind='tertiary' size='small' onClick={closeModal}>
            <M.Icon icon='Close' />
          </CloseButton>

          <h3>{t('adjudicationInfoModal.header')}</h3>
          <br />
          <p>{t('adjudicationInfoModal.headerInfo')}</p>
          <h5>
            <span style={{ color: colors.brandAqua2 }}>1&nbsp;&nbsp;</span>
            <span>{t('adjudicationInfoModal.engage')}</span>
          </h5>
          <p>{t('adjudicationInfoModal.engageDescription')}</p>
          <h5>
            <span style={{ color: colors.brandAqua2 }}>2&nbsp;&nbsp;</span>
            <span>{t('adjudicationInfoModal.adverseAction')}</span>
          </h5>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('adjudicationInfoModal.adverseActionDescription', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
          <br />
          <hr />
          <br />
          <p>{t('adjudicationInfoModal.actionsInfo')}</p>
          <h5>{t('adjudicationInfoModal.reverseDecision')}</h5>
          <p>{t('adjudicationInfoModal.reverseDecisionDescription')}</p>
          <h5>{t('adjudicationInfoModal.requestCandidateStory')}</h5>
          <p>{t('adjudicationInfoModal.requestCandidateStoryDescription')}</p>
          <h5>{t('adjudicationInfoModal.cancelAdverseAction')}</h5>
          <p>{t('adjudicationInfoModal.cancelAdverseActionDescription')}</p>
          <h5>{t('adjudicationInfoModal.pauseAdverseAction')}</h5>
          <p>{t('adjudicationInfoModal.pauseAdverseActionDescription')}</p>
          <h5>{t('adjudicationInfoModal.resumeAdverseAction')}</h5>
          <p>{t('adjudicationInfoModal.resumeAdverseActionDescription')}</p>
          <h5>{t('adjudicationInfoModal.postAdverseAction')}</h5>
          <p>{t('adjudicationInfoModal.postAdverseActionDescription')}</p>
          <h5>{t('adjudicationInfoModal.updateAssessment')}</h5>
          <p>{t('adjudicationInfoModal.updateAssessmentDescription')}</p>
          <h5>{t('adjudicationInfoModal.cancelScreenings')}</h5>
          <p>{t('adjudicationInfoModal.cancelScreeningsDescription')}</p>
          <h5>{t('adjudicationInfoModal.archiveReport')}</h5>
          <p>{t('adjudicationInfoModal.archiveReportDescription')}</p>
        </Content>
        <Footer>
          <FooterFlex>
            <M.Button kind='primary' onClick={closeModal}>
              {t('buttons.got_it')}
            </M.Button>
          </FooterFlex>
        </Footer>
      </Container>
    </div>
  );
};
export default InformationModal;
