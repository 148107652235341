import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/candidate/locales';

type Props = {
  label: string;
  handleTabClick: (label: string) => () => void;
  statusIndicator?: React.ReactNode | null;
  activeEnrollmentsCount?: number | undefined;
};

const Tooltip = styled(M.TooltipDefinition)`
  .cds--popover-caret {
    visibility: hidden;
  }
  .cds--definition-tooltip {
    max-width: 45rem !important;
    --cds-popover-offset: 0;
  }
`;

const trans_path = `${namespace}:report.tabs.tooltips`;

const TabContent = ({
  label,
  statusIndicator,
  handleTabClick,
  activeEnrollmentsCount,
}: Props) => {
  const { t } = useTranslation();

  const tab = (
    <M.Tab data-testid={label} key={label} onClick={handleTabClick(label)}>
      {label}
      {!!statusIndicator && statusIndicator}
    </M.Tab>
  );

  const showTooltip = label === 'Post-hire';

  let definitionText: string;
  if (!activeEnrollmentsCount) {
    definitionText = t(`${trans_path}.post-hire-enrolled-none`);
  } else if (activeEnrollmentsCount === 1) {
    definitionText = t(`${trans_path}.post-hire-enrolled-single`);
  } else {
    definitionText = t(`${trans_path}.post-hire-enrolled-multiple`);
  }

  return showTooltip ? (
    <Tooltip align='right' definition={definitionText} highlighted={false}>
      {tab}
    </Tooltip>
  ) : (
    tab
  );
};

export default TabContent;
