import React from 'react';
import { FieldComponentProps } from '../../../types';
import ReportPackage from './report-package';

const Package: React.FC<FieldComponentProps> = ({
  report,
  invitation,
  testid,
}) => {
  if (invitation && !report?.id) return <span>{invitation.package}</span>;

  return <ReportPackage report={report} testid={testid} />;
};

export default Package;
