import { OCC_HEALTH_SERVICES } from 'components/AddScreenings/Domestic/List/DrugAndHealthScreenings/OccupationalHealth'; // TODO: Move to BOX folder
import { t } from 'i18next';
import { namespace } from '../locales';
import type { AddonsT } from '.';
import { ScreeningProductType } from './AddonsStep.enums';

export const hasAllBasicPackageScreenings = (
  screenings: AddonsT.ScreeningType[],
) => {
  const basicScreenings = [
    ScreeningProductType.SSN_TRACE,
    ScreeningProductType.GLOBAL_WATCHLIST_SEARCH,
    ScreeningProductType.SEX_OFFENDER_SEARCH,
    ScreeningProductType.NATIONAL_CRIMINAL_SEARCH,
  ];
  return basicScreenings.every(screening => screenings.includes(screening));
};

export const getDollarAmount = (val: number) =>
  (val / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const getAdditionalPropertiesLabel = (
  type: AddonsT.ScreeningType,
  additionalProperties: AddonsT.AdditionalProperties = {},
) => {
  const {
    education_verification,
    employment_verification,
    drug_screening,
    occupational_health_screening,
    motor_vehicle_report,
    professional_reference_verification,
    personal_reference_verification,
  } = additionalProperties;

  const hasProfessionalReference =
    type === ScreeningProductType.PROFESSIONAL_REFERENCE_VERIFICATION &&
    professional_reference_verification;
  const hasPersonalReference =
    type === ScreeningProductType.PERSONAL_REFERENCE_VERIFICATION &&
    personal_reference_verification;
  const hasEmploymentVerification =
    type === ScreeningProductType.EMPLOYMENT_VERIFICATION &&
    employment_verification;
  const hasCommercialMvr =
    type === ScreeningProductType.MOTOR_VEHICLE_REPORT &&
    motor_vehicle_report?.mvr_type[1] === 'commercial';
  const hasEducationVerificationInternational =
    type === ScreeningProductType.EDUCATION_VERIFICATION &&
    education_verification?.international_upgrade;
  const hasDrugScreening =
    type === ScreeningProductType.DRUG_SCREENING && drug_screening;
  const hasOccHealth =
    type === ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING &&
    occupational_health_screening;

  const addOnsTranslation = `${namespace}:addOns`;
  const commercialMvrLabel = `${addOnsTranslation}.mvrSection.commercialMvrLabel`;
  const drugTestLabel = `${addOnsTranslation}.drugAndHealth.drug.label`;
  const occHealthLabel = `${addOnsTranslation}.drugAndHealth.ohs.label`;

  const addOnsVerificationTranslation = `${namespace}:addOns.verificationSection`;
  const professionalReferenceLabel = `${addOnsVerificationTranslation}.professionalReferenceVerification.requiredAmountLabel`;
  const personalReferenceLabel = `${addOnsVerificationTranslation}.personalReferenceVerification.requiredAmountLabel`;
  const educationVerifOutOfCountryLabel = `${addOnsVerificationTranslation}.educationVerification.outOfCountryLabel`;
  const empVerifCurrentEmployerLabel = `${addOnsVerificationTranslation}.employmentVerification.lookbackYearsLabel.currentOrMostRecent`;
  const empVerifLookbackLabel = `${addOnsVerificationTranslation}.employmentVerification.lookbackYearsLabel.labelText`;
  const empVerifInternational = `${addOnsVerificationTranslation}.outOfCountry.label`;
  const empVerifDOT = `${addOnsVerificationTranslation}.employmentVerification.dot.label`;

  if (hasProfessionalReference) {
    return [
      t(professionalReferenceLabel, {
        requiredAmount:
          professional_reference_verification?.required_quantity[1],
      }),
    ];
  }
  if (hasPersonalReference) {
    return [
      t(personalReferenceLabel, {
        requiredAmount: personal_reference_verification?.required_quantity[1],
      }),
    ];
  }
  if (hasCommercialMvr) {
    return [t(commercialMvrLabel)];
  }
  if (hasEducationVerificationInternational) {
    return [t(educationVerifOutOfCountryLabel)];
  }
  if (hasEmploymentVerification) {
    const lookbackYearsText =
      employment_verification?.maximum_allowed_employers === 1
        ? t(empVerifCurrentEmployerLabel)
        : t(empVerifLookbackLabel, {
            lookbackYears: employment_verification?.lookback_years,
          });
    if (employment_verification?.international_upgrade) {
      return [lookbackYearsText, t(empVerifInternational)];
    }
    if (employment_verification?.dot_compliance) {
      return [lookbackYearsText, t(empVerifDOT)];
    }
    return [lookbackYearsText];
  }

  if (hasDrugScreening) {
    return [
      t(drugTestLabel, { drugTest: drug_screening?.selectedDrugScreen?.label }),
    ];
  }
  if (hasOccHealth) {
    const occHealthLabels = occupational_health_screening?.subtype
      ? occupational_health_screening.subtype.map(occ => {
          return t(occHealthLabel, { ohs: OCC_HEALTH_SERVICES[occ]?.label });
        })
      : [];
    return [...occHealthLabels];
  }

  return [];
};

// Labels for screenings in a package (any package list)
export const getPackageScreeningLabel = (
  screeningType: ScreeningProductType,
  isPackageList: boolean = false, // In any package list we show different copies
) => {
  if (
    screeningType === ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH &&
    !isPackageList
  ) {
    return t(
      `${namespace}:addOns.international.crimSearch.internationalCrimSearch.screeningLabel.package`,
    );
  }
  if (
    screeningType === ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH &&
    isPackageList
  ) {
    return t(
      `${namespace}:addOns.international.crimSearch.internationalCrimSearch.screeningLabel.packageList`,
    );
  }
  if (screeningType === ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION)
    return t(
      `${namespace}:addOns.verificationSection.professionalLicenseVerification.screeningLabel`,
    );

  const label = screeningType
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return t(`${namespace}:addOns.screeningLabels`, { label });
};

// Labels for screenings added to an existing package (customization sections)
export const getScreeningLabel = (screeningType: ScreeningProductType) => {
  switch (screeningType) {
    case ScreeningProductType.INTERNATIONAL_CRIMINAL_SEARCH:
      return t(
        `${namespace}:addOns.international.crimSearch.internationalCrimSearch.screeningLabel.customization`,
      );
    case ScreeningProductType.INTERNATIONAL_GLOBAL_WATCHLIST_SEARCH:
      return t(
        `${namespace}:addOns.international.crimSearch.globalWatchlist.screeningLabel`,
      );
    case ScreeningProductType.INTERNATIONAL_RIGHT_TO_WORK:
      return t(
        `${namespace}:addOns.international.verifications.ukRightToWork.screeningLabel`,
      );
    case ScreeningProductType.INTERNATIONAL_EMPLOYMENT_VERIFICATION:
      return t(
        `${namespace}:addOns.international.verifications.employmentVerification.screeningLabel`,
      );
    case ScreeningProductType.INTERNATIONAL_EDUCATION_VERIFICATION:
      return t(
        `${namespace}:addOns.international.verifications.educationVerification.screeningLabel`,
      );
    case ScreeningProductType.PROFESSIONAL_LICENSE_VERIFICATION:
      return t(
        `${namespace}:addOns.verificationSection.professionalLicenseVerification.screeningLabel`,
      );
    default: {
      const label = screeningType
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return t(`${namespace}:addOns.screeningLabels`, { label });
    }
  }
};
