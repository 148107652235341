import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstanceVersionFactValues } from 'modules/assess/ui/rules/categories/state';
import MultiSelect from 'modules/assess/ui/rules/common/rule/editor/multi-select';
import { SelectItem } from 'modules/assess/ui/rules/common/rule/editor/types';
import { useConditions } from 'modules/assess/ui/rules/common/rule/editor/conditions-context';

const MultiSelectValues = ({ index }: { index: number }) => {
  const { conditions, updateConditionByIndex } = useConditions();

  const { t, i18n } = useTranslation('assess');
  const condition = conditions[index];
  const factValues = useInstanceVersionFactValues(condition);
  const items = factValues?.map((value: string) => {
    const key = `assess:rules.facts.${condition.fact}.values.${value}`;
    return {
      id: value,
      label: i18n.exists(key) ? t(key) : value,
    };
  });

  let selectedItems: SelectItem[] = [];
  if (Array.isArray(condition.value)) {
    selectedItems = condition?.value?.map((id: string) => {
      const valueKey = `assess:rules.facts.${condition.fact}.values.${id}`;
      return {
        id,
        label: i18n.exists(valueKey) ? t(valueKey) : id,
      };
    });
  }

  const onChange = useCallback(
    selectedItems => {
      const value = selectedItems.map((item: SelectItem) => item.id);
      updateConditionByIndex(index, { value });
    },
    [index, updateConditionByIndex],
  );

  return (
    <MultiSelect
      items={items || []}
      onChange={onChange}
      initialSelectedItems={selectedItems}
    />
  );
};

export default MultiSelectValues;
