import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ReviewAndSubmitT } from '..';
import {
  StyledComponentWrapper,
  StyledDot,
  StyledFirstColumn,
  StyledInfoContainer,
  StyledLink,
  StyledSectionTitleTypography,
} from './InfoSection.styles';
import { namespace } from '../../locales';

const InfoSection: React.FC<ReviewAndSubmitT.InfoSectionProps> = ({
  section,
  onEditClick,
  children,
  showDetailsSection,
  handleViewDetailsClick,
  viewDetails,
}) => {
  const { t } = useTranslation(namespace);

  return (
    <StyledInfoContainer>
      <StyledFirstColumn>
        <StyledSectionTitleTypography data-testid={`${section}-section-title`}>
          {t(`reviewAndSubmit.infoSection.${section}`)}
        </StyledSectionTitleTypography>
        <div>
          <StyledLink
            onClick={onEditClick}
            data-testid={`${section}-edit-link`}
          >
            {t(`reviewAndSubmit.edit`)}
          </StyledLink>
          {showDetailsSection && (
            <>
              <StyledDot />
              <StyledLink
                data-testid={`${section}-view-details-button`}
                onClick={handleViewDetailsClick}
              >
                {t(
                  `reviewAndSubmit.${
                    !viewDetails ? 'viewDetails' : 'hideDetails'
                  }`,
                )}
              </StyledLink>
            </>
          )}
        </div>
      </StyledFirstColumn>

      {children && <StyledComponentWrapper>{children}</StyledComponentWrapper>}
    </StyledInfoContainer>
  );
};

export default InfoSection;
