import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledAliasPriceRow = styled.div`
  margin-bottom: 8px;
`;

export const StyledAliasPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTooltip = styled(M.TooltipDefinition)`
  border-bottom: none !important;
  margin-left: 3px;

  .cds--definition-tooltip {
    padding: 1rem;
    max-width: 18rem;
  }
`;

export const StyledHelpIcon = styled(M.Icon)`
  color: ${colors.brandNavy2};
  margin-bottom: -3px;
`;
