import { useMutation } from 'react-query';
import { redeemPromotionCode, validatePromotionCode } from './actions';
import { PromoCodeReqParamsType } from './types';

export const useValidatePromotionCode = (
  params: PromoCodeReqParamsType,
  onSuccess?: Function | null,
  onError?: Function | null,
) => {
  const request = () => validatePromotionCode(params);

  const [call, result] = useMutation(request, {
    onSuccess: data => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: error => {
      if (onError) {
        onError(error);
      }
    },
  });

  const { isLoading, isError, isSuccess, isIdle, data, error, reset } = result;

  return {
    call,
    result,
    isLoading,
    isError,
    isSuccess,
    isIdle,
    data,
    error,
    reset,
  };
};

export const useRedeemPromotionCode = (
  params: PromoCodeReqParamsType,
  onSuccess?: Function | null,
  onError?: Function | null,
) => {
  const request = () => redeemPromotionCode(params);

  const [call, result] = useMutation(request, {
    onSuccess: data => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: error => {
      if (onError) {
        onError(error);
      }
    },
  });

  const { isLoading, isError, isSuccess, isIdle, data, error, reset } = result;

  return {
    call,
    result,
    isLoading,
    isError,
    isSuccess,
    isIdle,
    data,
    error,
    reset,
  };
};
