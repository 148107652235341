import React from 'react';
import {
  CurrentUser,
  GenericObject,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import SortedHeader, { SortDirections } from 'components/SortedHeader';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import { useSearchState, useDispatch } from '../../state/hooks';
import { ActionType } from '../../state/types';

const { ASC, DESC } = SortDirections;

type TableHeaderWithSortProps = {
  currentUser: CurrentUser;
  column: GenericObject;
  isDSP: boolean;
};

const TableHeaderWithSort = ({
  currentUser,
  column,
  isDSP,
}: TableHeaderWithSortProps) => {
  const { view } = useSearchState();
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const sortByColumn =
    view?.filters.order_by === 'reports.completed_at'
      ? 'completed_at'
      : view?.filters.order_by;
  const { header, key } = column;
  const isSortHeader = key === sortByColumn;
  const sortDirection = view?.filters?.sort?.toUpperCase();

  const updateHeaderFilter = (payload: any) => {
    dispatch({
      type: ActionType.UPDATE_FILTERS,
      payload,
    });
  };

  const handleSortClick = useDebouncedCallback(
    () => {
      const order_by = key === 'completed_at' ? 'reports.completed_at' : key;
      const sort = isSortHeader && sortDirection === DESC ? ASC : DESC;

      updateHeaderFilter({ order_by, sort });

      if (currentUser) {
        trackEvent(
          CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_RESULTS_SORTED,
          {
            Column: order_by,
            'Sort Order': sort,
          },
        );
      }
    },
    350,
    [
      currentUser,
      key,
      updateHeaderFilter,
      sortDirection,
      isSortHeader,
      trackEvent,
    ],
  );

  const label = header === 'Created' && isDSP ? 'Created on' : header;

  return (
    <SortedHeader
      isSortHeader={isSortHeader}
      sortDirection={sortDirection}
      onClick={handleSortClick}
      label={label}
    />
  );
};

export default TableHeaderWithSort;
