import { M, colors } from '@dashboard-experience/mastodon';
import { useReportLoading } from 'containers/Report';
import {
  Change,
  Defer,
  Engage,
  NeedsFollowUp,
  PostActions,
  PreAA,
  CancelScreenings,
  DisqualifyButton,
} from 'modules/adjudication/ui';
import { InformationModal } from 'modules/adjudication/ui/decisions';
import { useAdjudicationChangeAllowed } from 'modules/adjudication/utilities';
import React, { memo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

type MatchParams = {
  reportId: string;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr; // Two columns: one for the icon, one for the toggle/actions
  grid-template-rows: auto 1fr; // Two rows: first row for InformationModal/PrimaryActions, second for SecondaryActions
  gap: 12px;
  .mastodon-button {
    margin: 0 !important;
  }

  .adjudication-information-modal {
    grid-column: 1;
  }

  .primary-actions {
    grid-column: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }

  .primary-actions > * {
    margin: 0;
  }

  .secondary-actions {
    display: flex;
    gap: 12px;
    grid-column: 2;
    grid-row: 2;
  }
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 25px;
  background: ${colors.brandSlate2};
`;

const ReportLifeCycleActions = () => {
  const adjudicationChangeButtonsEnabled = useAdjudicationChangeAllowed();
  const isReportLoading = useReportLoading();

  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );
  const reportId = reportMatch?.params?.reportId;

  if (!adjudicationChangeButtonsEnabled || !reportId) return null;
  if (isReportLoading) return <M.LoadingInline />;

  return (
    <Container data-testid='report-lifecycle-actions'>
      <InformationModal />
      <div className='primary-actions'>
        <VerticalDivider />
        <CancelScreenings />
        <PostActions />
        <DisqualifyButton />
        <PreAA />
        <Engage />
        <Change />
      </div>
      <div className='secondary-actions'>
        <Defer />
        <NeedsFollowUp />
      </div>
    </Container>
  );
};

export default memo(ReportLifeCycleActions);
