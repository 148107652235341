import React from 'react';
import { FetchPackageType, PostPackageType } from '@dashboard-experience/utils';
import { UpdateInput } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import PackageCardList from './PackageCardList';
import { getNoPackageFoundMessage } from './SelectPackageUtils';
import type { GetStartedT } from '../GetStartedStep';
import type { SelectPackageT } from '.';
import { StyledPaginationContainer } from './styles/SelectPackageStyles';
import { PACKAGES_PER_PAGE } from './SelectPackageStep.const';

type Props = {
  totalCount: number;
  fetchedPackages: FetchPackageType[];
  hierarchy_present: boolean;
  node: GetStartedT.Node;
  localeType: GetStartedT.Locale;
  params: SelectPackageT.Params;
  handlePaginationClick: (pageIndex: number) => void;
  selectedPackage: PostPackageType;
  update: (values: UpdateInput) => void;
  isManualBulkOrder: boolean;
  showPrice: boolean;
};

const SelectablePackagesList: React.FC<Props> = ({
  totalCount,
  fetchedPackages,
  hierarchy_present,
  node,
  localeType,
  params,
  handlePaginationClick,
  selectedPackage,
  update,
  isManualBulkOrder,
  showPrice,
}) => {
  if (fetchedPackages?.length === 0)
    return (
      <em>{getNoPackageFoundMessage(hierarchy_present, node, localeType)}</em>
    );

  return (
    <>
      <PackageCardList
        packages={fetchedPackages}
        update={update}
        selectedPackage={selectedPackage}
        isManualBulkOrder={isManualBulkOrder}
        showPrice={showPrice}
      />
      {totalCount > PACKAGES_PER_PAGE && (
        <StyledPaginationContainer
          data-testid='packages-pagination'
          pageCount={Math.ceil(totalCount / PACKAGES_PER_PAGE)}
          selectedIndex={params.page - 1}
          onPageClick={handlePaginationClick}
        />
      )}
    </>
  );
};

export default SelectablePackagesList;
