/* eslint-disable react/destructuring-assignment */
import { PARAMS } from 'components/Search';
import { getUrlParams } from 'modules/reports-list/utilities';
import { ActionType, Action, State } from './types';

export const initialState = {
  filters: getUrlParams(),
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.INITIALIZE_FILTERS: {
      if (!state.view) return state;

      const filters = {
        ...PARAMS.default,
        ...state.view.filters,
        ...getUrlParams(),
      };
      // const columns = {}

      return {
        ...state,
        view: { ...state.view, filters },
      };
    }
    case ActionType.UPDATE_FILTERS: {
      if (!state.view) return state;

      const updatedView = {
        ...state.view,
        filters: { ...state.view.filters, ...action.payload, page: 1 },
      };

      return {
        ...state,
        view: updatedView,
      };
    }
    case ActionType.UPDATE_PAGE: {
      if (!state.view) return state;

      const updatedView = {
        ...state.view,
        filters: { ...state.view.filters, page: action.payload },
      };

      return {
        ...state,
        view: updatedView,
      };
    }

    case ActionType.RESET_VIEW: {
      if (!state.view) return state;

      const updatedView = {
        ...action.payload,
        filters: { ...action.payload.filters, page: 1 },
      };

      return {
        ...state,
        view: updatedView,
      };
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Unhandled action type');
      return state;
    }
  }
};

export default reducer;
